import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, Subject, throwError} from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AuthService {

userId: string;


  constructor( private db: AngularFireDatabase, public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe(
    user => { if(user) this.userId = user.uid; }
  )
  }



signInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();

          },
          (error) => {
            reject(error);
          }
        );
      }
    );

}

signOutUser() {
    firebase.auth().signOut();
}





}