export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyC_oNb26jHc0i1vQTe0qFrNkL-AI-28E7I",
        authDomain: "demandes-mssante.firebaseapp.com",
        databaseURL: "https://demandes-mssante.firebaseio.com",
        projectId: "demandes-mssante",
        storageBucket: "demandes-mssante.appspot.com",
        messagingSenderId: "289355510009",
        appId: "1:289355510009:web:48e15513e297181f3e7228"
    }
};