import { Injectable } from '@angular/core';

import { Remarque } from '../models/remarque.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from 'angularfire2/database';
import {switchMap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RemarquesService {
remarques: AngularFireList<any>;

constructor(private database: AngularFireDatabase) {this.remarques = database.list('messages');}

getRemarques() { return this.remarques.snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }

CreateRemarque(newRemarque: Remarque) { this.remarques.push(newRemarque); }




deleteRemarque(key: string){
return this.database.list('/messages').remove(key);
}    
}