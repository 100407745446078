import { Injectable } from '@angular/core';
import { Demandenomi } from '../models/demandenomi.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from 'angularfire2/database';
import {switchMap, map} from 'rxjs/operators';
import { Observable, interval, pipe } from 'rxjs';
declare var Email: any;

@Injectable({
  providedIn: 'root'
})
export class DemandenomisService {
Demandenomis: AngularFireList<any>;

constructor(private database: AngularFireDatabase) {this.Demandenomis = database.list('/Demandes/DemandesBALnominatives');}

getDemandenomis() { return this.Demandenomis.snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }

async CreateDemandenomi(newDemandenomi: Demandenomi) { 
  this.Demandenomis.push(newDemandenomi); 
}




getSingleDemandenomis2(key: string){
    return this.database.object('/Demandes/DemandesBALnominatives/' + key).valueChanges();
   }

getSingleDemandenomis(key: string): Observable<any>  {
   return this.database.list('/Demandes/DemandesBALnominatives/', ref => 
       ref.orderByKey().equalTo(key)).snapshotChanges().pipe(map(actions => {
          return actions.map(a => {
              const data = a.payload.val();
              const payloadKey = a.payload.key;
              return {key: payloadKey, ...data };
          });
   }));
 }





deleteDemandenomis(key: string){
return this.database.list('/Demandes/DemandesBALnominatives').remove(key);
}

UpdateDemandenomi(key: string, PPS){ this.Demandenomis.update(key, PPS); }



}