import { Directive, HostListener } from '@angular/core';


@Directive({
  selector: 'credit-card,[credit-card]'
})
export class CreditCardDirective {

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const inputEl = event.target as HTMLInputElement;
    let text = inputEl.value;
    text = text.replace(/\s+/g, '');
    if (text.length > 10) {
      text = text.substr(0, 9);
    }
    const num = [];
    for (let i = 0; i < text.length; i += 2) {
      num.push(text.substr(i, 2));
    }
    inputEl.value = num.join(' ');
  }
}