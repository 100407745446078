import { Injectable } from '@angular/core';
import { Demandenomi } from '../models/demandenomi.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from 'angularfire2/database';
import {switchMap, map} from 'rxjs/operators';
import { Observable, interval, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DemandenomisvService {
Demandenomis: AngularFireList<any>;

constructor(private database: AngularFireDatabase) {this.Demandenomis = database.list('/balok/DemandesBALnominatives');}

getDemandenomis() { return this.Demandenomis.snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }

CreateDemandenomi(newDemandenomi: Demandenomi) { this.Demandenomis.push(newDemandenomi); }

getSingleDemandenomis(key: string): Observable<any>  {
   return this.database.list('/balok/DemandesBALnominatives', ref => 
       ref.orderByKey().equalTo(key)).snapshotChanges().pipe(map(actions => {
          return actions.map(a => {
              const data = a.payload.val();
              const payloadKey = a.payload.key;
              return {key: payloadKey, ...data };
          });
   }));
 }



}