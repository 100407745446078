import { Injectable } from '@angular/core';
import { Demandenomi } from '../models/demandenomi.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject, SnapshotAction } from 'angularfire2/database';
import { switchMap, map } from 'rxjs/operators';
import { Observable, interval, pipe } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DemandesexportService {
  Demandenomis: AngularFireList<any>;

  constructor(private database: AngularFireDatabase) { this.Demandenomis = database.list('/Fileattente'); }

  getDemandenomis() {
    return this.Demandenomis.snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.val();
        const key = a.payload.key;
        return { key, ...data };
      });
    }));
  }

  getDateCreation(): Promise<string> { 
    return new Promise((resolve, reject) => {
      this.database.database.ref().child("MessageImport").once("value", (a: firebase.database.DataSnapshot, b?: string) => {
        resolve(a.val());
      });
    });
  }

  
  lancerCreation() {
    this.database.database.ref().update({'gotoImport': true});
    this.database.database.ref().child('gotoImport').on('value', (snap) => {
      console.log(snap);
    });
  }

}