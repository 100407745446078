import { Injectable } from '@angular/core';
import { Demandeorga } from '../models/demandeorga.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { switchMap, map } from 'rxjs/operators';
import { Observable, interval, pipe } from 'rxjs';
declare var Email: any;

@Injectable({
  providedIn: 'root'
})
export class DemandeorgasService {
  Demandeorgas: AngularFireList<any>;

  constructor(private database: AngularFireDatabase) { this.Demandeorgas = database.list('/Demandes/DemandesBALorga'); }

  getDemandeorgas() {
    return this.Demandeorgas.snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.val();
        const key = a.payload.key;
        return { key, ...data };
      });
    }));
  }

  async CreateDemandeorga(newDemandeorga: Demandeorga) {
    this.Demandeorgas.push(newDemandeorga);
  }


  getSingleDemandeorgas2(key: string) {
    return this.database.object('/Demandes/DemandesBALorga/' + key).valueChanges();
  }





  getSingleDemandeorgas(key: string): Observable<any> {
    return this.database.list('/Demandes/DemandesBALorga/', ref =>
      ref.orderByKey().equalTo(key)).snapshotChanges().pipe(map(actions => {
        return actions.map(a => {
          const data = a.payload.val();
          const payloadKey = a.payload.key;
          return { key: payloadKey, ...data };
        });
      }));
  }

  deleteDemandeorgas(key: string) {
    return this.database.list('/Demandes/DemandesBALorga').remove(key);
  }


  UpdateDemandeorga(key: string, PPS) { this.Demandeorgas.update(key, PPS); }

}