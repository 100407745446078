import { Component, OnInit,ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { DemandenomisService } from '../services/demandenomis.service';
import { DemandenomisfService } from '../services/demandenomisf.service';
import { DemandenomisdService } from '../services/demandenomisd.service';
import { DemandenomisvService } from '../services/demandenomisv.service';
import { DemandeorgasService } from '../services/demandeorgas.service';
import { DemandeorgasfService } from '../services/demandeorgasf.service';
import { DemandeorgasdService } from '../services/demandeorgasd.service';
import { DemandeorgasvService } from '../services/demandeorgasv.service';
import { DemandesexportService } from '../services/demandesexport.service';
import { FormControl, FormControlName, FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule,FormArray } from '@angular/forms'; 
import { Demandenomi } from '../models/demandenomi.model';
import {AppService} from '../services/app.service';
import {switchMap, debounceTime, tap, finalize} from 'rxjs/operators';
import {Observable} from 'rxjs'
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Demandeorga } from '../models/demandeorga.model';
import { of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { RemarquesService } from '../services/remarque.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private router: Router, private remarquesService: RemarquesService,public authService: AuthService, public demandenomisService: DemandenomisService,public demandenomisfService: DemandenomisfService,public demandenomisdService: DemandenomisdService,public demandeorgasService: DemandeorgasService,public demandeorgasfService: DemandeorgasfService,public demandeorgasdService: DemandeorgasdService,public demandesexportService: DemandesexportService,public demandenomisvService: DemandenomisvService,public demandeorgasvService: DemandeorgasvService) { }


onNomiClick(key : string) { this.router.navigate(['/modifnomi',key]); }
modiforga(key : string) { this.router.navigate(['/modiforga',key]); }


  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }



validnomi(key : string) {

  this.d2todisplay = 
    this.demandenomisService.getSingleDemandenomis(key).subscribe(val => 

    {const civ = val[0].civ;  
     const datedemande = val[0].datedemande; 
     const id = val[0].id; 
     const idtype = val[0].idtype; 
     const lieuexercice = val[0].lieuexercice; 
     const mail = val[0].mail; 
     const nom = val[0].nom; 
     const prenom = val[0].prenom; 
     const profession = val[0].profession; 
     const tel = val[0].tel; 

    const newDemandenomi = new Demandenomi (datedemande,civ,prenom,nom,profession,lieuexercice,idtype,id,mail,tel);
    this.demandenomisfService.CreateDemandenomi(newDemandenomi);



     }
  );
this.demandenomisService.deleteDemandenomis(key);

}

validorga(key : string) {

  this.d4todisplay = 
    this.demandeorgasService.getSingleDemandeorgas(key).subscribe(val => 

    {const datedemande = val[0].datedemande; 
     const demandeur = val[0].demandeur; 
     const email_demandeur = val[0].email_demandeur;
     const tel_demandeur = val[0].tel_demandeur;
     const nom_structure = val[0].nom_structure; 
     const responsable_structure = val[0].responsable_structure; 
     const idtype = val[0].idtype;
     const id = val[0].id;
     const mssante = val[0].mssante; 
     const administrateur_orga = val[0].administrateur_orga; 
     const email_principal = val[0].email_principal; 
     var comptes_délégués='';
     if (val[0].comptes_delegues !=null) { comptes_délégués=val[0].comptes_delegues} ;
     const email_structure = val[0].email_structure; 
     const tel_structure = val[0].tel_structure; 
     const fonction_demandeur = val[0].fonction_demandeur; 
     
     const newDemandeorga = new Demandeorga (datedemande,
demandeur,
email_demandeur,
tel_demandeur,
nom_structure,
responsable_structure,
idtype,
id,
mssante,
administrateur_orga,
email_principal,
comptes_délégués,
email_structure,
tel_structure,
fonction_demandeur);
    this.demandeorgasfService.CreateDemandeorga(newDemandeorga);



     }
  );
this.demandeorgasService.deleteDemandeorgas(key);

}



validorgaf(key : string) {

  this.d3todisplay = 
    this.demandeorgasfService.getSingleDemandeorgas(key).subscribe(val => 

    {const datedemande = val[0].datedemande; 
     const demandeur = val[0].demandeur; 
     const email_demandeur = val[0].email_demandeur; 
     const tel_demandeur = val[0].tel_demandeur; 
     const nom_structure = val[0].nom_structure; 
     const responsable_structure = val[0].responsable_structure; 
     const idtype = val[0].idtype; 
     const id = val[0].id; 
     const mssante = val[0].mssante; 
     const administrateur_orga = val[0].administrateur_orga; 
     const email_principal = val[0].email_principal; 
     var comptes_délégués='';
     if (val[0].comptes_delegues !=null) { comptes_délégués=val[0].comptes_delegues; console.log(comptes_délégués)} ;
     const email_structure = val[0].email_structure; 
     const tel_structure = val[0].tel_structure; 
     const fonction_demandeur = val[0].fonction_demandeur; 
     
     const newDemandeorga = new Demandeorga (datedemande,
demandeur,
email_demandeur,
tel_demandeur,
nom_structure,
responsable_structure,
idtype,
id,
mssante,
administrateur_orga,
email_principal,
comptes_délégués,
email_structure,
tel_structure,
fonction_demandeur);
    this.demandeorgasService.CreateDemandeorga(newDemandeorga);



     }
  );
this.demandeorgasfService.deleteDemandeorgas(key);

}


validnomif(key : string) {

  this.d1todisplay = 
    this.demandenomisfService.getSingleDemandenomis(key).subscribe(val => 

    {const civ = val[0].civ; 
     const datedemande = val[0].datedemande; 
     const id = val[0].id; 
     const idtype = val[0].idtype; 
     const lieuexercice = val[0].lieuexercice; 
     const mail = val[0].mail; 
     const nom = val[0].nom; 
     const prenom = val[0].prenom; 
     const profession = val[0].profession; 
     const tel = val[0].tel; 

    const newDemandenomi = new Demandenomi (datedemande,civ,prenom,nom,profession,lieuexercice,idtype,id,mail,tel);
    this.demandenomisService.CreateDemandenomi(newDemandenomi);



     }
  );
this.demandenomisfService.deleteDemandenomis(key);

}


deletenomi(key : string) {

  this.d5todisplay = 
    this.demandenomisService.getSingleDemandenomis(key).subscribe(val => 

    {const civ = val[0].civ; 
     const datedemande = val[0].datedemande; 
     const id = val[0].id; 
     const idtype = val[0].idtype; 
     const lieuexercice = val[0].lieuexercice; 
     const mail = val[0].mail; 
     const nom = val[0].nom; 
     const prenom = val[0].prenom; 
     const profession = val[0].profession; 
     const tel = val[0].tel; 
    const newDemandenomi = new Demandenomi (datedemande,civ,prenom,nom,profession,lieuexercice,idtype,id,mail,tel);
    this.demandenomisdService.CreateDemandenomi(newDemandenomi);
     }
  );
this.demandenomisService.deleteDemandenomis(key);
}

deleteorga(key : string) {

  this.d6todisplay = 
    this.demandeorgasService.getSingleDemandeorgas(key).subscribe(val => 

    {const datedemande = val[0].datedemande; 
     const demandeur = val[0].demandeur; 
     const email_demandeur = val[0].email_demandeur; 
     const tel_demandeur = val[0].tel_demandeur; 
     const nom_structure = val[0].nom_structure; 
     const responsable_structure = val[0].responsable_structure; 
     const idtype = val[0].idtype; 
     const id = val[0].id; 
     const mssante = val[0].mssante; 
     const administrateur_orga = val[0].administrateur_orga; 
     const email_principal = val[0].email_principal; 
     var comptes_délégués='';
     if (val[0].comptes_delegues !=null) { comptes_délégués=val[0].comptes_delegues; console.log(comptes_délégués)} ;
     const email_structure = val[0].email_structure; 
     const tel_structure = val[0].tel_structure; 
     const fonction_demandeur = val[0].fonction_demandeur; 
     
     const newDemandeorga = new Demandeorga (datedemande,
demandeur,
email_demandeur,
tel_demandeur,
nom_structure,
responsable_structure,
idtype,
id,
mssante,
administrateur_orga,
email_principal,
comptes_délégués,
email_structure,
tel_structure,
fonction_demandeur);
    this.demandeorgasdService.CreateDemandeorga(newDemandeorga);



     }
  );
this.demandeorgasService.deleteDemandeorgas(key);

}

validexportnomi(key : string) {

  this.d9todisplay = 
    this.demandenomisfService.getSingleDemandenomis(key).subscribe(val => 

    {const civ = val[0].civ; 
     const datedemande = val[0].datedemande; 
     const id = val[0].id; 
     const idtype = val[0].idtype; 
     const lieuexercice = val[0].lieuexercice; 
     const mail = val[0].mail; 
     const nom = val[0].nom; 
     const prenom = val[0].prenom; 
     const profession = val[0].profession; 
     const tel = val[0].tel; 

    const newDemandenomi = new Demandenomi (datedemande,civ,prenom,nom,profession,lieuexercice,idtype,id,mail,tel);
    this.demandenomisvService.CreateDemandenomi(newDemandenomi);



     }
  );
this.demandenomisfService.deleteDemandenomis(key);

}

validexportorga(key : string) {

  this.d10todisplay = 
    this.demandeorgasfService.getSingleDemandeorgas(key).subscribe(val => 

    {const datedemande = val[0].datedemande; 
     const demandeur = val[0].demandeur; 
     const email_demandeur = val[0].email_demandeur; 
     const tel_demandeur = val[0].tel_demandeur; 
     const nom_structure = val[0].nom_structure; 
     const responsable_structure = val[0].responsable_structure; 
     const idtype = val[0].idtype; 
     const id = val[0].id; 
     const mssante = val[0].mssante; 
     const administrateur_orga = val[0].administrateur_orga; 
     const email_principal = val[0].email_principal; 
     var comptes_délégués='';
     if (val[0].comptes_delegues !=null) { comptes_délégués=val[0].comptes_delegues; console.log(comptes_délégués)} ;
     const email_structure = val[0].email_structure; 
     const tel_structure = val[0].tel_structure; 
     const fonction_demandeur = val[0].fonction_demandeur; 
     
     const newDemandeorga = new Demandeorga (datedemande,
demandeur,
email_demandeur,
tel_demandeur,
nom_structure,
responsable_structure,
idtype,
id,
mssante,
administrateur_orga,
email_principal,
comptes_délégués,
email_structure,
tel_structure,
fonction_demandeur);
    this.demandeorgasvService.CreateDemandeorga(newDemandeorga);



     }
  );
this.demandeorgasfService.deleteDemandeorgas(key);

}







undeletenomi(key : string) {

  this.d7todisplay = 
    this.demandenomisdService.getSingleDemandenomis(key).subscribe(val => 

    {const civ = val[0].civ; 
     const datedemande = val[0].datedemande; 
     const id = val[0].id; 
     const idtype = val[0].idtype; 
     const lieuexercice = val[0].lieuexercice; 
     const mail = val[0].mail; 
     const nom = val[0].nom; 
     const prenom = val[0].prenom; 
     const profession = val[0].profession; 
     const tel = val[0].tel; 

    const newDemandenomi = new Demandenomi (datedemande,civ,prenom,nom,profession,lieuexercice,idtype,id,mail,tel);
    this.demandenomisService.CreateDemandenomi(newDemandenomi);



     }
  );
this.demandenomisdService.deleteDemandenomis(key);

}

undeleteorga(key : string) {

  this.d8todisplay = 
    this.demandeorgasdService.getSingleDemandeorgas(key).subscribe(val => 

    {const datedemande = val[0].datedemande; 
     const demandeur = val[0].demandeur; 
     const email_demandeur = val[0].email_demandeur; 
     const tel_demandeur = val[0].tel_demandeur; 
     const nom_structure = val[0].nom_structure; 
     const responsable_structure = val[0].responsable_structure; 
     const idtype = val[0].idtype; 
     const id = val[0].id; 
     const mssante = val[0].mssante; 
     const administrateur_orga = val[0].administrateur_orga; 
     const email_principal = val[0].email_principal; 
     var comptes_délégués='';
     if (val[0].comptes_delegues !=null) { comptes_délégués=val[0].comptes_delegues; console.log(comptes_délégués)} ;
     const email_structure = val[0].email_structure; 
     const tel_structure = val[0].tel_structure; 
     const fonction_demandeur = val[0].fonction_demandeur; 
     
     const newDemandeorga = new Demandeorga (datedemande,
demandeur,
email_demandeur,
tel_demandeur,
nom_structure,
responsable_structure,
idtype,
id,
mssante,
administrateur_orga,
email_principal,
comptes_délégués,
email_structure,
tel_structure,
fonction_demandeur);
    this.demandeorgasService.CreateDemandeorga(newDemandeorga);



     }
  );
this.demandeorgasdService.deleteDemandeorgas(key);

}




demandesorgavtodisplay;
demandesnomivtodisplay;
demandesexporttodisplay;
demandesnomidtodisplay;
demandesorgadtodisplay;
remarques;
remarquestodisplay;
d10todisplay;
d9todisplay;
d8todisplay;
d7todisplay;
d6todisplay;
d5todisplay;
d4todisplay;
d3todisplay;
d2todisplay;
d1todisplay;
demandesnomiftodisplay;
demandesorgaftodisplay;
demandesnomitodisplay ;
demandesorgatodisplay ;
finesstodisplay;
nomiForm: FormGroup;
nominatif;
orga;
search;
circs3 = [
{value: "FINESS" , viewValue:  "FINESS"},
{value: "SIRET" , viewValue:  "SIRET"},
{value: "SIREN" , viewValue:  "SIREN"},
];




Data = { 
    datedemande:'',
    prenom:'',
    nom:'',
    id: '',
    idtype:''
     }

displayedColumns = [
'datedemande',
'prenom',
'idtype',
'id'
];

selectnomi;

dataSource = new MatTableDataSource();

applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase(); 
    this.dataSource.filter = filterValue;
    }

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;




selectedRowIndex: number = -1;

highlight(row){
    this.selectedRowIndex = row.id;
    }

rowClicked(element): void 
  {
  this.selectnomi=element.id;
  console.log(this.selectnomi);
  }




Data2 = { 
    datedemande:'',
    mssante:'',
    id: '',
    idtype:''
     }

displayedColumns2 = [
'datedemande',
'mssante',
'idtype',
'id'
];

selectorga;

dataSource2 = new MatTableDataSource();

applyFilter2(filterValue2: string) {
    filterValue2 = filterValue2.trim(); 
    filterValue2 = filterValue2.toLowerCase(); 
    this.dataSource2.filter = filterValue2;
    }

@ViewChild('MatPaginator2') paginator2: MatPaginator;
@ViewChild('MatSort2') sort2: MatSort;




selectedRowIndex2: number = -1;

highlight2(row){
    this.selectedRowIndex = row.id;
    }

rowClicked2(element): void 
  {
  this.selectorga=element.id;
  console.log(this.selectorga);
  }


kill(key: string) {this.remarquesService.deleteRemarque(key);}



ngAfterViewInit() {
this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;
this.demandenomisvService.getDemandenomis().subscribe(res => {this.dataSource.data = res; });

this.dataSource2.paginator = this.paginator2;
this.dataSource2.sort = this.sort2;
this.demandeorgasvService.getDemandeorgas().subscribe(res => {this.dataSource2.data = res; });



}






































ngOnInit() {
this.remarques=false;
this.nominatif=false; 
this.orga=false; 
this.search=false;
this.demandesnomitodisplay = this.demandenomisService.getDemandenomis();
this.demandesorgatodisplay = this.demandeorgasService.getDemandeorgas();
this.demandesnomiftodisplay = this.demandenomisfService.getDemandenomis();
this.demandesexporttodisplay = this.demandesexportService.getDemandenomis();
this.demandesorgaftodisplay = this.demandeorgasfService.getDemandeorgas();
this.demandesorgavtodisplay = this.demandeorgasvService.getDemandeorgas();
this.demandesnomidtodisplay = this.demandenomisdService.getDemandenomis();
this.demandesnomivtodisplay = this.demandenomisvService.getDemandenomis();
this.demandesorgadtodisplay = this.demandeorgasdService.getDemandeorgas();
this.remarquestodisplay = this.remarquesService.getRemarques();
this.getDateCreation();


}


















onSignOut() { this.authService.signOutUser(); }
onClickNomi() { this.nominatif=true;this.orga=false;this.search=false; this.remarques=false; }
onClickOrga() { this.orga=true;this.nominatif=false;this.search=false; this.remarques=false; }
onClickSearch() { this.search=true; this.orga=false;this.nominatif=false;this.remarques=false;  }
onClickMessage() {this.search=false; this.orga=false; this.nominatif=false; this.remarques=true; }
public isCreationLancee = false;
lancerCreation() { 
  this.isCreationLancee = true;
  this.demandesexportService.lancerCreation();
}

dateCreation: string = "";
async getDateCreation() { 
  this.dateCreation = await this.demandesexportService.getDateCreation();
}
}