import {Ppsplan} from '../models/ppsplan.model';
import {Subject} from 'rxjs/Subject';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class PPStographService {
ppsplansChanged = new Subject<Ppsplan[]>();
  startedEditing=new Subject<number>();
  private ppsplans:Ppsplan[]=[
 
  ];
  getPpsplans(){
    return this.ppsplans.slice();
  }

  getPpsplan(index:number){
    return this.ppsplans[index];

}


  addPpsplan(ppsplan:Ppsplan){
    this.ppsplans.push(ppsplan);
    this.ppsplansChanged.next(this.ppsplans.slice());
  }

  addPpsplans(ppsplans:Ppsplan[]){
    this.ppsplans.push(...ppsplans);
    this.ppsplansChanged.next(this.ppsplans.slice());
  }

  updatePpsplan(index:number,newPpsplan:Ppsplan){
    this.ppsplans[index]=newPpsplan;
    this.ppsplansChanged.next(this.ppsplans.slice());
  }
  
  deletePpsplan(index:number){
    this.ppsplans.splice(index,1);
    this.ppsplansChanged.next(this.ppsplans.slice());
  }



   deleteAllPpsplan(){
    this.ppsplans= [];
    this.ppsplansChanged.next(this.ppsplans.slice());
  }
}
