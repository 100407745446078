import { Injectable, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Doc } from '../models/doc.model';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import { Observable, interval, pipe } from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class DocsService {

  constructor(private database: AngularFireDatabase) {
  this.diagdocs = database.list('diagdocs');
  this.ppsdocs = database.list('ppsdocs');
  this.ppsjoindocs = database.list('ppsjoindocs');
  this.analysemoldocs = database.list('analysemoldocs');
  this.examendocs = database.list('examendocs');
  this.traitementdocs = database.list('traitementdocs');
  this.antecentdocs = database.list('antecentdocs');
  this.etatcliniquedocs = database.list('etatcliniquedocs');
  this.anapthdocs = database.list('anapthdocs');
  this.synthesedocs  = database.list('synthesedocs');;
  this.sosdocs  = database.list('sosdocs');
  this.annoncedocs  = database.list('annoncedocs');




    }

diagdocs: AngularFireList<any>;
ppsdocs: AngularFireList<any>;
ppsjoindocs: AngularFireList<any>;
analysemoldocs: AngularFireList<any>;
examendocs: AngularFireList<any>;
traitementdocs: AngularFireList<any>;
antecentdocs: AngularFireList<any>;
etatcliniquedocs: AngularFireList<any>;
anapthdocs: AngularFireList<any>;
synthesedocs : AngularFireList<any>;
sosdocs : AngularFireList<any>;
annoncedocs : AngularFireList<any>;

CreateDiagDoc(newDoc: Doc) { this.diagdocs.push(newDoc); }
CreateAnalysemolDoc(newDoc: Doc) { this.analysemoldocs.push(newDoc); }
CreatePpsDoc(newDoc: Doc) { this.ppsdocs.push(newDoc); }
CreateJoinPpsDoc(newDoc: Doc) { this.ppsjoindocs.push(newDoc); }

CreateExamenDoc(newDoc: Doc) { this.examendocs.push(newDoc); }
CreateTraitementDoc(newDoc: Doc) { this.traitementdocs.push(newDoc); }
CreateAntecentDoc(newDoc: Doc) { this.antecentdocs.push(newDoc); }
CreateEtatcliniqueDoc(newDoc: Doc) { this.etatcliniquedocs.push(newDoc); }
CreateAnapthDoc(newDoc: Doc) { this.anapthdocs.push(newDoc); }
CreateSyntheseDoc(newDoc: Doc) { this.synthesedocs.push(newDoc); }
CreateSosDoc(newDoc: Doc) { this.sosdocs.push(newDoc); }
CreateAnnonceDoc(newDoc: Doc) { this.annoncedocs.push(newDoc); }



getAnalysemolDocByPatientid(Patientid: string){
return this.database.list('/analysemoldocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getExamenDocByPatientid(Patientid: string){
return this.database.list('/examendocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getTraitementDocByPatientid(Patientid: string){
return this.database.list('/traitementdocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getAntecentDocByPatientid(Patientid: string){
return this.database.list('/antecentdocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getEtatcliniqueDocByPatientid(Patientid: string){
return this.database.list('/etatcliniquedocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getAnapthDocByPatientid(Patientid: string){
return this.database.list('/anapthdocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getSyntheseDocByPatientid(Patientid: string){
return this.database.list('/synthesedocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getSosDocByPatientid(Patientid: string){
return this.database.list('/sosdocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}

getAnnonceDocByPatientid(Patientid: string){
return this.database.list('/annoncedocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();}





getDiagDocByPatientid(Patientid: string){
return this.database.list('/diagdocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();
}





getPpsDocByPatientid(Patientid: string){
return this.database.list('/ppsdocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();
}

getPpsJoinDocByPatientid(Patientid: string){
return this.database.list('/ppsjoindocs', ref => ref.orderByChild("Patientid").equalTo(Patientid)).valueChanges();
}


}
