import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DemandenomisService } from '../services/demandenomis.service';
import { FormControl, FormControlName, FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule, FormArray } from '@angular/forms';
import { Demandenomi } from '../models/demandenomi.model';

@Component({
  selector: 'app-nomimodif',
  templateUrl: './nomimodif.component.html',
  styleUrls: ['./nomimodif.component.scss']
})
export class NomimodifComponent implements OnInit {

  key;
  demandeToDisplay;
  ppsForm: FormGroup;

  showSpinner;


  constructor(private formBuilder: FormBuilder, public demandenomisService: DemandenomisService, private route: ActivatedRoute, private router: Router) { this.initForm(); }

  ngOnInit() {
    this.showSpinner = true;
    this.route.params.forEach((urlParameters) => {
      this.key = urlParameters['id'];
    });


    this.demandeToDisplay = this.demandenomisService.getSingleDemandenomis2(this.key)
      .subscribe(res => {
        this.demandeToDisplay = res;
        this.ppsForm.controls['civ'].setValue(this.demandeToDisplay.civ);
        this.ppsForm.controls['id'].setValue(this.demandeToDisplay.id);
        this.ppsForm.controls['idtype'].setValue(this.demandeToDisplay.idtype);
        this.ppsForm.controls['datedemande'].setValue(this.demandeToDisplay.datedemande);
        this.ppsForm.controls['lieuexercice'].setValue(this.demandeToDisplay.lieuexercice);
        this.ppsForm.controls['mail'].setValue(this.demandeToDisplay.mail);
        this.ppsForm.controls['nom'].setValue(this.demandeToDisplay.nom);
        this.ppsForm.controls['prenom'].setValue(this.demandeToDisplay.prenom);
        this.ppsForm.controls['profession'].setValue(this.demandeToDisplay.profession);
        this.ppsForm.controls['tel'].setValue(this.demandeToDisplay.tel);

      });
  }

  initForm(): void {

    this.ppsForm = this.formBuilder.group({


      civ: [''],
      id: [''],
      idtype: [''],
      datedemande: [''],
      lieuexercice: [''],
      mail: [''],
      nom: [''],
      prenom: [''],
      profession: [''],
      tel: ['']


    });
  }


  onSubmitForm() {

    const datedemande = this.ppsForm.get('datedemande').value;
    const civ = this.ppsForm.get('civ').value;
    const prenom = this.ppsForm.get('prenom').value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    const nom = this.ppsForm.get('nom').value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    const profession = this.ppsForm.get('profession').value;
    const lieuexercice = this.ppsForm.get('lieuexercice').value;
    const idtype = this.ppsForm.get('idtype').value;
    const id = this.ppsForm.get('id').value;
    const mail = this.ppsForm.get('mail').value;
    const tel = this.ppsForm.get('tel').value;


    const newDemandenomi = new Demandenomi(datedemande, civ, prenom, nom, profession, lieuexercice, idtype, id, mail, tel);
    this.demandenomisService.UpdateDemandenomi(this.key, newDemandenomi);
    this.router.navigate(['admin']);

  }


  goback() { this.router.navigate(['admin']); }



}
