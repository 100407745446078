import { Component, OnDestroy, OnInit, Inject, ViewChild, ChangeDetectorRef, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepickerModule, MatFormFieldModule, } from '@angular/material';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { FormControl, FormControlName, FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList, AngularFireObject, AngularFireAction } from 'angularfire2/database';
import { AngularFireStorage } from 'angularfire2/storage';
import { NgModel } from '@angular/forms';
import { MatIconRegistry } from "@angular/material/icon";
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { AuthService } from '../services/auth.service';
import { RemarquesService } from '../services/remarque.service';
import { DemandenomisService } from '../services/demandenomis.service';
import { Remarque } from '../models/remarque.model';
import { Demandenomi } from '../models/demandenomi.model';
import { HostListener } from '@angular/core'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Doc } from '../models/doc.model';
import { DocsService } from '../services/docs.service';
import { AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { finalize } from 'rxjs/operators';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { startWith } from 'rxjs/operators/startWith';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Demandeorga } from '../models/demandeorga.model';
import { DemandeorgasService } from '../services/demandeorgas.service';
import { Ppsplan } from './../models/ppsplan.model';
import { PPStographService } from './../services/ppstograph.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})


export class LiveComponent implements OnInit {


  constructor(public authService: AuthService, private database: AngularFireDatabase, private storage: AngularFireStorage, private router: Router,
    public dialog: MatDialog) { }







  ngOnInit() {
  }


  openDialog(): void {
    let dialogRef = this.dialog.open(ContactComponent, { width: '800px' });
  }

  openDialog2(): void {
    let dialogRef = this.dialog.open(TelComponent, { width: '500px' });
  }

  openDialogNom(): void {
    let dialogRef = this.dialog.open(InscrinominativeComponent, { width: '1000px' });
  }

  openDialogOrg(): void {
    let dialogRef = this.dialog.open(InscriorgaComponent, { width: '1500px' });
  }

}



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})

export class ContactComponent implements OnInit {




  circs2 = [
    { value: "Messagerie personnelle", viewValue: "Messagerie personnelle" },
    { value: "Messagerie organisationnelle", viewValue: "Messagerie organisationnelle" },
    { value: "Messagerie applicative", viewValue: "Messagerie applicative" },


  ];


  geoForm: FormGroup;


  constructor(private formBuilder: FormBuilder, private remarquesService: RemarquesService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContactComponent>,
  ) {




  }

  getErrorEmail() {
    return this.geoForm.get('email').hasError('required') ? 'Le champs est requis' :
      this.geoForm.get('email').hasError('pattern') ? "L'adresse email n'est pas valide" :
        '';
  }

  ngOnInit() {








    this.initForm();

  }

  initForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.geoForm = this.formBuilder.group
      ({
        email: [null, Validators.compose([Validators.required, Validators.pattern(emailregex)])],
        tel: ['', Validators.required],
        nomSos: ['', Validators.required],
        descriptionSos: ['', Validators.required],






      });
  }
  onSaveGeo() {
    const email = this.geoForm.get('email').value;
    const tel = this.geoForm.get('tel').value;
    const nomSos = this.geoForm.get('nomSos').value;
    const descriptionSos = this.geoForm.get('descriptionSos').value;
    const Dateadd = Date.now().toString();

    const newRemarque = new Remarque(nomSos, descriptionSos, Dateadd, email, tel);
    this.remarquesService.CreateRemarque(newRemarque);
    this.dialogRef.close();

  }

  sanitize(str) {
    if (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    }
    return str;
  }
}



@Component({
  selector: 'app-tel',
  templateUrl: './tel.component.html',
})

export class TelComponent implements OnInit {


  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<TelComponent>) { }


  ngOnInit() {
  }







}
@Component({
  selector: 'app-tel2',
  templateUrl: './tel2.component.html',
})

export class Tel2Component implements OnInit {


  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<Tel2Component>) { }


  ngOnInit() { }


}





@Component({
  selector: 'app-inscrinominative',
  templateUrl: './inscrinominative.component.html',
})

export class InscrinominativeComponent implements OnInit {












  me;
  me0;
  me1;
  me2;
  me3;
  me4;
  me5;
  me6;
  me7;
  me8;
  me9;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  url: string;
  patientid: string;
  nomfichier: string;


  circs3 = [
    { value: "ADELI", viewValue: "ADELI" },
    { value: "RPPS", viewValue: "RPPS" },
  ];

  circs5 = [
    { value: "Monsieur", viewValue: "Monsieur" },
    { value: "Madame", viewValue: "Madame" }

  ];

  circs4 = [
    { value: "Audio-prothésiste", viewValue: "Audio-prothésiste" },
    { value: "Chirurgien-Dentiste", viewValue: "Chirurgien-Dentiste" },
    { value: "Diététicien", viewValue: "Diététicien" },
    { value: "Ergothérapeute", viewValue: "Ergothérapeute" },
    { value: "Infirmier", viewValue: "Infirmier" },
    { value: "Manipulateur ERM", viewValue: "Manipulateur ERM" },
    { value: "Masseur-Kiné", viewValue: "Masseur-Kiné" },
    { value: "Médecin", viewValue: "Médecin" },
    { value: "Orthophoniste", viewValue: "Orthophoniste" },
    { value: "Orthoprothésiste", viewValue: "Orthoprothésiste" },
    { value: "Orthoptiste", viewValue: "Orthoptiste" },
    { value: "Pédicure-Podologue", viewValue: "Pédicure-Podologue" },
    { value: "Pharmacien", viewValue: "Pharmacien" },
    { value: "Psychomotricien", viewValue: "Psychomotricien" },
    { value: "Sage-Femme", viewValue: "Sage-Femme" },
    { value: "Autre profession", viewValue: "Autre profession" }





  ];



  selected: any;
  selected2: any;
  selected3: any;


  constructor(private route: ActivatedRoute,
    private demandenomisService: DemandenomisService,
    private docsService: DocsService,
    private location: Location,
    private storage: AngularFireStorage,
    private router: Router, public dialog: MatDialog,
    public dialogRef: MatDialogRef<TelComponent>, private _formBuilder: FormBuilder) { }

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;


  validateType(c: FormGroup) {
    const idtype = c.get('Ctrl4').value;
    let isValid = true;
    if (idtype && c.get('Ctrl5').value) { 
      switch (idtype) {
        case 'ADELI':
          if (c.get('Ctrl5').value.toString().length !== 9) {
            isValid = false;
          }
        break;
        case 'RPPS':
          if (c.get('Ctrl5').value.toString().length !== 11) {
            isValid = false;
          }
        break;
        case 'FINESS':
          if (c.get('Ctrl5').value.toString().length !== 9) {
            isValid = false;
          }
        break;
        case 'SIRET':
          if (c.get('Ctrl5').value.toString().length !== 14) {
            isValid = false;
          }
        break;
        case 'SIREN':
          if (c.get('Ctrl5').value.toString().length !== 9) {
            isValid = false;
          }
        break;
        default: 
          isValid = false;
      }
    } else {
      isValid = false;
    }

    if (!isValid) {
      c.controls['Ctrl5'].setErrors({typeInvalid: true});
    }
    
    return isValid ? null : {
      'typeInvalid': true
    };
  }

  uploadFile(event) {
    const Patientid = this.patientid;
    const file = event.target.files[0];
    const filePath = Date.now().toString();
    const fileRef = this.storage.ref('/examens/' + filePath);
    const task = this.storage.upload('/examens/' + filePath, file);
    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.nomfichier = file.name;
        this.downloadURL.subscribe(url => this.url = url
        )

      }))
      .subscribe();

  }





  getErrorEmail() {
    return this.secondFormGroup.get('Ctrl6').hasError('required') ? 'Le champs est requis' :
      this.secondFormGroup.get('Ctrl6').hasError('pattern') ? "L'adresse email n'est pas valide" :
        '';
  }






  ngOnInit() {





    this.firstFormGroup = this._formBuilder.group({
      CtrlO: ['', Validators.required],
      Ctrl1: ['', Validators.required],
      Ctrl2: ['', Validators.required],
      Ctrl3: ['', Validators.required],
      Ctrl8: ['', Validators.required],
      Ctrl4: ['', Validators.required],
      Ctrl5: ['', Validators.required],
    }, {
      validators: this.validateType
    });
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.secondFormGroup = this._formBuilder.group({
      Ctrl6: [null, Validators.compose([Validators.required, Validators.pattern(emailregex)])],
      Ctrl7: ['', Validators.required]

    });


  }

  action() {
    const datedemande = Date.now().toString();
    const civ = this.firstFormGroup.get('CtrlO').value;
    const prenom = this.firstFormGroup.get('Ctrl1').value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    const nom = this.firstFormGroup.get('Ctrl2').value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    const profession = this.firstFormGroup.get('Ctrl3').value;
    const lieuexercice = this.firstFormGroup.get('Ctrl8').value;
    const idtype = this.firstFormGroup.get('Ctrl4').value;
    const id = this.firstFormGroup.get('Ctrl5').value;
    const mail = this.secondFormGroup.get('Ctrl6').value;
    const tel = this.secondFormGroup.get('Ctrl7').value;



    const newDemandenomi = new Demandenomi(datedemande, civ, prenom, nom, profession, lieuexercice, idtype, id, mail, tel);
    this.demandenomisService.CreateDemandenomi(newDemandenomi);
    this.dialogRef.close();

  }

  openDialog3(): void {
    let dialogRef = this.dialog.open(Tel2Component, { width: '800px' });
  }

  sanitize(str) {
    if (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    }
    return str;
  }

}





















@Component({
  selector: 'app-inscriorga',
  templateUrl: './inscriorga.component.html',
})

export class InscriorgaComponent implements OnInit {


  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  url: string;
  patientid: string;
  nomfichier: string;
  circs5 = [
    { value: "Monsieur", viewValue: "Monsieur" },
    { value: "Madame", viewValue: "Madame" },

  ];


  circs3 = [
    { value: "FINESS", viewValue: "FINESS" },
    { value: "SIRET", viewValue: "SIRET" },
    { value: "SIREN", viewValue: "SIREN" },
  ];


  circs4 = [

    { value: "assistant médical", viewValue: "assistant médical" },
    { value: "cadre de santé", viewValue: "coordinateur de soins" },
    { value: "professionnel de santé", viewValue: "professionnel de santé" }
  ];


  circs8 = [

    { value: "Cadre de santé", viewValue: "Cadre de santé" },
    { value: "Coordinateur technique", viewValue: "Coordinateur technique" },
    { value: "Professionnel de santé", viewValue: "Professionnel de santé" },
    { value: "Responsable de la structure", viewValue: "Responsable de la structure" },
  ];



  me;
  me0;
  me1;
  me2;
  me3;
  me4;
  me5;
  me6;
  me7;
  me8;
  me9;
  me10;
  me11;
  me12;
  me13;
  selected: any;
  selected2: any;
  constructor(
    private route: ActivatedRoute,
    private slService: PPStographService,
    private demandenomisService: DemandenomisService,
    private demandeorgasService: DemandeorgasService,
    private docsService: DocsService,
    private location: Location,
    private storage: AngularFireStorage,
    private router: Router, public dialog: MatDialog,
    public dialogRef: MatDialogRef<TelComponent>, private _formBuilder: FormBuilder) { }

  isLinear = true;
  zeroFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  @ViewChild('f') form: NgForm;

  formData: FormGroup;



  filteredOptions: Observable<string[]>;
  filteredOptions3: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  trait: string;
  ppsplans: Ppsplan[];




  subscription: Subscription;
  subscription2: Subscription;
  editMode = false;
  editedItemIndex: number;
  editedItem: Ppsplan;








  onClear() {
    this.form.reset();
    this.editMode = false;
  }

  onDelete() {
    this.slService.deletePpsplan(this.editedItemIndex);
    this.onClear();
  }

  onEditItem(index: number) {
    this.slService.startedEditing.next(index);
  }


  getErrorEmail() {
    return this.secondFormGroup.get('Control8').hasError('required') ? 'Le champs est requis' :
      this.secondFormGroup.get('Control8').hasError('pattern') ? "L'adresse email n'est pas valide" :
        '';
  }

  getErrorEmail2() {
    return this.zeroFormGroup.get('Control1').hasError('required') ? 'Le champs est requis' :
      this.zeroFormGroup.get('Control1').hasError('pattern') ? "L'adresse email n'est pas valide" :
        '';
  }
  getErrorEmail3() {
    return this.firstFormGroup.get('Control10').hasError('required') ? 'Le champs est requis' :
      this.firstFormGroup.get('Control10').hasError('pattern') ? "L'adresse email n'est pas valide" :
        '';
  }



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ppsplans, event.previousIndex, event.currentIndex);
  }



  ngOnInit() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.zeroFormGroup = this._formBuilder.group({
      Control0: ['', Validators.required],
      Control1: [null, Validators.compose([Validators.required, Validators.pattern(emailregex)])],
      Control11: ['', Validators.required],
      Control12: ['', Validators.required],
    });
    this.firstFormGroup = this._formBuilder.group({
      Control13: ['', Validators.required],
      Control2: ['', Validators.required],
      Control3: ['', Validators.required],
      Control4: ['', Validators.required],
      Control10: [null, Validators.compose([Validators.required, Validators.pattern(emailregex)])],
      Control5: ['', Validators.required]
    }, {
      validators: this.validateType
    });

    this.secondFormGroup = this._formBuilder.group({
      Control6: ['', Validators.required],
      Control7: ['', Validators.required],
      Control8: [null, Validators.compose([Validators.required, Validators.pattern(emailregex)])]

    });

    this.ppsplans = this.slService.getPpsplans();

    this.subscription2 = this.slService.ppsplansChanged
      .subscribe(
        (ppsplans: Ppsplan[]) => {
          this.ppsplans = ppsplans;
        }
      );
    this.subscription = this.slService.startedEditing
      .subscribe(
        (index: number) => {
          this.editMode = true;
          this.editedItemIndex = index;
          console.log(index);
          this.editedItem = this.slService.getPpsplan(index);
          this.form.setValue({
            prenom: this.editedItem.prenom,
            metier: this.editedItem.metier,
            civilite: this.editedItem.civilite,
            nom: this.editedItem.nom,
            email: this.editedItem.email,
          });
        });

  }

  isPpsplanEmpty() {
    return this.slService.getPpsplans().length === 0 ? true : false;
  }

  onSubmit(form: NgForm) {
    const value = form.value;
    const prenom = this.form.value.prenom.toLowerCase();
    const nom = this.form.value.nom.toLowerCase();
    const newPpsplan = new Ppsplan(value.civilite, prenom, nom, value.metier, value.email);
    if (this.editMode) {
      this.slService.updatePpsplan(this.editedItemIndex, newPpsplan);
    } else {
      this.slService.addPpsplan(newPpsplan);
    }
    this.editMode = false;
    form.reset();
  }

  validateType(c: FormGroup) {
    const idtype = c.get('Control4').value;
    let isValid = true;
    if (idtype && c.get('Control5').value) { 
      switch (idtype) {
        case 'ADELI':
          if (c.get('Control5').value.toString().length !== 9) {
            isValid = false;
          }
        break;
        case 'RPPS':
          if (c.get('Control5').value.toString().length !== 11) {
            isValid = false;
          }
        break;
        case 'FINESS':
          if (c.get('Control5').value.toString().length !== 9) {
            isValid = false;
          }
        break;
        case 'SIRET':
          if (c.get('Control5').value.toString().length !== 14) {
            isValid = false;
          }
        break;
        case 'SIREN':
          if (c.get('Control5').value.toString().length !== 9) {
            isValid = false;
          }
        break;
        default: 
          isValid = false;
      }
    } else {
      isValid = false;
    }

    if (!isValid) {
      c.controls['Control5'].setErrors({typeInvalid: true});
    }
    
    return isValid ? null : {
      'typeInvalid': true
    };
  }

  action() {
    const PPSplanif = this.ppsplans;
    const datedemande = Date.now().toString();;
    const demandeur = this.zeroFormGroup.get('Control0').value;
    const email_demandeur = this.zeroFormGroup.get('Control1').value;
    const tel_demandeur = this.zeroFormGroup.get('Control12').value;
    const nom_structure = this.firstFormGroup.get('Control2').value;
    const email_structure = this.firstFormGroup.get('Control10').value;
    const tel_structure = this.firstFormGroup.get('Control13').value;
    const fonction_demandeur = this.zeroFormGroup.get('Control11').value;
    const responsable_structure = this.firstFormGroup.get('Control3').value;
    const idtype = this.firstFormGroup.get('Control4').value;
    const id = this.firstFormGroup.get('Control5').value;
    const mssante = this.secondFormGroup.get('Control6').value.toLowerCase();
    const administrateur_orga = this.secondFormGroup.get('Control7').value;
    const email_principal = this.secondFormGroup.get('Control8').value;

    const comptes_délégués = this.ppsplans;
    this.slService.deleteAllPpsplan();
    this.dialogRef.close();


    const newDemandeorga = new Demandeorga(
      datedemande,
      demandeur,
      email_demandeur,
      tel_demandeur,
      nom_structure,
      responsable_structure,
      idtype,
      id,
      mssante,
      administrateur_orga,
      email_principal,
      comptes_délégués,
      email_structure,
      tel_structure,
      fonction_demandeur);


    this.demandeorgasService.CreateDemandeorga(newDemandeorga);
    this.dialogRef.close();

  }

  openDialog3(): void {
    let dialogRef = this.dialog.open(Tel2Component, { width: '800px' });
  }

  sanitize(str) {
    if (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    }
    return str;
  }

}






