import { Component } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ESANTE-MSS';


  constructor() {

    localStorage.removeItem('firebase:previous_websocket_failure');
    // PROD
    const config = {
      apiKey: "AIzaSyC_oNb26jHc0i1vQTe0qFrNkL-AI-28E7I",
      authDomain: "demandes-mssante.firebaseapp.com",
      databaseURL: "https://demandes-mssante.firebaseio.com",
      projectId: "demandes-mssante",
      storageBucket: "demandes-mssante.appspot.com",
      messagingSenderId: "289355510009",
      appId: "1:289355510009:web:48e15513e297181f3e7228"
    };
    // PREPROD
    // const config = {
    //   apiKey: "AIzaSyDvMbZGRLifMkV_hz82NBQc5ry6BpRjFbg",
    //   authDomain: "formulaire-mss-v2.firebaseapp.com",
    //   databaseURL: "https://formulaire-mss-v2.firebaseio.com",
    //   projectId: "formulaire-mss-v2",
    //   storageBucket: "formulaire-mss-v2.appspot.com",
    //   messagingSenderId: "730522950224",
    //   appId: "1:730522950224:web:b1855b17d53a984db2cf55"
    // };
    firebase.initializeApp(config);
  }







}
