import { Injectable } from '@angular/core';
import { Demandeorga } from '../models/demandeorga.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from 'angularfire2/database';
import {switchMap, map} from 'rxjs/operators';
import { Observable, interval, pipe } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DemandeorgasfService {
Demandeorgas: AngularFireList<any>;

constructor(private database: AngularFireDatabase) {this.Demandeorgas = database.list('/Fileattente/DemandesBALorga');}

getDemandeorgas() { return this.Demandeorgas.snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }

CreateDemandeorga(newDemandeorga: Demandeorga) { this.Demandeorgas.push(newDemandeorga); }

getSingleDemandeorgas(key: string): Observable<any>  {
   return this.database.list('/Fileattente/DemandesBALorga', ref => 
       ref.orderByKey().equalTo(key)).snapshotChanges().pipe(map(actions => {
          return actions.map(a => {
              const data = a.payload.val();
              const payloadKey = a.payload.key;
              return {key: payloadKey, ...data };
          });
   }));
 }

deleteDemandeorgas(key: string){
return this.database.list('/Fileattente/DemandesBALorga').remove(key);
}

}