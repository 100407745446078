import { Component, OnInit, Inject, ChangeDetectorRef, HostListener, ElementRef, ViewChild, Input,Output,OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DemandeorgasService } from '../services/demandeorgas.service';
import { FormControl, FormControlName, FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule, FormArray } from '@angular/forms';
import { Demandeorga } from '../models/demandeorga.model';
import {Ppsplan} from './../models/ppsplan.model';
import {PPStographService} from './../services/ppstograph.service';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-modiforga',
  templateUrl: './modiforga.component.html',
  styleUrls: ['./modiforga.component.scss']
})


export class ModiforgaComponent implements OnInit {


key;
demandeToDisplay;
demandeToDisplay2;
ppsForm: FormGroup ;
showSpinner;
ppsplans:Ppsplan[];

@ViewChild('f') form: NgForm;
subscription: Subscription;
subscription2: Subscription;
editMode = false;
editedItemIndex: number;
editedItem: Ppsplan;

constructor(private slService: PPStographService,private formBuilder: FormBuilder,public demandeorgaService: DemandeorgasService,private route: ActivatedRoute, private router: Router) { this.initForm();  }

  ngOnInit() {
this.showSpinner=true;
this.route.params.forEach((urlParameters) => {
     this.key = urlParameters['id'];
   });
console.log(this.key);

this.slService.deleteAllPpsplan();

  this.subscription2=this.slService.ppsplansChanged
    .subscribe(
    (ppsplans:Ppsplan[]) => {this.ppsplans=ppsplans; }
    );

    this.subscription = this.slService.startedEditing
      .subscribe(
      (index : number) => {
        this.editMode = true;
        this.editedItemIndex = index;

        this.editedItem = this.slService.getPpsplan(index);
        this.form.setValue({
            prenom: this.editedItem.prenom,
        metier: this.editedItem.metier,
        civilite: this.editedItem.civilite,
        nom: this.editedItem.nom, 
        email: this.editedItem.email,                  
        });});  






this.demandeToDisplay = this.demandeorgaService.getSingleDemandeorgas2(this.key)
.subscribe(res=>{
    this.demandeToDisplay = res;
    this.ppsForm.controls['administrateur_orga'].setValue(this.demandeToDisplay.administrateur_orga);
    this.ppsForm.controls['id'].setValue(this.demandeToDisplay.id); 
    this.ppsForm.controls['idtype'].setValue(this.demandeToDisplay.idtype);   
    this.ppsForm.controls['datedemande'].setValue(this.demandeToDisplay.datedemande); 
    this.ppsForm.controls['demandeur'].setValue(this.demandeToDisplay.demandeur);
    this.ppsForm.controls['email_demandeur'].setValue(this.demandeToDisplay.email_demandeur); 
    this.ppsForm.controls['email_principal'].setValue(this.demandeToDisplay.email_principal); 
    this.ppsForm.controls['email_structure'].setValue(this.demandeToDisplay.email_structure); 
    this.ppsForm.controls['fonction_demandeur'].setValue(this.demandeToDisplay.fonction_demandeur);   
    this.ppsForm.controls['mssante'].setValue(this.demandeToDisplay.mssante); 
    this.ppsForm.controls['nom_structure'].setValue(this.demandeToDisplay.nom_structure);   
    this.ppsForm.controls['responsable_structure'].setValue(this.demandeToDisplay.responsable_structure); 
    this.ppsForm.controls['tel_structure'].setValue(this.demandeToDisplay.tel_structure);   
    this.ppsForm.controls['tel_demandeur'].setValue(this.demandeToDisplay.tel_demandeur); 
  
});


this.demandeToDisplay2 = this.demandeorgaService.getSingleDemandeorgas(this.key)
this.demandeToDisplay2.subscribe((ppsList: Demandeorga[]) => {
let interestingFields = ['civilite','prenom','nom', 'metier',  'email'];
  let data =[];
  ppsList.map((pps: Demandeorga) => {
  Object.keys(pps.comptes_delegues).forEach(key => 
  {
  let ppsplan: Ppsplan = pps.comptes_delegues[key];
  data.push([...interestingFields.map(field => ppsplan[field]) ]); 
let data2 = [];
let convert_data= (list: any[]) => new Ppsplan(list[0], list[1], list[2], list[3], list[4]);
for (let list of data) {
data2.push(convert_data(list));
this.ppsplans=data2;
};
  });
this.slService.addPpsplans(this.ppsplans); 
 });
 });






  }

initForm(): void {

this.ppsForm = this.formBuilder.group({
  

administrateur_orga: [''],
id: [''],
idtype: [''],
datedemande: [''],
demandeur: [''],
email_demandeur: [''],
email_principal: [''],
email_structure: [''],
fonction_demandeur: [''],
mssante: [''],
nom_structure: [''],
responsable_structure: [''],
tel_structure: [''],
tel_demandeur: [''],




     });
    }


onSubmit(form: NgForm) {
    const value = form.value;
    const prenom =this.form.value.prenom.toLowerCase();
    const nom =this.form.value.nom.toLowerCase();
    const newPpsplan = new Ppsplan( value.civilite, prenom,nom,value.metier, value.email);
    if (this.editMode){
      this.slService.updatePpsplan(this.editedItemIndex,newPpsplan);
    } else {
      this.slService.addPpsplan(newPpsplan);
    }
    this.editMode=false;
    form.reset();
}

onClear(){
    this.form.reset();
    this.editMode=false;
}

onDelete(){
    this.slService.deletePpsplan(this.editedItemIndex);
    this.onClear();
}

onEditItem(index:number){
    this.slService.startedEditing.next(index);
}








onSubmitForm() {
  
    const administrateur_orga = this.ppsForm.get('administrateur_orga').value;
    const datedemande = this.ppsForm.get('datedemande').value;
    const demandeur = this.ppsForm.get('demandeur').value.toLowerCase();
    const email_demandeur = this.ppsForm.get('email_demandeur').value.toLowerCase();
    const email_principal = this.ppsForm.get('email_principal').value;
    const email_structure = this.ppsForm.get('email_structure').value;
    const idtype = this.ppsForm.get('idtype').value;
    const id = this.ppsForm.get('id').value;
    const fonction_demandeur = this.ppsForm.get('fonction_demandeur').value;
    const mssante = this.ppsForm.get('mssante').value;
    const comptes_délégués =  this.ppsplans;
    const nom_structure = this.ppsForm.get('nom_structure').value;
    const responsable_structure = this.ppsForm.get('responsable_structure').value;
    const tel_structure = this.ppsForm.get('tel_structure').value;
    const tel_demandeur = this.ppsForm.get('tel_demandeur').value;




    const newDemandenomi = new Demandeorga (
datedemande,
demandeur,
email_demandeur,
tel_demandeur,
nom_structure,
responsable_structure,
idtype,
id,
mssante,
administrateur_orga,
email_principal,
comptes_délégués,
email_structure,
tel_structure,
fonction_demandeur);
    this.demandeorgaService.UpdateDemandeorga(this.key,newDemandenomi);
    this.router.navigate(['admin']);

}


goback() {this.router.navigate(['admin']);}

}