import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import { DatePipe, CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RemarquesService } from './services/remarque.service';
import { DemandenomisService } from './services/demandenomis.service';
import { DemandenomisfService } from './services/demandenomisf.service';
import { DemandenomisdService } from './services/demandenomisd.service';
import { DemandenomisvService } from './services/demandenomisv.service';
import { DemandeorgasService } from './services/demandeorgas.service';

import { DemandesexportService } from './services/demandesexport.service';
import { DemandeorgasfService } from './services/demandeorgasf.service';
import { DemandeorgasdService } from './services/demandeorgasd.service';
registerLocaleData(localeFr);
import { DigitOnlyModule } from '@uiowa/digit-only';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CreditCardDirective } from './credit-card.directive';
import { CdkTreeModule} from '@angular/cdk/tree';
import { InMemDataService} from './services/in-memory-data.service';
import { AppService} from './services/app.service';
import  {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';


import {  
  MatAutocompleteModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,  
  MatFormFieldModule,
   } from '@angular/material';


import { SignupComponent } from './auth/signup/signup.component';
import { SigninComponent } from './auth/signin/signin.component';

import { AppComponent } from './app.component';
import { LiveComponent, ContactComponent,TelComponent,InscrinominativeComponent,InscriorgaComponent,Tel2Component, } from './live/live.component';

import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AdminComponent } from './admin/admin.component';
import { TestComponent } from './test/test.component';
import { NomimodifComponent } from './nomimodif/nomimodif.component';
import { ModiforgaComponent } from './modiforga/modiforga.component';


const appRoutes: Routes =
[

{ path: 'auth/signup', component: SignupComponent },
{ path: 'auth/signin', component: SigninComponent },
{ path: '', component: LiveComponent },
{ path: 'admin', canActivate: [AuthGuardService], component: AdminComponent },
{ path: 'test', canActivate: [AuthGuardService], component: TestComponent },
{ path: 'modifnomi/:id', canActivate: [AuthGuardService], component: NomimodifComponent },
{ path: 'modiforga/:id', canActivate: [AuthGuardService], component: ModiforgaComponent },
{ path: '**', redirectTo: ''},
];


@NgModule({
  declarations: 
  [
    CreditCardDirective,
    AppComponent,
    LiveComponent,
    ContactComponent,
    TelComponent,
    SignupComponent,
    SigninComponent,
    InscrinominativeComponent,
    InscriorgaComponent,
    Tel2Component,
    AdminComponent,
    TestComponent,
    NomimodifComponent,
    ModiforgaComponent,
   ],

  imports: [
    NgxUpperCaseDirectiveModule,
    DigitOnlyModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatInputModule,
    MatExpansionModule,
    MatToolbarModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatIconModule,
    MatRadioModule,
    MatListModule,
    MatSortModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatGridListModule,
    LayoutModule,
    MatStepperModule,
    MatIconModule,
    MatDialogModule,
    MatPaginatorModule,
    CommonModule,
    MatFormFieldModule, 
    MatTooltipModule, 
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MatSlideToggleModule,
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    DragDropModule,
    ScrollingModule,
    CdkTreeModule,
    HttpClientInMemoryWebApiModule.forRoot(
    InMemDataService, { dataEncapsulation: false, delay: 1000 }),
    MatCheckboxModule
   
        ],

entryComponents: [LiveComponent,SigninComponent, ContactComponent,TelComponent,Tel2Component,InscrinominativeComponent,InscriorgaComponent],
  
  bootstrap: [AppComponent], 
    providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    AuthService,
    AuthGuardService,
    AppService

    
    ]
,})


export class AppModule {  }