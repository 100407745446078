import { InMemoryDbService } from 'angular-in-memory-web-api';



export class InMemDataService implements InMemoryDbService {
  createDb() {
    let users = [
  {
   finess: "FINESS",
   name: "AIRE DE STATIONNEMENT",
   finessju: 530003599,
   id: 530003649,
   commune: "CRAON",
   cp: 53400,
   telephone: "-",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE DE STATIONNEMENT",
   finessju: 530003698,
   id: 530003748,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "-",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE DE STATIONNEMENT",
   finessju: 530031343,
   id: 530003789,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "-",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STAT.NOMADES LA CLARIERE",
   finessju: 440018679,
   id: 440008225,
   commune: "NANTES",
   cp: 44300,
   telephone: "240500191",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STAT.NOMADES LA FARDIERE",
   finessju: 440018679,
   id: 440009520,
   commune: "NANTES",
   cp: 44100,
   telephone: "240433210",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STAT.NOMADES MEAN",
   finessju: 440018679,
   id: 440019032,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240667394",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850016494,
   id: 850005158,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251930047",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850016957,
   id: 850005166,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390529",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012535,
   id: 850005174,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372108",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850016767,
   id: 850005182,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251555157",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012915,
   id: 850005190,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251324650",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012717,
   id: 850005208,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692064",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012634,
   id: 850009788,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943036",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850016742,
   id: 850009937,
   commune: "L HERBERGEMENT",
   cp: 85260,
   telephone: "251428174",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850016759,
   id: 850010299,
   commune: "ST FULGENT",
   cp: 85250,
   telephone: "251426226",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012733,
   id: 850010554,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910767",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012618,
   id: 850020157,
   commune: "LA BRUFFIERE",
   cp: 85530,
   telephone: "251425036",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012782,
   id: 850020165,
   commune: "LUCON",
   cp: 85400,
   telephone: "251560500",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012659,
   id: 850020207,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696041",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "AIRE STATIONNEMENT NOMADES",
   finessju: 850012840,
   id: 850020215,
   commune: "MOUTIERS LES MAUXFAITS",
   cp: 85540,
   telephone: "251989033",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "ASSOCIATION LE RELAIS",
   finessju: 440018679,
   id: 440009546,
   commune: "BOUGUENAIS CEDEX",
   cp: 44341,
   telephone: "251706915",
   categorie: "Aire Station Nomades"
 },
 {
   finess: "FINESS",
   name: "A.C.T. ENTRACT",
   finessju: 750719361,
   id: 440046167,
   commune: "NANTES",
   cp: 44000,
   telephone: "251826099",
   categorie: "Appartement de Coordination Therapeutique (A.C.T.)"
 },
 {
   finess: "FINESS",
   name: "A.C.T. MONTJOIE",
   finessju: 720008705,
   id: 720018621,
   commune: "LE MANS",
   cp: 72000,
   telephone: "",
   categorie: "Appartement de Coordination Therapeutique (A.C.T.)"
 },
 {
   finess: "FINESS",
   name: "ACT LES 2 RIVES",
   finessju: 530000819,
   id: 530008887,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Appartement de Coordination Therapeutique (A.C.T.)"
 },
 {
   finess: "FINESS",
   name: "ACT LOGIS 49 MONTJOIE",
   finessju: 720008705,
   id: 490019718,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Appartement de Coordination Therapeutique (A.C.T.)"
 },
 {
   finess: "FINESS",
   name: "ACT PASSERELLES",
   finessju: 850013236,
   id: 850025784,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624343",
   categorie: "Appartement de Coordination Therapeutique (A.C.T.)"
 },
 {
   finess: "FINESS",
   name: "LOGIS MONTJOIE 44",
   finessju: 720008705,
   id: 440029049,
   commune: "NANTES",
   cp: 44300,
   telephone: "240943133",
   categorie: "Appartement de Coordination Therapeutique (A.C.T.)"
 },
 {
   finess: "FINESS",
   name: "APPART. THERAPEUT. CESAME STE GEMMES",
   finessju: 490000163,
   id: 490015500,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Appartement Therapeutique"
 },
 {
   finess: "FINESS",
   name: "LA CHICOTIERE-APPARTEMENT THeRAPEUTIQU",
   finessju: 440018729,
   id: 440049328,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Appartement Therapeutique"
 },
 {
   finess: "FINESS",
   name: "ATELIER THERAPEUTIQUE A CADRE AGRICOLE",
   finessju: 850012436,
   id: 850003401,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251376855",
   categorie: "Atelier Therapeutique"
 },
 {
   finess: "FINESS",
   name: "ATELIER THERAPEUTIQUE BAZINIERES",
   finessju: 850012436,
   id: 850003393,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478808",
   categorie: "Atelier Therapeutique"
 },
 {
   finess: "FINESS",
   name: "ATELIERS THERAPEUTIQUES SUD VENDEE",
   finessju: 850012436,
   id: 850011545,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690761",
   categorie: "Atelier Therapeutique"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LA CLAIRE FONTAINE",
   finessju: 440012664,
   id: 440044865,
   commune: "NANTES",
   cp: 44100,
   telephone: "240699134",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LA FLAMBEE",
   finessju: 720019207,
   id: 720018944,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243285313",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL JOUR SOLIDARITES ET CREATIONS",
   finessju: 440049153,
   id: 440049161,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240661070",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "ADGESTI DISPOSITIF ALT",
   finessju: 720008820,
   id: 720018209,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243876910",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "ASS COPAINVILLE HEBERGEMENT URGENCE",
   finessju: 530000827,
   id: 530008218,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243302500",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "ASS REVIVRE HEBERGEMENT URGENCE",
   finessju: 530031459,
   id: 530008846,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531523",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "BON PASTEUR 49",
   finessju: 490534823,
   id: 490020260,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241482140",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CC LOIR BERCe DISPOSITIF ALT",
   finessju: 720019678,
   id: 720018878,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243381717",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CCAS LAVAL PLACES HEBERGEMENT URGENCE",
   finessju: 530031178,
   id: 530008184,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243494747",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CCAS MAYENNE HEBERGEMENT URGENCE",
   finessju: 530031160,
   id: 530008192,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243302137",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'HEBERGEMENT FOYER BOUTIN",
   finessju: 440002533,
   id: 440052520,
   commune: "REZE",
   cp: 44400,
   telephone: "251110000",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CROIX ROUGE DL SABLE HEB HORS CHRS",
   finessju: 750721334,
   id: 720020726,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243959200",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CTRE D HEBERGEMENT D URGENCE BLANCHY",
   finessju: 440018422,
   id: 440046217,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240665780",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF  DANH FRANCE HORIZON",
   finessju: 750806606,
   id: 490019874,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241470195",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT ADOMA  ANGERS",
   finessju: 750808511,
   id: 490017985,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241378383",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT ADOMA CHOLET",
   finessju: 750808511,
   id: 490018645,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241466321",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT ADOMA SAUMUR",
   finessju: 750808511,
   id: 490018629,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241378383",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT AIDE ACCUEIL",
   finessju: 490004231,
   id: 490017977,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241253560",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT AIH",
   finessju: 490016904,
   id: 490018637,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241370700",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT ARBRE VERT SEGRE",
   finessju: 490018140,
   id: 490018157,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241617289",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT CAVA SAUMUR",
   finessju: 490534849,
   id: 490018165,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241673181",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT CORDELIERS CHOLET",
   finessju: 490004223,
   id: 490018132,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241588081",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT EMMAUS",
   finessju: 490018074,
   id: 490018090,
   commune: "ST JEAN DE LINIERES",
   cp: 49070,
   telephone: "241397733",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT HABITAT SOLIDARITE SAUM",
   finessju: 490018173,
   id: 490018181,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502506",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT PELLETIER CHOLET",
   finessju: 490534823,
   id: 490018611,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241482140",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT ST VINCENT DE PAUL",
   finessju: 490018215,
   id: 490018223,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241370212",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF ALT UDAF49",
   finessju: 490017878,
   id: 490017969,
   commune: "ANGERS CEDEX 01",
   cp: 49003,
   telephone: "241365100",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE (SAMU SOCIAL)",
   finessju: 850013236,
   id: 850018748,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624343",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "FOYERS 24 BIS & ST MARTIN",
   finessju: 440012664,
   id: 440044881,
   commune: "NANTES",
   cp: 44000,
   telephone: "240434725",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "FRANCE HORIZON HEBERGEMENT HORS CHRS",
   finessju: 750806606,
   id: 720020734,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243426465",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HABITAT ET HUMANISME DISPOSITIF ALT",
   finessju: 720019165,
   id: 720019173,
   commune: "LE MANS",
   cp: 72000,
   telephone: "950264510",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HABITAT SOLIDARITE",
   finessju: 490018173,
   id: 490019486,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502506",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HALTE DE NUIT 44",
   finessju: 440012664,
   id: 440051134,
   commune: "NANTES",
   cp: 44200,
   telephone: "682553861",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HBGT INSERTION HORS CHRS SOS FEMMES",
   finessju: 850021882,
   id: 850027491,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "251477759",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HBGT URGENCE HORS CHRS PASSERELLES",
   finessju: 850013236,
   id: 850027475,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624343",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HBGT URGENCE HORS CHRS SOS FEMMES",
   finessju: 850021882,
   id: 850027483,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "251477759",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HOTEL SOCIAL DE MARCE",
   finessju: 720001379,
   id: 720018175,
   commune: "BEILLE",
   cp: 72160,
   telephone: "243894269",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HOTEL SOCIAL DE SABLE",
   finessju: 750721334,
   id: 720018167,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243927451",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HOTEL SOCIAL LES TOITS DU COEUR",
   finessju: 720018480,
   id: 720018498,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243401133",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HUDC ABRI DE LA PROVIDENCE",
   finessju: 490544277,
   id: 490017845,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241254740",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "HUDC AIDE ACCUEIL",
   finessju: 490004231,
   id: 490017837,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241253560",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "LA MAISON DE COLUCHE",
   finessju: 440048007,
   id: 440048015,
   commune: "NANTES",
   cp: 44000,
   telephone: "251841050",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "LE BUS DU COEUR",
   finessju: 720018480,
   id: 720018951,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243821614",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ALT",
   finessju: 850012733,
   id: 850019621,
   commune: "LES HERBIERS CEDEX",
   cp: 85502,
   telephone: "251910767",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ALT",
   finessju: 850019639,
   id: 850019647,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "251534122",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ALT AUFD",
   finessju: 850021882,
   id: 850019605,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "251477759",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ALT HABITAT ET HUMANISME",
   finessju: 850013657,
   id: 850019613,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251554182",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "MAISON D'ACCUEIL DE JOUR",
   finessju: 440018422,
   id: 440045433,
   commune: "NANTES",
   cp: 44000,
   telephone: "240292100",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "MAISON D'ACCUEIL DE JOUR",
   finessju: 850017773,
   id: 850009739,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251210622",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "MAISON D'ACCUEIL DE JOUR",
   finessju: 850013236,
   id: 850018722,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624343",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "MISE A L'ABRI ABRI DE LA PROVIDENCE",
   finessju: 490544277,
   id: 490019536,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241253049",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "MONTJOIE DISPOSITIF ALT",
   finessju: 720008705,
   id: 720018191,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243835190",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "REVIVRE HALTE DE NUIT",
   finessju: 530031459,
   id: 530008903,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531523",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "S.A.O. DE CHALLANS",
   finessju: 850017773,
   id: 850009689,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251497690",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "S.A.O. DES SABLES D'OLONNE",
   finessju: 850017773,
   id: 850009648,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85102,
   telephone: "251214541",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SAINT VINCENT DE PAUL DISPOSITIF ALT",
   finessju: 720018241,
   id: 720018258,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243280391",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SECOURS CATHOLIQUE ACCUEIL DE JOUR",
   finessju: 750045189,
   id: 530008853,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243564010",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SERVICE ATOLLS ST YVES",
   finessju: 440026482,
   id: 440048023,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240800202",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'ACCUEIL ET D'ORIENTATION",
   finessju: 850013236,
   id: 850018771,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624343",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SERVICE LOGEMENT REGULARISE - RELOGIP",
   finessju: 440012664,
   id: 440052546,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "251809124",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SLR SERVICE LOGEMENT ReGULARISe",
   finessju: 440026482,
   id: 440051241,
   commune: "NANTES",
   cp: 44000,
   telephone: "240357969",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SOS FEMMES",
   finessju: 490004264,
   id: 490020252,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241879722",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "SOS FEMMES ACCUEIL 72 DISPOSITIF ALT",
   finessju: 720018993,
   id: 720019009,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243781275",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "STARIP 44",
   finessju: 440012664,
   id: 440048049,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569444",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "TARMAC  HEBERGEMENT HORS CHRS",
   finessju: 720019207,
   id: 720018985,
   commune: "SARGE LES LE MANS",
   cp: 72190,
   telephone: "243721645",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "VEILLE SOCIALE 44",
   finessju: 440045441,
   id: 440045458,
   commune: "NANTES",
   cp: 44200,
   telephone: "240759898",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "VEILLE SOCIALE TARMAC",
   finessju: 720019207,
   id: 720018084,
   commune: "SARGE LES LE MANS",
   cp: 72190,
   telephone: "272164570",
   categorie: "Autre Centre d'Accueil"
 },
 {
   finess: "FINESS",
   name: "CENTRE JEAN BERNARD",
   finessju: 720002294,
   id: 720014729,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243281311",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "CENTRE MALLET-PROUX",
   finessju: 720002294,
   id: 530005628,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243641333",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "CENTRE RADIOLOGIE ECHOGRAPHIE",
   finessju: 720002385,
   id: 720011717,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243931320",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "CENTRE SCANOGRAPHIE BEAUVERGER",
   finessju: 720002377,
   id: 720014745,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243242474",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "GIE IRM SAINT NAZAIRE SITE DU CH",
   finessju: 440041606,
   id: 440053031,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "272278648",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "IRM CENTRE MEDICO CHIRURGICAL DU MANS",
   finessju: 720002393,
   id: 720020650,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243783888",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "IRM DE LA CLINIQUE DU PRE",
   finessju: 720002393,
   id: 720020635,
   commune: "LE MANS CEDEX 2",
   cp: 72018,
   telephone: "243775775",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "IRM DE SAUMUR",
   finessju: 490016722,
   id: 490019965,
   commune: "SAUMUR CEDEX",
   cp: 49403,
   telephone: "241513201",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "IRM DU CH DU MANS",
   finessju: 720002393,
   id: 720020627,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243434343",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "IRM DU POLE SANTE SARTHE ET LOIR",
   finessju: 720017292,
   id: 720020643,
   commune: "LA FLECHE CEDEX",
   cp: 72205,
   telephone: "244713000",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "S.C.M. SOURDEAU",
   finessju: 720002419,
   id: 720015429,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243866400",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "S.C.M. SOURDEAU",
   finessju: 720002419,
   id: 720015809,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243746464",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "SCANNER CENTRE MEDICO CHIRURG DU MANS",
   finessju: 720002229,
   id: 720020601,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243783838",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "SCANNER CLINIQUE DU PRE",
   finessju: 720018035,
   id: 720020619,
   commune: "LE MANS CEDEX 2",
   cp: 72018,
   telephone: "243775775",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "SCANNER POLE SARTHE ET LOIR",
   finessju: 720017292,
   id: 720020593,
   commune: "LA FLECHE CEDEX",
   cp: 72205,
   telephone: "244713000",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "SCANNER VAL DE MAYENNE IMAGIS",
   finessju: 530001080,
   id: 530009596,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243643521",
   categorie: "Autre Etablissement Loi Hospitali�re"
 },
 {
   finess: "FINESS",
   name: "LABM DU CES",
   finessju: 440050987,
   id: 440038438,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "240908685",
   categorie: "Autre Laboratoire de Biologie Medicale sans FSE"
 },
 {
   finess: "FINESS",
   name: "EMMAUS RESIDENCE SOCIALE",
   finessju: 490018074,
   id: 490018082,
   commune: "ST JEAN DE LINIERES",
   cp: 49070,
   telephone: "241397339",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ADOMA BASSE CHENAIE",
   finessju: 750808511,
   id: 440054856,
   commune: "NANTES",
   cp: 44300,
   telephone: "240761402",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ADOMA JULIENNE DAVID",
   finessju: 750808511,
   id: 440009561,
   commune: "NANTES",
   cp: 44300,
   telephone: "240762251",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ADOMA LES FONDERIES",
   finessju: 750808511,
   id: 440018786,
   commune: "NANTES",
   cp: 44200,
   telephone: "240761402",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE JEUNES ACTIFS LES PRELES",
   finessju: 850007139,
   id: 850025149,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251370552",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE MARGUERITE BARANKITSE ANGERS",
   finessju: 750808511,
   id: 490003266,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241685539",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE",
   finessju: 850021411,
   id: 850021494,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251060685",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE GATE ARGENT ANGERS",
   finessju: 750808511,
   id: 490018058,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241431135",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE GUY MOQUET TRELAZE",
   finessju: 750808511,
   id: 490018066,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241766116",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE INTERGENERATIONNELLE",
   finessju: 720019165,
   id: 720020767,
   commune: "LE MANS",
   cp: 72100,
   telephone: "895026451",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE LE BOIS BLANC",
   finessju: 850007139,
   id: 850019498,
   commune: "ST HILAIRE DE LOULAY",
   cp: 85600,
   telephone: "970808221",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE LE CHENET",
   finessju: 490019908,
   id: 490019916,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "768537147",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE LE MAIL",
   finessju: 850007139,
   id: 850019597,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "970808221",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE LES BLEUETS",
   finessju: 750721334,
   id: 720019579,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943283",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE LUCIE AUBRAC L2R",
   finessju: 530000819,
   id: 530008028,
   commune: "LAVAL CEDEX",
   cp: 53014,
   telephone: "243668855",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE MAMERS",
   finessju: 720018118,
   id: 720020742,
   commune: "MAMERS",
   cp: 72600,
   telephone: "967388050",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE MONTJOIE",
   finessju: 720008705,
   id: 720020759,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243835190",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE NELSON MANDELA",
   finessju: 720018118,
   id: 720018142,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243863058",
   categorie: "Autre Residence Sociale (hors Maison Relais, Pension de Fami"
 },
 {
   finess: "FINESS",
   name: "C.A.D.A.",
   finessju: 850017773,
   id: 850006198,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251329388",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA",
   finessju: 750806598,
   id: 530002799,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243037120",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA  SAUMUR ASEA-CAVA",
   finessju: 490534849,
   id: 490020195,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241673181",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA 44 COALLIA",
   finessju: 750825846,
   id: 440053700,
   commune: "NANTES",
   cp: 44000,
   telephone: "777962492",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA ADOMA ANGERS",
   finessju: 750808511,
   id: 490007408,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241378383",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA ADOMA CHOLET",
   finessju: 750808511,
   id: 490008158,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241378383",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA ALTHEA",
   finessju: 610787657,
   id: 720013804,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243500710",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA ANGERS ABRI DE LA PROVIDENCE",
   finessju: 490544277,
   id: 490020187,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241253049",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA ANGERS FRANCE HORIZON",
   finessju: 750806606,
   id: 490020211,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241470195",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA FRANCE HORIZON",
   finessju: 750806606,
   id: 440053684,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "228550133",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA FRANCE TERRE D ASILE",
   finessju: 750806598,
   id: 440053676,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240710531",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA FTDA ANGERS",
   finessju: 750806598,
   id: 490007358,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241880183",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA FTDA SAUMUR",
   finessju: 750806598,
   id: 490019858,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241880183",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA LES ALIZES",
   finessju: 440026482,
   id: 440022838,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240800356",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA LES TROIS RIVIERES",
   finessju: 440012664,
   id: 440025138,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569562",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA MONTJOIE",
   finessju: 720008705,
   id: 720007459,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243835190",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA PASSERELLES",
   finessju: 850013236,
   id: 850009598,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372622",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA SAUMUR FRANCE HORIZON",
   finessju: 750806606,
   id: 490020203,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241470195",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA SOS NANTES",
   finessju: 750015968,
   id: 440053692,
   commune: "NANTES",
   cp: 44100,
   telephone: "240357476",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA SUD VENDEE",
   finessju: 850020413,
   id: 850022815,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85205,
   telephone: "251690557",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA TARMAC",
   finessju: 720019207,
   id: 720015908,
   commune: "SARGE LES LE MANS",
   cp: 72190,
   telephone: "272164570",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CADA TRAJET",
   finessju: 440002533,
   id: 440044329,
   commune: "REZE",
   cp: 44400,
   telephone: "251110000",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D ACCUEIL POUR DEMANDEURS ASILE",
   finessju: 750808511,
   id: 440051282,
   commune: "NANTES",
   cp: 44000,
   telephone: "240676973",
   categorie: "Centre Accueil Demandeurs Asile (C.A.D.A.)"
 },
 {
   finess: "FINESS",
   name: "ANTENNE",
   finessju: 720008788,
   id: 720020825,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243434357",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "ANTENNE CAMSP HENRI WALLON BLAIN",
   finessju: 440000248,
   id: 440052728,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240436146",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "ANTENNE CAMSP HENRY WALLON CARQUEFOU",
   finessju: 440000248,
   id: 440052736,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240436146",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP ANTENNE LES HAUTS THEBAUDIERES",
   finessju: 440002467,
   id: 440052439,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240812785",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP APF",
   finessju: 750719239,
   id: 530007194,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243580070",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP ASEA",
   finessju: 490534849,
   id: 490007796,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241721838",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP CENTRE HOSPITALIER DE NANTES",
   finessju: 440000289,
   id: 440012946,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240083440",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP DE KERBRUN",
   finessju: 440018612,
   id: 440012003,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240702017",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP DEPARTEMENTAL",
   finessju: 720008788,
   id: 720008358,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243434357",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP HENRI WALLON",
   finessju: 440000248,
   id: 440011997,
   commune: "NANTES",
   cp: 44100,
   telephone: "240436146",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP LES HAUTS THEBAUDIERES",
   finessju: 440002467,
   id: 440012847,
   commune: "NANTES CEDEX 1",
   cp: 44024,
   telephone: "251710408",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP POLYVALENT",
   finessju: 850023664,
   id: 850023672,
   commune: "LA ROCHE SUR YON CEDEX 9",
   cp: 85925,
   telephone: "251473500",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CAMSP POLYVALENT DEPARTEMENTAL",
   finessju: 490535168,
   id: 490542735,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "241738719",
   categorie: "Centre Action Medico-Sociale Precoce (C.A.M.S.P.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440004034,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240789798",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440008373,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240703502",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440008498,
   commune: "REZE",
   cp: 44400,
   telephone: "240757064",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440008571,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240341111",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440008654,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240831615",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440008779,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602528",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440009264,
   commune: "NANTES",
   cp: 44000,
   telephone: "240744708",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440009637,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240790653",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440019040,
   id: 440009991,
   commune: "NANTES",
   cp: 44300,
   telephone: "240768830",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440020790,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240436100",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440026003,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240822779",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440026011,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240882818",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440026854,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240280120",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION",
   finessju: 440023620,
   id: 440030427,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240936909",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION NORD",
   finessju: 850018581,
   id: 850011297,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251070213",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CIRCONSCRIPTION SUD",
   finessju: 850018581,
   id: 850011289,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251070213",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011313,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320725",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011339,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251941670",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011347,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943065",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011354,
   commune: "LUCON",
   cp: 85400,
   telephone: "251560231",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011362,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910579",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011370,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251932475",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CTRE CIRCONSCRIPTION SANIT SOC",
   finessju: 850018581,
   id: 850011388,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251554017",
   categorie: "Centre Circonscription Sanitaire et Sociale"
 },
 {
   finess: "FINESS",
   name: "CAFS ARRIA",
   finessju: 440001485,
   id: 440049021,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS DU CENRO",
   finessju: 440000156,
   id: 440034726,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240030301",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS IME JB MESSAGER",
   finessju: 530031434,
   id: 530000223,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243590933",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS IME LEON DOUDARD",
   finessju: 530033000,
   id: 530031699,
   commune: "MONTAUDIN",
   cp: 53220,
   telephone: "243053326",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS IME LES PERRI�RES",
   finessju: 440018380,
   id: 440026474,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240281523",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS IME PAUL ELUARD",
   finessju: 440018380,
   id: 440034700,
   commune: "ANCENIS CEDEX",
   cp: 44153,
   telephone: "-",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440033827,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792311",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS LA DURANTIERE",
   finessju: 440018612,
   id: 440040384,
   commune: "NANTES",
   cp: 44000,
   telephone: "251816049",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS LA GUIBERDIERE",
   finessju: 490535184,
   id: 490537289,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241210720",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS LAMORICIERE",
   finessju: 690793435,
   id: 440044469,
   commune: "NANTES",
   cp: 44100,
   telephone: "240710834",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS LES CHESNAIES",
   finessju: 490536828,
   id: 490016987,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241211480",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS POLE NANTAIS",
   finessju: 440018380,
   id: 440034692,
   commune: "NANTES",
   cp: 44300,
   telephone: "251843860",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CAFS SARTHE ET LOIR",
   finessju: 720009562,
   id: 720015338,
   commune: "BAZOUGES CRE SUR LOIR",
   cp: 72200,
   telephone: "243481130",
   categorie: "Centre d'Accueil Familial Specialise"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTE LE BOUT DES PAVES PSY 4",
   finessju: 440000289,
   id: 440050557,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240764628",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTE RUE BRUNELIERE PSY 5",
   finessju: 440000289,
   id: 440050565,
   commune: "NANTES",
   cp: 44100,
   telephone: "240697402",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTE RUE HENNEBIQUE PSY 3",
   finessju: 440000289,
   id: 440050540,
   commune: "NANTES",
   cp: 44300,
   telephone: "240491047",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTE RUE TOUR D'AUVERGNE PSY 2",
   finessju: 440000289,
   id: 440050532,
   commune: "NANTES",
   cp: 44200,
   telephone: "251822507",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTE ST EXUPERY CH ST NAZAIRE",
   finessju: 440000057,
   id: 440050508,
   commune: "ST MICHEL CHEF CHEF",
   cp: 44730,
   telephone: "-",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTES",
   finessju: 720000058,
   id: 720014448,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243333399",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTES LE CABESTAN",
   finessju: 440000057,
   id: 440043750,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTES MARAT CH ST NAZAIRE",
   finessju: 440000057,
   id: 440050490,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTES RUE DES MAUGES",
   finessju: 490000676,
   id: 490018447,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "-",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ANDERSEN INFANTO JUVENILE",
   finessju: 720000058,
   id: 720013333,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243756629",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP CH ST NAZAIRE",
   finessju: 440000057,
   id: 440037539,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240220590",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP EMERAUDE ADULTES",
   finessju: 490000676,
   id: 490017670,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "CATTP INTERSECTORIEL BARBARA",
   finessju: 440000289,
   id: 440050524,
   commune: "NANTES",
   cp: 44000,
   telephone: "240206640",
   categorie: "Centre d'Accueil Therapeutique a temps partiel (C.A.T.T.P.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU C.A.E. DE BOUGUENAIS",
   finessju: 440028561,
   id: 440041374,
   commune: "REZE",
   cp: 44400,
   telephone: "-",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU C.A.E. DE BOUGUENAIS",
   finessju: 440028561,
   id: 440041382,
   commune: "REZE",
   cp: 44400,
   telephone: "-",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU C.A.E. DE BOUGUENAIS",
   finessju: 440028561,
   id: 440041424,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "-",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU C.A.E. DE NANTES",
   finessju: 440028561,
   id: 440034262,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240813954",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU C.A.E. DU MANS",
   finessju: 490539194,
   id: 720016526,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243727870",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D ACTION EDUCATIVE",
   finessju: 440028561,
   id: 440041036,
   commune: "NANTES",
   cp: 44000,
   telephone: "240206420",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D ACTION EDUCATIVE",
   finessju: 490539194,
   id: 720007053,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243841414",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'ACTION EDUCATIVE",
   finessju: 440028561,
   id: 440013019,
   commune: "NANTES CEDEX 1",
   cp: 44007,
   telephone: "240145960",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'ACTION EDUCATIVE",
   finessju: 440028561,
   id: 440013027,
   commune: "ST NAZAIRE CEDEX",
   cp: 44611,
   telephone: "240018631",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'ACTION EDUCATIVE",
   finessju: 490539194,
   id: 490539202,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241868282",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'ACTION EDUCATIVE",
   finessju: 490539194,
   id: 530031681,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537869",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'ACTION EDUCATIVE",
   finessju: 440028561,
   id: 850011305,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251361787",
   categorie: "Centre d'Action Educative (C.A.E.)"
 },
 {
   finess: "FINESS",
   name: "AUDACE",
   finessju: 850010968,
   id: 850010976,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251055558",
   categorie: "Centre de Consultations Cancer"
 },
 {
   finess: "FINESS",
   name: "CAMELIA",
   finessju: 530005909,
   id: 530005958,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243493830",
   categorie: "Centre de Consultations Cancer"
 },
 {
   finess: "FINESS",
   name: "CAP SANTE 49",
   finessju: 490015468,
   id: 490015518,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241050892",
   categorie: "Centre de Consultations Cancer"
 },
 {
   finess: "FINESS",
   name: "CAP SANTE PLUS 44",
   finessju: 440046043,
   id: 440046050,
   commune: "NANTES CEDEX 2",
   cp: 44265,
   telephone: "240895065",
   categorie: "Centre de Consultations Cancer"
 },
 {
   finess: "FINESS",
   name: "GEMS",
   finessju: 720017649,
   id: 720017656,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243507777",
   categorie: "Centre de Consultations Cancer"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'HeMODIALYSE -CONFLUENT",
   finessju: 440002590,
   id: 440036499,
   commune: "REZE CEDEX",
   cp: 44402,
   telephone: "-",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'HEMODIALYSE-POLE SANTE SUD",
   finessju: 440002590,
   id: 720017839,
   commune: "LE MANS CEDEX 2",
   cp: 72058,
   telephone: "-",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'HEMODIALYSE-SITE CH",
   finessju: 440002590,
   id: 530002898,
   commune: "LAVAL CEDEX",
   cp: 53015,
   telephone: "243663952",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE DIALYSE DIAVERUM ANGERS",
   finessju: 490011384,
   id: 490537818,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241797676",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE HEMODIALYSE",
   finessju: 440002590,
   id: 490009008,
   commune: "CHOLET CEDEX",
   cp: 49303,
   telephone: "272627403",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE HEMODIALYSE AMBU-ST HERBLAIN",
   finessju: 440002590,
   id: 440036176,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228011782",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE HEMODIALYSE POLYCL ATLANTIQUE",
   finessju: 440002590,
   id: 440055770,
   commune: "ST HERBLAIN CEDEX",
   cp: 44819,
   telephone: "272004072",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CENTRE HEMODIALYSE-BOCQUEL",
   finessju: 440002590,
   id: 490007499,
   commune: "ANGERS",
   cp: 49000,
   telephone: "244857000",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CTRE DIALYSE AMBULATOIRE AUTODIALYSE",
   finessju: 440002590,
   id: 850025156,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "-",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "CTRE HEMODIALYSE -SITE CH DU MANS",
   finessju: 440002590,
   id: 720003441,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243432475",
   categorie: "Centre de dialyse"
 },
 {
   finess: "FINESS",
   name: "ACC JOUR PAVILLON MADELEINE JULIEN",
   finessju: 440001766,
   id: 440046555,
   commune: "NANTES",
   cp: 44000,
   telephone: "240356824",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR AL'FA REPIT",
   finessju: 440042562,
   id: 440042570,
   commune: "DREFFEAC",
   cp: 44530,
   telephone: "240669458",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR AMAD",
   finessju: 850020314,
   id: 850026089,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251543333",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR AUTONOME DU CCAS LAVAL",
   finessju: 530031178,
   id: 530009000,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR DE LA HAUTE MITRIE",
   finessju: 440018406,
   id: 440047678,
   commune: "NANTES",
   cp: 44300,
   telephone: "240522792",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR DES RECOLLETS",
   finessju: 440018406,
   id: 440046860,
   commune: "NANTES",
   cp: 44200,
   telephone: "240893423",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LA PARENTHESE",
   finessju: 720008390,
   id: 720018522,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243952800",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LE MAILLON DES AGES",
   finessju: 440031169,
   id: 440046969,
   commune: "MAISDON SUR SEVRE",
   cp: 44690,
   telephone: "240803792",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LES HUTTIERS",
   finessju: 850012444,
   id: 850011784,
   commune: "MAILLEZAIS",
   cp: 85420,
   telephone: "251872518",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LES MAGNOLIAS",
   finessju: 490018488,
   id: 490016565,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241467602",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LES MOTS BLEUS",
   finessju: 850013384,
   id: 850013509,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251242354",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LES NOELLES",
   finessju: 440018430,
   id: 440046563,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252780",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR PLAISANCE",
   finessju: 440018620,
   id: 440050201,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "228960319",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR RELAIS ET PRESENCE",
   finessju: 490017043,
   id: 490017050,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241630034",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR RETZ' ACCUEIL",
   finessju: 440044600,
   id: 440044618,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240542038",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR SADAPA",
   finessju: 850005075,
   id: 850026550,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251361229",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR SOINS SANTE",
   finessju: 490535663,
   id: 490016862,
   commune: "TIERCE",
   cp: 49125,
   telephone: "230300900",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "AJ ITINERANT AU FIL DE L'AGE",
   finessju: 490017084,
   id: 490017092,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "629142883",
   categorie: "Centre de Jour pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ICO - SITE GAUDUCHEAU",
   finessju: 490017258,
   id: 440001113,
   commune: "ST HERBLAIN CEDEX",
   cp: 44805,
   telephone: "240679900",
   categorie: "Centre de Lutte Contre Cancer"
 },
 {
   finess: "FINESS",
   name: "ICO - SITE PAUL PAPIN",
   finessju: 490017258,
   id: 490000155,
   commune: "ANGERS CEDEX 02",
   cp: 49055,
   telephone: "241352700",
   categorie: "Centre de Lutte Contre Cancer"
 },
 {
   finess: "FINESS",
   name: "ICO: SITE SAINT- AUGUSTIN",
   finessju: 490017258,
   id: 440048700,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre de Lutte Contre Cancer"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE MEDECINE SPORTIVE",
   finessju: 440018539,
   id: 440005247,
   commune: "NANTES",
   cp: 44200,
   telephone: "240474671",
   categorie: "Centre de Medecine Sportive"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE MEDECINE SPORTIVE",
   finessju: 440018554,
   id: 440009769,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240811700",
   categorie: "Centre de Medecine Sportive"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE MEDECINE SPORTIVE",
   finessju: 440018547,
   id: 440009777,
   commune: "REZE",
   cp: 44400,
   telephone: "240757117",
   categorie: "Centre de Medecine Sportive"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE MEDECINE SPORTIVE",
   finessju: 440028751,
   id: 440009785,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240533006",
   categorie: "Centre de Medecine Sportive"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SPORTIF",
   finessju: 720011212,
   id: 720005651,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243863254",
   categorie: "Centre de Medecine Sportive"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO-SPORTIF",
   finessju: 490001856,
   id: 490530920,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241621422",
   categorie: "Centre de Medecine Sportive"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP ARPS",
   finessju: 370002370,
   id: 720017227,
   commune: "SABLE SUR SARTHE CEDEX",
   cp: 72303,
   telephone: "243626364",
   categorie: "Centre de Pre orientation pour Handicapes"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP L'ADAPT",
   finessju: 930019484,
   id: 720017201,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243512041",
   categorie: "Centre de Pre orientation pour Handicapes"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP LES HAUTS THEBAUDIERES",
   finessju: 440002467,
   id: 440049674,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251795000",
   categorie: "Centre de Pre orientation pour Handicapes"
 },
 {
   finess: "FINESS",
   name: "CDS COEUR PAYS DE RETZ",
   finessju: 440011625,
   id: 440025682,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240024779",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE COMMUNAL DE SANTE DE SAUMUR",
   finessju: 490534740,
   id: 490020906,
   commune: "SAUMUR CEDEX",
   cp: 49408,
   telephone: "241677760",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE",
   finessju: 850027806,
   id: 850027814,
   commune: "BAZOGES EN PAREDS",
   cp: 85390,
   telephone: "-",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE - HENRI DUNANT",
   finessju: 490535168,
   id: 530008879,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE LA PYRAMIDE FILIERIS",
   finessju: 420009508,
   id: 490538592,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241181333",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DE L'HOPITAL DUMONTE",
   finessju: 850000043,
   id: 850017252,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251593900",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DE MORANNES",
   finessju: 490535663,
   id: 490018660,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "241720612",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE",
   finessju: 440051951,
   id: 440051969,
   commune: "NANTES",
   cp: 44000,
   telephone: "240896553",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE",
   finessju: 490535168,
   id: 530032549,
   commune: "LAVAL CEDEX",
   cp: 53003,
   telephone: "243564004",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE",
   finessju: 720009810,
   id: 720002351,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243280593",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE",
   finessju: 720009828,
   id: 720004662,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243843226",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE - POLE SANTE",
   finessju: 490535168,
   id: 530008440,
   commune: "CRAON",
   cp: 53400,
   telephone: "253945264",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE BEAUSEJOUR",
   finessju: 750057440,
   id: 440054351,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "607262576",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE DE CHANGE",
   finessju: 490535168,
   id: 530008739,
   commune: "CHANGE",
   cp: 53810,
   telephone: "272884105",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE DE SAUMUR",
   finessju: 490535168,
   id: 490009289,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241403840",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE DENTOCEANE",
   finessju: 440052223,
   id: 440052231,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240646939",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DENTAIRE MUTUALISTE",
   finessju: 490535168,
   id: 490015880,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241199140",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTe DENTAIRE SAINT JACQUES",
   finessju: 440053486,
   id: 440053494,
   commune: "NANTES",
   cp: 44200,
   telephone: "672835772",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE DU SILLON",
   finessju: 440005841,
   id: 440051183,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240630422",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE ET PREVENTION",
   finessju: 440018570,
   id: 440005049,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240805504",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE INFIRMIER DE  VARADES",
   finessju: 440003937,
   id: 440011740,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240983365",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE INFIRMIER DE BENET",
   finessju: 850013434,
   id: 850011404,
   commune: "BENET",
   cp: 85490,
   telephone: "251009719",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE INFIRMIERS",
   finessju: 440003499,
   id: 440055739,
   commune: "ST GEREON",
   cp: 44150,
   telephone: "240830298",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE INFIRMIERS DE POUANCE",
   finessju: 440000313,
   id: 490531639,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241948700",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE INFIRMIERS VAL DU LOIR",
   finessju: 490016144,
   id: 490530904,
   commune: "DURTAL",
   cp: 49430,
   telephone: "241763415",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE MED ST MARS LA JAILLE",
   finessju: 440003937,
   id: 440053361,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240587297",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE MEDICAL DE BELLIGNE",
   finessju: 440003937,
   id: 440054831,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240969697",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE MEDICAL UNIVERSITAIRE",
   finessju: 720018068,
   id: 720018076,
   commune: "LE MANS CEDEX 9",
   cp: 72085,
   telephone: "243833033",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE MUNICIPAL",
   finessju: 850025107,
   id: 850025115,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251572032",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE PLURIDISCIPLINAIRE",
   finessju: 440002590,
   id: 490018678,
   commune: "ANGERS CEDEX 02",
   cp: 49055,
   telephone: "240328525",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE PLURIDISCIPLINAIRE",
   finessju: 440002590,
   id: 720017920,
   commune: "LE MANS",
   cp: 72000,
   telephone: "272004072",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE PLURIPROFESSIONNEL",
   finessju: 490535010,
   id: 490021029,
   commune: "MAUGES SUR LOIRE",
   cp: 49290,
   telephone: "-",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SANTE POLYVALENT",
   finessju: 440011716,
   id: 440054369,
   commune: "VUE",
   cp: 44640,
   telephone: "240642493",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS DE COMBREE",
   finessju: 490534997,
   id: 490531175,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "241942323",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS DE NORT SUR ERDRE",
   finessju: 440010148,
   id: 440025732,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240721167",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIER",
   finessju: 490012184,
   id: 490017746,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241292183",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS",
   finessju: 440005841,
   id: 440011831,
   commune: "NANTES",
   cp: 44300,
   telephone: "240591902",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS ANCENIS",
   finessju: 440003499,
   id: 440011419,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240830298",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS ANDOUILLE",
   finessju: 530029057,
   id: 530002252,
   commune: "ANDOUILLE",
   cp: 53240,
   telephone: "243697326",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS BELLEVUE",
   finessju: 440005841,
   id: 440005858,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240464318",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS DE PALLUAU",
   finessju: 850014119,
   id: 850008053,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "251985478",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS DE PORNIC",
   finessju: 440002707,
   id: 440007631,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240822287",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS INDRE",
   finessju: 440005841,
   id: 440025757,
   commune: "INDRE",
   cp: 44610,
   telephone: "240860372",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS LE BOUPERE",
   finessju: 850013160,
   id: 850009309,
   commune: "LE BOUPERE",
   cp: 85510,
   telephone: "251914483",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS INFIRMIERS MISERICORDE",
   finessju: 720020577,
   id: 720006865,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976016",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS POLYVALENT RAPHAEL",
   finessju: 490537966,
   id: 490531126,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241465425",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS UNION CHRETIENNE",
   finessju: 850008038,
   id: 850011446,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85205,
   telephone: "251694804",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE DENTIFREE",
   finessju: 780022927,
   id: 440052934,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240467349",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE LES PIERRES NOIRES",
   finessju: 440041895,
   id: 440052942,
   commune: "NANTES",
   cp: 44300,
   telephone: "678504187",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 440041895,
   id: 440025187,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240585701",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 440041895,
   id: 440025195,
   commune: "REZE",
   cp: 44400,
   telephone: "240847115",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 440041895,
   id: 440043180,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240178900",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 440041895,
   id: 440043206,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240191903",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 490535168,
   id: 490538535,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241468219",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 490535168,
   id: 530032093,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243701429",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 850013350,
   id: 850011172,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251934565",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 850013350,
   id: 850012055,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251441953",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 850013350,
   id: 850012063,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251216830",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 850013350,
   id: 850019688,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251265331",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE",
   finessju: 850013350,
   id: 850021940,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251344255",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE MUTUALISTE HERBIERS",
   finessju: 850013350,
   id: 850026642,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251441900",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE NANTES OMNIPRATIQUE A",
   finessju: 440041895,
   id: 440052504,
   commune: "NANTES",
   cp: 44000,
   telephone: "240290040",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE NANTES ORTHODONTIE B",
   finessju: 440041895,
   id: 440004901,
   commune: "NANTES",
   cp: 44000,
   telephone: "240290040",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DENTAIRE SAINT ETIENNE DU BOIS",
   finessju: 850013350,
   id: 850026063,
   commune: "ST ETIENNE DU BOIS",
   cp: 85670,
   telephone: "251441900",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE INTERCOMM SANTE ALPES MANCELLES",
   finessju: 720020551,
   id: 720020569,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243343459",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE",
   finessju: 720021047,
   id: 720021054,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243474578",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE",
   finessju: 720021302,
   id: 720021310,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974047",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE",
   finessju: 850026808,
   id: 850026816,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251621602",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE CORONNAIS",
   finessju: 490019551,
   id: 490019569,
   commune: "CORON",
   cp: 49690,
   telephone: "241558172",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE DE CONNERRE",
   finessju: 720019587,
   id: 720019595,
   commune: "CONNERRE",
   cp: 72160,
   telephone: "243898947",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE DE DAMVIX",
   finessju: 850026212,
   id: 850026220,
   commune: "DAMVIX",
   cp: 85420,
   telephone: "251871420",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL DE SANTE L'HUISSERIE",
   finessju: 530008689,
   id: 530008697,
   commune: "L HUISSERIE",
   cp: 53970,
   telephone: "243918060",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE MUNICIPAL ST COSME EN VAIRAIS",
   finessju: 720020437,
   id: 720020445,
   commune: "ST COSME EN VAIRAIS",
   cp: 72110,
   telephone: "243339108",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOINS INFIRMIERS AIGREFEUILLE",
   finessju: 440011757,
   id: 440025583,
   commune: "AIGREFEUILLE SUR MAINE",
   cp: 44140,
   telephone: "240038074",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOINS INFIRMIERS CHATEAUBRIANT",
   finessju: 440018513,
   id: 440011765,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240281153",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOINS INFIRMIERS ROUGE",
   finessju: 440003929,
   id: 440011732,
   commune: "ROUGE",
   cp: 44660,
   telephone: "240287013",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOINS INFIRMIERS SECTEUR BRIERE",
   finessju: 440003895,
   id: 440011724,
   commune: "ST JOACHIM",
   cp: 44720,
   telephone: "240884302",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CIS MeDICAL DU PAYS CALAISIEN",
   finessju: 720020692,
   id: 720020700,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631393",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI A2SA ANGERS",
   finessju: 490535218,
   id: 490020492,
   commune: "ANGERS CEDEX 01",
   cp: 49010,
   telephone: "241662766",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ADMR DE LA SEVRE",
   finessju: 850013582,
   id: 850011412,
   commune: "LES EPESSES",
   cp: 85590,
   telephone: "251573751",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ADMR DE SOUGE / MESLAY",
   finessju: 530008317,
   id: 530029065,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243984011",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ANGLES",
   finessju: 850014093,
   id: 850003021,
   commune: "ANGLES",
   cp: 85750,
   telephone: "221975532",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ASSIRNO",
   finessju: 440010155,
   id: 440026052,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240794112",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI BELLE BEILLE",
   finessju: 490535663,
   id: 490528551,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241480653",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI BOUAYE",
   finessju: 440003382,
   id: 440010007,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "240326162",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI BRISEPOTIERE",
   finessju: 490535663,
   id: 490531050,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241438072",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI CENTRE VILLE",
   finessju: 440005841,
   id: 440025724,
   commune: "NANTES",
   cp: 44000,
   telephone: "240200852",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI CHANTONNAY",
   finessju: 850024506,
   id: 850024514,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943134",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI COUERON",
   finessju: 440005841,
   id: 440010098,
   commune: "COUERON",
   cp: 44220,
   telephone: "240861457",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI DE CHAILLE LES MARAIS",
   finessju: 850024563,
   id: 850002098,
   commune: "CHAILLE LES MARAIS",
   cp: 85450,
   telephone: "251567138",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI DE RUILLE SUR LOIR",
   finessju: 720014810,
   id: 720006717,
   commune: "LOIR EN VALLEE",
   cp: 72340,
   telephone: "243445400",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI DE SAINT NAZAIRE",
   finessju: 440052363,
   id: 440052371,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "632659730",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI DERVAL-SION LES MINES",
   finessju: 440003929,
   id: 440052470,
   commune: "SION LES MINES",
   cp: 44590,
   telephone: "240070124",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI DOUE LA FONTAINE",
   finessju: 490001740,
   id: 490528395,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241592052",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI DOULON",
   finessju: 440005841,
   id: 440005874,
   commune: "NANTES",
   cp: 44300,
   telephone: "240494529",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI EDOUARD BRANLY LA ROCHE SUR YON",
   finessju: 850013129,
   id: 850002304,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251377821",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI JEANNE RIVEREAU",
   finessju: 490535010,
   id: 490531258,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241777087",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI LA CHAIZE LE VICOMTE",
   finessju: 850005570,
   id: 850003039,
   commune: "LA CHAIZE LE VICOMTE",
   cp: 85310,
   telephone: "251057055",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI LA MOTHE ACHARD",
   finessju: 850006081,
   id: 850006818,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251056644",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI LES HERBIERS",
   finessju: 850006396,
   id: 850002387,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251671043",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI LES LUCS SUR BOULOGNE",
   finessju: 850000613,
   id: 850011131,
   commune: "LES LUCS SUR BOULOGNE",
   cp: 85170,
   telephone: "251465022",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI LES PONTS DE CE",
   finessju: 490536554,
   id: 490020856,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241666800",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI LES SORINIERES",
   finessju: 440003382,
   id: 440054732,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240130013",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI MADELEINE SAINT LEONARD",
   finessju: 490537495,
   id: 490537503,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241442841",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI MAREUIL SUR LAY DISSAIS",
   finessju: 850014135,
   id: 850011115,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251305484",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI MONPLAISIR",
   finessju: 490535663,
   id: 490528387,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241438830",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI NANTES CHAMP DE TIR",
   finessju: 440005841,
   id: 440051167,
   commune: "NANTES",
   cp: 44300,
   telephone: "240499704",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI NANTES CONSTITUTION",
   finessju: 440005841,
   id: 440051191,
   commune: "NANTES",
   cp: 44100,
   telephone: "240951087",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ORVAULT",
   finessju: 440005841,
   id: 440051175,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240631100",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SAINT GEORGES SUR LOIRE",
   finessju: 490001880,
   id: 490531167,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241393039",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SAINT MACAIRE EN MAUGES",
   finessju: 490001757,
   id: 490528429,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241553322",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SAINT PIERRE LA COUR",
   finessju: 530029081,
   id: 530002492,
   commune: "ST PIERRE LA COUR",
   cp: 53410,
   telephone: "243018393",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SANTE A DOMICILE",
   finessju: 440011773,
   id: 440025773,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240785008",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SERVANTES DES PAUVRES",
   finessju: 490535838,
   id: 490528320,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241663830",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SERVANTES DES PAUVRES",
   finessju: 490535838,
   id: 490528338,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241877118",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SOIN SANTE",
   finessju: 440011716,
   id: 440025674,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240217088",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI SOLESMES",
   finessju: 720001411,
   id: 720004282,
   commune: "SOLESMES",
   cp: 72300,
   telephone: "243954540",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ST HILAIRE DE RIEZ",
   finessju: 850013905,
   id: 850006826,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251543390",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ST HILAIRE DES LOGES",
   finessju: 850013145,
   id: 850002445,
   commune: "ST HILAIRE DES LOGES",
   cp: 85240,
   telephone: "251521096",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI ST MICHEL EN L'HERM",
   finessju: 850013137,
   id: 850011438,
   commune: "ST MICHEL EN L HERM",
   cp: 85580,
   telephone: "251302230",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI TIERCE",
   finessju: 490535663,
   id: 490528361,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241426491",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI TRELAZE",
   finessju: 490535663,
   id: 490531654,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241181871",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI VERNEAU",
   finessju: 490535663,
   id: 490528379,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241484408",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI VIE A DOMICILE",
   finessju: 490536554,
   id: 490542933,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241666800",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CSI VILLEVEQUE",
   finessju: 490535663,
   id: 490531092,
   commune: "VILLEVEQUE",
   cp: 49140,
   telephone: "241695521",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CTRE SANTE DENTAIRE CHATEAU D'OLONNE",
   finessju: 850013350,
   id: 850018250,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251204080",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CTRE SANTE MEDICAL DE L'ECHO",
   finessju: 440002590,
   id: 440039899,
   commune: "NANTES",
   cp: 44200,
   telephone: "272004072",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CTRE SANTE MEDICAL NEPHROLOGIQUE",
   finessju: 440002590,
   id: 850017377,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85109,
   telephone: "251217800",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "PaLE SANTE SIMONE VEIL",
   finessju: 720015601,
   id: 720018860,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243939393",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "SUMPPS",
   finessju: 440042810,
   id: 440049344,
   commune: "NANTES CEDEX 3",
   cp: 44322,
   telephone: "240371050",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "SUMPPS",
   finessju: 490018454,
   id: 490018462,
   commune: "ANGERS CEDEX 01",
   cp: 49001,
   telephone: "241226910",
   categorie: "Centre de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE VACCINATION",
   finessju: 720011188,
   id: 720005735,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243849797",
   categorie: "Centre de Vaccination BCG"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE VACCINATION INTERNATIONAL",
   finessju: 850018581,
   id: 850017369,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251442195",
   categorie: "Centre de Vaccination BCG"
 },
 {
   finess: "FINESS",
   name: "CECOS NANTES",
   finessju: 350025359,
   id: 440028686,
   commune: "NANTES",
   cp: 44000,
   telephone: "240483054",
   categorie: "Centre d'Examens de Sante"
 },
 {
   finess: "FINESS",
   name: "CENTRE D EXAMENS DE SANTE",
   finessju: 850013327,
   id: 850003518,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251373386",
   categorie: "Centre d'Examens de Sante"
 },
 {
   finess: "FINESS",
   name: "CES DE LOIRE-ATLANTIQUE",
   finessju: 440050987,
   id: 440011435,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "240908680",
   categorie: "Centre d'Examens de Sante"
 },
 {
   finess: "FINESS",
   name: "IRSA",
   finessju: 370011157,
   id: 490011467,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241253050",
   categorie: "Centre d'Examens de Sante"
 },
 {
   finess: "FINESS",
   name: "IRSA CHOLET",
   finessju: 370011157,
   id: 490016292,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241719281",
   categorie: "Centre d'Examens de Sante"
 },
 {
   finess: "FINESS",
   name: "ATELIERS CHRS",
   finessju: 720019207,
   id: 720016765,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243235160",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "C.H.R.S.",
   finessju: 850017773,
   id: 850023789,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85104,
   telephone: "251214341",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "C.H.R.S. ABRI DE LA PROVIDENCE",
   finessju: 490544277,
   id: 490531811,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241253040",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "C.H.R.S. AIDE ACCUEIL",
   finessju: 490004231,
   id: 490007655,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241253560",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "C.H.R.S. CITE LA GAUTRECHE",
   finessju: 750720591,
   id: 490534799,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49510,
   telephone: "241631031",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "C.H.R.S. S.O.S. FEMMES",
   finessju: 490004264,
   id: 490539343,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241879722",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CCAS POINT ACCUEIL SANTE SOLIDARITE",
   finessju: 490534732,
   id: 490014149,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241888740",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS",
   finessju: 850013236,
   id: 850027699,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251626930",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS ABRI DES CORDELIERS",
   finessju: 490004223,
   id: 490539327,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241588081",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS AMETIS - CENTRE DE VIE ST YVES",
   finessju: 440026482,
   id: 440012581,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240800202",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS ANEF FERRER",
   finessju: 440018422,
   id: 440048254,
   commune: "NANTES",
   cp: 44200,
   telephone: "240480533",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS BON PASTEUR 49 BETHANIE",
   finessju: 490534823,
   id: 490531555,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241482140",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS BON PASTEUR PELLETIER",
   finessju: 490534823,
   id: 490531506,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241716845",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS COPAINVILLE",
   finessju: 530000827,
   id: 530029628,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243302500",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS CTRE D'ADAPTATION A LA VIE ACTIVE",
   finessju: 490534849,
   id: 490532009,
   commune: "SAUMUR CEDEX",
   cp: 49411,
   telephone: "241673181",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS D'HEBERGEMENT TARMAC",
   finessju: 720019207,
   id: 720011998,
   commune: "SARGE LES LE MANS",
   cp: 72190,
   telephone: "272164570",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS FRANCE HORIZON",
   finessju: 750806606,
   id: 440045995,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240354851",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS FRANCE HORIZON",
   finessju: 750806606,
   id: 490534955,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241470195",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS L'ETAPE",
   finessju: 440018646,
   id: 440013670,
   commune: "NANTES",
   cp: 44100,
   telephone: "251836400",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS LA PARENTHESE",
   finessju: 440018406,
   id: 440026599,
   commune: "NANTES",
   cp: 44300,
   telephone: "240993084",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS LA RESIDENCE",
   finessju: 440052769,
   id: 440017630,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240533763",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS LA SABLIERE",
   finessju: 850020413,
   id: 850003997,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690557",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS LE 102 GAMBETTA",
   finessju: 440052769,
   id: 440052777,
   commune: "NANTES",
   cp: 44000,
   telephone: "240290118",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS LE VAL",
   finessju: 440012664,
   id: 440026516,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "682553861",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS LES DEUX RIVES",
   finessju: 530000819,
   id: 530032481,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243668855",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS REVIVRE REGROUPE",
   finessju: 530031459,
   id: 530009018,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531523",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS SOLIDARITE FEMMES LA",
   finessju: 440004737,
   id: 440017978,
   commune: "NANTES",
   cp: 44000,
   telephone: "240120960",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS SOS FEMMES VENDEE",
   finessju: 850021882,
   id: 850021890,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "251477759",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "CHRS TRAJET",
   finessju: 440002533,
   id: 440004968,
   commune: "REZE",
   cp: 44400,
   telephone: "251110000",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "FRANCE HORIZON CHRS LE MANS",
   finessju: 750806606,
   id: 720011824,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243426465",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "TARMAC CHRS ACCUEIL DE JOUR",
   finessju: 720019207,
   id: 720016740,
   commune: "LE MANS CEDEX 2",
   cp: 72016,
   telephone: "243769445",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "VEILLE SOCIALE ABRI DE LA PROVIDENCE",
   finessju: 490544277,
   id: 490014198,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241253044",
   categorie: "Centre Hebergement & Reinsertion Sociale (C.H.R.S.)"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL FAMILIAL THERAPEUTIQUE",
   finessju: 530000371,
   id: 530007285,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243664687",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "APPARTEMENT THERAPEUTIQUE",
   finessju: 440000057,
   id: 440033744,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "APPARTEMENT THERAPEUTIQUE",
   finessju: 440000057,
   id: 440053510,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "APPARTEMENT THeRAPEUTIQUE",
   finessju: 440000057,
   id: 440053502,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CATTP ADULTES CHNM",
   finessju: 530000074,
   id: 530007632,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243082245",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER -SITE DE LUCON",
   finessju: 850000019,
   id: 850000209,
   commune: "LUCON CEDEX",
   cp: 85407,
   telephone: "251283333",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER -SITE DE MONTAIGU",
   finessju: 850000019,
   id: 850000225,
   commune: "MONTAIGU CEDEX",
   cp: 85602,
   telephone: "251454000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER ANCENIS",
   finessju: 440053643,
   id: 440000396,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "240094400",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER CHATEAU DU LOIR",
   finessju: 720000066,
   id: 720000124,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243447777",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER CHATEAUBRIANT",
   finessju: 440000313,
   id: 440000503,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44146,
   telephone: "240558800",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER COTE DE LUMI�RE",
   finessju: 850000084,
   id: 850000241,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251218580",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE CHOLET",
   finessju: 490000676,
   id: 490000635,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "241496000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE LAVAL",
   finessju: 530000371,
   id: 530000264,
   commune: "LAVAL CEDEX",
   cp: 53015,
   telephone: "243665000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE MAUBREUIL",
   finessju: 440000065,
   id: 440000024,
   commune: "ST HERBLAIN CEDEX",
   cp: 44819,
   telephone: "240682727",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE SAINT- NAZAIRE",
   finessju: 440000057,
   id: 440000016,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "272278000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE SAUMUR",
   finessju: 490528452,
   id: 490001765,
   commune: "SAUMUR CEDEX",
   cp: 49403,
   telephone: "241533120",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DU HAUT ANJOU",
   finessju: 530000025,
   id: 530000017,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53204,
   telephone: "243093333",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DU MANS",
   finessju: 720000025,
   id: 720000033,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243434343",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER LA FERTE BERNARD",
   finessju: 720006022,
   id: 720001437,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72401,
   telephone: "243716151",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER MAMERS",
   finessju: 610780082,
   id: 720000470,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243313131",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER NORD MAYENNE",
   finessju: 530000074,
   id: 530000173,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243087300",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER NOZAY",
   finessju: 440000313,
   id: 440000677,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240794755",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER POUANCE",
   finessju: 440000313,
   id: 490000569,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241948700",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER ST CALAIS",
   finessju: 720000140,
   id: 720000520,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243636465",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER-SITE LA ROCHE/YON",
   finessju: 850000019,
   id: 850000142,
   commune: "LA ROCHE SUR YON CEDEX 9",
   cp: 85925,
   telephone: "251446161",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH FONTENAY SITE POLE SANTE SUD VENDEE",
   finessju: 850000035,
   id: 850009630,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH FONTENAY- LE- COMTE  SITE RABELAIS",
   finessju: 850000035,
   id: 850000183,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "251535153",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH HAUT ANJOU SITE SEGRE",
   finessju: 530000025,
   id: 490532173,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241610444",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH HAUT ANJOU SITE SEGRE  (RUE CUGNOT)",
   finessju: 530000025,
   id: 490020245,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH LVO :SITE DE  CHALLANS",
   finessju: 850009010,
   id: 850000175,
   commune: "CHALLANS CEDEX",
   cp: 85302,
   telephone: "251495000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH LVO: SITE DE MACHECOUL",
   finessju: 850009010,
   id: 440000560,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240784400",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH NORD MAYENNE PSY HTP JOUR LA PLAINE",
   finessju: 530000074,
   id: 530009588,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243087683",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CH ST NAZAIRE - HEINLEX",
   finessju: 440000057,
   id: 440007607,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240906060",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DE CHATEAU GONTIER",
   finessju: 530000025,
   id: 530007707,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243095577",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES CHATEAU GONTIER",
   finessju: 530000025,
   id: 530004258,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243707310",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 490000676,
   id: 490016839,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE MONT DE VIE",
   finessju: 490000676,
   id: 490018439,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CMP HIPPOLYTE CHAUVIERE SITE ST GILLES",
   finessju: 850009010,
   id: 850020652,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251558984",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CRECHE COLLECTIVE C.H. ST NAZAIRE",
   finessju: 440000057,
   id: 440046688,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240906398",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "CTRE DE PLANIFICATION - CH CHOLET -",
   finessju: 490000676,
   id: 490539293,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "241496000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HDJ PSYCHIATRIE INFANTO-JUVENILE",
   finessju: 440000057,
   id: 440053296,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOP JOUR CMP ADULTES BEAUPREAU",
   finessju: 490000676,
   id: 490542628,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241635117",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOP JOUR PEDO-PSY CH ST NAZAIRE",
   finessju: 440000057,
   id: 440013654,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240534759",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL A DOMICILE CH ST NAZAIRE",
   finessju: 440000057,
   id: 440017440,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240534751",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR LE SEMAPHORE",
   finessju: 850009010,
   id: 850006479,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "228120871",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR LAVAL EST",
   finessju: 530000371,
   id: 530030121,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR LAVAL OUEST",
   finessju: 530000371,
   id: 530003177,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PEDOPSYCHIATRIE",
   finessju: 530000371,
   id: 530004548,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY - CH DE SAUMUR",
   finessju: 490528452,
   id: 490011418,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241407034",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSYCHIATRIE -",
   finessju: 490000676,
   id: 490011269,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE ST-GILLES- CROIX- DE- VIE",
   finessju: 850009010,
   id: 850000233,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251608300",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL DE CANDE",
   finessju: 440053643,
   id: 490000296,
   commune: "CANDE",
   cp: 49440,
   telephone: "241927360",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "POLE SANTE SARTHE ET LOIR",
   finessju: 720016724,
   id: 720016179,
   commune: "LE BAILLEUL",
   cp: 72200,
   telephone: "244713000",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "PSYCHIATRIE GENERALE HTP  - HEINLEX",
   finessju: 440000057,
   id: 440053452,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE PEDOPSYCHIATRIE CH LAVAL",
   finessju: 530000371,
   id: 530031301,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531014",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE PSYCHIATRIE ADULTE LAVALLOIS",
   finessju: 530000371,
   id: 530003466,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "UNITe CHIMIO CH LAVAL SITE POLYCL DU M",
   finessju: 530000371,
   id: 530008671,
   commune: "LAVAL CEDEX",
   cp: 53010,
   telephone: "-",
   categorie: "Centre Hospitalier (C.H.)"
 },
 {
   finess: "FINESS",
   name: "ANTENNE SMUR - SITE CH ANCENIS",
   finessju: 440000289,
   id: 440053007,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU D' ANGERS :SITE LARREY",
   finessju: 490000031,
   id: 490000049,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "241353637",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU D' ANGERS:CENTRE DE SSR",
   finessju: 490000031,
   id: 490529005,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241353079",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU DE NANTES - SITE SAINT-JACQUES",
   finessju: 440000289,
   id: 440003291,
   commune: "NANTES",
   cp: 44200,
   telephone: "240083333",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU DE NANTES : HOPITAL G. R. LAENNEC",
   finessju: 440000289,
   id: 440017598,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240083333",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU DE NANTES :SITE HOPITAL BELLIER",
   finessju: 440000289,
   id: 440000453,
   commune: "NANTES CEDEX 1",
   cp: 44046,
   telephone: "240686660",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU DE NANTES :SITE HaTEL-DIEU- HME",
   finessju: 440000289,
   id: 440000271,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240087323",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CHU NANTES SITE CLINIQUE JULES VERNE",
   finessju: 440000289,
   id: 440044428,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANT JUVENIL DOUET GARNIER",
   finessju: 440000289,
   id: 440044261,
   commune: "NANTES CEDEX 1",
   cp: 44006,
   telephone: "240762105",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE CTRE NANTAI",
   finessju: 440000289,
   id: 440044279,
   commune: "NANTES",
   cp: 44000,
   telephone: "228088490",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE HTE ROCHE",
   finessju: 440000289,
   id: 440044246,
   commune: "NANTES CEDEX 4",
   cp: 44101,
   telephone: "240749494",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE MALAKOFF",
   finessju: 440000289,
   id: 440044253,
   commune: "NANTES",
   cp: 44000,
   telephone: "240895645",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CRECHE DU CHU DE NANTES",
   finessju: 440000289,
   id: 440046308,
   commune: "NANTES",
   cp: 44200,
   telephone: "240846704",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "CRECHE FAMIL. ST-JACQUES CHU NANTES",
   finessju: 440000289,
   id: 440018273,
   commune: "NANTES",
   cp: 44200,
   telephone: "240846697",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOP DE JOUR PSY ENFANTS IPEA DENVER",
   finessju: 440000289,
   id: 440055747,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOP JOUR PEDO-PSY CHU NANTES",
   finessju: 440000289,
   id: 440011864,
   commune: "NANTES",
   cp: 44000,
   telephone: "240749494",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOP PSY ST-JACQUES - CHU NANTES",
   finessju: 440000289,
   id: 440021392,
   commune: "NANTES",
   cp: 44200,
   telephone: "240483333",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PEDOPSY",
   finessju: 440000289,
   id: 440044436,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY ADULTES",
   finessju: 440000289,
   id: 440044345,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY ADULTES",
   finessju: 440000289,
   id: 440044352,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY ADULTES",
   finessju: 440000289,
   id: 440044360,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY ADULTES",
   finessju: 440000289,
   id: 440044378,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY ADULTES",
   finessju: 440000289,
   id: 440044394,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSY ADULTES",
   finessju: 440000289,
   id: 440044402,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PSYCHOGERIAT BELLIER",
   finessju: 440000289,
   id: 440044386,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR ENFANTS CHU DE NANTES",
   finessju: 440000289,
   id: 440034460,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "HOTEL THERAPEUTIQUE PSYCHIATRIQUE",
   finessju: 440000289,
   id: 440044410,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "IRM CHU ANGERS RADIOLOGIE CHAPELLE",
   finessju: 490000031,
   id: 490020971,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "241354832",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "MAISON BEAUSeJOUR - SITE SSR",
   finessju: 440000289,
   id: 440050458,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240686600",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "MAISON PIRMIL - SITE SSR",
   finessju: 440000289,
   id: 440050466,
   commune: "NANTES",
   cp: 44200,
   telephone: "-",
   categorie: "Centre Hospitalier Regional (C.H.R.)"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL FAMILIAL THERAPEUTIQUE ENFANTS",
   finessju: 440003309,
   id: 440052397,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "251829310",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "ATELIER LA FLEUR VERTE",
   finessju: 850000092,
   id: 850016858,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251052413",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "ATELIER LA SIMBRANDIERE",
   finessju: 850000092,
   id: 850016841,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251373624",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "C.H.S. ROCHE MORNA - CESAME STE GEMMES",
   finessju: 490000163,
   id: 490002581,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241807908",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "C.M.P. C.A.T.T.P. ADOS (C.A.S.A.)",
   finessju: 440000263,
   id: 440034411,
   commune: "NANTES CEDEX 3",
   cp: 44323,
   telephone: "251771200",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CATTP CMP ADULTES",
   finessju: 490000163,
   id: 490010089,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241807712",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CATTP CMP ADULTES",
   finessju: 490000163,
   id: 490010469,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241807669",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CATTP CMP ADULTES HOPITAL DE JOUR",
   finessju: 490000163,
   id: 490010709,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241807640",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CATTP CMP HOPITAL DE JOUR ADULTES",
   finessju: 490000163,
   id: 490010659,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241807686",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CATTP CMP HOPITAL JOUR ADULTES",
   finessju: 490000163,
   id: 490010568,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241807655",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE PSYCHIATRIE INFANTO-JUVENILE",
   finessju: 490000163,
   id: 490019148,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CENTRE HELENE CHAIGNEAU",
   finessju: 720000058,
   id: 720019157,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243502160",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER G. MAZURELLE",
   finessju: 850000092,
   id: 850000258,
   commune: "LA ROCHE SUR YON CEDEX 9",
   cp: 85925,
   telephone: "251097272",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER GEORGES DAUMEZON",
   finessju: 440003309,
   id: 440001972,
   commune: "BOUGUENAIS CEDEX",
   cp: 44342,
   telephone: "251829300",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CENTRE INTERSECTORIEL D'ADDICTOLOGIE",
   finessju: 720000058,
   id: 720013762,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243760909",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CESAME STE GEMMES SUR LOIRE",
   finessju: 490000163,
   id: 490000106,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241807908",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CHS BLAIN",
   finessju: 440000263,
   id: 440000180,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240515151",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CHS MAZURELLE - HDJ CH FONTENAY",
   finessju: 850000092,
   id: 850025941,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP  ADULTES",
   finessju: 440000263,
   id: 440045664,
   commune: "PETIT MARS",
   cp: 44390,
   telephone: "228249841",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES SITE LA CHATAIGNERAIE",
   finessju: 850000092,
   id: 850020637,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251526128",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES SITE SAINTE HERMINE",
   finessju: 850000092,
   id: 850018490,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251273106",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 440000263,
   id: 440023778,
   commune: "ST GEREON",
   cp: 44150,
   telephone: "240961641",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 440000263,
   id: 440026722,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240568490",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 440000263,
   id: 440027308,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240511617",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 440000263,
   id: 440051225,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "228249841",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 720000058,
   id: 720007749,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243313158",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 720000058,
   id: 720007798,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243945061",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 720000058,
   id: 720007988,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243161540",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 720000058,
   id: 720014604,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243716271",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 720000058,
   id: 720020452,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243520458",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES",
   finessju: 850000092,
   id: 850025644,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251210727",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES CAMILLE CLAUDEL",
   finessju: 850000092,
   id: 850016833,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251050437",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES DOUE LA FONTAINE",
   finessju: 490000163,
   id: 490010758,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241807721",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES LA JANIERE",
   finessju: 490000163,
   id: 490010378,
   commune: "ST GERMAIN DES PRES",
   cp: 49170,
   telephone: "241807703",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES LA MARTINIERE",
   finessju: 720000058,
   id: 720007939,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "244713393",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES PAUL VERLAINE",
   finessju: 850000092,
   id: 850025636,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251460254",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP ADULTES ST CALAIS",
   finessju: 720000058,
   id: 720014620,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243359888",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP HOP JOUR CASIA INFANTO JUVEN",
   finessju: 490000163,
   id: 490009669,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241807660",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP HOPITAL DE JOUR ADULTES",
   finessju: 490000163,
   id: 490010428,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241807630",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP HOPITAL DE JOUR ADULTES",
   finessju: 490000163,
   id: 490010519,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241807621",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP HOPITAL JOUR ADULTES BAUGE",
   finessju: 490000163,
   id: 490016318,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241807648",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP HOPITAL JOUR ENFTS ADOS",
   finessju: 440000263,
   id: 440028983,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240798680",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 440000263,
   id: 440028967,
   commune: "NANTES CEDEX 3",
   cp: 44323,
   telephone: "251771200",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720008978,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243624706",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720012103,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243241600",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720012111,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243762411",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720020460,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243975070",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720020478,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243945061",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 850000092,
   id: 850006594,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251477869",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 850000092,
   id: 850020702,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251063249",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 850000092,
   id: 850020983,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251979582",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE",
   finessju: 850000092,
   id: 850021130,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251669002",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP TOULOUSE LAUTREC ADULTES",
   finessju: 850000092,
   id: 850006289,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251473096",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CMPI ADULTES L'ARC EN CIEL",
   finessju: 850000092,
   id: 850016866,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251376811",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CTRE SOINS SANTE MENTALE FRANTZ FANON",
   finessju: 720000058,
   id: 720007889,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243435250",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "EP DE SANTE MENTALE DE LA SARTHE",
   finessju: 720000058,
   id: 720000041,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243435151",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "FOYER ROCHELOIRE -  PONTS DE CE",
   finessju: 490000163,
   id: 490003282,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241446211",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  ADULTES  CMP CATTP  LES HERBIERS",
   finessju: 850000092,
   id: 850018466,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251668106",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  ADULTES CMP CATTP  CHANTONNAY",
   finessju: 850000092,
   id: 850018458,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251944676",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  INF JUV   CMP CATTP   LUCON",
   finessju: 850000092,
   id: 850022542,
   commune: "LUCON",
   cp: 85400,
   telephone: "251279138",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  INF JUV   CMP CATTP  CHALLANS",
   finessju: 850000092,
   id: 850018375,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251354408",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  INF JUV  CMP CATTP  CHANTONNAY",
   finessju: 850000092,
   id: 850020629,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251944312",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  INF JUV  CMP CATTP  FONTENAY LE C",
   finessju: 850000092,
   id: 850011917,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251691908",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ  INF JUV  CMP CATTP  LES HERBIERS",
   finessju: 850000092,
   id: 850011008,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ ADULTES  CMP CATTP  LUCON",
   finessju: 850000092,
   id: 850021122,
   commune: "LUCON",
   cp: 85400,
   telephone: "251290017",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ ADULTES CMP CATTP LA ROCHE",
   finessju: 850000092,
   id: 850020611,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371421",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ ADULTES CMP CATTP MONTAIGU",
   finessju: 850000092,
   id: 850011875,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251941660",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ ADULTES LES SABLES D'OLONNE",
   finessju: 850000092,
   id: 850011883,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251211017",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ CATTP ADULTES BOURG BELE",
   finessju: 720000058,
   id: 720019900,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ CMP  CATTP  ADULTES",
   finessju: 440000263,
   id: 440028975,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240283350",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ CMP CATTP LE TRAIN BLEU",
   finessju: 850000092,
   id: 850020686,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371108",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ CMP ET CATTP INFANTO JUVENILE",
   finessju: 440000263,
   id: 440036879,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240280788",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ ET CMP/CATTP POUR ADOLESCEN",
   finessju: 440000263,
   id: 440054724,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ INF JUV CMP CATTP LA CHATAIGNERAIE",
   finessju: 850000092,
   id: 850020975,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251526073",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ INF JUV ELLINE CMP CATTP LA ROCHE",
   finessju: 850000092,
   id: 850017815,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ INF JUV JEAN ITARD LES SABLES",
   finessju: 850000092,
   id: 850004417,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320081",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ INF JUV WINICOTT CMP CATTP LA ROCH",
   finessju: 850000092,
   id: 850006552,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251089240",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HDJ-CMP-CATTP PSY ADULTES HERIC",
   finessju: 440000263,
   id: 440054377,
   commune: "HERIC",
   cp: 44810,
   telephone: "240515164",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOP JOUR  CMP  CATTP ADULTES LE PHARE",
   finessju: 440000263,
   id: 440007714,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240881867",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOP JOUR CMP ENFTS LA TANNERIE",
   finessju: 440003309,
   id: 440030674,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "240338486",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR",
   finessju: 440003309,
   id: 440003820,
   commune: "REZE",
   cp: 44400,
   telephone: "240754611",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR",
   finessju: 440000263,
   id: 440034403,
   commune: "NANTES CEDEX 3",
   cp: 44323,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR - SQ DES JONCHERES",
   finessju: 490000163,
   id: 490011459,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR - ST GERMAIN DES PRES",
   finessju: 490000163,
   id: 490011608,
   commune: "ST GERMAIN DES PRES",
   cp: 49170,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR ADULTES",
   finessju: 490000163,
   id: 490010048,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241807966",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR ADULTES",
   finessju: 490000163,
   id: 490010139,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241807854",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR ADULTES - MUSSET",
   finessju: 490000163,
   id: 490011558,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR CATTP PEDOPSY ANGERS",
   finessju: 490000163,
   id: 490011749,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR CMP CATTP ADULTES",
   finessju: 490000163,
   id: 490009958,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241807610",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR DE GERONTO PSYCHIATRIE",
   finessju: 440000263,
   id: 440034437,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "240014886",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR ET CMP ADULTES",
   finessju: 440003309,
   id: 440025328,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251710435",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR ET CMP ADULTES REZE",
   finessju: 440003309,
   id: 440017457,
   commune: "REZE",
   cp: 44400,
   telephone: "240840611",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR OREE",
   finessju: 440003309,
   id: 440050482,
   commune: "MONTBERT",
   cp: 44140,
   telephone: "240054159",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR PEDO-PSY.",
   finessju: 440003309,
   id: 440021574,
   commune: "NANTES",
   cp: 44200,
   telephone: "240478686",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CATTP ADULTE L'ARANTELE",
   finessju: 490000163,
   id: 490013489,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CATTP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720016922,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243161090",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP ADULTES BOIS MARINIER",
   finessju: 440003309,
   id: 440026177,
   commune: "REZE",
   cp: 44400,
   telephone: "240040107",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP ADULTES LA NOUAISON",
   finessju: 440003309,
   id: 440033538,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240540898",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP ADULTES LES PIBALES",
   finessju: 440003309,
   id: 440033157,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "240056136",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP CATTP ADULTES",
   finessju: 720000058,
   id: 720012251,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243500671",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP CHAMP LIBRE ADULTES",
   finessju: 440003309,
   id: 440034445,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240787910",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP INFANTO JUVENILE",
   finessju: 440003309,
   id: 440025302,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240786100",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP INFANTO JUVENILE",
   finessju: 440003309,
   id: 440033520,
   commune: "GORGES",
   cp: 44190,
   telephone: "240547323",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR CMP MADELEINE ENFANTS",
   finessju: 440003309,
   id: 440035418,
   commune: "NANTES",
   cp: 44000,
   telephone: "240205113",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR ET CMP ADULTES",
   finessju: 440003309,
   id: 440025310,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240314548",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "HOPITAL JOUR ISATIS INFANTO JUVENILE",
   finessju: 720000058,
   id: 720014372,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243785666",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "INSTITUT DEP DE THERAPIE FAMILIALE",
   finessju: 720000058,
   id: 720017706,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243422541",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "PE TRANSVERSAL CATTP",
   finessju: 440000263,
   id: 440054799,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "PLACEMENT FAMILIAL THERAP INFANTO JUV",
   finessju: 440003309,
   id: 440047496,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "PLACEMENT FAMILIAL THERAPEUTIQ ADULTES",
   finessju: 440000263,
   id: 440047504,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "SHIP - SITE CH DAUMEZON",
   finessju: 440003309,
   id: 440046001,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "UNITE PEDPSYCHIATRIQUE ACCUEIL ET HOSP",
   finessju: 720000058,
   id: 720019181,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Centre Hospitalier Specialise lutte Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "BAUGEOIS VALLeE: SITE DE BEAUFORT",
   finessju: 490015765,
   id: 490000254,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241575000",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "BAUGEOIS VALLeE:SITE DE BAUGe",
   finessju: 490015765,
   id: 490000239,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241841384",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE LONGUe",
   finessju: 490000411,
   id: 490000353,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241536363",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DE SAVENAY",
   finessju: 440000859,
   id: 440001295,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240575000",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "CENTRE HOSPITALIER DOUE EN ANJOU",
   finessju: 490000403,
   id: 490000338,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241834610",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "CH LAYON-AUBANCE MARTIGNE BRIAND",
   finessju: 490000429,
   id: 490000494,
   commune: "TERRANJOU",
   cp: 49540,
   telephone: "241835555",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DU HAUT MAINE ET PAIL",
   finessju: 530002591,
   id: 530007806,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243082890",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "CSI HOPITAL  DE CRAON",
   finessju: 530007202,
   id: 530029115,
   commune: "CRAON",
   cp: 53400,
   telephone: "243061226",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HI DE LA  PRESQU'ILE :SITE DE GUERANDE",
   finessju: 440028538,
   id: 440001253,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240626540",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HI DE LA PRESQU'ILE: SITE DU  CROISIC",
   finessju: 440028538,
   id: 440001261,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240626600",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HL  BONNETABLE - CENTRE SOINS DE SUITE",
   finessju: 720002062,
   id: 720000819,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243294500",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HL SILLE LE GUILLAUME-CENTRE SOINS SUI",
   finessju: 720007244,
   id: 720001569,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243525354",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOP NOIRMOUTIER SSR NON SPEC. ADULTES",
   finessju: 850000100,
   id: 850022013,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390399",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL  DE VERTOU",
   finessju: 440042141,
   id: 440000883,
   commune: "VERTOU CEDEX",
   cp: 44122,
   telephone: "240808000",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL  DU LOROUX- BOTTEREAU",
   finessju: 440042141,
   id: 440000552,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251711515",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL CORNICHE ANGEVINE a CHALONNES",
   finessju: 490000395,
   id: 490000320,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241742700",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE PAIMBOEUF",
   finessju: 440041531,
   id: 440001279,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "228532822",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE PORNIC LA CHAUSSEE",
   finessju: 440041531,
   id: 440001287,
   commune: "PORNIC",
   cp: 44210,
   telephone: "251747878",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DES COLLINES VENDEENNES",
   finessju: 850025867,
   id: 850000647,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251536565",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL FRAN�OIS DE DAILLON AU LUDE",
   finessju: 720000090,
   id: 720000314,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243484848",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL CORCOUE S/LOGNE",
   finessju: 440000347,
   id: 440000644,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251116500",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL DE CLISSON",
   finessju: 440003267,
   id: 440001964,
   commune: "CLISSON CEDEX",
   cp: 44191,
   telephone: "240801919",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL ERNEE",
   finessju: 530000058,
   id: 530000140,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243083131",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL EVRON",
   finessju: 530000066,
   id: 530000165,
   commune: "EVRON",
   cp: 53600,
   telephone: "243666666",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL ILE D'YEU",
   finessju: 850000043,
   id: 850000191,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251260800",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL INTERCOMMUNAL LYS HYROME",
   finessju: 490007689,
   id: 490000650,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241462424",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL INTERCOMMUNAL LYS HYROME",
   finessju: 490007689,
   id: 490001831,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241758036",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL SAINT LOUIS",
   finessju: 490002334,
   id: 490001146,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241396060",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL LOCAL VILLAINES LA JUHEL",
   finessju: 530002591,
   id: 530000611,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243087000",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL S.O. MAYENNAIS :SITE DE CRAON",
   finessju: 530007202,
   id: 530000132,
   commune: "CRAON",
   cp: 53400,
   telephone: "243093232",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "HOPITAL S.O. MAYENNAIS :SITE DE RENAZE",
   finessju: 530007202,
   id: 530000181,
   commune: "RENAZE",
   cp: 53800,
   telephone: "243092700",
   categorie: "Centre hospitalier, ex H�pital local"
 },
 {
   finess: "FINESS",
   name: "C.M.P. ADULTES C.H. ST-NAZAIRE",
   finessju: 440000057,
   id: 440037588,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "228539041",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "C.M.P. C.H. ST-NAZAIRE ADULTES",
   finessju: 440000057,
   id: 440027290,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240668387",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "C.M.P. INFANTO-JUVENILE",
   finessju: 720000058,
   id: 720014570,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243447777",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "C.M.P. INFANTO-JUVENILE",
   finessju: 850000092,
   id: 850020991,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390399",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO PSYCHOLOGIQUE",
   finessju: 720000058,
   id: 720012244,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243161542",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES",
   finessju: 490000676,
   id: 490008638,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241497170",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES",
   finessju: 490000676,
   id: 490008679,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241497170",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES",
   finessju: 530000074,
   id: 530003110,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243058385",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES CH ST NAZAIRE",
   finessju: 440000057,
   id: 440026698,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240906460",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES CH ST NAZAIRE",
   finessju: 440000057,
   id: 440042273,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240248806",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES CHU DE NANTES",
   finessju: 440000289,
   id: 440025377,
   commune: "NANTES",
   cp: 44300,
   telephone: "251843875",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES CTRE C MILCENDEAU",
   finessju: 850009010,
   id: 850020645,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251496290",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES CTRE HOSPITALIER CHALLANS",
   finessju: 850009010,
   id: 850020678,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251260800",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES FONTENAY LE COMTE",
   finessju: 850000035,
   id: 850009218,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251532853",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES LA PEROUSE CHU DE NANTES",
   finessju: 440000289,
   id: 440025385,
   commune: "NANTES",
   cp: 44000,
   telephone: "240731800",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES LAVAL EST",
   finessju: 530000371,
   id: 530003144,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES LAVAL OUEST",
   finessju: 530000371,
   id: 530003151,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES MAYENNE",
   finessju: 530000074,
   id: 530005768,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243304898",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES RUE SEIGNEUR SAUMUR",
   finessju: 490528452,
   id: 490012218,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241500313",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ADULTES VILLAINES LA JUHEL",
   finessju: 530000074,
   id: 530003102,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243037544",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP CATTP INFANTO JUVENILE SAMOTHRACE",
   finessju: 440000289,
   id: 440044287,
   commune: "NANTES",
   cp: 44300,
   telephone: "240596060",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP ENFTS ADULTES EST EVRON",
   finessju: 530000371,
   id: 530004498,
   commune: "EVRON",
   cp: 53600,
   telephone: "243013247",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP FRANCOIS TOSQUELLES INFANTO JUVENI",
   finessju: 490000163,
   id: 490009818,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241807693",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE",
   finessju: 490000163,
   id: 490009628,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241807653",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE",
   finessju: 490528452,
   id: 490011889,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241898228",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720007699,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243201862",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE",
   finessju: 720000058,
   id: 720012095,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243503701",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE",
   finessju: 850000092,
   id: 850018441,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251947386",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE",
   finessju: 850000092,
   id: 850022518,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251583113",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE ANCENIS",
   finessju: 440018729,
   id: 440025922,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240960404",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE ARSENE LELOUP",
   finessju: 440018729,
   id: 440009173,
   commune: "NANTES",
   cp: 44100,
   telephone: "240733117",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE CENTRE BOTTIERE",
   finessju: 440018729,
   id: 440025401,
   commune: "NANTES",
   cp: 44300,
   telephone: "240493459",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE CH LAVAL",
   finessju: 530000371,
   id: 530031830,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243057842",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE CHATEAUBRIANT",
   finessju: 440018729,
   id: 440004166,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810099",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE DU PAYS BLANC",
   finessju: 440000057,
   id: 440025336,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240248263",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE MAMERS",
   finessju: 720000058,
   id: 720012418,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243311661",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE PAYS DE RETZ",
   finessju: 440000057,
   id: 440025351,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820291",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE PINEAU CHAILLOU",
   finessju: 440018729,
   id: 440025906,
   commune: "NANTES",
   cp: 44300,
   telephone: "240768053",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE PONTCHATEAU",
   finessju: 440018729,
   id: 440026706,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240880379",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE REZE",
   finessju: 440018729,
   id: 440025419,
   commune: "REZE",
   cp: 44400,
   telephone: "240754597",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE SECTEUR EST",
   finessju: 720000058,
   id: 720011808,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243601540",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE SEGRE",
   finessju: 490000163,
   id: 490009859,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241807627",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE ST GEORGES SUR LO",
   finessju: 490000163,
   id: 490009909,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241807741",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE ST HERBLAIN",
   finessju: 440018729,
   id: 440025369,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240460267",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE ST NAZAIRE",
   finessju: 440018729,
   id: 440005015,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240702663",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO JUVENILE TRELAZE",
   finessju: 490000163,
   id: 490009719,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241807694",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP INFANTO-JUVENILE",
   finessju: 490528452,
   id: 490011939,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241839861",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP L'ILE A HELICE INFANTO JUVENILE",
   finessju: 440003309,
   id: 440026615,
   commune: "NANTES",
   cp: 44200,
   telephone: "240479924",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP L'OREE INFANTO JUVENILE",
   finessju: 490528452,
   id: 490531803,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241401180",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMP LES EMBRUNS",
   finessju: 850009010,
   id: 850020942,
   commune: "LA BARRE DE MONTS",
   cp: 85550,
   telephone: "251394170",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "ESP D ACC ET DE SOINS GIVERNY",
   finessju: 440003309,
   id: 440044295,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240089880",
   categorie: "Centre Medico-Psychologique (C.M.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP ANDRES PONTOIZEAU",
   finessju: 490020310,
   id: 850003070,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251242323",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP ARPEP ANTENNE",
   finessju: 490020310,
   id: 720018852,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243760860",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP ASEA",
   finessju: 490534849,
   id: 490000122,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241721838",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP DE KERBRUN",
   finessju: 440018612,
   id: 440024685,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240702017",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP ESPACE FRANCOISE DOLTO",
   finessju: 490020310,
   id: 720000272,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243811443",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP HENRI WALLON",
   finessju: 440000248,
   id: 440024677,
   commune: "NANTES",
   cp: 44100,
   telephone: "232905260",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP INALTA",
   finessju: 720008853,
   id: 530000280,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243560700",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CMPP SASAD CANCER ET ENFANCE",
   finessju: 490541026,
   id: 490541034,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241730761",
   categorie: "Centre Medico-Psycho-Pedagogique (C.M.P.P.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 440018935,
   id: 440025617,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240830981",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 440012615,
   id: 440026029,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240791125",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 440012839,
   id: 440026037,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240723385",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 440021087,
   id: 440026045,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240342038",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 530031335,
   id: 530029669,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531520",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 530031343,
   id: 530029677,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243041758",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 530031285,
   id: 530029685,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243071487",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 720011964,
   id: 720006063,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976570",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 720012897,
   id: 720006071,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950465",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 720012913,
   id: 720006774,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243452252",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004458,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372190",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004466,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371243",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004474,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251370162",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004482,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371921",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004490,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251930721",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004508,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692933",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004516,
   commune: "LUCON",
   cp: 85400,
   telephone: "251561258",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004524,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251940187",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004532,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251550616",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850004540,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251583113",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850012212,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910579",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850012220,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696183",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850012238,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251210252",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850012246,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251373558",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SCOLAIRE",
   finessju: 850024050,
   id: 850012253,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251360980",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO-SCOLAIRE",
   finessju: 440028751,
   id: 440011468,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225746",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO-SCOLAIRE",
   finessju: 440018554,
   id: 440012623,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810504",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO-SCOLAIRE",
   finessju: 440018570,
   id: 440012631,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240343493",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO-SCOLAIRE",
   finessju: 440018547,
   id: 440012797,
   commune: "REZE",
   cp: 44400,
   telephone: "240751224",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "SERVICE MUNICIPAL D'HYGIENE",
   finessju: 720011212,
   id: 720006766,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243849797",
   categorie: "Centre Medico-Scolaire"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL FAMILIAL",
   finessju: 440018737,
   id: 440007615,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240220172",
   categorie: "Centre Placement Familial Socio-Educatif (C.P.F.S.E.)"
 },
 {
   finess: "FINESS",
   name: "SEAD L'ESCABELLE",
   finessju: 720008853,
   id: 720011741,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243445991",
   categorie: "Centre Placement Familial Socio-Educatif (C.P.F.S.E.)"
 },
 {
   finess: "FINESS",
   name: "SERAF MONTJOIE",
   finessju: 720008705,
   id: 720006741,
   commune: "SARGE LES LE MANS",
   cp: 72190,
   telephone: "243866201",
   categorie: "Centre Placement Familial Socio-Educatif (C.P.F.S.E.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE ACCUEIL FAMILIAL SPECIALISE",
   finessju: 440004281,
   id: 440036648,
   commune: "NANTES CEDEX 2",
   cp: 44265,
   telephone: "240080567",
   categorie: "Centre Placement Familial Socio-Educatif (C.P.F.S.E.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D ACCUEIL FAMILIAL SPECIALISE",
   finessju: 530031426,
   id: 530005891,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243268990",
   categorie: "Centre Placement Familial Socio-Educatif (C.P.F.S.E.)"
 },
 {
   finess: "FINESS",
   name: "CENT PLANIF D EDUCAT FAMILIALE",
   finessju: 530031285,
   id: 530029719,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243560017",
   categorie: "Centre Planification ou Education Familiale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE PLANIFICATION",
   finessju: 530031285,
   id: 530032671,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243041239",
   categorie: "Centre Planification ou Education Familiale"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE PLANIFICATION FAMILIALE",
   finessju: 530031285,
   id: 530007061,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243096868",
   categorie: "Centre Planification ou Education Familiale"
 },
 {
   finess: "FINESS",
   name: "CENTRE PLANIF. FAMILIAL",
   finessju: 720000983,
   id: 720002336,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243249184",
   categorie: "Centre Planification ou Education Familiale"
 },
 {
   finess: "FINESS",
   name: "CENTRE PLANIFICATION FAMILIALE",
   finessju: 440043040,
   id: 440025203,
   commune: "NANTES",
   cp: 44000,
   telephone: "240204151",
   categorie: "Centre Planification ou Education Familiale"
 },
 {
   finess: "FINESS",
   name: "CENT. DE POST-CURE PSY - SITE LA MAING",
   finessju: 440007482,
   id: 440043792,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "-",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "CENT. POST-CURE PSY LE CLOS TOREAU",
   finessju: 440007482,
   id: 440043800,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "-",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "CENTRE LA CHICOTIERE",
   finessju: 440018729,
   id: 440004943,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240464810",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "CENTRE POST CURE LES BRIORDS",
   finessju: 440007482,
   id: 440002624,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240509075",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "CENTRE POST-CURE PSY - RUE DES ALOUETT",
   finessju: 440007482,
   id: 440043818,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOINS-ETUDES PIERRE DAGUET",
   finessju: 750720575,
   id: 720018100,
   commune: "SABLE SUR SARTHE CEDEX",
   cp: 72303,
   telephone: "244552081",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "FOYER POST CURE LA PORTE ST MICHEL",
   finessju: 850012436,
   id: 850011529,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690749",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE JOUR LES APSYADES",
   finessju: 440018729,
   id: 440051308,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Centre Postcure Malades Mentaux"
 },
 {
   finess: "FINESS",
   name: "CENTRE NANTAIS HEBERGEMENT REFUGIES",
   finessju: 440018406,
   id: 440007730,
   commune: "NANTES",
   cp: 44100,
   telephone: "228019845",
   categorie: "Centre Provisoire Hebergement (C.P.H.)"
 },
 {
   finess: "FINESS",
   name: "CPH ANGERS FRANCE TERRE D'ASILE",
   finessju: 750806598,
   id: 490020286,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241880183",
   categorie: "Centre Provisoire Hebergement (C.P.H.)"
 },
 {
   finess: "FINESS",
   name: "CPH AREAMS",
   finessju: 850020413,
   id: 850027434,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478518",
   categorie: "Centre Provisoire Hebergement (C.P.H.)"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP",
   finessju: 370002370,
   id: 720002278,
   commune: "SABLE SUR SARTHE CEDEX",
   cp: 72303,
   telephone: "243626364",
   categorie: "Centre Reeducation Professionnelle"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP L'ADAPT",
   finessju: 930019484,
   id: 720008465,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243512041",
   categorie: "Centre Reeducation Professionnelle"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP LA TOURMALINE",
   finessju: 440042844,
   id: 440036440,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240385959",
   categorie: "Centre Reeducation Professionnelle"
 },
 {
   finess: "FINESS",
   name: "CPO/CRP LES HAUTS THEBAUDIERES",
   finessju: 440002467,
   id: 440039618,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251795000",
   categorie: "Centre Reeducation Professionnelle"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC BELLEVUE",
   finessju: 440054864,
   id: 440054872,
   commune: "NANTES",
   cp: 44100,
   telephone: "240464422",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC BOURDERIES",
   finessju: 440054864,
   id: 440054880,
   commune: "NANTES",
   cp: 44100,
   telephone: "240464146",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC BOUT DES LANDES",
   finessju: 440054864,
   id: 440054948,
   commune: "NANTES",
   cp: 44300,
   telephone: "240761552",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC BOUT DES PAVES",
   finessju: 440054864,
   id: 440055002,
   commune: "NANTES",
   cp: 44300,
   telephone: "240401916",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC CENTRE VILLE",
   finessju: 440054864,
   id: 440054955,
   commune: "NANTES",
   cp: 44000,
   telephone: "240352121",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC CLOS TOREAU",
   finessju: 440054864,
   id: 440055010,
   commune: "NANTES",
   cp: 44200,
   telephone: "240341927",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC DE LA BOISSIERE",
   finessju: 440054864,
   id: 440054930,
   commune: "NANTES",
   cp: 44300,
   telephone: "240769685",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC DE LA BOTTIERE",
   finessju: 440054864,
   id: 440054997,
   commune: "NANTES",
   cp: 44300,
   telephone: "240496220",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC MALAKOFF",
   finessju: 440054864,
   id: 440054971,
   commune: "NANTES",
   cp: 44000,
   telephone: "240477053",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC PETITE SENSIVE",
   finessju: 440054864,
   id: 440054989,
   commune: "NANTES",
   cp: 44300,
   telephone: "240406963",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC PILOTIERE",
   finessju: 440054864,
   id: 440055036,
   commune: "NANTES",
   cp: 44300,
   telephone: "240503244",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD CSC PORT BOYER",
   finessju: 440054864,
   id: 440055044,
   commune: "NANTES",
   cp: 44300,
   telephone: "240492181",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD MAISON DE QUARTIER DE DOULON",
   finessju: 440054864,
   id: 440054963,
   commune: "NANTES",
   cp: 44300,
   telephone: "240506040",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD MAISON DE QUARTIER DE L ILE",
   finessju: 440054864,
   id: 440054914,
   commune: "NANTES",
   cp: 44200,
   telephone: "240486101",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD MAISON DE QUARTIER HALVEQUE",
   finessju: 440054864,
   id: 440055028,
   commune: "NANTES",
   cp: 44300,
   telephone: "240499410",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCOORD MAISON QUARTIER DERVALLIERES",
   finessju: 440054864,
   id: 440054906,
   commune: "NANTES",
   cp: 44100,
   telephone: "240460217",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCORD CSC DU PERRAY HALUCHERE",
   finessju: 440054864,
   id: 440054922,
   commune: "NANTES",
   cp: 44300,
   telephone: "240497469",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ACCORD MAISON QUARTIER BREIL MALVILLE",
   finessju: 440054864,
   id: 440054898,
   commune: "NANTES",
   cp: 44100,
   telephone: "240760854",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "ASEC DU BOURG",
   finessju: 440055077,
   id: 440055085,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252270",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'ECONOMIE FAMILIALE",
   finessju: 720009794,
   id: 720003789,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943480",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE DU PATIS ST LAZARE",
   finessju: 720009794,
   id: 720003722,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243247024",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 530031335,
   id: 530029529,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531802",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 530031335,
   id: 530029743,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243532501",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 530031335,
   id: 530029750,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243539627",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 530031335,
   id: 530029768,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243561540",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 530031418,
   id: 530029776,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243567292",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 530000843,
   id: 530030022,
   commune: "EVRON",
   cp: 53600,
   telephone: "243016465",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL",
   finessju: 720012939,
   id: 720008531,
   commune: "MULSANNE",
   cp: 72230,
   telephone: "243428395",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO SOCIAL INTERCOM.",
   finessju: 490008406,
   id: 490008414,
   commune: "SEICHES SUR LE LOIR",
   cp: 49140,
   telephone: "-",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 490535739,
   id: 490004066,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241803147",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 490535739,
   id: 490004074,
   commune: "CANDE",
   cp: 49440,
   telephone: "241881757",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 490535739,
   id: 490004082,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241591110",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 490535739,
   id: 490004090,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241881757",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 490534880,
   id: 490004199,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241910056",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 490531613,
   id: 490007341,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241305055",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720003698,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930445",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720003714,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976190",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720003763,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243350495",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720001346,
   id: 720003946,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243273677",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720003953,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243293383",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720003961,
   commune: "BOULOIRE",
   cp: 72440,
   telephone: "243354170",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720001353,
   id: 720003979,
   commune: "LA CHARTRE SUR LE LOIR",
   cp: 72340,
   telephone: "243444097",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720003995,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930375",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004001,
   commune: "VILLENEUVE EN PERSEIGNE",
   cp: 72600,
   telephone: "243978008",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004019,
   commune: "LOUE",
   cp: 72540,
   telephone: "243274117",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720011956,
   id: 720004027,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243409276",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004035,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243946329",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004043,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976306",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004050,
   commune: "MAYET",
   cp: 72360,
   telephone: "243446054",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720001379,
   id: 720004068,
   commune: "MONTFORT LE GESNOIS",
   cp: 72450,
   telephone: "243297025",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004076,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974008",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004084,
   commune: "NOYEN SUR SARTHE",
   cp: 72430,
   telephone: "243948748",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004092,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950300",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004100,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243350200",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720004118,
   commune: "ST GERVAIS EN BELIN",
   cp: 72220,
   telephone: "243270259",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720004332,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943480",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720004340,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243201039",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720004357,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243245062",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720004365,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243440212",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009794,
   id: 720007210,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940177",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720001643,
   id: 720008515,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243971289",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720011337,
   id: 720008549,
   commune: "VIBRAYE",
   cp: 72320,
   telephone: "243936923",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720011329,
   id: 720008556,
   commune: "LA SUZE SUR SARTHE",
   cp: 72210,
   telephone: "243203170",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720009802,
   id: 720011303,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940559",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL",
   finessju: 720001726,
   id: 720011311,
   commune: "OISSEAU LE PETIT",
   cp: 72610,
   telephone: "243269076",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL 49",
   finessju: 490534880,
   id: 490004033,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241886354",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL BELLE-BEILLE",
   finessju: 490534880,
   id: 490004140,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241483217",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL BELLEVUE",
   finessju: 720009794,
   id: 720003797,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243814530",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL BIGARREAUX",
   finessju: 720009794,
   id: 720003805,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243840888",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL CITE VERNEAU",
   finessju: 490534880,
   id: 490004157,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241483319",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL COCHEREAUX",
   finessju: 720009794,
   id: 720003656,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243284471",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL CONLIE",
   finessju: 720001361,
   id: 720003987,
   commune: "CONLIE",
   cp: 72240,
   telephone: "243521166",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DE LA DOUTRE",
   finessju: 490534880,
   id: 490004165,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241875290",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DE LA HAIE",
   finessju: 490004116,
   id: 490007317,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241622744",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DE LA ROSERAIE",
   finessju: 490534880,
   id: 490004124,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241664176",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DES FORGES",
   finessju: 850012535,
   id: 850012394,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251050740",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DES PLAINES",
   finessju: 490004041,
   id: 490007283,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241663830",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DES PYRAMIDES",
   finessju: 850012535,
   id: 850004334,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251620300",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL DU CHEMIN VERT",
   finessju: 490534880,
   id: 490004108,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502173",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL EPINE",
   finessju: 720009794,
   id: 720004324,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243283827",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL ET CULTUREL",
   finessju: 720011212,
   id: 720008523,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243845950",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL ET SOCIO CULTUREL",
   finessju: 490004207,
   id: 490007325,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241426009",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL FUNAY",
   finessju: 720009794,
   id: 720003664,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243843088",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL JANOT",
   finessju: 490534880,
   id: 490004181,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241874566",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL JEAN DIMA",
   finessju: 490007762,
   id: 490007770,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241597709",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL LA MADELEINE",
   finessju: 490534880,
   id: 490004173,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241668020",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL MAILLETS",
   finessju: 720009794,
   id: 720003706,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243853876",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL PASTEUR",
   finessju: 490525037,
   id: 490007333,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241555952",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL SABLE",
   finessju: 720009794,
   id: 720003730,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950290",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL SABLONS I",
   finessju: 720009794,
   id: 720003748,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243840719",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL SABLONS II",
   finessju: 720009794,
   id: 720003755,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243846066",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL ST ANDRE D ORNAY",
   finessju: 850012535,
   id: 850012402,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251622899",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL STE CROIX",
   finessju: 720009794,
   id: 720003771,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243812302",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIAL ZUP MONTPLAISIR",
   finessju: 490534880,
   id: 490004132,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241377360",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030048,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243530794",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030055,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243566265",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030063,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243530125",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030071,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243563964",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030089,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243530083",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030097,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243538047",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030105,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243538488",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 530031897,
   id: 530030113,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537728",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL",
   finessju: 720009794,
   id: 720003649,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243287562",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL DU BUISSON",
   finessju: 490538766,
   id: 490007366,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241690491",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL HENRI NORMAND",
   finessju: 440055598,
   id: 440055606,
   commune: "COUERON",
   cp: 44220,
   telephone: "240383199",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIO CULTUREL SOLEIL LEVANT",
   finessju: 440055119,
   id: 440055127,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252690",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL DE L ALLEE VERTE",
   finessju: 440055457,
   id: 440055465,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240331688",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL DE LA BUGALLIERE",
   finessju: 440055556,
   id: 440055564,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240634445",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL DE LA FONTAINE",
   finessju: 440055473,
   id: 440055481,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240343414",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL DE PLAISANCE",
   finessju: 440055531,
   id: 440055549,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240769447",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL DE RAGON",
   finessju: 440055176,
   id: 440055184,
   commune: "REZE",
   cp: 44400,
   telephone: "228252940",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL DU CHATEAU",
   finessju: 440055242,
   id: 440055259,
   commune: "REZE",
   cp: 44400,
   telephone: "251707570",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL JAUNAIS BLORDIERE",
   finessju: 440055150,
   id: 440055168,
   commune: "REZE",
   cp: 44400,
   telephone: "228444000",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL LE GRAND B",
   finessju: 440055093,
   id: 440055101,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252290",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL LOIRE DIVATTE",
   finessju: 440055630,
   id: 440055648,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240368776",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL LOIRE ET SEIL",
   finessju: 440055267,
   id: 440055275,
   commune: "REZE",
   cp: 44400,
   telephone: "240480139",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOCIOCULTUREL MUNICIPAL",
   finessju: 440055317,
   id: 440055325,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240680605",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CSC DU SILLON DE BRETAGNE",
   finessju: 440055135,
   id: 440055143,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252680",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CSC INTERCOMMUNAL MIREILLE MOYON",
   finessju: 440055333,
   id: 440055341,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "240275177",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CSC MAISON POUR TOUS DE BOUGUENAIS",
   finessju: 440055291,
   id: 440055309,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320212",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CSC PIERRE LEGENDRE",
   finessju: 440055614,
   id: 440055622,
   commune: "COUERON",
   cp: 44220,
   telephone: "240861342",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CSC TEMPO",
   finessju: 440055515,
   id: 440055523,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240871258",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "CTRE SOCIAL ET SOCIO CULTUREL",
   finessju: 490004058,
   id: 490007291,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630633",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "LE RELAIS",
   finessju: 440018679,
   id: 440055283,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "251706920",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "MAISON DE QUARTIER CHESNAIE TREBALE",
   finessju: 440055432,
   id: 440055440,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "228559990",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "MAISON DE QUARTIER DE MEAN PENHOET",
   finessju: 440055390,
   id: 440055408,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240660450",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "MAISON DE QUARTIER KERLEDE",
   finessju: 440055374,
   id: 440055382,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240535000",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "MAISON DE QUARTIER LA BOULETTERIE",
   finessju: 440055358,
   id: 440055366,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240703522",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "MAISON POUR TOUS",
   finessju: 440055499,
   id: 440055507,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240936819",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "MAISON QUARTIER AVALIX QUARTIERS NORD",
   finessju: 440055416,
   id: 440055424,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240709592",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "OFFICE SOCIO CULTUREL DONGES",
   finessju: 440055051,
   id: 440055069,
   commune: "DONGES",
   cp: 44480,
   telephone: "240910055",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "OFFICE SOCIO CULTUREL MONTOIRIN",
   finessju: 440055572,
   id: 440055580,
   commune: "MONTOIR DE BRETAGNE",
   cp: 44550,
   telephone: "240885876",
   categorie: "Centre Social"
 },
 {
   finess: "FINESS",
   name: "COMMUNAUTE THERAPEUTIQUE PRE EN PAIL",
   finessju: 720008705,
   id: 530007343,
   commune: "PRE EN PAIL ST SAMSON",
   cp: 53140,
   telephone: "-",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA",
   finessju: 530000371,
   id: 530007236,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243268860",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA 49",
   finessju: 490016813,
   id: 490537248,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241474737",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA AID",
   finessju: 720008705,
   id: 720008275,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243141540",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA ANPAA 85",
   finessju: 750713406,
   id: 850009580,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251620772",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA COORDINATION",
   finessju: 440018729,
   id: 440051449,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240731218",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA LA METAIRIE",
   finessju: 850007048,
   id: 850020918,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251052339",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA LA ROSE DES VENTS",
   finessju: 750054157,
   id: 440030013,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240019612",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA LE TRIANGLE",
   finessju: 750054157,
   id: 440012011,
   commune: "NANTES",
   cp: 44000,
   telephone: "240484858",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA MAISON D'ARR�T",
   finessju: 440000289,
   id: 440030526,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240534618",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CSAPA MOLIERE",
   finessju: 720008390,
   id: 720015791,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243503240",
   categorie: "Centre soins accompagnement prevention addictologie (CSAPA)"
 },
 {
   finess: "FINESS",
   name: "CRA",
   finessju: 490000031,
   id: 440042885,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240949400",
   categorie: "Centres de Ressources S.A.I. (Sans Aucune Indication)"
 },
 {
   finess: "FINESS",
   name: "CRA",
   finessju: 490000031,
   id: 490016128,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Centres de Ressources S.A.I. (Sans Aucune Indication)"
 },
 {
   finess: "FINESS",
   name: "CLIC ATOUT'AGE",
   finessju: 440050573,
   id: 440050581,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251719589",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC CHARLES GARNIER",
   finessju: 720019231,
   id: 720019249,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243359116",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC CIDPA LE MANS",
   finessju: 720019215,
   id: 720019223,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243814040",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC COMMUNAUTE COMMUNES PAYS FLECHOIS",
   finessju: 720019256,
   id: 720019264,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243486617",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC COUERON SAUTRON",
   finessju: 440053577,
   id: 440053585,
   commune: "COUERON",
   cp: 44220,
   telephone: "-",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC COURONNE EST",
   finessju: 720019272,
   id: 720019280,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243610349",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC COURONNE MNO",
   finessju: 720019298,
   id: 720019306,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243211020",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DE L'ANJOU BLEU",
   finessju: 490018397,
   id: 490018405,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241922494",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DE LOIRE EN LAYON",
   finessju: 490018413,
   id: 490018421,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "241806813",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DES 2 TERRITOIRES BONNETABLE",
   finessju: 720019421,
   id: 720019488,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243290001",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DES 2 TERRITOIRES MONTFORT",
   finessju: 720019421,
   id: 720019439,
   commune: "MONTFORT LE GESNOIS",
   cp: 72450,
   telephone: "243767025",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS D'ANCENIS",
   finessju: 440050664,
   id: 440050672,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240961251",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DE CHATEAUBRIANT",
   finessju: 440050599,
   id: 440050607,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "228040585",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DE CRAON",
   finessju: 530007657,
   id: 530007665,
   commune: "CRAON",
   cp: 53400,
   telephone: "243093064",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DE LOIRON",
   finessju: 530007814,
   id: 530007822,
   commune: "LOIRON RUILLE",
   cp: 53320,
   telephone: "243260283",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DE MAYENNE",
   finessju: 530007673,
   id: 530007681,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243080916",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DE MESLAY GREZ",
   finessju: 530005578,
   id: 530007749,
   commune: "GREZ EN BOUERE",
   cp: 53290,
   telephone: "243067994",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DES COEVRONS",
   finessju: 530000066,
   id: 530007780,
   commune: "EVRON",
   cp: 53600,
   telephone: "243666666",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS DES MAUGES",
   finessju: 490018371,
   id: 490018389,
   commune: "BEAUPREAU CEDEX",
   cp: 49602,
   telephone: "241717700",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU PAYS SAUMUROIS",
   finessju: 490018355,
   id: 490018363,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241514750",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC DU SAOSNOIS",
   finessju: 720019363,
   id: 720019371,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243972547",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC ECLAIR'AGE",
   finessju: 440043255,
   id: 440050615,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240626464",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC ERDRE ET GESVRES REGION DE BLAIN",
   finessju: 440050888,
   id: 440050896,
   commune: "GRANDCHAMPS DES FONTAINES",
   cp: 44119,
   telephone: "228022545",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC GERONTOLOGIQUE CC PORNIC",
   finessju: 440050847,
   id: 440050854,
   commune: "PORNIC CEDEX",
   cp: 44215,
   telephone: "800307712",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC GERONTOLOGIQUE D'ANGERS",
   finessju: 490018249,
   id: 490018256,
   commune: "ANGERS",
   cp: 49100,
   telephone: "800250800",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC GUIPA DU SUD ESTUAIRE",
   finessju: 440050722,
   id: 440050730,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "240277512",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC HUISNE ET BRAYE",
   finessju: 720009687,
   id: 720019314,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72402,
   telephone: "243607823",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC IGEAC",
   finessju: 490018322,
   id: 490018330,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241302634",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC INTERCOMMUNAL LOIRE ET ERDRE",
   finessju: 440050706,
   id: 440050714,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "228232651",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC LAVAL AGGLOMERATION",
   finessju: 530007756,
   id: 530007764,
   commune: "LAVAL",
   cp: 53000,
   telephone: "253227003",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC LOIR LUCE BERCE",
   finessju: 720019322,
   id: 720019330,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243447833",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC LOIRE ACHENEAU",
   finessju: 440050805,
   id: 440050813,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240694110",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC LOIRE AUTHION",
   finessju: 490018280,
   id: 490018298,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241457882",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC NANTES ENTOUR'AGE",
   finessju: 440050748,
   id: 440050755,
   commune: "NANTES CEDEX 1",
   cp: 44036,
   telephone: "240992980",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC NORD EST ANJOU",
   finessju: 490018264,
   id: 490018272,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891454",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC NORD SARTHE",
   finessju: 720019348,
   id: 720019355,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243311313",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC ORVAULT LA CHAPELLE-SUR-ERDRE",
   finessju: 440018455,
   id: 440053569,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "-",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC PASS'AGES",
   finessju: 440050680,
   id: 440050698,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "251709337",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC PAYS ERNEE ET BOCAGE MAYENNAIS",
   finessju: 530007715,
   id: 530007723,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243033713",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC PILOT'AGE",
   finessju: 440055218,
   id: 440050771,
   commune: "ST NAZAIRE CEDEX",
   cp: 44614,
   telephone: "240171920",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC PONTCHATEAU ST GILDAS DES BOIS",
   finessju: 440050862,
   id: 440050870,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240426193",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC SARTHE NORD OUEST",
   finessju: 720019389,
   id: 720019397,
   commune: "CONLIE",
   cp: 72240,
   telephone: "243279562",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC SENIORS INDRE ST HERBLAIN",
   finessju: 440018430,
   id: 440050797,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252786",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC SUD SARTHE",
   finessju: 720019405,
   id: 720019413,
   commune: "PONTVALLAIN",
   cp: 72510,
   telephone: "243792310",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC VALLEE DE CLISSON",
   finessju: 440050821,
   id: 440050839,
   commune: "ST HILAIRE DE CLISSON",
   cp: 44190,
   telephone: "285521639",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC VALLEE DE LA SARTHE",
   finessju: 720019447,
   id: 720019454,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243957272",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC VILLES VILL'AGES",
   finessju: 440050649,
   id: 440050656,
   commune: "VERTOU",
   cp: 44120,
   telephone: "228018224",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "CLIC VIVRE SON AGE",
   finessju: 440050623,
   id: 440050631,
   commune: "LA CHEVROLIERE",
   cp: 44118,
   telephone: "240360642",
   categorie: "Centres Locaux Information Coordination P.A .(C.L.I.C.)"
 },
 {
   finess: "FINESS",
   name: "AGENCE DEP PREVENTION SPECIALISEE",
   finessju: 440054666,
   id: 440044311,
   commune: "NANTES",
   cp: 44200,
   telephone: "240636340",
   categorie: "Club Equipe de Prevention"
 },
 {
   finess: "FINESS",
   name: "ASEA PREVENTION SPECIALISEE",
   finessju: 490534849,
   id: 490528973,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241185858",
   categorie: "Club Equipe de Prevention"
 },
 {
   finess: "FINESS",
   name: "ASS NAZAIRIENNE PREVENTION SPECIALISEE",
   finessju: 440051092,
   id: 440033207,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251760881",
   categorie: "Club Equipe de Prevention"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE PREVENTION SPECIALISEE 72",
   finessju: 720008853,
   id: 720005826,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243283120",
   categorie: "Club Equipe de Prevention"
 },
 {
   finess: "FINESS",
   name: "SERVICE PREVENTION SPECIALISEE 53",
   finessju: 720008853,
   id: 530029990,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537892",
   categorie: "Club Equipe de Prevention"
 },
 {
   finess: "FINESS",
   name: "C.A.A.R.U.D AIDES 53",
   finessju: 930013768,
   id: 530007483,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243492443",
   categorie: "Ctre.Accueil/ Accomp.Reduc.Risq.Usag. Drogues (C.A.A.R.U.D.)"
 },
 {
   finess: "FINESS",
   name: "C.A.A.R.U.D LA ROSE DES VENTS",
   finessju: 750054157,
   id: 440046076,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240019612",
   categorie: "Ctre.Accueil/ Accomp.Reduc.Risq.Usag. Drogues (C.A.A.R.U.D.)"
 },
 {
   finess: "FINESS",
   name: "C.A.A.R.U.D.",
   finessju: 930013768,
   id: 850010869,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251477888",
   categorie: "Ctre.Accueil/ Accomp.Reduc.Risq.Usag. Drogues (C.A.A.R.U.D.)"
 },
 {
   finess: "FINESS",
   name: "C.A.A.R.U.D. ARTOX",
   finessju: 720008705,
   id: 720017714,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243774967",
   categorie: "Ctre.Accueil/ Accomp.Reduc.Risq.Usag. Drogues (C.A.A.R.U.D.)"
 },
 {
   finess: "FINESS",
   name: "C.A.A.R.U.D. L ACaTHe",
   finessju: 750054157,
   id: 440046084,
   commune: "NANTES",
   cp: 44000,
   telephone: "251720659",
   categorie: "Ctre.Accueil/ Accomp.Reduc.Risq.Usag. Drogues (C.A.A.R.U.D.)"
 },
 {
   finess: "FINESS",
   name: "C.A.A.R.U.D.49",
   finessju: 490016813,
   id: 490015799,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241936317",
   categorie: "Ctre.Accueil/ Accomp.Reduc.Risq.Usag. Drogues (C.A.A.R.U.D.)"
 },
 {
   finess: "FINESS",
   name: "CLAT D ANGERS",
   finessju: 490535697,
   id: 490019619,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241313141",
   categorie: "Dispensaire Antituberculeux"
 },
 {
   finess: "FINESS",
   name: "CLAT DE CHOLET",
   finessju: 490535697,
   id: 490528486,
   commune: "CHOLET CEDEX",
   cp: 49321,
   telephone: "241462029",
   categorie: "Dispensaire Antituberculeux"
 },
 {
   finess: "FINESS",
   name: "CENTRE ANTI-VENERIEN GOUGEROT",
   finessju: 490535697,
   id: 490538790,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241353224",
   categorie: "Dispensaire Antivenerien"
 },
 {
   finess: "FINESS",
   name: "DISPENSAIRE ANTI-VENERIEN",
   finessju: 440023620,
   id: 440011849,
   commune: "NANTES CEDEX 1",
   cp: 44035,
   telephone: "240471354",
   categorie: "Dispensaire Antivenerien"
 },
 {
   finess: "FINESS",
   name: "DISPENSAIRE ANTI-VENERIEN",
   finessju: 440023620,
   id: 440028629,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "-",
   categorie: "Dispensaire Antivenerien"
 },
 {
   finess: "FINESS",
   name: "CPPS",
   finessju: 440006039,
   id: 440030906,
   commune: "REZE",
   cp: 44400,
   telephone: "240471098",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "ECOLE SAGES FEMMES CHU NANTES",
   finessju: 440000289,
   id: 440013639,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240084610",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "FORMATION PROFESSIONS SANTE CHU ANGERS",
   finessju: 490000031,
   id: 490530821,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "241353434",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "I.F.A.S",
   finessju: 720017946,
   id: 720017953,
   commune: "LE MANS CEDEX 1",
   cp: 72003,
   telephone: "243501230",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS",
   finessju: 530008143,
   id: 530008150,
   commune: "EVRON CEDEX",
   cp: 53602,
   telephone: "243016244",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS",
   finessju: 850000019,
   id: 850023516,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85016,
   telephone: "251366550",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS DES SABLES D'OLONNE",
   finessju: 850000084,
   id: 850023557,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251204164",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS DU CH DE CHOLET",
   finessju: 490000676,
   id: 490020351,
   commune: "BEAUPREAU CEDEX",
   cp: 49601,
   telephone: "-",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS DU CH DE FONTENAY LE COMTE",
   finessju: 850000035,
   id: 850023524,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "251532811",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS DU CH LOIRE VENDEE OCEAN",
   finessju: 850009010,
   id: 440044931,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240786505",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS DU HAUT ANJOU",
   finessju: 530000025,
   id: 530006238,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "-",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFAS LYCEE RENE COUZINET",
   finessju: 850026345,
   id: 850026352,
   commune: "CHALLANS CEDEX",
   cp: 85302,
   telephone: "251930721",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFM3R DES PAYS DE LA LOIRE",
   finessju: 440003408,
   id: 440010932,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "251790979",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI - IFAS DU CH NORD MAYENNE",
   finessju: 530000074,
   id: 530004738,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243082249",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI - IFAS POLE SANTE SARTHE ET LOIR",
   finessju: 720016724,
   id: 720017078,
   commune: "LA FLECHE CEDEX",
   cp: 72205,
   telephone: "244713443",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI CH LA ROCHE SUR YON",
   finessju: 850000019,
   id: 850009408,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85016,
   telephone: "251366565",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI DE L EPSM DE LA SARTHE",
   finessju: 720000058,
   id: 720007038,
   commune: "ALLONNES CEDEX",
   cp: 72703,
   telephone: "243435142",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI DU CH DE CHOLET",
   finessju: 490000676,
   id: 490530813,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "241496658",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI ET IFAS DU CH DE CHATEAUBRIANT",
   finessju: 440000313,
   id: 440013647,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44146,
   telephone: "240558825",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI ET IFAS DU CH DE SAINT NAZAIRE",
   finessju: 440000057,
   id: 440007722,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "240700811",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI ET IFAS DU CH DU MANS",
   finessju: 720000025,
   id: 720007020,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243434330",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSI ET IFAS DU CH SAUMUR",
   finessju: 490528452,
   id: 490532124,
   commune: "SAUMUR CEDEX",
   cp: 49403,
   telephone: "241401461",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSO",
   finessju: 490001773,
   id: 440033611,
   commune: "NANTES",
   cp: 44100,
   telephone: "240377662",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSO LAVAL",
   finessju: 490001773,
   id: 530033166,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243695905",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IFSO VENDEE",
   finessju: 490001773,
   id: 850012527,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251626727",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUT DE FORMATION AIDES SOIGNANTS",
   finessju: 440004802,
   id: 440021517,
   commune: "ANCENIS CEDEX",
   cp: 44153,
   telephone: "240964122",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUT DE FORMATION D'AMBULANCIERS",
   finessju: 440000289,
   id: 440021533,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "253482049",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUT DE FORMATION D'AMBULANCIERS",
   finessju: 720006949,
   id: 720006998,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243406060",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUT DE FORMATION DE SANTE",
   finessju: 490001773,
   id: 490528593,
   commune: "ANGERS CEDEX 01",
   cp: 49004,
   telephone: "241721717",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUT FORMATION AIDES-SOIGNANTS",
   finessju: 490001773,
   id: 720014695,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243286637",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUT FORMATION AMBULANCIERS",
   finessju: 850000019,
   id: 850012600,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85016,
   telephone: "251366556",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "INSTITUTS DE FORMATION DU CH DE LAVAL",
   finessju: 530000371,
   id: 530029537,
   commune: "LAVAL CEDEX",
   cp: 53015,
   telephone: "243665069",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IRFSS PAYS DE LA LOIRE",
   finessju: 750721334,
   id: 440043511,
   commune: "REZE CEDEX",
   cp: 44402,
   telephone: "240148732",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IRFSS PAYS DE LA LOIRE",
   finessju: 750721334,
   id: 530029099,
   commune: "LAVAL CEDEX",
   cp: 53003,
   telephone: "243679595",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "IRFSS PAYS DE LA LOIRE",
   finessju: 750721334,
   id: 720005933,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243810652",
   categorie: "Ecoles Formant aux Professions Sanitaires"
 },
 {
   finess: "FINESS",
   name: "DEPARTEMENT DES INSTITUTS DE FORMATION",
   finessju: 440000289,
   id: 440010973,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240846808",
   categorie: "Ecoles Formant aux Professions Sanitaires et Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE LOUIS-JACQUES GOUSSIER",
   finessju: 440047280,
   id: 440047306,
   commune: "REZE",
   cp: 44400,
   telephone: "240324400",
   categorie: "Ecoles Formant aux Professions Sanitaires et Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE PROFESSIONNEL SIMONE VEIL",
   finessju: 490016219,
   id: 490016227,
   commune: "ANGERS CEDEX 01",
   cp: 49015,
   telephone: "241668031",
   categorie: "Ecoles Formant aux Professions Sanitaires et Sociales"
 },
 {
   finess: "FINESS",
   name: "ARIFTS PAYS DE LA LOIRE - SITE ANGEVIN",
   finessju: 490016730,
   id: 490528981,
   commune: "ANGERS CEDEX 01",
   cp: 49045,
   telephone: "241221470",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "ARIFTS PAYS DE LA LOIRE - SITE NANTAIS",
   finessju: 490016730,
   id: 440052975,
   commune: "REZE",
   cp: 44400,
   telephone: "240756994",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "C.E.F.R.A.S.",
   finessju: 490535903,
   id: 490535929,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241305709",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEAS DE LA VENDEE",
   finessju: 850011065,
   id: 850024209,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85016,
   telephone: "251372836",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEFRAS",
   finessju: 490535903,
   id: 440045698,
   commune: "NANTES",
   cp: 44100,
   telephone: "251862413",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEFRAS",
   finessju: 490535903,
   id: 720017482,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243729722",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEFRAS ANGERS",
   finessju: 490535903,
   id: 490018793,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241205990",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEFRAS SITE LA ROCHE SUR YON",
   finessju: 490535903,
   id: 850010661,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251477198",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEFRAS SITE MAYENNE",
   finessju: 490535903,
   id: 530005719,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243642092",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CEFRAS SITE SAINT NAZAIRE",
   finessju: 490535903,
   id: 440051324,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251160139",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE FORMATION DES TOUCHES",
   finessju: 530003318,
   id: 530003359,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243597989",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CNAM - UNITE DE SANTE PUBLIQUE",
   finessju: 440045706,
   id: 440045714,
   commune: "NANTES CEDEX 3",
   cp: 44311,
   telephone: "240161067",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "CNAM IFORIS",
   finessju: 490004348,
   id: 490540515,
   commune: "ANGERS CEDEX 01",
   cp: 49045,
   telephone: "241221730",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "FORMATION ASSISTANT FAMILIAL- SERV PMI",
   finessju: 440023620,
   id: 440046894,
   commune: "NANTES CEDEX 1",
   cp: 44041,
   telephone: "-",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "FORSYFA",
   finessju: 440052983,
   id: 440052991,
   commune: "NANTES",
   cp: 44200,
   telephone: "240734193",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "INST. FORMATION SUPERIEURE DE MESLAY",
   finessju: 850009820,
   id: 850009838,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251488484",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "LES HORIZONS FORMATION CONTINUE",
   finessju: 720020189,
   id: 720020197,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243292150",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE ATLANTIQUE",
   finessju: 850011156,
   id: 850011164,
   commune: "LUCON CEDEX",
   cp: 85402,
   telephone: "-",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE PROFESSIONNEL ROBERT BURON",
   finessju: 530007269,
   id: 530007228,
   commune: "LAVAL CEDEX",
   cp: 53013,
   telephone: "243672420",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE REAUMUR",
   finessju: 530006089,
   id: 530006139,
   commune: "LAVAL CEDEX",
   cp: 53013,
   telephone: "-",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE TALENSAC",
   finessju: 440003481,
   id: 440011393,
   commune: "NANTES CEDEX 1",
   cp: 44002,
   telephone: "251729510",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "LYCEE TECHNO AGRICOLE PRIVE D ORION",
   finessju: 530008598,
   id: 530008606,
   commune: "EVRON",
   cp: 53600,
   telephone: "243016230",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "MFR DE LA FERRIERE",
   finessju: 850014242,
   id: 850018276,
   commune: "LA FERRIERE",
   cp: 85280,
   telephone: "251983026",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "POLE FORMATION CONTINUE LETTRES - SHS",
   finessju: 440042810,
   id: 440042836,
   commune: "NANTES",
   cp: 44200,
   telephone: "272648841",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "SUP SOCIAL",
   finessju: 850009804,
   id: 850009812,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85015,
   telephone: "251940964",
   categorie: "Ecoles Formant aux Professions Sociales"
 },
 {
   finess: "FINESS",
   name: "COLLECTIF LE TRIANON",
   finessju: 720009729,
   id: 720020346,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243244692",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "COMMUNAUTE DE FRERES",
   finessju: 440025039,
   id: 440025047,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240791173",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "CTRE HEBGT TEMPORAIRE LE PERQUOI",
   finessju: 720014844,
   id: 720013424,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243405430",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF",
   finessju: 440042141,
   id: 440040780,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "240548720",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF BROUSSAIS",
   finessju: 440018620,
   id: 440034833,
   commune: "NANTES",
   cp: 44300,
   telephone: "240501400",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF BUTTE STE ANNE",
   finessju: 440018620,
   id: 440033777,
   commune: "NANTES",
   cp: 44100,
   telephone: "240691836",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF CREMETTERIE",
   finessju: 440018620,
   id: 440033165,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240435271",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF HAUTS DE CHEZINE",
   finessju: 440018620,
   id: 440011476,
   commune: "NANTES",
   cp: 44100,
   telephone: "240466874",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF L OREE DE GOLENE",
   finessju: 440042141,
   id: 440040772,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "240545650",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "DOMICILE COLLECTIF LES GLYCINES",
   finessju: 440018620,
   id: 440023869,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "228073070",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPA MAF LE BOISTISSANDEAU",
   finessju: 850006347,
   id: 850024951,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251647800",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPA PARIGNE LE POLIN",
   finessju: 790000731,
   id: 720008150,
   commune: "PARIGNE LE POLIN",
   cp: 72330,
   telephone: "243878143",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPA SAINT JOSEPH",
   finessju: 720012160,
   id: 720008879,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243815347",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA AGORA",
   finessju: 850026527,
   id: 850027764,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251315260",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA ESSARTS EN BOCAGE",
   finessju: 850026972,
   id: 850027350,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628104",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LA CAP LINE",
   finessju: 850006610,
   id: 850026584,
   commune: "LE PERRIER",
   cp: 85300,
   telephone: "-",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LA CLE DE SOL",
   finessju: 850011834,
   id: 850026592,
   commune: "MOUILLERON ST GERMAIN",
   cp: 85390,
   telephone: "-",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LA MAISONNEE",
   finessju: 850026527,
   id: 850027731,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251410123",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LA MOULINOTTE",
   finessju: 850012931,
   id: 850026568,
   commune: "ST HILAIRE DES LOGES",
   cp: 85240,
   telephone: "-",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LA PEUPLERAIE",
   finessju: 850026527,
   id: 850027749,
   commune: "ST HILAIRE DE LOULAY",
   cp: 85600,
   telephone: "251463022",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LE CLOS DU GRENOUILLER",
   finessju: 850026527,
   id: 850027756,
   commune: "BOUFFERE",
   cp: 85600,
   telephone: "251095470",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA LE VAL DES MAINES",
   finessju: 850026527,
   id: 850027772,
   commune: "ST GEORGES DE MONTAIGU",
   cp: 85600,
   telephone: "251420842",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA UNION CHRETIENNE",
   finessju: 850024449,
   id: 850026576,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "EHPAA VENANSAULT",
   finessju: 850022856,
   id: 850027376,
   commune: "VENANSAULT",
   cp: 85190,
   telephone: "251481224",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "MAISON D'ACCUEIL TEMPORAIRE",
   finessju: 530031129,
   id: 530030204,
   commune: "SAULGES",
   cp: 53340,
   telephone: "243902154",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "MAISON DE VIE LES SAULNIERS",
   finessju: 850018979,
   id: 850026659,
   commune: "LANDERONDE",
   cp: 85150,
   telephone: "-",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "MAISON DU SAINT ESPRIT",
   finessju: 850024852,
   id: 850024878,
   commune: "ST LAURENT SUR SEVRE",
   cp: 85290,
   telephone: "251643710",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "MR ABBAYE DE LA COUDRE",
   finessju: 530006014,
   id: 530006022,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243028585",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE FLORILEGE",
   finessju: 440013076,
   id: 440034304,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "228222030",
   categorie: "EHPA ne percevant pas des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL TEMPORAIRE L'ESCALE",
   finessju: 440002038,
   id: 440044584,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "240398850",
   categorie: "EHPA percevant des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "AMAD CENTRE HEBERGEMENT TEMPORAIRE",
   finessju: 850020314,
   id: 850024720,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251543333",
   categorie: "EHPA percevant des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "HEBERGEMENT TEMPORAIRE SADAPA",
   finessju: 850005075,
   id: 850025677,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251361229",
   categorie: "EHPA percevant des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LE CONDORCET",
   finessju: 440018620,
   id: 440023208,
   commune: "NANTES",
   cp: 44100,
   telephone: "240952960",
   categorie: "EHPA percevant des credits d'assurance maladie"
 },
 {
   finess: "FINESS",
   name: "IRM CENTRE DE RADIOLOGIE MOLLIERE",
   finessju: 490015914,
   id: 490019577,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "241874888",
   categorie: "Entite Ayant Autorisation"
 },
 {
   finess: "FINESS",
   name: "SCM SCANNER REGION NANTAISE SITE PSNA",
   finessju: 440005718,
   id: 440055788,
   commune: "ST HERBLAIN CEDEX",
   cp: 44819,
   telephone: "240958031",
   categorie: "Entite Ayant Autorisation"
 },
 {
   finess: "FINESS",
   name: "UFR STAPS",
   finessju: 440042810,
   id: 440052801,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Entite Ayant Autorisation"
 },
 {
   finess: "FINESS",
   name: "AACSEN 53",
   finessju: 530006519,
   id: 530006568,
   commune: "JUBLAINS",
   cp: 53160,
   telephone: "243043373",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "AAMIPI BEAUCOUZE",
   finessju: 490536935,
   id: 490531498,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241497890",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "AAMIPI CHOLET",
   finessju: 490536935,
   id: 490531548,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241497890",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "AAMIPI LE MANS",
   finessju: 490536935,
   id: 720011501,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243287203",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ADAPEI CHOLET ENTREPRISE ADAPTEE",
   finessju: 490535192,
   id: 490537685,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241581921",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "AMA LEA 53",
   finessju: 530007384,
   id: 530007400,
   commune: "AZE",
   cp: 53200,
   telephone: "243070588",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ANR SERVICES",
   finessju: 750710451,
   id: 440021616,
   commune: "NANTES CEDEX 3",
   cp: 44332,
   telephone: "240500099",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "APAC",
   finessju: 720008762,
   id: 720016641,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631150",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ASAR ENTREPRISE ADAPTEE",
   finessju: 490016466,
   id: 490016508,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241937515",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ASI BEAUCOUZE",
   finessju: 440030641,
   id: 490008554,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "241480072",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "AVENIR SERVICE INDUSTRIE",
   finessju: 440030641,
   id: 440030740,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "251859300",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "CARQUEFOU COUPE",
   finessju: 490536935,
   id: 440021608,
   commune: "CARQUEFOU CEDEX",
   cp: 44472,
   telephone: "241497890",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "CARQUEFOU MONTAGE",
   finessju: 490536935,
   id: 440040731,
   commune: "CARQUEFOU CEDEX",
   cp: 44472,
   telephone: "241497890",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "CDTD FRERE FRANCES",
   finessju: 750721037,
   id: 440036101,
   commune: "NANTES",
   cp: 44300,
   telephone: "240522839",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "CERAME ATELIER",
   finessju: 440037976,
   id: 440030385,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "228013109",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA DE CHANTEPIE ET DE L'ETANG",
   finessju: 490539400,
   id: 490016482,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241917212",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA DE CHERANCE",
   finessju: 720008762,
   id: 720014059,
   commune: "CHERANCE",
   cp: 72170,
   telephone: "243344303",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA DE ST PAVACE",
   finessju: 610000754,
   id: 720006352,
   commune: "ST PAVACE",
   cp: 72190,
   telephone: "243825538",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA DES BAZINIERES",
   finessju: 850012436,
   id: 850011495,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478800",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA DU HT ANJOU",
   finessju: 490536885,
   id: 490540473,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241947360",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA L'ALBIZIA",
   finessju: 440018380,
   id: 440047298,
   commune: "ORVAULT CEDEX",
   cp: 44701,
   telephone: "240630923",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA LE SENEVE",
   finessju: 490007721,
   id: 490007747,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241484906",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA LES ATELIERS DU RIBAY",
   finessju: 720014331,
   id: 720007236,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243242120",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA LES COURBES",
   finessju: 720008762,
   id: 720005941,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940114",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA LES IRIS",
   finessju: 440018380,
   id: 440041663,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240541408",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA LES SOURCES",
   finessju: 490535754,
   id: 490540499,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241305144",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA SICOMEN",
   finessju: 530001031,
   id: 530032630,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243597100",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EA UTIL 85",
   finessju: 850020413,
   id: 850024969,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251462767",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EARTA",
   finessju: 440031128,
   id: 440030823,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240658574",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENT ADAPTEE LES ATELIERS DU VERGER",
   finessju: 720009562,
   id: 720011493,
   commune: "LE BAILLEUL",
   cp: 72200,
   telephone: "243954546",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENT ADAPTEE LES SERRES DES HUNAUDIERES",
   finessju: 720009562,
   id: 720013796,
   commune: "RUAUDIN",
   cp: 72230,
   telephone: "243844542",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE ADAPEI",
   finessju: 490535192,
   id: 490537677,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241211141",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE ADAPEI LA POMMERAYE",
   finessju: 490535192,
   id: 490540465,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241777469",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE ARCEAU ANJOU",
   finessju: 490535168,
   id: 490543220,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241430506",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE DU PETIT AVALIX",
   finessju: 750042434,
   id: 440042455,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240531616",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE HORTIPRO",
   finessju: 440018380,
   id: 440030369,
   commune: "LA BERNERIE EN RETZ",
   cp: 44760,
   telephone: "240213600",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE NORD SARTHE",
   finessju: 720009562,
   id: 720018647,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72405,
   telephone: "243710228",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE PREST 2000",
   finessju: 720009562,
   id: 720005966,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243841229",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE SAPRENA",
   finessju: 440006054,
   id: 440031227,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "240325222",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE TRIADE AVENIR OUEST",
   finessju: 490020740,
   id: 490020757,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241188001",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ENTREPRISE ADAPTEE VERT 2000",
   finessju: 720009562,
   id: 720016716,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243399939",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "EPA SERVICES",
   finessju: 440018380,
   id: 440030336,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240960011",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "HORTIPRO",
   finessju: 440018380,
   id: 440047264,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213600",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "IDEM 85",
   finessju: 850006511,
   id: 850006537,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251484240",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ISTA BEJOCOM",
   finessju: 490542602,
   id: 490007705,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241938407",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ISTA DES GUEDERIES",
   finessju: 490542602,
   id: 490007713,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241383461",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "ISTA PIGNEROLLES",
   finessju: 490542602,
   id: 490540457,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241210270",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "LES ATELIERS D'ELTA",
   finessju: 530007384,
   id: 530007392,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243538468",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "LES ATELIERS DU PLANTY",
   finessju: 850012436,
   id: 850024928,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251463715",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "MAINE ATELIERS",
   finessju: 530032986,
   id: 530032655,
   commune: "GORRON",
   cp: 53120,
   telephone: "243086651",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "MCA",
   finessju: 850012436,
   id: 850011776,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251463715",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "MSEA",
   finessju: 850011735,
   id: 850011743,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "685540318",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "PEPINIERES LA FORET",
   finessju: 440006062,
   id: 440031235,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240044203",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "PHI SERVICES",
   finessju: 440047249,
   id: 440047256,
   commune: "PORNIC CEDEX",
   cp: 44212,
   telephone: "240214378",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "QUALEA",
   finessju: 490016474,
   id: 490007754,
   commune: "CHOLET CEDEX",
   cp: 49303,
   telephone: "241621208",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "UMEA",
   finessju: 850011750,
   id: 850011768,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251616849",
   categorie: "Entreprise adaptee"
 },
 {
   finess: "FINESS",
   name: "E.A.M CAA NORT SUR ERDRE",
   finessju: 440018380,
   id: 440040970,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "251126538",
   categorie: "Etab.Acc.Medicalise en tout ou partie personnes handicapees"
 },
 {
   finess: "FINESS",
   name: "CONSULTATION DE PROTECT INFANT",
   finessju: 530031475,
   id: 530028711,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243564969",
   categorie: "Etablissement Consultation Protection Infantile"
 },
 {
   finess: "FINESS",
   name: "CENTRE EDUCATIF ANJORRANT",
   finessju: 440001360,
   id: 440001121,
   commune: "NANTES CEDEX 1",
   cp: 44009,
   telephone: "240145130",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "CENTRE MATERNEL L'ILOT FAMILLES",
   finessju: 490535168,
   id: 490002623,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241887888",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "CENTRE MATERNEL ST LUC",
   finessju: 750721334,
   id: 440007532,
   commune: "NANTES",
   cp: 44100,
   telephone: "240737067",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "CTRE MATERNEL ASMI",
   finessju: 850017773,
   id: 850025347,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251214541",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "LIEU ACCUEIL FAMILLES MONOPARENTALES",
   finessju: 850021148,
   id: 850023607,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251583571",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "MAISON MATERNELLE LES CHRYSALIDES",
   finessju: 720001445,
   id: 720019611,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243459166",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "MAISON MATERNELLE TOM POUCE",
   finessju: 720013820,
   id: 720013838,
   commune: "JOUE EN CHARNIE",
   cp: 72540,
   telephone: "243826437",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE MATERNELLE",
   finessju: 850013236,
   id: 850016718,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478200",
   categorie: "Etablissement d'Accueil M�re-Enfant"
 },
 {
   finess: "FINESS",
   name: "MAT JARDIN D'ALEXANDRE",
   finessju: 720008770,
   id: 720017102,
   commune: "VILLENEUVE EN PERSEIGNE",
   cp: 72600,
   telephone: "243311315",
   categorie: "Etablissement d'Accueil Temporaire d'Enfants Handicapes"
 },
 {
   finess: "FINESS",
   name: "SATVA_E LA CHAPELLE",
   finessju: 750719239,
   id: 440049005,
   commune: "LA CHAPELLE SUR ERDRE CEDE",
   cp: 44243,
   telephone: "140786957",
   categorie: "Etablissement d'Accueil Temporaire d'Enfants Handicapes"
 },
 {
   finess: "FINESS",
   name: "VRF LA SALAMANDRE - ENFANTS",
   finessju: 490001971,
   id: 490018926,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241226060",
   categorie: "Etablissement d'Accueil Temporaire d'Enfants Handicapes"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL TEMPORAIRE DE RESSOURCEMENT",
   finessju: 350050456,
   id: 530008416,
   commune: "LOIRON RUILLE",
   cp: 53320,
   telephone: "299750169",
   categorie: "Etablissement d'Accueil Temporaire pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "MAISON ACC TEMPO LES AMIS DE RAYMOND",
   finessju: 440044741,
   id: 440044758,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240986028",
   categorie: "Etablissement d'Accueil Temporaire pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "VRF LA SALAMANDRE - ADULTES",
   finessju: 490001971,
   id: 490018579,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241226060",
   categorie: "Etablissement d'Accueil Temporaire pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "C.R.R.F. LA TOURMALINE",
   finessju: 440042844,
   id: 440000255,
   commune: "ST HERBLAIN CEDEX",
   cp: 44818,
   telephone: "240385959",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE  GALLOUEDEC SITE POLE SANTE SUD",
   finessju: 720008390,
   id: 720016138,
   commune: "LE MANS",
   cp: 72100,
   telephone: "-",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE LE BOIS RIGNOUX",
   finessju: 440042844,
   id: 440024669,
   commune: "VIGNEUX DE BRETAGNE",
   cp: 44360,
   telephone: "240574057",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE  L'ARCHE",
   finessju: 720000454,
   id: 720000744,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243517272",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE CONVALESCENCE ARCOLE",
   finessju: 490000890,
   id: 490534922,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241634200",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE MPR LE CLOUSIS",
   finessju: 750721334,
   id: 850002403,
   commune: "ST JEAN DE MONTS CEDEX",
   cp: 85167,
   telephone: "251599100",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE READAPTATION DE L'ESTUAIRE",
   finessju: 440055952,
   id: 440044451,
   commune: "NANTES",
   cp: 44100,
   telephone: "228204060",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE REEDUC CARDIAQUE ST JOSEPH",
   finessju: 490000171,
   id: 490020344,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241477171",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE ROUGEMONT S.S.R.",
   finessju: 310020383,
   id: 720012509,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243775500",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS A. BOCQUET MAMERS",
   finessju: 610787764,
   id: 720019918,
   commune: "MAMERS",
   cp: 72600,
   telephone: "233328774",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS DE SUITE SAINT- CLAUDE",
   finessju: 490535168,
   id: 490009248,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241205592",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS LA BREHONNIERE",
   finessju: 530000868,
   id: 530031509,
   commune: "ASTILLE",
   cp: 53230,
   telephone: "243989117",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SSR  LE CONFLUENT",
   finessju: 750721334,
   id: 440046944,
   commune: "NANTES CEDEX 2",
   cp: 44263,
   telephone: "228258800",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SSR DU CONFLUENT",
   finessju: 750721334,
   id: 440048676,
   commune: "NANTES CEDEX 2",
   cp: 44263,
   telephone: "-",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SSR LE BODIO",
   finessju: 440018661,
   id: 440002459,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240175100",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE LES  METIVES LA ROCHE SUR YON",
   finessju: 850007048,
   id: 850002130,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85003,
   telephone: "251375411",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE LES CAPUCINS",
   finessju: 490535093,
   id: 490531910,
   commune: "ANGERS CEDEX 02",
   cp: 49103,
   telephone: "241351515",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE LES MeTIVES LES SABLES D'OLONNE",
   finessju: 850007048,
   id: 850005224,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251218980",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICAL F.GALLOUEDEC",
   finessju: 720008390,
   id: 720000413,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243412525",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE MeDICAL G. COULON:SITE DU MANS",
   finessju: 720012749,
   id: 720016823,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE SSR  DE LA CHIMOTAIE",
   finessju: 750005068,
   id: 850000399,
   commune: "CUGAND",
   cp: 85610,
   telephone: "251456800",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE SSR DE MONTFAUCON-MONTIGNE",
   finessju: 490000759,
   id: 490000817,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241647176",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE SSR DE ST SeBASTIEN/LOIRE-ESPIC",
   finessju: 440053411,
   id: 440000693,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "-",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE SSR DU CHILLON",
   finessju: 440042844,
   id: 490000643,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241734500",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE THERAPEUTIQUE DE  LA BARONNAIS",
   finessju: 440018729,
   id: 440000719,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240269400",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE ST CHARLES - SITE DES ESSARTS",
   finessju: 850013244,
   id: 850002395,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251484141",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CMPR COTE D'AMOUR",
   finessju: 440018661,
   id: 440053387,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240627500",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CTRE BASSE VISION ET TROUBLES AUDITION",
   finessju: 490535168,
   id: 490007549,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241368010",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CTRE READAPTATION VILLA NOTRE DAME",
   finessju: 850026378,
   id: 850000357,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251608282",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "E.S.E.A.N.",
   finessju: 750719239,
   id: 440043123,
   commune: "NANTES",
   cp: 44200,
   telephone: "240343884",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "ETABLISSEMENT DE SSR  ROZ ARVOR",
   finessju: 440001220,
   id: 440000701,
   commune: "NANTES CEDEX 3",
   cp: 44303,
   telephone: "240683900",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "LES EUMENIDES HTP ANGERS",
   finessju: 440042844,
   id: 490018769,
   commune: "ANGERS",
   cp: 49000,
   telephone: "253617000",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "MAISON DE CONVALESCENCE DE L ANJOU",
   finessju: 490009529,
   id: 490543675,
   commune: "ANGERS CEDEX 01",
   cp: 49007,
   telephone: "241447000",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "MAISON DE CONVALESCENCE LES RECOLLETS",
   finessju: 490015856,
   id: 490000601,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241832222",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "UNITE SSR CENTRE LE CHILLON A CHOLET",
   finessju: 440042844,
   id: 490018777,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "-",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "UNITE SSR CENTRE LE CHILLON A SAUMUR",
   finessju: 440042844,
   id: 490018785,
   commune: "SAUMUR CEDEX",
   cp: 49403,
   telephone: "-",
   categorie: "Etablissement de sante prive autorise en SSR"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE LA MAIN",
   finessju: 490004330,
   id: 490540440,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241868641",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE CHIRURGICALE DE LA LOIRE",
   finessju: 490000627,
   id: 490007929,
   commune: "SAUMUR CEDEX",
   cp: 49402,
   telephone: "241833300",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE DU PRE",
   finessju: 720000595,
   id: 720000199,
   commune: "LE MANS CEDEX 2",
   cp: 72018,
   telephone: "243775775",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE JULES VERNE",
   finessju: 440000974,
   id: 440029379,
   commune: "NANTES CEDEX 3",
   cp: 44314,
   telephone: "251171717",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE SOURDILLE",
   finessju: 440001212,
   id: 440000651,
   commune: "NANTES CEDEX 1",
   cp: 44046,
   telephone: "251833200",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE STE-MARIE",
   finessju: 440000925,
   id: 440000404,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240556464",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE SUD VENDEE",
   finessju: 850022948,
   id: 850000126,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85204,
   telephone: "251534444",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE UROLOGIQUE NANTES ATLANTIS",
   finessju: 440001014,
   id: 440000487,
   commune: "ST HERBLAIN CEDEX",
   cp: 44815,
   telephone: "228030609",
   categorie: "Etablissement de Soins Chirurgicaux"
 },
 {
   finess: "FINESS",
   name: "CENTRE LES CAPUCINS:USLD",
   finessju: 490535093,
   id: 490537040,
   commune: "ANGERS CEDEX 02",
   cp: 49103,
   telephone: "241351515",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "HOPITAL DE PORNIC USLD",
   finessju: 440041531,
   id: 440032399,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240640142",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD - HL DU LOROUX BOTTEREAU -",
   finessju: 440042141,
   id: 440021251,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251711515",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD ANNE DE BRETAGNE",
   finessju: 440000859,
   id: 440021095,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240575000",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD AQUARELLE",
   finessju: 850009010,
   id: 850003377,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251495280",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD BEAUSEJOUR",
   finessju: 440000289,
   id: 440042091,
   commune: "NANTES",
   cp: 44000,
   telephone: "240163360",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CASSIOPEE",
   finessju: 440000057,
   id: 440021152,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240906060",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CENTRE HOPITALIER CHATEAUBRIANT",
   finessju: 440000313,
   id: 440033645,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44146,
   telephone: "240558800",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CENTRE HOSPITALIER DU MANS",
   finessju: 720000025,
   id: 720014422,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243434343",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CENTRE HOSPITALIER FRANCIS ROBERT",
   finessju: 440053643,
   id: 440021285,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "240094445",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CENTRE HOSPITALIER LAVAL",
   finessju: 530000371,
   id: 530032424,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243665000",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CENTRE HOSPITALIER POUANCE",
   finessju: 440000313,
   id: 490539103,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241948700",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CH CHATEAU DU LOIR",
   finessju: 720000066,
   id: 720014505,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243447777",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CH DROUET CTRE HOSP DU MANS",
   finessju: 720000025,
   id: 720005909,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243804545",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CH HAUT ANJOU  CHATEAU GONTIER",
   finessju: 530000025,
   id: 530032788,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243093400",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CH LOIRE VENDEE OCEAN MACHECOUL",
   finessju: 850009010,
   id: 440021202,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240023400",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CH LVO ST GILLES",
   finessju: 850009010,
   id: 850020504,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251608300",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CHD LA ROCHE LUCON MONTAIGU",
   finessju: 850000019,
   id: 850003351,
   commune: "LUCON",
   cp: 85400,
   telephone: "251283333",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CHEVEUX BLANCS ORVAULT",
   finessju: 440018620,
   id: 440002939,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240634013",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CHS GEORGES MAZURELLE",
   finessju: 850000092,
   id: 850021866,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85026,
   telephone: "251097272",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CHU D'ANGERS",
   finessju: 490000031,
   id: 490536224,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241353637",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CTRE DE L'ISAC A BLAIN",
   finessju: 440000263,
   id: 440029825,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240515240",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CTRE HOSPITALIER LA ROCHE S/Y",
   finessju: 850000019,
   id: 850020546,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251446305",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CTRE HOSPITALIER MAMERS",
   finessju: 610780082,
   id: 720014968,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243313138",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD CTRE HOSPITALIER ST CALAIS",
   finessju: 720000140,
   id: 720014513,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243636476",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD DE GUERANDE",
   finessju: 440028538,
   id: 440021194,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240626553",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD DE L'HOPITAL DU  LUDE",
   finessju: 720000090,
   id: 720014539,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243484848",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD DES COLLINES VENDEENNES",
   finessju: 850025867,
   id: 850020579,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251536565",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD DU CH DE CHOLET",
   finessju: 490000676,
   id: 490019601,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "-",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HL LUCIEN BOISSIN",
   finessju: 490000411,
   id: 490536109,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241536362",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HL ST NICOLAS ANGERS",
   finessju: 490001070,
   id: 490008604,
   commune: "ANGERS CEDEX 01",
   cp: 49016,
   telephone: "241734100",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HOPITAL  DE CRAON",
   finessju: 530007202,
   id: 530032770,
   commune: "CRAON",
   cp: 53400,
   telephone: "243061286",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HOPITAL BEL AIR",
   finessju: 440000347,
   id: 440028884,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251116500",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HOPITAL LOCAL BEAUMONT",
   finessju: 720002054,
   id: 720000801,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970390",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HOPITAL LOCAL DE  VERTOU",
   finessju: 440042141,
   id: 440021236,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240808000",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HOPITAL LOCAL DE CLISSON",
   finessju: 440003267,
   id: 440028843,
   commune: "CLISSON CEDEX",
   cp: 44194,
   telephone: "240801919",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD HOPITAL LOCAL NOIRMOUTIER",
   finessju: 850000100,
   id: 850000266,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390399",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD JARDIN D'ARCADIE",
   finessju: 530000074,
   id: 530031384,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243087300",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD LES MAISONNEES DE LUMIERE",
   finessju: 850000084,
   id: 850021049,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85109,
   telephone: "251218910",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD LYS HYROME",
   finessju: 490007689,
   id: 490537768,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241462424",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "USLD PIRMIL ST JACQUES CHU NANTES",
   finessju: 440000289,
   id: 440021400,
   commune: "NANTES",
   cp: 44200,
   telephone: "240483333",
   categorie: "Etablissement de Soins Longue Duree"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICAL G. COULON-LE GRAND LUCe",
   finessju: 720012749,
   id: 720000389,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243615151",
   categorie: "Etablissement de Soins Medicaux"
 },
 {
   finess: "FINESS",
   name: "CHIMIOTHeRAPIE - SITE JULES VERNE",
   finessju: 440041572,
   id: 440048718,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Etablissement de Soins Medicaux"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE VICTOR HUGO",
   finessju: 720000645,
   id: 720000249,
   commune: "LE MANS CEDEX 2",
   cp: 72015,
   telephone: "243479494",
   categorie: "Etablissement de Soins Medicaux"
 },
 {
   finess: "FINESS",
   name: "HOPITAL PRIVE ST MARTIN BEAUPREAU",
   finessju: 490001716,
   id: 490004256,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241713100",
   categorie: "Etablissement de Soins Medicaux"
 },
 {
   finess: "FINESS",
   name: "HOPITAL SAINT- JOSEPH",
   finessju: 490000692,
   id: 490000700,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241637163",
   categorie: "Etablissement de Soins Medicaux"
 },
 {
   finess: "FINESS",
   name: "CAMERA SITE POLYCLINIQUE DU MAINE",
   finessju: 530007319,
   id: 530008705,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE MEDICO-CHIRURGICAL DU MANS",
   finessju: 720000561,
   id: 720017748,
   commune: "LE MANS CEDEX 2",
   cp: 72058,
   telephone: "243783838",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE  BRETECHE",
   finessju: 440000941,
   id: 440000412,
   commune: "NANTES CEDEX 1",
   cp: 44046,
   telephone: "251868686",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE CHIRURGICALE PORTE OCEANE",
   finessju: 850013269,
   id: 850000134,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251211515",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE DE L'ANJOU",
   finessju: 490008109,
   id: 490014909,
   commune: "ANGERS CEDEX 01",
   cp: 49044,
   telephone: "241447171",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE DU TERTRE ROUGE",
   finessju: 720000637,
   id: 720000231,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243785858",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE J.VERNE- POLE HOSP MUTUALISTE",
   finessju: 440053411,
   id: 440029338,
   commune: "NANTES CEDEX 3",
   cp: 44314,
   telephone: "251171717",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE MUTUALISTE DE L'ESTUAIRE",
   finessju: 440053429,
   id: 440050433,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "272275300",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE SAINT CHARLES",
   finessju: 850013244,
   id: 850000118,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85016,
   telephone: "251444444",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE SAINT JOSEPH",
   finessju: 490000171,
   id: 490000262,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241477171",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE SAINT-AUGUSTIN",
   finessju: 440000461,
   id: 440024982,
   commune: "NANTES CEDEX 1",
   cp: 44046,
   telephone: "240123000",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE ST LEONARD",
   finessju: 490000197,
   id: 490015906,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241417373",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM CLINIQUE DE L'ANJOU",
   finessju: 490015914,
   id: 490019973,
   commune: "ANGERS CEDEX 01",
   cp: 49044,
   telephone: "241447171",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM CLINIQUE SAINT LEONARD",
   finessju: 490015914,
   id: 490019981,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241417373",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM ET SCAN SITE CLINIQUE MUT J VERNE",
   finessju: 440050177,
   id: 440054336,
   commune: "NANTES CEDEX 3",
   cp: 44314,
   telephone: "228038888",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM ET SCAN SITE HOP PRIVE CONFLUENT",
   finessju: 440050177,
   id: 440054344,
   commune: "NANTES CEDEX 2",
   cp: 44277,
   telephone: "228038888",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CH CHOLET",
   finessju: 490004405,
   id: 490020013,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CH COTE DE LUMI�RE",
   finessju: 850019670,
   id: 850026246,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CH HAUT ANJOU",
   finessju: 530005933,
   id: 530008630,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53204,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CH LAVAL",
   finessju: 530005933,
   id: 530008655,
   commune: "LAVAL CEDEX",
   cp: 53015,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CH LVO - CHALLANS",
   finessju: 850023052,
   id: 850026253,
   commune: "CHALLANS CEDEX",
   cp: 85302,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CH NORD MAYENNE",
   finessju: 530005933,
   id: 530008663,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE CLINIQUE SAINT CHARLES",
   finessju: 850005778,
   id: 850026261,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE POLYCLINIQUE DU MAINE",
   finessju: 530005933,
   id: 530008648,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "IRM SITE POLYCLINIQUE DU PARC - CHOLET",
   finessju: 490004405,
   id: 490019957,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "L'HOPITAL PRIVE DU CONFLUENT",
   finessju: 440041572,
   id: 440041580,
   commune: "NANTES CEDEX 2",
   cp: 44277,
   telephone: "228255000",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "MAISON DE NICODEME",
   finessju: 220020739,
   id: 440055945,
   commune: "NANTES",
   cp: 44000,
   telephone: "296319494",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "POLYCLINIQUE DE L'ATLANTIQUE",
   finessju: 440006344,
   id: 440033819,
   commune: "ST HERBLAIN CEDEX",
   cp: 44819,
   telephone: "240959393",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "POLYCLINIQUE DE L'EUROPE",
   finessju: 440001386,
   id: 440002020,
   commune: "ST NAZAIRE CEDEX",
   cp: 44615,
   telephone: "251161616",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "POLYCLINIQUE DU MAINE",
   finessju: 530000975,
   id: 530031962,
   commune: "LAVAL CEDEX",
   cp: 53010,
   telephone: "243663600",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "POLYCLINIQUE DU PARC",
   finessju: 490000890,
   id: 490002037,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241634200",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "SCANNER CLINIQUE DE L'ANJOU",
   finessju: 490004637,
   id: 490019999,
   commune: "ANGERS CEDEX 01",
   cp: 49044,
   telephone: "241447171",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "SCANNER CLINIQUE SAINT LEONARD",
   finessju: 490004637,
   id: 490020005,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241417373",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "SCANNER SITE BRETECHE",
   finessju: 440006393,
   id: 440053312,
   commune: "NANTES CEDEX 1",
   cp: 44046,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "SCANNER SITE CH ANCENIS",
   finessju: 440052165,
   id: 440053304,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "SCANNER SITE CH COTE DE LUMI�RE",
   finessju: 850019670,
   id: 850026238,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "-",
   categorie: "Etablissement de Soins Pluridisciplinaire"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS MEDICAUX",
   finessju: 930019229,
   id: 530003474,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243669000",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SOINS MEDICAUX",
   finessju: 930019229,
   id: 720014927,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243399494",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "CENTRE SOINS MED.HEMOPHILIE",
   finessju: 930019229,
   id: 440032233,
   commune: "NANTES CEDEX 1",
   cp: 44011,
   telephone: "240123300",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE - PDL LAVAL DOMAIGNE MDD",
   finessju: 930019229,
   id: 530009026,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE PAYS DE LA  LOIRE NANTES",
   finessju: 930019229,
   id: 440000628,
   commune: "NANTES CEDEX 1",
   cp: 44011,
   telephone: "240123300",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE PAYS DE LA LOIRE ANGERS",
   finessju: 930019229,
   id: 490528460,
   commune: "ANGERS CEDEX 02",
   cp: 49103,
   telephone: "241724422",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE PAYS DE LA LOIRE LE MANS",
   finessju: 930019229,
   id: 720005792,
   commune: "LE MANS CEDEX 2",
   cp: 72018,
   telephone: "243399494",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE PAYS DE LA LOIRE ST NAZAIRE",
   finessju: 930019229,
   id: 440021178,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240906390",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE PAYS DE LOIRE ST HERBLAIN",
   finessju: 930019229,
   id: 440042745,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS CENTRE-PDL ATLANTIC BIO GMP",
   finessju: 930019229,
   id: 440054815,
   commune: "ST HERBLAIN CEDEX",
   cp: 44801,
   telephone: "-",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "EFS PAYS DE LA LOIRE LA ROCHE SUR YON",
   finessju: 930019229,
   id: 850002437,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251446361",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "ETS CENTRE PAYS DE LA LOIRE LAVAL",
   finessju: 930019229,
   id: 530028976,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243669000",
   categorie: "Etablissement de Transfusion Sanguine"
 },
 {
   finess: "FINESS",
   name: "CIGMA",
   finessju: 530009570,
   id: 530006709,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243663100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ALAIN ET JEAN CRAPEZ",
   finessju: 720000967,
   id: 720002260,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243758059",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ALBERT TROTTE",
   finessju: 720001551,
   id: 720007228,
   commune: "THORIGNE SUR DUE",
   cp: 72160,
   telephone: "243890535",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ALEXANDRE PLANCHER",
   finessju: 440050904,
   id: 440026847,
   commune: "REZE",
   cp: 44400,
   telephone: "240782525",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ALIENOR D AQUITAINE",
   finessju: 490008331,
   id: 490542644,
   commune: "FONTEVRAUD L ABBAYE",
   cp: 49590,
   telephone: "241520211",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ALIENOR D'AQUITAINE",
   finessju: 850012931,
   id: 850023045,
   commune: "NIEUL SUR L AUTISE",
   cp: 85240,
   telephone: "251524848",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD AMBROISE PARE",
   finessju: 530000454,
   id: 530002336,
   commune: "COSSE LE VIVIEN",
   cp: 53230,
   telephone: "243988014",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD AMICIE",
   finessju: 720000900,
   id: 720002161,
   commune: "MONTFORT LE GESNOIS",
   cp: 72450,
   telephone: "243541200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANDRE BOUTELIER",
   finessju: 850012535,
   id: 850003278,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251373063",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANDREE ROCHEFORT",
   finessju: 440033223,
   id: 440033231,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240623030",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANNE DE BRETAGNE",
   finessju: 440000859,
   id: 440021111,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240575000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANNE DE BRETAGNE",
   finessju: 440018620,
   id: 440030583,
   commune: "NANTES",
   cp: 44000,
   telephone: "240897962",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANNE DE LA GIROUARDIERE",
   finessju: 490000882,
   id: 490000874,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891220",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANNE DE MELUN",
   finessju: 490543279,
   id: 490004215,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891804",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ANTOINE CRISTAL",
   finessju: 490528452,
   id: 490536042,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241383811",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD AOLYS",
   finessju: 560014649,
   id: 440047447,
   commune: "ST ANDRE DES EAUX",
   cp: 44117,
   telephone: "251761370",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD AQUARELLE",
   finessju: 850009010,
   id: 850020124,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251495280",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEAULIEU",
   finessju: 440018620,
   id: 440002079,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240651313",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEAULIEU",
   finessju: 720013507,
   id: 720008093,
   commune: "LE MANS CEDEX 2",
   cp: 72018,
   telephone: "243247211",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEAUSEJOUR",
   finessju: 850012642,
   id: 850003112,
   commune: "LE CHAMP ST PERE",
   cp: 85540,
   telephone: "251409326",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEAUSOLEIL",
   finessju: 490001369,
   id: 490002789,
   commune: "MIRE",
   cp: 49330,
   telephone: "241328228",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEL ACCUEIL",
   finessju: 490535168,
   id: 490003225,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241255080",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEL AIR",
   finessju: 440018620,
   id: 440002715,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240343738",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEL AIR",
   finessju: 440018620,
   id: 440052793,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240297520",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BEL AIR",
   finessju: 490000080,
   id: 490000056,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241725176",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BELLEVUE",
   finessju: 530000595,
   id: 530002476,
   commune: "ST DENIS DE GASTINES",
   cp: 53500,
   telephone: "243005342",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BELLEVUE",
   finessju: 850012741,
   id: 850003161,
   commune: "L HERMENAULT",
   cp: 85570,
   telephone: "251001343",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BERENGERE",
   finessju: 720019470,
   id: 720017565,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243547500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BETHANIE",
   finessju: 850026535,
   id: 850003211,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251455200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BON ACCUEIL",
   finessju: 440001584,
   id: 440002749,
   commune: "TOUVOIS",
   cp: 44650,
   telephone: "240316429",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BON ACCUEIL",
   finessju: 530031244,
   id: 530029313,
   commune: "CHEMAZE",
   cp: 53200,
   telephone: "243702170",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BON ACCUEIL",
   finessju: 850012659,
   id: 850003138,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696101",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BON AIR",
   finessju: 920030152,
   id: 490002847,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241188888",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BON REPOS",
   finessju: 440001857,
   id: 440003069,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240657254",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD BONCHAMPS",
   finessju: 490001138,
   id: 490002326,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241725102",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CALYPSO",
   finessju: 850012998,
   id: 850005034,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251587747",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CAMILLE CLAUDEL",
   finessju: 440006302,
   id: 440033215,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "240458555",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CARPE DIEM",
   finessju: 530000074,
   id: 530033067,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243087300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CASTERAN",
   finessju: 530031194,
   id: 530002500,
   commune: "ST PIERRE DES NIDS",
   cp: 53370,
   telephone: "243035201",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CATHERINE DE COURTOUX",
   finessju: 720000769,
   id: 720000496,
   commune: "LA CHARTRE SUR LE LOIR",
   cp: 72340,
   telephone: "243381238",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CEGVS",
   finessju: 720015999,
   id: 720002039,
   commune: "AUVERS LE HAMON",
   cp: 72300,
   telephone: "243953123",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CEGVS",
   finessju: 720015999,
   id: 720002070,
   commune: "BRULON",
   cp: 72350,
   telephone: "243924629",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CEGVS",
   finessju: 720015999,
   id: 720002179,
   commune: "ROEZE SUR SARTHE",
   cp: 72210,
   telephone: "243773511",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CEGVS",
   finessju: 720015999,
   id: 720007111,
   commune: "VALLON SUR GEE",
   cp: 72540,
   telephone: "243889358",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CENTRE HOSPITALIER LE MANS",
   finessju: 720000025,
   id: 720018415,
   commune: "LE MANS CEDEX 9",
   cp: 72037,
   telephone: "243434343",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CESAR GEOFFRAY",
   finessju: 490534732,
   id: 490541117,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241241530",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH AIME JALLOT",
   finessju: 440053643,
   id: 490536075,
   commune: "CANDE",
   cp: 49440,
   telephone: "241927360",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH CHATEAUBRIANT - BERE",
   finessju: 440000313,
   id: 440018133,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44146,
   telephone: "761712641",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH CHATEAUBRIANT - MAPA",
   finessju: 440000313,
   id: 440021368,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44146,
   telephone: "761712629",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH CHATEAUBRIANT - PARC LA LANDE",
   finessju: 440000313,
   id: 440047629,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44146,
   telephone: "761712629",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH DOUE EN ANJOU",
   finessju: 490000403,
   id: 490002284,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241834610",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH DOUE EN ANJOU",
   finessju: 490000403,
   id: 490536141,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241834610",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH FONTENAY",
   finessju: 850000035,
   id: 850020389,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "251535153",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH LAYON AUBANCE",
   finessju: 490000429,
   id: 490002102,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241542260",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH LAYON AUBANCE",
   finessju: 490000429,
   id: 490002391,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "241541430",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH LAYON-AUBANCE",
   finessju: 490000429,
   id: 490008141,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "241541430",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH LAYON-AUBANCE",
   finessju: 490000429,
   id: 490536166,
   commune: "TERRANJOU",
   cp: 49540,
   telephone: "241835555",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH LOIRE VENDEE OCEAN",
   finessju: 850009010,
   id: 440021210,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240023480",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH LUCIEN BOISSIN",
   finessju: 490000411,
   id: 490536158,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241536353",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH NOZAY",
   finessju: 440000313,
   id: 440021327,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240797027",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CH POUANCE",
   finessju: 440000313,
   id: 490011517,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241948700",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHAMPFLEURI",
   finessju: 440003168,
   id: 440024636,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240265182",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHANTERIVIERE",
   finessju: 490000676,
   id: 490008844,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "241496220",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHARLES MARGUERITE",
   finessju: 850000514,
   id: 850003906,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251946079",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHD HENRY RENAUD",
   finessju: 850000019,
   id: 850020405,
   commune: "LUCON",
   cp: 85400,
   telephone: "251560303",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHD LA ROCHE LUCON MON",
   finessju: 850000019,
   id: 850021270,
   commune: "MONTAIGU CEDEX",
   cp: 85602,
   telephone: "251454000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHD VENDEE LA ROCHE SUR YON",
   finessju: 850000019,
   id: 850021353,
   commune: "LA ROCHE SUR YON CEDEX 9",
   cp: 85925,
   telephone: "251446305",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHI LYS HYROME",
   finessju: 490007689,
   id: 490002425,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241758036",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHI LYS HYROME",
   finessju: 490007689,
   id: 490536133,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241462424",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHIC POLE SANTE SARTHE ET LOIR",
   finessju: 720016724,
   id: 720012202,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "244713172",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CHM SITE ALLONNES",
   finessju: 720000025,
   id: 720018423,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243804545",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CLAIREFONTAINE",
   finessju: 490001385,
   id: 490002805,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241895052",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD CREISKER",
   finessju: 440041689,
   id: 440041739,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "228550320",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD D'OREE",
   finessju: 490020088,
   id: 490002763,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240987226",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE FONTENAY",
   finessju: 720000926,
   id: 720002187,
   commune: "LOIR EN VALLEE",
   cp: 72340,
   telephone: "243444428",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE FONTENY",
   finessju: 440018406,
   id: 440013357,
   commune: "NANTES",
   cp: 44100,
   telephone: "240927570",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE L'ABBAYE",
   finessju: 720013101,
   id: 720013119,
   commune: "TUFFE VAL DE LA CHERONNE",
   cp: 72160,
   telephone: "243710346",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE L'AUBRAIE",
   finessju: 850020033,
   id: 850022781,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "251901672",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE LA BRIERE",
   finessju: 440001642,
   id: 440002822,
   commune: "ST LYPHARD",
   cp: 44410,
   telephone: "240914113",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE LA VALLEE DU DON",
   finessju: 440000669,
   id: 440000354,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240511515",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE LOUE",
   finessju: 720000850,
   id: 720002104,
   commune: "LOUE",
   cp: 72540,
   telephone: "243873100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE MAMERS",
   finessju: 610780082,
   id: 720018753,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243313137",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE SAINT GILLES CROIX DE VIE",
   finessju: 850009010,
   id: 850020488,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251608300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DE SEVRET",
   finessju: 490001484,
   id: 490002854,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241627920",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DELANTE",
   finessju: 720000892,
   id: 720002153,
   commune: "NOGENT LE BERNARD",
   cp: 72110,
   telephone: "243521252",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DES AVALOIRS",
   finessju: 530003524,
   id: 530002211,
   commune: "PRE EN PAIL ST SAMSON",
   cp: 53140,
   telephone: "243030144",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DES COLLINES VENDEENNES",
   finessju: 850025867,
   id: 850013343,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251536565",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DES DEUX CLOCHERS",
   finessju: 490542958,
   id: 490540481,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241515288",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DR GEHERE LAMOTTE",
   finessju: 530000587,
   id: 530002468,
   commune: "ST DENIS D ANJOU",
   cp: 53290,
   telephone: "243705208",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU BOCAGE",
   finessju: 440047454,
   id: 440003606,
   commune: "JOUE SUR ERDRE",
   cp: 44440,
   telephone: "240723545",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU BON PASTEUR",
   finessju: 440003325,
   id: 440009462,
   commune: "NANTES CEDEX 1",
   cp: 44046,
   telephone: "240743731",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU CENTRE HOSPITALIER",
   finessju: 440000057,
   id: 440047637,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240906060",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU CH DE MONTVAL SUR LOIR",
   finessju: 720000066,
   id: 720012178,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243447900",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU CHS G. MAZURELLE",
   finessju: 850000092,
   id: 850017658,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85026,
   telephone: "251097272",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU CLERGE",
   finessju: 850024225,
   id: 850024233,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910127",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU DON",
   finessju: 440006310,
   id: 440033413,
   commune: "ISSE",
   cp: 44520,
   telephone: "240551406",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU HAVRE",
   finessju: 440053643,
   id: 440003564,
   commune: "OUDON",
   cp: 44521,
   telephone: "240836027",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU PAYS DE ST FULGENT",
   finessju: 850026964,
   id: 850024746,
   commune: "CHAUCHE",
   cp: 85140,
   telephone: "251418872",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU PAYS DE ST FULGENT",
   finessju: 850026964,
   id: 850024761,
   commune: "LES BROUZILS",
   cp: 85260,
   telephone: "251429726",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DU SOLEIL",
   finessju: 440001543,
   id: 440002681,
   commune: "LA BERNERIE EN RETZ",
   cp: 44760,
   telephone: "240827167",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DUJARIE",
   finessju: 720016674,
   id: 720016682,
   commune: "LOIR EN VALLEE",
   cp: 72340,
   telephone: "243381108",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD DURAND ROBIN",
   finessju: 850012691,
   id: 850003583,
   commune: "LA FERRIERE",
   cp: 85280,
   telephone: "251984199",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD EAU VIVE",
   finessju: 530000074,
   id: 530033547,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243087300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ELSA TRIOLET",
   finessju: 440018521,
   id: 440009371,
   commune: "ST JOACHIM",
   cp: 44720,
   telephone: "240916150",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD EMILE GIBIER",
   finessju: 440018620,
   id: 440047611,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240634013",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ERNEST GUERIN",
   finessju: 850002239,
   id: 850020470,
   commune: "ST JEAN DE MONTS CEDEX",
   cp: 85167,
   telephone: "251599696",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESBV",
   finessju: 490015765,
   id: 490002227,
   commune: "MAZE MILON",
   cp: 49630,
   telephone: "241806027",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESBV",
   finessju: 490015765,
   id: 490002235,
   commune: "LA MENITRE",
   cp: 49250,
   telephone: "241456767",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESBV",
   finessju: 490015765,
   id: 490536059,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241841384",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESBV",
   finessju: 490015765,
   id: 490536067,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241575000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESPERANCE",
   finessju: 440003341,
   id: 440009512,
   commune: "NANTES",
   cp: 44000,
   telephone: "272015400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESSARTS EN BOCAGE",
   finessju: 850026972,
   id: 850003575,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628104",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ESSARTS EN BOCAGE",
   finessju: 850026972,
   id: 850023037,
   commune: "ST MARTIN DES NOYERS",
   cp: 85140,
   telephone: "251078838",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ETOILE DU SOIR",
   finessju: 850012618,
   id: 850002429,
   commune: "LA BRUFFIERE",
   cp: 85530,
   telephone: "251425062",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD EUGENE AUJALEU",
   finessju: 720012749,
   id: 720014067,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243615151",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD EUGENE MARIE",
   finessju: 530031798,
   id: 530029321,
   commune: "MONTAUDIN",
   cp: 53220,
   telephone: "243053312",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD EUPHRASIE PELLETIER",
   finessju: 490534823,
   id: 490007473,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241190101",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD EUROLAT",
   finessju: 530000793,
   id: 530029347,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243693259",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD FERRIE",
   finessju: 530031178,
   id: 530009034,
   commune: "LAVAL",
   cp: 53000,
   telephone: "253742323",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD FLEURS DES CHAMPS",
   finessju: 440001840,
   id: 440003051,
   commune: "LA PLANCHE",
   cp: 44140,
   telephone: "240319616",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD FOYER DE LA PERRIERE",
   finessju: 440001873,
   id: 440003101,
   commune: "HERIC",
   cp: 44810,
   telephone: "240576140",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD FRANCOIS DE DAILLON",
   finessju: 720000090,
   id: 720013580,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243484848",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD FRAN�OISE D'ANDIGNE",
   finessju: 490017357,
   id: 490541497,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241223516",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD FRERE ANDRE",
   finessju: 720000728,
   id: 720005958,
   commune: "PRECIGNE",
   cp: 72300,
   telephone: "243622121",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD GALATHEA",
   finessju: 920028560,
   id: 440021160,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240002400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD GASTON BIRGE",
   finessju: 490534732,
   id: 490003837,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241211300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD GILLES DE TYR",
   finessju: 490528452,
   id: 490536026,
   commune: "SAUMUR CEDEX",
   cp: 49403,
   telephone: "241533580",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HENRI PANETIER",
   finessju: 850012865,
   id: 850003237,
   commune: "NIEUL LE DOLENT",
   cp: 85430,
   telephone: "251079340",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HEOL",
   finessju: 920028560,
   id: 440021145,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251757001",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HESTIA",
   finessju: 530031178,
   id: 530003409,
   commune: "LAVAL",
   cp: 53000,
   telephone: "253741350",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HIC DE LA PRESQU'ILE",
   finessju: 440028538,
   id: 440021186,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240626540",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HIC DE LA PRESQU'ILE",
   finessju: 440028538,
   id: 440021350,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240626600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL BEAUMONT SUR SARTHE",
   finessju: 720002054,
   id: 720015759,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970390",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL BONNETABLE",
   finessju: 720002062,
   id: 720012293,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243294500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL LE BOIS JOLI",
   finessju: 530000066,
   id: 530031368,
   commune: "EVRON",
   cp: 53600,
   telephone: "243666507",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL LES TILLEULS",
   finessju: 720007244,
   id: 720011758,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243525354",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL PIERRE DELAROCHE",
   finessju: 440003267,
   id: 440047579,
   commune: "CLISSON CEDEX",
   cp: 44194,
   telephone: "240801919",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL SOM",
   finessju: 530007202,
   id: 530032739,
   commune: "RENAZE",
   cp: 53800,
   telephone: "243092700",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HL SOM",
   finessju: 530007202,
   id: 530032762,
   commune: "CRAON",
   cp: 53400,
   telephone: "243093232",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL BEL AIR",
   finessju: 440000347,
   id: 440047561,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251116500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL DE LA CORNICHE ANGEVINE",
   finessju: 490000395,
   id: 490002318,
   commune: "ROCHEFORT SUR LOIRE",
   cp: 49190,
   telephone: "241787060",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL DE LA CORNICHE ANGEVINE",
   finessju: 490000395,
   id: 490536083,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241742700",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL LOCAL",
   finessju: 850000100,
   id: 850020439,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390399",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL LOCAL ERNEE",
   finessju: 530000058,
   id: 530032754,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243083131",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL SEVRE ET LOIRE",
   finessju: 440042141,
   id: 440021228,
   commune: "VERTOU CEDEX",
   cp: 44122,
   telephone: "240808000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL ST JACQUES",
   finessju: 440000289,
   id: 440047595,
   commune: "NANTES",
   cp: 44200,
   telephone: "240483333",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL ST NICOLAS",
   finessju: 490001070,
   id: 490002268,
   commune: "ANGERS CEDEX 01",
   cp: 49016,
   telephone: "241734287",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD HOPITAL THIERRY DE LANGERAYE",
   finessju: 440000313,
   id: 490536174,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241948700",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD IASO",
   finessju: 260019005,
   id: 490003647,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241767208",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ILE DE NANTES",
   finessju: 920030152,
   id: 440047694,
   commune: "NANTES",
   cp: 44200,
   telephone: "251179900",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ISAC DE ROHAN - BLEU OCEAN",
   finessju: 440001915,
   id: 440003184,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240790039",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ISAC DE ROHAN - VERT PRE",
   finessju: 440001915,
   id: 440053601,
   commune: "BLAIN",
   cp: 44130,
   telephone: "-",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JACQUES BERTRAND",
   finessju: 440030914,
   id: 440030922,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240540403",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JARDIN DES MAGNOLIAS",
   finessju: 490000783,
   id: 490000858,
   commune: "MAULEVRIER",
   cp: 49360,
   telephone: "241550011",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JEAN JAURES",
   finessju: 720009729,
   id: 720013622,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243757758",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JEAN MACE",
   finessju: 440018620,
   id: 440032639,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240531213",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JEANNE JUGAN",
   finessju: 530000371,
   id: 530028968,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243665054",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JEANSON",
   finessju: 490535713,
   id: 490536471,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241669366",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JOLIOT CURIE",
   finessju: 720009729,
   id: 720009844,
   commune: "LE MANS CEDEX 2",
   cp: 72016,
   telephone: "243818775",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD JULES BERARD DE BONNIERE",
   finessju: 750056368,
   id: 720008580,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243812091",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KER MARIA",
   finessju: 440003010,
   id: 440003077,
   commune: "LA LIMOUZINIERE",
   cp: 44310,
   telephone: "240263555",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN ARTEMIS",
   finessju: 250015658,
   id: 720013663,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243406767",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN BOIS ROBILLARD",
   finessju: 750056335,
   id: 440042612,
   commune: "NANTES",
   cp: 44300,
   telephone: "251894000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN BOLLEE CHANZY",
   finessju: 720012699,
   id: 720016542,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243503510",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN JARDIN ATLANTIQUE",
   finessju: 250018686,
   id: 440025948,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240150889",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN LE BOURGENAY",
   finessju: 850004904,
   id: 850004912,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251968889",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN LE CASTELLI",
   finessju: 250015658,
   id: 530033133,
   commune: "L HUISSERIE",
   cp: 53970,
   telephone: "243916400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN LE RANZAY",
   finessju: 750056335,
   id: 440009439,
   commune: "NANTES",
   cp: 44300,
   telephone: "251898383",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN LES CORALLINES",
   finessju: 750056335,
   id: 440041200,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "251731111",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD KORIAN PONTLIEUE",
   finessju: 250018165,
   id: 720016419,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243787272",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ABBAYE",
   finessju: 490007739,
   id: 490002888,
   commune: "SAUMUR CEDEX",
   cp: 49426,
   telephone: "241501201",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ACCUEIL DE LA COTE DE JADE",
   finessju: 440005726,
   id: 440026318,
   commune: "LA PLAINE SUR MER",
   cp: 44770,
   telephone: "251748989",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'AGARET",
   finessju: 850016577,
   id: 850016569,
   commune: "BREM SUR MER",
   cp: 85470,
   telephone: "251909027",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'AIR DU TEMPS",
   finessju: 440018620,
   id: 440048817,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "228070000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'AIR DU TEMPS",
   finessju: 490001476,
   id: 490002938,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241553795",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ARBRASEVE",
   finessju: 850026527,
   id: 850003260,
   commune: "ROCHESERVIERE",
   cp: 85620,
   telephone: "251949044",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ARC EN CIEL",
   finessju: 720000884,
   id: 720002146,
   commune: "MONTMIRAIL",
   cp: 72320,
   telephone: "243937920",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'AUTOMNE",
   finessju: 440002970,
   id: 440024594,
   commune: "MONTOIR DE BRETAGNE",
   cp: 44550,
   telephone: "240454647",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'AVERSALE",
   finessju: 530000561,
   id: 530002443,
   commune: "LE PAS",
   cp: 53300,
   telephone: "243088712",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ENCHANTERIE",
   finessju: 440018620,
   id: 440044717,
   commune: "NANTES",
   cp: 44300,
   telephone: "251830997",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'EPINETTE",
   finessju: 490541190,
   id: 490541208,
   commune: "SOMLOIRE",
   cp: 49360,
   telephone: "241463031",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'EQUAIZIERE",
   finessju: 850012725,
   id: 850000423,
   commune: "LA GARNACHE",
   cp: 85710,
   telephone: "251681154",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ERMITAGE",
   finessju: 850012840,
   id: 850003229,
   commune: "MOUTIERS LES MAUXFAITS",
   cp: 85540,
   telephone: "251989108",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'HIRONDELLE DE SEVRE",
   finessju: 440018406,
   id: 440013316,
   commune: "NANTES",
   cp: 44200,
   telephone: "240042323",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'ILE VERTE",
   finessju: 440001618,
   id: 440002798,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240787050",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'IMMACULeE",
   finessju: 440002277,
   id: 440003598,
   commune: "VILLENEUVE EN RETZ",
   cp: 44580,
   telephone: "240214167",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'OREE DES PINS",
   finessju: 720013291,
   id: 720013309,
   commune: "MULSANNE",
   cp: 72230,
   telephone: "243470560",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'OREE DU BOCAGE",
   finessju: 850026469,
   id: 850022500,
   commune: "BELLEVIGNY",
   cp: 85170,
   telephone: "251412425",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD L'OREE DU PARC",
   finessju: 490535168,
   id: 490003811,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241724646",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA BAIE",
   finessju: 440041531,
   id: 440021301,
   commune: "VILLENEUVE EN RETZ",
   cp: 44580,
   telephone: "240213343",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA BERTHOMIERE",
   finessju: 850027681,
   id: 850022385,
   commune: "LONGEVILLE SUR MER",
   cp: 85560,
   telephone: "251333428",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA BIENVENUE",
   finessju: 850009382,
   id: 850009390,
   commune: "DOMPIERRE SUR YON",
   cp: 85170,
   telephone: "251480819",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA BLANCHINE",
   finessju: 490001468,
   id: 490002920,
   commune: "LA TESSOUALLE",
   cp: 49280,
   telephone: "241563297",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA BOURGONNIERE",
   finessju: 440001675,
   id: 440002855,
   commune: "ST HERBLAIN CEDEX",
   cp: 44807,
   telephone: "240860035",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA BUISSAIE",
   finessju: 490001377,
   id: 490002797,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "241797480",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CAP'LINE",
   finessju: 850006610,
   id: 850006651,
   commune: "LE PERRIER",
   cp: 85300,
   telephone: "251938394",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CERISAIE",
   finessju: 440041853,
   id: 440041861,
   commune: "NANTES",
   cp: 44200,
   telephone: "228441300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHALANDIERE",
   finessju: 440002970,
   id: 440013290,
   commune: "LA CHAPELLE DES MARAIS",
   cp: 44410,
   telephone: "240539953",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHANTERIE",
   finessju: 720011881,
   id: 720011899,
   commune: "COULANS SUR GEE",
   cp: 72550,
   telephone: "243887562",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHARMILLE",
   finessju: 530000439,
   id: 530002310,
   commune: "CHANTRIGNE",
   cp: 53300,
   telephone: "243008215",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHARMILLE",
   finessju: 850023953,
   id: 850023961,
   commune: "LE BOUPERE",
   cp: 85510,
   telephone: "251675726",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHATAIGNERAIE",
   finessju: 440001741,
   id: 440002921,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240016020",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHEZALIERE",
   finessju: 440005783,
   id: 440027118,
   commune: "NANTES",
   cp: 44100,
   telephone: "251847847",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CHIMOTAIE",
   finessju: 750005068,
   id: 850021973,
   commune: "CUGAND",
   cp: 85610,
   telephone: "251456600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CLE DE SOL",
   finessju: 850011834,
   id: 850011842,
   commune: "MOUILLERON ST GERMAIN",
   cp: 85390,
   telephone: "251507920",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CLERGERIE",
   finessju: 850016593,
   id: 850016585,
   commune: "COEX",
   cp: 85220,
   telephone: "251541597",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CLOSERAIE",
   finessju: 530000421,
   id: 530002302,
   commune: "BALLOTS",
   cp: 53350,
   telephone: "243066576",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA COLMONT",
   finessju: 530000553,
   id: 530002435,
   commune: "OISSEAU",
   cp: 53300,
   telephone: "243001260",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CORMETIERE",
   finessju: 490018488,
   id: 490536547,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241293602",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA COTE D'AMOUR",
   finessju: 440002970,
   id: 440028694,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240615050",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA CROIX DU GUE",
   finessju: 750034589,
   id: 440028850,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240844040",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA DIVE",
   finessju: 610780082,
   id: 720006550,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243313131",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA DOUCEUR DE VIVRE",
   finessju: 530000512,
   id: 530002393,
   commune: "MARTIGNE SUR MAYENNE",
   cp: 53470,
   telephone: "243025024",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA DOUCEUR DE VIVRE",
   finessju: 530000546,
   id: 530002427,
   commune: "MONTSURS ST CENERE",
   cp: 53150,
   telephone: "243010063",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA FONTAINE DU JEU",
   finessju: 850012733,
   id: 850003153,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251912631",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA FORET",
   finessju: 850012949,
   id: 850003302,
   commune: "ST JEAN DE MONTS CEDEX",
   cp: 85167,
   telephone: "251580382",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA FORET D'ESCOUBLAC",
   finessju: 440018620,
   id: 440044337,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240195287",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA GRANDE PROVIDENCE",
   finessju: 440047454,
   id: 440003432,
   commune: "NANTES",
   cp: 44000,
   telephone: "240358280",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA GRANGE",
   finessju: 440001402,
   id: 440002053,
   commune: "COUERON",
   cp: 44220,
   telephone: "240383814",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA GUILBOURDERIE",
   finessju: 440001774,
   id: 440002954,
   commune: "NANTES CEDEX 3",
   cp: 44322,
   telephone: "240672099",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA HAUTE MITRIE",
   finessju: 440018406,
   id: 440046134,
   commune: "NANTES",
   cp: 44300,
   telephone: "240522792",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA HAUTIERE",
   finessju: 440002426,
   id: 440003648,
   commune: "SUCE SUR ERDRE",
   cp: 44240,
   telephone: "240777279",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA HOUSSAIS",
   finessju: 440047454,
   id: 440002897,
   commune: "REZE CEDEX",
   cp: 44407,
   telephone: "240754579",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA HOUSSAYE",
   finessju: 720000934,
   id: 720002195,
   commune: "ST JEAN DU BOIS",
   cp: 72430,
   telephone: "243957705",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA JONCIERE",
   finessju: 440001410,
   id: 440002061,
   commune: "BOUSSAY",
   cp: 44190,
   telephone: "240068112",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA LANDE ST MARTIN",
   finessju: 750046518,
   id: 440022960,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "240549455",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA MADELEINE",
   finessju: 440018406,
   id: 440023190,
   commune: "NANTES",
   cp: 44200,
   telephone: "240890100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA MAISON D'ACCUEIL",
   finessju: 490001708,
   id: 490019643,
   commune: "LA SEGUINIERE",
   cp: 49280,
   telephone: "241569305",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA MAISON DU REPOS CH ST CALAIS",
   finessju: 720000140,
   id: 720011782,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243636465",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA MAISON DU SACRE COEUR",
   finessju: 850025180,
   id: 850007899,
   commune: "CHAVAGNES EN PAILLERS",
   cp: 85250,
   telephone: "251423072",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA MARTINIERE",
   finessju: 720016724,
   id: 720011766,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "244713232",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA MOULINOTTE",
   finessju: 850012931,
   id: 850002254,
   commune: "ST HILAIRE DES LOGES",
   cp: 85240,
   telephone: "251521015",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PERELLE",
   finessju: 530000496,
   id: 530002377,
   commune: "LANDIVY",
   cp: 53190,
   telephone: "243054215",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PERRIERE",
   finessju: 490004314,
   id: 490019668,
   commune: "LES GARENNES SUR LOIRE",
   cp: 49610,
   telephone: "241546615",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PETITE BRUYERE",
   finessju: 720001528,
   id: 720007087,
   commune: "VIBRAYE",
   cp: 72320,
   telephone: "243936069",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PIBOLE",
   finessju: 850000365,
   id: 850006644,
   commune: "LA BARRE DE MONTS",
   cp: 85550,
   telephone: "251938665",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PIERRE ROSE",
   finessju: 850006172,
   id: 850022831,
   commune: "ST PIERRE DU CHEMIN",
   cp: 85120,
   telephone: "251536363",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PROVIDENCE",
   finessju: 440002228,
   id: 440003440,
   commune: "ROUANS",
   cp: 44640,
   telephone: "240642323",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PROVIDENCE",
   finessju: 530000272,
   id: 530000397,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243984051",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PROVIDENCE",
   finessju: 750056368,
   id: 530005883,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243304432",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PROVIDENCE",
   finessju: 530006618,
   id: 720004175,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "243421071",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA PROVIDENCE",
   finessju: 590035762,
   id: 720005982,
   commune: "LA FLECHE CEDEX",
   cp: 72203,
   telephone: "243940144",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA REPOSANCE",
   finessju: 720006725,
   id: 720006790,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243842771",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA RESIDENCE PAUL LAIZE",
   finessju: 530031483,
   id: 530029297,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243018116",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA RETRAITE",
   finessju: 490004579,
   id: 490542792,
   commune: "ANGERS CEDEX 01",
   cp: 49044,
   telephone: "241687676",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA REYNERIE",
   finessju: 850000373,
   id: 850002163,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251687408",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA ROCHEFOUCAULD",
   finessju: 440000370,
   id: 440021293,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240796003",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA ROCTERIE",
   finessju: 850012444,
   id: 850012493,
   commune: "BARBATRE",
   cp: 85630,
   telephone: "251394210",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA ROSE DES VENTS",
   finessju: 440018638,
   id: 440002764,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240257325",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA ROSE DES VENTS",
   finessju: 720019462,
   id: 720018373,
   commune: "RUAUDIN",
   cp: 72230,
   telephone: "243722902",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA ROSELIERE",
   finessju: 440005759,
   id: 440026839,
   commune: "PONT ST MARTIN",
   cp: 44860,
   telephone: "240327272",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA ROSERAIE",
   finessju: 490001328,
   id: 490002748,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241752480",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SAGESSE",
   finessju: 490534740,
   id: 490002904,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241402380",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SAGESSE",
   finessju: 850009945,
   id: 850009952,
   commune: "ST LAURENT SUR SEVRE",
   cp: 85290,
   telephone: "251643809",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SAINTE FAMILLE",
   finessju: 440001576,
   id: 440002731,
   commune: "TEILLE",
   cp: 44440,
   telephone: "240972317",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SAINTE FAMILLE",
   finessju: 440003531,
   id: 440025443,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "240000300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SAINTE FAMILLE",
   finessju: 850014424,
   id: 850003849,
   commune: "STE GEMME LA PLAINE",
   cp: 85400,
   telephone: "251270314",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SANGLERIE",
   finessju: 440018638,
   id: 440007458,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240044373",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SMAGNE",
   finessju: 850026600,
   id: 850003294,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251273732",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SOURCE",
   finessju: 850023078,
   id: 850023086,
   commune: "BEAULIEU SOUS LA ROCHE",
   cp: 85190,
   telephone: "251982242",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SOUVENANCE",
   finessju: 720018613,
   id: 720017581,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243801010",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA SUZAIE",
   finessju: 440001568,
   id: 440002723,
   commune: "TRANS SUR ERDRE",
   cp: 44440,
   telephone: "240972371",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA TOUR DU PE",
   finessju: 440018638,
   id: 440007441,
   commune: "ST JEAN DE BOISEAU",
   cp: 44640,
   telephone: "240657042",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA VARENNE",
   finessju: 530000363,
   id: 530002278,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243301515",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA VIGNE AUX ROSES",
   finessju: 850012535,
   id: 850008699,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251376432",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LA VILLA DU CHENE D'OR",
   finessju: 690034475,
   id: 530007368,
   commune: "BONCHAMP LES LAVAL",
   cp: 53960,
   telephone: "243915930",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LAC DE MAINE",
   finessju: 490003670,
   id: 490538576,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241735500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LBA - LES SOURCES DU VERDET",
   finessju: 770001154,
   id: 440040467,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "251770552",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BEL ACCUEIL",
   finessju: 530000462,
   id: 530002344,
   commune: "FOUGEROLLES DU PLESSIS",
   cp: 53190,
   telephone: "243055017",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BOCAGE",
   finessju: 850023649,
   id: 850023656,
   commune: "ANTIGNY",
   cp: 85120,
   telephone: "251516060",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BOIS CLAIRAY",
   finessju: 490015757,
   id: 490008786,
   commune: "ALLONNES",
   cp: 49650,
   telephone: "241535500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BOIS FLEURI",
   finessju: 440001949,
   id: 440003218,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "251120060",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BOIS HERCE",
   finessju: 440001766,
   id: 440027092,
   commune: "NANTES",
   cp: 44100,
   telephone: "240952201",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BON VIEUX TEMPS",
   finessju: 440003127,
   id: 440024628,
   commune: "GORGES",
   cp: 44190,
   telephone: "240069171",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE BOURG JOLY",
   finessju: 490001179,
   id: 490002367,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241795500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CERCLE DES AINES ANGERS",
   finessju: 490007820,
   id: 490538840,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241245555",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CHAMBELLAN",
   finessju: 440018406,
   id: 440007276,
   commune: "NANTES",
   cp: 44300,
   telephone: "240496311",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CHAMP DE L'ORMEAU",
   finessju: 720014091,
   id: 720014109,
   commune: "ROUILLON",
   cp: 72700,
   telephone: "243393636",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CHENE DE LA CORMIERE",
   finessju: 440001881,
   id: 440003119,
   commune: "GUENROUET",
   cp: 44530,
   telephone: "240876424",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CHENE VERT",
   finessju: 850026600,
   id: 850023102,
   commune: "PUYRAVAULT",
   cp: 85450,
   telephone: "251286329",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CLAIR LOGIS",
   finessju: 490536646,
   id: 490530896,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241464333",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CLOS DE L'�LE MACe",
   finessju: 920030152,
   id: 440047546,
   commune: "REZE",
   cp: 44400,
   telephone: "251868133",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CLOS DES MURIERS",
   finessju: 920030152,
   id: 440044659,
   commune: "BATZ SUR MER",
   cp: 44740,
   telephone: "228567100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CLOS DU MOULIN",
   finessju: 440001865,
   id: 440003093,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "240333280",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CLOS FLEURI",
   finessju: 440003192,
   id: 440028827,
   commune: "DONGES",
   cp: 44480,
   telephone: "240910559",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE CLOS ST SEBASTIEN",
   finessju: 920030152,
   id: 440042851,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240808484",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE COLOMBIER",
   finessju: 850008921,
   id: 850008947,
   commune: "ST ETIENNE DU BOIS",
   cp: 85670,
   telephone: "251986868",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE COTEAU",
   finessju: 490001252,
   id: 490002532,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241714200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE DAUPHIN",
   finessju: 440053643,
   id: 440002640,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240983334",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE FAUBOURG ST VENERAND",
   finessju: 530000371,
   id: 530030139,
   commune: "LAVAL CEDEX",
   cp: 53006,
   telephone: "243665127",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE FOULON",
   finessju: 720020718,
   id: 720013648,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243931205",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE GUE FLORENT",
   finessju: 440018638,
   id: 440003887,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240633701",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE HAVRE DU PAYRE",
   finessju: 850012964,
   id: 850003310,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "251906054",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE HOME DU VERGER",
   finessju: 750056335,
   id: 850024712,
   commune: "APREMONT",
   cp: 85220,
   telephone: "251548957",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE LOGIS DES JARDINS",
   finessju: 490535168,
   id: 490538626,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241207272",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE LOGIS DES OLONNES",
   finessju: 850020116,
   id: 850017294,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251201111",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE LOGIS PETITE FORET",
   finessju: 440001535,
   id: 440002657,
   commune: "BOUVRON",
   cp: 44130,
   telephone: "240563113",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE MONTHEARD",
   finessju: 720018266,
   id: 720014471,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243402600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE MOULIN ROUGE",
   finessju: 850012535,
   id: 850016643,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85035,
   telephone: "251373444",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE MUGUET",
   finessju: 440018620,
   id: 440029544,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "251710896",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PARADIS",
   finessju: 720000942,
   id: 720002211,
   commune: "TENNIE",
   cp: 72240,
   telephone: "243205234",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PARC DE DIANE",
   finessju: 440034320,
   id: 440034338,
   commune: "NANTES",
   cp: 44200,
   telephone: "251795200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PARC DE L'AMANDE",
   finessju: 440018620,
   id: 440047470,
   commune: "NANTES",
   cp: 44300,
   telephone: "228258710",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PARC DE L'AUZANCE",
   finessju: 850025222,
   id: 850025230,
   commune: "VAIRE",
   cp: 85150,
   telephone: "251337474",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PARC DE LA PLESSE",
   finessju: 490003878,
   id: 490539236,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241374300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PERE LAURENT",
   finessju: 440003556,
   id: 440024651,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "251769500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PLOREAU",
   finessju: 440002335,
   id: 440003622,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240720010",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PORT",
   finessju: 440002970,
   id: 440042174,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251761919",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PRIEURE",
   finessju: 590035762,
   id: 440001196,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240016261",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PRIEURE",
   finessju: 440003952,
   id: 440012086,
   commune: "CORDEMAIS",
   cp: 44360,
   telephone: "240577198",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PRIEURE",
   finessju: 490001690,
   id: 490003795,
   commune: "MONTILLIERS",
   cp: 49310,
   telephone: "241758139",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE PRIEURE",
   finessju: 720000447,
   id: 720013598,
   commune: "PONTVALLAIN",
   cp: 72510,
   telephone: "243385151",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE RICHELIEU",
   finessju: 750056335,
   id: 850011909,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251242400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE ROCHARD",
   finessju: 530008168,
   id: 530002294,
   commune: "BAIS",
   cp: 53160,
   telephone: "243379040",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE ROCHER FLEURI",
   finessju: 530000371,
   id: 530033240,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243665000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE SEPTIER D'OR",
   finessju: 850016684,
   id: 850016676,
   commune: "TREIZE SEPTIERS",
   cp: 85600,
   telephone: "251415210",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE SEQUOIA",
   finessju: 720009836,
   id: 720008101,
   commune: "PARCE SUR SARTHE",
   cp: 72300,
   telephone: "243953920",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE SILLON",
   finessju: 440002269,
   id: 440003572,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240869072",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE TRAICT",
   finessju: 440002970,
   id: 440003234,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240005160",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE TUSSON",
   finessju: 610000754,
   id: 720000017,
   commune: "LOIR EN VALLEE",
   cp: 72340,
   telephone: "243353504",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE VAL D'EMILIE",
   finessju: 440001931,
   id: 440003200,
   commune: "DERVAL",
   cp: 44590,
   telephone: "240077025",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE VAL D'EVRE",
   finessju: 490018488,
   id: 490004249,
   commune: "TREMENTINES",
   cp: 49340,
   telephone: "241624052",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE VAL DE L'EVE",
   finessju: 750812844,
   id: 440009421,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "228550170",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE VAL FLEURI",
   finessju: 850022856,
   id: 850022872,
   commune: "VENANSAULT",
   cp: 85190,
   telephone: "251481224",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE VERGER",
   finessju: 440002616,
   id: 440007466,
   commune: "MAUVES SUR LOIRE",
   cp: 44470,
   telephone: "240255319",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LE VOLLIER",
   finessju: 530000447,
   id: 530002328,
   commune: "BOUERE",
   cp: 53290,
   telephone: "243705104",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LEON TAPON",
   finessju: 850012535,
   id: 850003286,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372982",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LEONTINE VIE",
   finessju: 440018620,
   id: 440049302,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240726340",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES 3 MOULINS",
   finessju: 440001691,
   id: 440002871,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "240978020",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ACACIAS",
   finessju: 490001518,
   id: 490003027,
   commune: "LES HAUTS D ANJOU",
   cp: 49330,
   telephone: "241420272",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES AJONCS",
   finessju: 440003531,
   id: 440003457,
   commune: "STE REINE DE BRETAGNE",
   cp: 44160,
   telephone: "240011023",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ARDILLERS",
   finessju: 850012816,
   id: 850003203,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251305092",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES AUGUSTINES",
   finessju: 490001658,
   id: 490003662,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241256060",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BIGOURETTES",
   finessju: 440018638,
   id: 440002665,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240462553",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BLOUINES",
   finessju: 490001674,
   id: 490003696,
   commune: "LES BOIS D ANJOU",
   cp: 49250,
   telephone: "241572402",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BORDS D'AMBOISE",
   finessju: 850012980,
   id: 850022864,
   commune: "MOUILLERON LE CAPTIF",
   cp: 85000,
   telephone: "251380820",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BORDS DE SARTHE",
   finessju: 490001088,
   id: 490002276,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "241422128",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BORDS DE SEVRE",
   finessju: 440047454,
   id: 440047462,
   commune: "REZE",
   cp: 44400,
   telephone: "272653121",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BOUTONS D'OR",
   finessju: 850009036,
   id: 850009044,
   commune: "L AIGUILLON SUR VIE",
   cp: 85220,
   telephone: "251228559",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES BRUYERES",
   finessju: 850012758,
   id: 850003187,
   commune: "LES LANDES GENUSSON",
   cp: 85130,
   telephone: "251916050",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CAPUCINS",
   finessju: 490535093,
   id: 490536562,
   commune: "ANGERS CEDEX 02",
   cp: 49103,
   telephone: "241351515",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHANTERELLES",
   finessju: 720000868,
   id: 720002120,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974046",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHARMILLES",
   finessju: 530000371,
   id: 530003128,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243597059",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHATAIGNIERS",
   finessju: 850016635,
   id: 850016627,
   commune: "SOULLANS",
   cp: 85300,
   telephone: "251490830",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHENES DU BELLAY",
   finessju: 490020161,
   id: 490002136,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240982011",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHENES DU BELLAY",
   finessju: 490020161,
   id: 490002201,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240090325",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHENES VERTS",
   finessju: 850012998,
   id: 850003179,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251260404",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CHEVRIERS",
   finessju: 720000876,
   id: 720002138,
   commune: "MAYET",
   cp: 72360,
   telephone: "243466064",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES COLLINES",
   finessju: 850012899,
   id: 850003492,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251570492",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CORDELIERES",
   finessju: 490001104,
   id: 490002292,
   commune: "LES PONTS DE CE CEDEX",
   cp: 49135,
   telephone: "241797400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CORDELIERS",
   finessju: 490000676,
   id: 490536018,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "241497151",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES CORDELIERS",
   finessju: 850027582,
   id: 850025602,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251238622",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES COROLLES",
   finessju: 440053643,
   id: 440021277,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "240094445",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES COTEAUX DE L'YON",
   finessju: 850025610,
   id: 850025628,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "251467980",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES COULEURS DE LA VIE",
   finessju: 530002591,
   id: 530031350,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243087000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES COULEURS DU TEMPS",
   finessju: 490535168,
   id: 490002961,
   commune: "VILLEVEQUE",
   cp: 49140,
   telephone: "241411213",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ECRIVAINS",
   finessju: 920030152,
   id: 440047744,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "228550600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES EGLANTINES",
   finessju: 440002038,
   id: 440024727,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "240398850",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES FILS D ARGENT",
   finessju: 750056335,
   id: 850022807,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251510616",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES FONTAINES",
   finessju: 490000833,
   id: 490000866,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "241698505",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES FONTAINES",
   finessju: 490001864,
   id: 490530987,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241454335",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES FONTENELLES",
   finessju: 440001600,
   id: 440002772,
   commune: "ST VINCENT DES LANDES",
   cp: 44590,
   telephone: "240553038",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES FOYERS DE LA FUIE",
   finessju: 720013408,
   id: 720013416,
   commune: "LAIGNE EN BELIN",
   cp: 72220,
   telephone: "243422828",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES FRESNES - LES CHATAIGNIERS",
   finessju: 720000835,
   id: 720002088,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243332440",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES GLENANS",
   finessju: 440048429,
   id: 440017747,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "240035657",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES GLYCINES",
   finessju: 530000538,
   id: 530002419,
   commune: "MONTENAY",
   cp: 53500,
   telephone: "243051778",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES GLYCINES",
   finessju: 720000488,
   id: 720000116,
   commune: "MANSIGNE",
   cp: 72510,
   telephone: "243461569",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES GLYCINES",
   finessju: 850018326,
   id: 850003856,
   commune: "ST PHILBERT DE BOUAINE",
   cp: 85660,
   telephone: "251419125",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES GLYCINES",
   finessju: 850009283,
   id: 850009317,
   commune: "FALLERON",
   cp: 85670,
   telephone: "251354238",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES GLYCINES",
   finessju: 850025206,
   id: 850025214,
   commune: "ST DENIS LA CHEVASSE",
   cp: 85170,
   telephone: "251414302",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HAUTS DE MAINE",
   finessju: 490000981,
   id: 490002151,
   commune: "ECOUFLANT",
   cp: 49000,
   telephone: "241324040",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HAUTS DE MAINE",
   finessju: 490000981,
   id: 490002169,
   commune: "FENEU",
   cp: 49460,
   telephone: "241320522",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HAUTS DE PLAISANCE",
   finessju: 850012584,
   id: 850003096,
   commune: "BENET",
   cp: 85490,
   telephone: "251009030",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HAUTS DE ST AIGNAN",
   finessju: 440018638,
   id: 440022671,
   commune: "NANTES",
   cp: 44100,
   telephone: "240694849",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HAUTS DU CHATEAU",
   finessju: 490000932,
   id: 490002110,
   commune: "CHAMPTOCE SUR LOIRE",
   cp: 49123,
   telephone: "241399166",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HESPERIDES",
   finessju: 720018050,
   id: 720011915,
   commune: "NEUFCHATEL EN SAOSNOIS",
   cp: 72600,
   telephone: "243972250",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HIRONDELLES",
   finessju: 850025164,
   id: 850025172,
   commune: "BEAUREPAIRE",
   cp: 85500,
   telephone: "251666814",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES HORTENSIAS",
   finessju: 440041531,
   id: 440041333,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240218866",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES IRIS",
   finessju: 330050899,
   id: 850009432,
   commune: "GIVRAND",
   cp: 85800,
   telephone: "251262222",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES JARDINS D OLONNE",
   finessju: 850011826,
   id: 850011503,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251212222",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES JARDINS DE BOTTEREL",
   finessju: 440042141,
   id: 440021244,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251711515",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES JARDINS DE L'ERDRE",
   finessju: 440001626,
   id: 440002806,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970096",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES JARDINS DE LA CHENAIE",
   finessju: 440047454,
   id: 440049062,
   commune: "NANTES",
   cp: 44300,
   telephone: "272657500",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES JARDINS DU VERT PRAUD",
   finessju: 440001725,
   id: 440002905,
   commune: "REZE",
   cp: 44400,
   telephone: "228272900",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES JARDINS MEDICIS",
   finessju: 850018862,
   id: 850011057,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251934818",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES LYS",
   finessju: 440018638,
   id: 440002327,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240508374",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES LYS",
   finessju: 720013564,
   id: 720013572,
   commune: "CHAMPFLEUR",
   cp: 72610,
   telephone: "233279083",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MAISONNEES DE LUMIERE",
   finessju: 850000084,
   id: 850020454,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85109,
   telephone: "251218585",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MAISONS DE L'HARMONIE",
   finessju: 850012444,
   id: 850017302,
   commune: "LA CHAIZE GIRAUD",
   cp: 85220,
   telephone: "251968373",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MARAICHERS",
   finessju: 920030152,
   id: 720014679,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243849999",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MARRONNIERS",
   finessju: 530000025,
   id: 530030147,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243093430",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MARRONNIERS",
   finessju: 850026600,
   id: 850019829,
   commune: "LA CAILLERE ST HILAIRE",
   cp: 85410,
   telephone: "251515858",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MATHURINS",
   finessju: 850000365,
   id: 850002155,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251938800",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MIMOSAS",
   finessju: 850012675,
   id: 850003146,
   commune: "COMMEQUIERS",
   cp: 85220,
   telephone: "251548074",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES MONCELLIERES",
   finessju: 490020468,
   id: 490020476,
   commune: "INGRANDES LE FRESNE SUR LO",
   cp: 49123,
   telephone: "241392113",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES NOISETIERS",
   finessju: 490535168,
   id: 490003829,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241669531",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ORMEAUX",
   finessju: 530000405,
   id: 530002286,
   commune: "LA BACONNIERE",
   cp: 53240,
   telephone: "243027108",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ORMES",
   finessju: 330050899,
   id: 440046936,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240640600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES OYATS",
   finessju: 850005240,
   id: 850005257,
   commune: "NOTRE DAME DE MONTS",
   cp: 85690,
   telephone: "251598989",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES PAMPRES DORES",
   finessju: 440018620,
   id: 440002673,
   commune: "VALLET",
   cp: 44330,
   telephone: "240339122",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES PICTONS",
   finessju: 850026600,
   id: 850003104,
   commune: "CHAILLE LES MARAIS",
   cp: 85450,
   telephone: "251567021",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES PLAINES",
   finessju: 490001237,
   id: 490002458,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241961741",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES RESIDENCES BOCAGE D'ANJOU",
   finessju: 490001211,
   id: 490002086,
   commune: "BECON LES GRANITS",
   cp: 49370,
   telephone: "241221010",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES RESIDENCES BOCAGE D'ANJOU",
   finessju: 490001211,
   id: 490002193,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241953006",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES RESIDENCES BOCAGE D'ANJOU",
   finessju: 490001211,
   id: 490002417,
   commune: "ERDRE EN ANJOU",
   cp: 49220,
   telephone: "241614118",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES RIVES DE L'AUXENCE",
   finessju: 490020468,
   id: 440002087,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240091616",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ROCHES",
   finessju: 720014919,
   id: 720014489,
   commune: "ST DENIS D ORQUES",
   cp: 72350,
   telephone: "243886000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES ROCHES",
   finessju: 850000431,
   id: 850002189,
   commune: "CHATEAU GUIBERT",
   cp: 85320,
   telephone: "251305505",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES SAVARIERES",
   finessju: 440018638,
   id: 440002780,
   commune: "ST SEBASTIEN SUR LOIRE CED",
   cp: 44235,
   telephone: "240805529",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES SOEURS DE RILLe",
   finessju: 350043915,
   id: 530029172,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243050729",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TEREBINTHES",
   finessju: 720011733,
   id: 720011980,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243404033",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TILLEULS",
   finessju: 440002970,
   id: 440003465,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240583109",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TILLEULS",
   finessju: 530000504,
   id: 530002385,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243047213",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TROENES",
   finessju: 490001229,
   id: 490002433,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241757201",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TROIS CLOCHERS",
   finessju: 440002608,
   id: 440007318,
   commune: "GETIGNE",
   cp: 44190,
   telephone: "251711800",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TROIS MOULINS",
   finessju: 490536679,
   id: 490019635,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241441245",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TROIS RIVIERES",
   finessju: 440001394,
   id: 440002046,
   commune: "FEGREAC",
   cp: 44460,
   telephone: "240002700",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TROIS VALLEES",
   finessju: 720009646,
   id: 720014075,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243762076",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES TULIPES",
   finessju: 850022401,
   id: 850022419,
   commune: "LA TRANCHE SUR MER",
   cp: 85360,
   telephone: "251277225",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LES VALLEES",
   finessju: 850027582,
   id: 850016601,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251956432",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LETORT LA CHEVRONNAIS",
   finessju: 530000579,
   id: 530002450,
   commune: "ST SATURNIN DU LIMET",
   cp: 53800,
   telephone: "243064351",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LOUIS CAIVEAU",
   finessju: 850017963,
   id: 850021544,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251540000",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LOUIS CROSNIER",
   finessju: 850012550,
   id: 850003559,
   commune: "ANGLES",
   cp: 85750,
   telephone: "251975270",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LOUIS CUBAYNES",
   finessju: 440035079,
   id: 440024701,
   commune: "PIRIAC SUR MER",
   cp: 44420,
   telephone: "240235378",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LOUIS PASTEUR",
   finessju: 720000777,
   id: 720000546,
   commune: "BESSE SUR BRAYE",
   cp: 72310,
   telephone: "243353037",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD LOUISE MICHEL",
   finessju: 440018620,
   id: 440052694,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240221215",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MA MAISON",
   finessju: 440003333,
   id: 440009488,
   commune: "NANTES CEDEX 1",
   cp: 44042,
   telephone: "240200050",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MA MAISON",
   finessju: 490001666,
   id: 490003688,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241436382",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAINE COEUR DE SARTHE",
   finessju: 720021260,
   id: 720002047,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243273006",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAINE COEUR DE SARTHE",
   finessju: 720021260,
   id: 720002252,
   commune: "STE JAMME SUR SARTHE",
   cp: 72380,
   telephone: "243276039",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAISON D'ACCUEIL ST JULIEN",
   finessju: 440001659,
   id: 440002830,
   commune: "ST JULIEN DE VOUVANTES",
   cp: 44670,
   telephone: "240555127",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAISON DE RETRAITE PROTESTANTE",
   finessju: 750721300,
   id: 440024735,
   commune: "NANTES",
   cp: 44200,
   telephone: "240693544",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAISON DES SOEURS AINEES",
   finessju: 850007469,
   id: 850007709,
   commune: "LES BROUZILS",
   cp: 85260,
   telephone: "251429114",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAISON DES SOEURS AINEES",
   finessju: 850007469,
   id: 850007758,
   commune: "MONTREVERD",
   cp: 85260,
   telephone: "251439154",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAISON RETRAITE CH ST CALAIS",
   finessju: 720000140,
   id: 720006006,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243636405",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAISON SAINT GABRIEL",
   finessju: 440044477,
   id: 440044485,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "251859765",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MARCEL LEBRETON",
   finessju: 490536588,
   id: 490019676,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241476030",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MARIE BERNARD",
   finessju: 490009339,
   id: 490007440,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241651396",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MARIE FANNEAU DE LA HORIE",
   finessju: 530031202,
   id: 530002518,
   commune: "JAVRON LES CHAPELLES",
   cp: 53250,
   telephone: "243034146",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MARIE LOUISE BODIN",
   finessju: 720000843,
   id: 720002096,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243409135",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MARIN BOUILLE",
   finessju: 530000348,
   id: 530002260,
   commune: "ALEXAIN",
   cp: 53240,
   telephone: "243698088",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MARTIAL CAILLAUD",
   finessju: 850026527,
   id: 850023425,
   commune: "L HERBERGEMENT",
   cp: 85260,
   telephone: "251428907",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MAUPERTHUIS",
   finessju: 440050904,
   id: 440002913,
   commune: "REZE",
   cp: 44400,
   telephone: "240754992",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MER ET PINS",
   finessju: 440042356,
   id: 440023810,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "251747112",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MICHELLE GUILLAUME",
   finessju: 440044535,
   id: 440044543,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "240015516",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MON REPOS",
   finessju: 440001451,
   id: 440002103,
   commune: "AIGREFEUILLE SUR MAINE",
   cp: 44140,
   telephone: "240066048",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MONTCLAIR",
   finessju: 440001832,
   id: 440003044,
   commune: "LE CELLIER",
   cp: 44850,
   telephone: "240254189",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MONTFORT",
   finessju: 850000464,
   id: 850002221,
   commune: "ST LAURENT SUR SEVRE",
   cp: 85290,
   telephone: "251678006",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MONTREUIL BELLAY",
   finessju: 490001062,
   id: 490002250,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241402290",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MOULIN SOLINE",
   finessju: 440006005,
   id: 440030484,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240035074",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITE LE MARAIS",
   finessju: 850012931,
   id: 850003484,
   commune: "MAILLEZAIS",
   cp: 85420,
   telephone: "251007469",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITE LE MARAIS",
   finessju: 850012931,
   id: 850003815,
   commune: "MAILLE",
   cp: 85420,
   telephone: "251870063",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITE PISSOTTE-VOUVANT",
   finessju: 850012717,
   id: 850003245,
   commune: "PISSOTTE",
   cp: 85200,
   telephone: "251690389",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITE PISSOTTE-VOUVANT",
   finessju: 850012717,
   id: 850023136,
   commune: "VOUVANT",
   cp: 85120,
   telephone: "251008585",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES PAYS DE CHANTONNAY",
   finessju: 850009242,
   id: 850003120,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943204",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES PAYS DE CHANTONNAY",
   finessju: 850009242,
   id: 850004896,
   commune: "ST PROUANT",
   cp: 85110,
   telephone: "251664265",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES PAYS DE CHANTONNAY",
   finessju: 850009242,
   id: 850021379,
   commune: "BOURNEZEAU",
   cp: 85480,
   telephone: "251407749",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850002015,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251940209",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850022435,
   commune: "ST HILAIRE DE LOULAY",
   cp: 85600,
   telephone: "251463022",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850022443,
   commune: "ST GEORGES DE MONTAIGU",
   cp: 85600,
   telephone: "251420842",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850026279,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251315260",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850026287,
   commune: "BOUFFERE",
   cp: 85600,
   telephone: "251095470",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD MULTISITES TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850026295,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "-",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD NAZARETH",
   finessju: 490001310,
   id: 490002730,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241622898",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ND DE LA MISERICORDE",
   finessju: 530000744,
   id: 530005818,
   commune: "ENTRAMMES",
   cp: 53260,
   telephone: "243566855",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ND DE LA MISERICORDE",
   finessju: 530000744,
   id: 530029164,
   commune: "LAVAL CEDEX",
   cp: 53013,
   telephone: "243590470",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD NOTRE DAME DE CHARITE",
   finessju: 440018620,
   id: 440022861,
   commune: "NANTES",
   cp: 44000,
   telephone: "240738326",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD NOTRE DAME DE LORETTE",
   finessju: 850026451,
   id: 850003914,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251572235",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD NOTRE DAME DU BON SECOURS",
   finessju: 490535705,
   id: 490002821,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241700019",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD NOTRE DAME DU CHENE",
   finessju: 440018620,
   id: 440044592,
   commune: "NANTES",
   cp: 44100,
   telephone: "240467412",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD NOTRE DAME DU DON",
   finessju: 440002012,
   id: 440003416,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240076106",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD OCEANE",
   finessju: 490003670,
   id: 440040616,
   commune: "NANTES",
   cp: 44100,
   telephone: "251843100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ORPEA LES SABLONS",
   finessju: 920030152,
   id: 720017573,
   commune: "LE MANS CEDEX 1",
   cp: 72001,
   telephone: "243185100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PAIMBOEUF",
   finessju: 440041531,
   id: 440021129,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "228532822",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PAUL BOUHIER",
   finessju: 850012543,
   id: 850003088,
   commune: "L AIGUILLON SUR MER",
   cp: 85460,
   telephone: "251271410",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PAUL CHAPRON",
   finessju: 720006022,
   id: 720012186,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72401,
   telephone: "243716151",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PAUL LINTIER",
   finessju: 530000074,
   id: 530031376,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243087300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PAYRAUDEAU",
   finessju: 850000381,
   id: 850002171,
   commune: "LA CHAIZE LE VICOMTE",
   cp: 85310,
   telephone: "251401515",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PERRINE THULARD",
   finessju: 530006618,
   id: 530006758,
   commune: "EVRON CEDEX",
   cp: 53602,
   telephone: "243263131",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PICASSO",
   finessju: 490535168,
   id: 490535648,
   commune: "ANGERS CEDEX 01",
   cp: 49044,
   telephone: "241809280",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PIERRE GENAIS",
   finessju: 850012576,
   id: 850003567,
   commune: "AVRILLE",
   cp: 85440,
   telephone: "251223396",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PIERRE GUICHENEY",
   finessju: 530000330,
   id: 530002229,
   commune: "LE BOURGNEUF LA FORET",
   cp: 53410,
   telephone: "243377131",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD PORNIC",
   finessju: 440041531,
   id: 440032407,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240640142",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD QUIETUS",
   finessju: 440005932,
   id: 440029866,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240627400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RENOIR",
   finessju: 440018406,
   id: 440028900,
   commune: "NANTES",
   cp: 44100,
   telephone: "240734183",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD REPOS DE PROCE",
   finessju: 440018620,
   id: 440009447,
   commune: "NANTES",
   cp: 44100,
   telephone: "240952959",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE AU FIL DES MAINES",
   finessju: 850000456,
   id: 850002197,
   commune: "CHAVAGNES EN PAILLERS",
   cp: 85250,
   telephone: "251422153",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE AU FIL DES MAINES",
   finessju: 850000456,
   id: 850002213,
   commune: "ST FULGENT",
   cp: 85250,
   telephone: "251426038",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DE L'ORIOLET",
   finessju: 530007863,
   id: 530002534,
   commune: "VAIGES",
   cp: 53480,
   telephone: "243641220",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DE L'ORIOLET",
   finessju: 530007863,
   id: 530029198,
   commune: "SOULGE SUR OUETTE",
   cp: 53210,
   telephone: "243023127",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DES SOURCES",
   finessju: 490001153,
   id: 490002342,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241646168",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DU LATTAY",
   finessju: 490001443,
   id: 490002896,
   commune: "VAL DU LAYON",
   cp: 49750,
   telephone: "241743200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DU PARC",
   finessju: 720013382,
   id: 720013390,
   commune: "CHAHAIGNES",
   cp: 72340,
   telephone: "243798632",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DU VAL D'OUDON",
   finessju: 490001195,
   id: 490002219,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241945600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DU VAL D'OUDON",
   finessju: 490001195,
   id: 490002359,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241613140",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DU VAL D'OUDON",
   finessju: 490001195,
   id: 490002383,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241921433",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE DU VAL D'OUDON",
   finessju: 490001195,
   id: 490536190,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241928860",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE FLEURIE",
   finessju: 850026600,
   id: 850003831,
   commune: "NALLIERS",
   cp: 85370,
   telephone: "251307019",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE SAINTE MARIE",
   finessju: 490016359,
   id: 490002052,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241465549",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCE ST GABRIEL",
   finessju: 530007129,
   id: 530033075,
   commune: "ST AIGNAN SUR ROE",
   cp: 53390,
   telephone: "243065154",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES AU FIL DU LOIR",
   finessju: 490000767,
   id: 490000841,
   commune: "SEICHES SUR LE LOIR",
   cp: 49140,
   telephone: "241214242",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES AU FIL DU LOIR",
   finessju: 490000767,
   id: 490002144,
   commune: "DURTAL",
   cp: 49430,
   telephone: "241761313",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES DE L'EVRE",
   finessju: 490000999,
   id: 490002185,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241641045",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES DE L'EVRE",
   finessju: 490000999,
   id: 490002771,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "241638046",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES LES LIGERIENNES",
   finessju: 490002334,
   id: 490002243,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241398107",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES LES LIGERIENNES",
   finessju: 490002334,
   id: 490002300,
   commune: "LA POSSONNIERE",
   cp: 49170,
   telephone: "241722073",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES LES LIGERIENNES",
   finessju: 490002334,
   id: 490002375,
   commune: "SAVENNIERES",
   cp: 49170,
   telephone: "241722114",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RESIDENCES LES LIGERIENNES",
   finessju: 490002334,
   id: 490536182,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241396060",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD RICHEBOURG",
   finessju: 440018620,
   id: 440028553,
   commune: "NANTES",
   cp: 44000,
   telephone: "240379394",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ROCHE MAILLARD",
   finessju: 440003473,
   id: 440024644,
   commune: "VIGNEUX DE BRETAGNE",
   cp: 44360,
   telephone: "240571200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT ALDRIC",
   finessju: 750056368,
   id: 720017862,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243819098",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT ALEXANDRE",
   finessju: 850020280,
   id: 850020298,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651230",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT ANDRE",
   finessju: 440001683,
   id: 440002863,
   commune: "ST HILAIRE DE CHALEONS",
   cp: 44680,
   telephone: "240317065",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT CHARLES",
   finessju: 490018587,
   id: 490003720,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241771455",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT CHARLES",
   finessju: 490018587,
   id: 490007481,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241480033",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT DENIS",
   finessju: 850014390,
   id: 850003898,
   commune: "VOUILLE LES MARAIS",
   cp: 85450,
   telephone: "251525480",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT FRAIMBAULT",
   finessju: 530007129,
   id: 530029180,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243047245",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT FRANCOIS",
   finessju: 920028560,
   id: 490007515,
   commune: "ANGERS CEDEX 01",
   cp: 49044,
   telephone: "241666642",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT GILDAS",
   finessju: 440047454,
   id: 440002699,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820630",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT JOSEPH",
   finessju: 440001766,
   id: 440002947,
   commune: "NANTES CEDEX 1",
   cp: 44042,
   telephone: "251860180",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT JOSEPH",
   finessju: 490541174,
   id: 490003761,
   commune: "JARZE VILLAGES",
   cp: 49140,
   telephone: "241210110",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT JOSEPH",
   finessju: 530000025,
   id: 530002013,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243093400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT JOSEPH",
   finessju: 850014408,
   id: 850003872,
   commune: "VIX",
   cp: 85770,
   telephone: "251006032",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT JULIEN",
   finessju: 720006022,
   id: 720011154,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72401,
   telephone: "243713942",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT LAURENT",
   finessju: 530000470,
   id: 530002351,
   commune: "GORRON",
   cp: 53120,
   telephone: "243086312",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT LUC",
   finessju: 850014382,
   id: 850003799,
   commune: "DOIX LES FONTAINES",
   cp: 85200,
   telephone: "251518379",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT MARTIN",
   finessju: 440001303,
   id: 440028595,
   commune: "CAMPBON",
   cp: 44750,
   telephone: "240565656",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT MARTIN",
   finessju: 490001716,
   id: 490536208,
   commune: "BEAUPREAU CEDEX",
   cp: 49601,
   telephone: "241713100",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT MARTIN LA FORET",
   finessju: 490020773,
   id: 490003654,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241225200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT PAUL",
   finessju: 440001709,
   id: 440002889,
   commune: "REZE",
   cp: 44400,
   telephone: "240323865",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT PIERRE",
   finessju: 850012873,
   id: 850002296,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "251985151",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT VETERIN",
   finessju: 490001336,
   id: 490002755,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "241518114",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT-JOSEPH",
   finessju: 440018620,
   id: 440003549,
   commune: "FAY DE BRETAGNE",
   cp: 44130,
   telephone: "240874632",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT-JOSEPH",
   finessju: 490001872,
   id: 490531001,
   commune: "CHENILLE CHAMPTEUSSE",
   cp: 49220,
   telephone: "241951101",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT-LOUIS",
   finessju: 440018620,
   id: 440003135,
   commune: "GENESTON",
   cp: 44140,
   telephone: "240267033",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT-LOUIS",
   finessju: 490020112,
   id: 490002441,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240835210",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINT-PIERRE",
   finessju: 440001782,
   id: 440002988,
   commune: "LIGNE",
   cp: 44850,
   telephone: "240770423",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE ANNE",
   finessju: 440001634,
   id: 440002814,
   commune: "ST MARS DE COUTAIS",
   cp: 44680,
   telephone: "240315019",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE ANNE",
   finessju: 490001484,
   id: 490002946,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241429509",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE ANNE",
   finessju: 490003704,
   id: 490538832,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE ANNE",
   finessju: 850012774,
   id: 850003195,
   commune: "LES LUCS SUR BOULOGNE",
   cp: 85170,
   telephone: "251312040",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE ANNE",
   finessju: 850009275,
   id: 850003930,
   commune: "JARD SUR MER",
   cp: 85520,
   telephone: "251334055",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE CLAIRE",
   finessju: 490001393,
   id: 490002813,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241615132",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE MARIE",
   finessju: 490020773,
   id: 490007556,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241368040",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE MARIE",
   finessju: 850005117,
   id: 850003955,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "251906076",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SAINTE SOPHIE",
   finessju: 850006560,
   id: 850003807,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251910481",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SIMON RINGEARD",
   finessju: 440001808,
   id: 440003002,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "240046462",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SIMONNE MOREAU",
   finessju: 850012444,
   id: 850011958,
   commune: "AUBIGNY LES CLOUZEAUX",
   cp: 85430,
   telephone: "251443720",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SOEURS AINEES J DELANOUE",
   finessju: 490535721,
   id: 490007432,
   commune: "SAUMUR CEDEX",
   cp: 49426,
   telephone: "241501137",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST ANDRE D'ORNAY",
   finessju: 850012535,
   id: 850006545,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251240444",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST CHARLES",
   finessju: 440001311,
   id: 440028868,
   commune: "MISSILLAC",
   cp: 44780,
   telephone: "240004775",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST CHRISTOPHE",
   finessju: 850017831,
   id: 850023060,
   commune: "ST CHRISTOPHE DU LIGNERON",
   cp: 85670,
   telephone: "251688920",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST GABRIEL",
   finessju: 850014374,
   id: 850003781,
   commune: "CUGAND",
   cp: 85610,
   telephone: "251421541",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST GEORGES DE LISLE",
   finessju: 750056368,
   id: 530002609,
   commune: "ST FRAIMBAULT DE PRIERES",
   cp: 53300,
   telephone: "243008764",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST JOSEPH",
   finessju: 440001444,
   id: 440002095,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213015",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST JOSEPH",
   finessju: 440001790,
   id: 440002996,
   commune: "LES TOUCHES",
   cp: 44390,
   telephone: "251128900",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST JOSEPH",
   finessju: 440003010,
   id: 440024602,
   commune: "LEGE",
   cp: 44650,
   telephone: "240263555",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST JOSEPH",
   finessju: 490001492,
   id: 490002953,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241309010",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST JOSEPH",
   finessju: 850006560,
   id: 850003963,
   commune: "LA VERRIE",
   cp: 85130,
   telephone: "251659269",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST MARTIN",
   finessju: 440003085,
   id: 440024610,
   commune: "LA CHEVROLIERE",
   cp: 44118,
   telephone: "240313298",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST RAPHAEL",
   finessju: 720001395,
   id: 720004142,
   commune: "SOLESMES",
   cp: 72300,
   telephone: "243951925",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD ST VINCENT DE PAUL",
   finessju: 590035762,
   id: 720008135,
   commune: "YVRE L EVEQUE",
   cp: 72530,
   telephone: "243899920",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD STE BERNADETTE",
   finessju: 850014440,
   id: 850003773,
   commune: "CHAMPAGNE LES MARAIS",
   cp: 85450,
   telephone: "251566116",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD STE FAMILLE DE GRILLAUD",
   finessju: 440045599,
   id: 440045607,
   commune: "NANTES CEDEX 4",
   cp: 44107,
   telephone: "272227400",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD SUZANNE FLON",
   finessju: 440018620,
   id: 440051589,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240175570",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD THARREAU",
   finessju: 590019568,
   id: 490003928,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241624074",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD THEOPHILE BRETONNIERE",
   finessju: 440001667,
   id: 440002848,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240541059",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD UNION CHRETIENNE",
   finessju: 850024449,
   id: 850024456,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251501333",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VAL DE BRUTZ",
   finessju: 440005866,
   id: 440028934,
   commune: "ROUGE",
   cp: 44660,
   telephone: "240287242",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VAL DE MOINE",
   finessju: 490018488,
   id: 490017480,
   commune: "CHOLET",
   cp: 49300,
   telephone: "249720200",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VALLEE GELUSSEAU",
   finessju: 490000940,
   id: 490002128,
   commune: "CORON",
   cp: 49690,
   telephone: "241558181",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VICTOIRE BRIELLE",
   finessju: 530000520,
   id: 530002401,
   commune: "MERAL",
   cp: 53230,
   telephone: "243914600",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VICTOR ECOMARD",
   finessju: 440001592,
   id: 440002756,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240024055",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VILLA BEAUSOLEIL",
   finessju: 920028669,
   id: 850017070,
   commune: "NOTRE DAME DE RIEZ",
   cp: 85270,
   telephone: "251552300",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VILLAGE FLEURI",
   finessju: 530000488,
   id: 530002369,
   commune: "JUVIGNE",
   cp: 53380,
   telephone: "243685303",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VILLAGE SANTE SAINT JOSEPH",
   finessju: 490000692,
   id: 490536216,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241637163",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VIVES ALOUETTES",
   finessju: 490020088,
   id: 490540390,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240837027",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VIVRE ENSEMBLE",
   finessju: 490017761,
   id: 490002862,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241490330",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VIVRE ENSEMBLE",
   finessju: 490017761,
   id: 490003787,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241643325",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD VIVRE ENSEMBLE",
   finessju: 490017761,
   id: 490007424,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241491245",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "EHPAD YVES COUGNAUD",
   finessju: 850012881,
   id: 850003252,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251981730",
   categorie: "Etablissement d'hebergement pour personnes �gees dependantes"
 },
 {
   finess: "FINESS",
   name: "ANNEXE ESAT ANCENIS",
   finessju: 440018380,
   id: 440042422,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240960011",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE ESAT ARC EN CIEL",
   finessju: 490536877,
   id: 490542271,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241621912",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ACTI SUD",
   finessju: 850012436,
   id: 850000290,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372859",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI 49",
   finessju: 490535192,
   id: 490007614,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241581921",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI 49",
   finessju: 490535192,
   id: 490011475,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241691159",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI 49",
   finessju: 490535192,
   id: 490532066,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241211141",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI 49",
   finessju: 490535192,
   id: 490542768,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241778440",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI ARIA",
   finessju: 850012436,
   id: 850000274,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692240",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI ARIA",
   finessju: 850012436,
   id: 850011230,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251056579",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ADAPEI ARIA",
   finessju: 850012436,
   id: 850011990,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251351873",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ANAIS",
   finessju: 610000754,
   id: 720014703,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243825538",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ANNEXE DES ESSARTS",
   finessju: 930712393,
   id: 850009416,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251484848",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ANNEXE LA ROCHE/YON",
   finessju: 850012436,
   id: 850016650,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251372859",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ANNEXE MARIE MOREAU",
   finessju: 440001352,
   id: 440041051,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "251160305",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT APF LE CORMIER",
   finessju: 750719239,
   id: 490543055,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241588586",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ARC EN CIEL",
   finessju: 490536877,
   id: 490531837,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241621912",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ARCEAU ANJOU",
   finessju: 490535168,
   id: 490532090,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241961350",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ARTA",
   finessju: 490535168,
   id: 440033397,
   commune: "ST SEBASTIEN SUR LOIRE CED",
   cp: 44233,
   telephone: "251790909",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ATELIERS BLINOIS",
   finessju: 440018380,
   id: 440022523,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240791781",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ATELIERS DE LA CHOLI�RE",
   finessju: 440018380,
   id: 440007490,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240630923",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ATELIERS DE LA COLMONT",
   finessju: 530033000,
   id: 530028554,
   commune: "GORRON",
   cp: 53120,
   telephone: "243084122",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ATELIERS DU LANDAS",
   finessju: 440018380,
   id: 440005122,
   commune: "REZE CEDEX",
   cp: 44401,
   telephone: "240841334",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT BIOCAT",
   finessju: 440018380,
   id: 440031458,
   commune: "GETIGNE",
   cp: 44190,
   telephone: "240540199",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT BORD DE LOIRE",
   finessju: 490011343,
   id: 490542750,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241662121",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT CATMANOR",
   finessju: 720008820,
   id: 720008333,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243242248",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT CATOUEST",
   finessju: 440018380,
   id: 440032944,
   commune: "ST HERBLAIN CEDEX",
   cp: 44813,
   telephone: "240630330",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT CHAMPROVENT",
   finessju: 850012436,
   id: 850020603,
   commune: "STE GEMME LA PLAINE",
   cp: 85400,
   telephone: "251270100",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DE LA FLECHE",
   finessju: 720008762,
   id: 720008317,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243947822",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DE LA VERTONNE",
   finessju: 440011484,
   id: 440033900,
   commune: "VERTOU CEDEX",
   cp: 44121,
   telephone: "251710969",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DE LEGE",
   finessju: 440018380,
   id: 440011492,
   commune: "LEGE",
   cp: 44650,
   telephone: "240263237",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DE PESCHERAY",
   finessju: 750720591,
   id: 720005743,
   commune: "LE BREIL SUR MERIZE",
   cp: 72370,
   telephone: "243898362",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DE SAILLE",
   finessju: 440018398,
   id: 440012706,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240151020",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DU BRIVET",
   finessju: 440018398,
   id: 440007540,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240456655",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DU CIRCUIT",
   finessju: 720009562,
   id: 720005750,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243503470",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DU HAUT ANJOU",
   finessju: 490536885,
   id: 490535135,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241947360",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DU JONCHERAY",
   finessju: 490543600,
   id: 490543618,
   commune: "LES HAUTS D ANJOU",
   cp: 49330,
   telephone: "241339393",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT DU PAYS D'ANCENIS",
   finessju: 440018380,
   id: 440003713,
   commune: "ANCENIS CEDEX",
   cp: 44153,
   telephone: "240960011",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT GERARD CORRE",
   finessju: 490538642,
   id: 490016052,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241347932",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT GERMAINE CHERBONNIER",
   finessju: 490535754,
   id: 490531746,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241305144",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT HANDIPEPITE",
   finessju: 850012436,
   id: 850026311,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372859",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT HORS LES MURS",
   finessju: 720008762,
   id: 720018027,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243500475",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT HORTICAT",
   finessju: 440018380,
   id: 440012714,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213600",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT IONESCO",
   finessju: 530000710,
   id: 530028562,
   commune: "LA CHAPELLE ANTHENAISE",
   cp: 53950,
   telephone: "243011071",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT JARDIN DES PLANTES",
   finessju: 750721334,
   id: 490531845,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241402100",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440032951,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240622811",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT L'ARGERIE",
   finessju: 490020310,
   id: 490011491,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241772360",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT L'ETAPE TOURNIERE",
   finessju: 440018646,
   id: 440001162,
   commune: "CARQUEFOU CEDEX",
   cp: 44475,
   telephone: "240509586",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA BELLE OUVRAGE",
   finessju: 530000702,
   id: 530028588,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660444",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA BREOTIERE",
   finessju: 490016979,
   id: 490536570,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241828845",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA GIBAUDIERE",
   finessju: 490538642,
   id: 490543022,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241772352",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA LARGERE",
   finessju: 850012436,
   id: 850014309,
   commune: "THOUARSAIS BOUILDROUX",
   cp: 85410,
   telephone: "251519696",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA MADELEINE",
   finessju: 530007186,
   id: 530033042,
   commune: "MAYENNE CEDEX",
   cp: 53104,
   telephone: "243320754",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA REBELLERIE",
   finessju: 490534831,
   id: 490541083,
   commune: "LYS HAUT LAYON",
   cp: 49560,
   telephone: "241595351",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA SOUBRETI�RE",
   finessju: 440004315,
   id: 440012573,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LA VERZEE",
   finessju: 490016979,
   id: 490012234,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241926028",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LANCHENEIL",
   finessju: 530000710,
   id: 530028604,
   commune: "NUILLE SUR VICOIN",
   cp: 53970,
   telephone: "243641280",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE BOCAGE",
   finessju: 930712393,
   id: 850000407,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251484848",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE BOIS JOLI",
   finessju: 720009562,
   id: 720008010,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243399939",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE GENETEIL",
   finessju: 530031434,
   id: 530028547,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53202,
   telephone: "243091190",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE GUETTE MIDI",
   finessju: 720009562,
   id: 720011949,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243273991",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE PONCEAU",
   finessju: 530031905,
   id: 530028570,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "243061604",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE SENEVE",
   finessju: 490011798,
   id: 490015773,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241484906",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LE TERTRE",
   finessju: 720009562,
   id: 720008291,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243710228",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES 3 PAROISSES",
   finessju: 490538642,
   id: 490531738,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241684422",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES 4 VENTS",
   finessju: 850013277,
   id: 850012261,
   commune: "L EPINE",
   cp: 85740,
   telephone: "251358800",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES ATELIERS CALAISIENS",
   finessju: 720008762,
   id: 720006733,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631150",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES ATELIERS DE LA MEE",
   finessju: 440018380,
   id: 440003739,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240280951",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES BAZINIERES",
   finessju: 850012436,
   id: 850021742,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478816",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES BEJONNIERES",
   finessju: 490538642,
   id: 490002664,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241347005",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES CHENES",
   finessju: 720008804,
   id: 720007251,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243957899",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES ESPACES",
   finessju: 530031434,
   id: 530028596,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243530370",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES IRIS",
   finessju: 440018380,
   id: 440005502,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240541408",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES OISEAUX",
   finessju: 720009562,
   id: 720007095,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243441486",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT LES PRAIRIES",
   finessju: 720009562,
   id: 720008309,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243502850",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT MARIE MOREAU",
   finessju: 440001352,
   id: 440030476,
   commune: "ST NAZAIRE CEDEX",
   cp: 44612,
   telephone: "240531616",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ML ET R BURON",
   finessju: 930019484,
   id: 530028612,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243301880",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT MOULIN DU PIN",
   finessju: 490011343,
   id: 490531944,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241515406",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT NANT'EST",
   finessju: 440018380,
   id: 440012722,
   commune: "NANTES",
   cp: 44000,
   telephone: "240500325",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT OCEANIS",
   finessju: 440018398,
   id: 440003226,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251765570",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT PARC POLARIS NORD",
   finessju: 850012436,
   id: 850012006,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251485800",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT PASSERELLE POUR L'EMPLOI",
   finessju: 440018380,
   id: 440026359,
   commune: "NANTES",
   cp: 44000,
   telephone: "251250640",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ROBIDA",
   finessju: 530031913,
   id: 530028620,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688016",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT SERILLAC PRESTATIONS",
   finessju: 720008762,
   id: 720013523,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243311414",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT SESAME SERVICES",
   finessju: 440033884,
   id: 440033892,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240659500",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ST LAMBERT DES LEVEES",
   finessju: 490535192,
   id: 490541091,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241678592",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT SUD LOIRE",
   finessju: 440007482,
   id: 440044725,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "228013109",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT UTIL'85",
   finessju: 850020413,
   id: 850023797,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251462767",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT VAL DE LOIR",
   finessju: 720009562,
   id: 720013093,
   commune: "LE BAILLEUL",
   cp: 72200,
   telephone: "243453434",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT VAL DE VAY",
   finessju: 440018661,
   id: 440042786,
   commune: "VAY",
   cp: 44170,
   telephone: "240879380",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ZI DE LA GUERCHE",
   finessju: 850012436,
   id: 850003666,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910821",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ESAT ZI LE PLANTY",
   finessju: 850012436,
   id: 850000282,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251488383",
   categorie: "Etablissement et Service d'Aide par le Travail (E.S.A.T.)"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL COUPLES EN VIE DE FAMILLE",
   finessju: 750721334,
   id: 440045292,
   commune: "NANTES",
   cp: 44100,
   telephone: "240716629",
   categorie: "Etablissement Experimental Enfance Protegee"
 },
 {
   finess: "FINESS",
   name: "ACCUEILS EDUCATIFS DE LOIRE ATLANTIQUE",
   finessju: 920026838,
   id: 440052900,
   commune: "CARQUEFOU CEDEX",
   cp: 44481,
   telephone: "240575650",
   categorie: "Etablissement Experimental Enfance Protegee"
 },
 {
   finess: "FINESS",
   name: "SERVICE PLACEMENT EDUCATIF A DOMICILE",
   finessju: 720008705,
   id: 720021203,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Etablissement Experimental Enfance Protegee"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF MULTISERVICES HAND PSYCHIQU",
   finessju: 850012436,
   id: 850025388,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "228159919",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE DE MEDICALISATION",
   finessju: 440018398,
   id: 440053478,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240539999",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE DE MEDICALISATION",
   finessju: 850025867,
   id: 850026386,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251527379",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE DE MEDICALISATION",
   finessju: 850012436,
   id: 850026394,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251343773",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE DE MeDICALISATION",
   finessju: 440018380,
   id: 440053460,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320345",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LA CHAUMIERE",
   finessju: 850025263,
   id: 850010844,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251502356",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LA MAISON DES SOURCES",
   finessju: 850008608,
   id: 850025776,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251507521",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LA MAISONNEE",
   finessju: 850012444,
   id: 850009465,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251347231",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LE BOIS MARIE",
   finessju: 850012444,
   id: 850027004,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251058817",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LES CERISIERS",
   finessju: 850006347,
   id: 850017286,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251243189",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LES MESANGES",
   finessju: 850006347,
   id: 850010653,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251620453",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LES MIMOSAS",
   finessju: 850006347,
   id: 850011081,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251074398",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS LES MOUETTES",
   finessju: 850006347,
   id: 850017401,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "960453934",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAVS VILLA COTTAGE",
   finessju: 850025263,
   id: 850025735,
   commune: "ST DENIS LA CHEVASSE",
   cp: 85170,
   telephone: "251417552",
   categorie: "Etablissement Experimental pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "ECOLE ABA LES PETITS MALINS",
   finessju: 750062234,
   id: 440051118,
   commune: "NANTES",
   cp: 44100,
   telephone: "602285044",
   categorie: "Etablissement Experimental pour Enfance Handicapee"
 },
 {
   finess: "FINESS",
   name: "ECOLE ABA SITE ST NAZAIRE",
   finessju: 750062234,
   id: 440052819,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "952602921",
   categorie: "Etablissement Experimental pour Enfance Handicapee"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE RESSOURCES",
   finessju: 440000966,
   id: 440052306,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "249148140",
   categorie: "Etablissement Experimental pour Enfance Handicapee"
 },
 {
   finess: "FINESS",
   name: "EQUIPE MOBILE RESSOURCES",
   finessju: 590799730,
   id: 850025370,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251050981",
   categorie: "Etablissement Experimental pour Enfance Handicapee"
 },
 {
   finess: "FINESS",
   name: "MAFS LE TEMPS D UNE PAUSE",
   finessju: 850027590,
   id: 850027608,
   commune: "COMMEQUIERS",
   cp: 85220,
   telephone: "-",
   categorie: "Etablissement Experimental pour Personnes Agees"
 },
 {
   finess: "FINESS",
   name: "ASSOC. COUPLE ET FAMILLE",
   finessju: 750815458,
   id: 440025229,
   commune: "NANTES",
   cp: 44100,
   telephone: "240438304",
   categorie: "Etablissement Information Consultation Conseil Familial"
 },
 {
   finess: "FINESS",
   name: "CONSEIL CONJUGAL",
   finessju: 750815177,
   id: 440025211,
   commune: "NANTES",
   cp: 44100,
   telephone: "240693081",
   categorie: "Etablissement Information Consultation Conseil Familial"
 },
 {
   finess: "FINESS",
   name: "COUPLE ET FAMILLE",
   finessju: 440004323,
   id: 440012680,
   commune: "NANTES",
   cp: 44100,
   telephone: "240438304",
   categorie: "Etablissement Information Consultation Conseil Familial"
 },
 {
   finess: "FINESS",
   name: "PLANNING FAMILIAL",
   finessju: 530032689,
   id: 530002633,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243492387",
   categorie: "Etablissement Information Consultation Conseil Familial"
 },
 {
   finess: "FINESS",
   name: "EEAP LA TREMBLAYE",
   finessju: 490015856,
   id: 490002524,
   commune: "ANGERS CEDEX 02",
   cp: 49103,
   telephone: "241530404",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "EEAP LE BOCAGE",
   finessju: 490535184,
   id: 490008430,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241969190",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "IEAP INSTITUT CALYPSO",
   finessju: 750719239,
   id: 530033406,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243641777",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "IEM IPEAP L'ESTRAN",
   finessju: 440018661,
   id: 440040632,
   commune: "ST NAZAIRE CEDEX",
   cp: 44612,
   telephone: "240118810",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "IPEAP PARC DE LA BLORDIERE",
   finessju: 440018612,
   id: 440007698,
   commune: "REZE",
   cp: 44400,
   telephone: "240750304",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "ISSE PATRICK GUILLON VERNE",
   finessju: 440026730,
   id: 440040392,
   commune: "NANTES",
   cp: 44200,
   telephone: "240349095",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "SECTION POLYHANDICAPES L MALECOT",
   finessju: 720009562,
   id: 720012574,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243541160",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "SERVICE ACCUEIL LA PASSERELLE",
   finessju: 440018612,
   id: 440034395,
   commune: "NANTES",
   cp: 44200,
   telephone: "251110147",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "SITE MAS LES ROMANS",
   finessju: 490015856,
   id: 490020336,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241832222",
   categorie: "Etablissement pour Enfants ou Adolescents Polyhandicapes"
 },
 {
   finess: "FINESS",
   name: "FJT DU PONT DE MAYENNE",
   finessju: 530000678,
   id: 530028331,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243535086",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FJT FRANCOIS PESLIER",
   finessju: 530000678,
   id: 530028323,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243532430",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FJT PIERRE DE COUBERTIN",
   finessju: 530000678,
   id: 530028349,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531798",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER CLAIR SOLEIL",
   finessju: 490534740,
   id: 490528247,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241513010",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER MUNICIPAL SAINT-MICHEL",
   finessju: 490534773,
   id: 490528536,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241602808",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAU CHAMPS FLEURIS",
   finessju: 490535796,
   id: 490528270,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241664488",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 490536620,
   id: 490008570,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "-",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 490535804,
   id: 490528254,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "241518686",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 490535820,
   id: 490528262,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241895325",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 490535812,
   id: 490528288,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241521002",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 530031210,
   id: 530028265,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243052120",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 530031244,
   id: 530028273,
   commune: "CHEMAZE",
   cp: 53200,
   telephone: "243071749",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 530031269,
   id: 530028281,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243070710",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 530031228,
   id: 530028299,
   commune: "EVRON",
   cp: 53600,
   telephone: "243981803",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 530000603,
   id: 530028372,
   commune: "VAIGES",
   cp: 53480,
   telephone: "243012089",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 530031160,
   id: 530028380,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243041809",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 720011212,
   id: 720004571,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243249182",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 720011212,
   id: 720012053,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243721567",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 720011212,
   id: 720012061,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243852236",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 720011212,
   id: 720012079,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243815547",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 720011212,
   id: 720012087,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243231408",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 850012717,
   id: 850004342,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692983",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 850012923,
   id: 850006230,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404382",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 850012535,
   id: 850011552,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251376432",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT",
   finessju: 850012568,
   id: 850012378,
   commune: "APREMONT",
   cp: 85220,
   telephone: "251557366",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT CLOS ST JEAN",
   finessju: 490019825,
   id: 490528296,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630206",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT H BARBIN",
   finessju: 720011212,
   id: 720004555,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243843011",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT HL VILLAINES",
   finessju: 530002591,
   id: 530028364,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243032101",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT LA HAIE",
   finessju: 490018488,
   id: 490537131,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241659741",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT LE MAIL",
   finessju: 490018488,
   id: 490537123,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241525215",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT PERS AGEES",
   finessju: 530000504,
   id: 530029438,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243047189",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT PERS AGEES",
   finessju: 530000595,
   id: 530029446,
   commune: "ST DENIS DE GASTINES",
   cp: 53500,
   telephone: "243051321",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT PERS AGEES",
   finessju: 530031186,
   id: 530029453,
   commune: "STE SUZANNE ET CHAMMES",
   cp: 53270,
   telephone: "243014017",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT RAMBOURG",
   finessju: 490018488,
   id: 490537115,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241626764",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER RESTAURANT RENEE AUDUC",
   finessju: 720011212,
   id: 720004597,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER-RESTAURANT LOUISE MICHEL",
   finessju: 440055218,
   id: 440018877,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240221219",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FOYER-RESTAURANT MUNICIPAL",
   finessju: 440018513,
   id: 440026078,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240812255",
   categorie: "Foyer Club Restaurant"
 },
 {
   finess: "FINESS",
   name: "FAM ANNE DE LA GIROUARDIERE",
   finessju: 490000882,
   id: 490016623,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891220",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM BEAUSEJOUR",
   finessju: 440018398,
   id: 440032969,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240621095",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM BLANC",
   finessju: 920809829,
   id: 440040764,
   commune: "LA CHAPELLE SUR ERDRE CEDE",
   cp: 44243,
   telephone: "240725858",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM CENTRE ST JEAN DE DIEU",
   finessju: 750052037,
   id: 440044493,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240626000",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM DAMIEN SEGUIN",
   finessju: 690793435,
   id: 850025420,
   commune: "LUCON",
   cp: 85400,
   telephone: "253067030",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM DE TRESSE",
   finessju: 490003563,
   id: 490018470,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241925427",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM DIAPASON",
   finessju: 440048767,
   id: 440051795,
   commune: "GRANDCHAMPS DES FONTAINES",
   cp: 44119,
   telephone: "240140739",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM DU MARTRAIS",
   finessju: 440030229,
   id: 440043727,
   commune: "LE GAVRE",
   cp: 44130,
   telephone: "240815980",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM EPMS LE LITTORAL",
   finessju: 440041127,
   id: 440032746,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "251747100",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM GEORGES COULON",
   finessju: 720012749,
   id: 720018381,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243615170",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM GEORGES GODET",
   finessju: 850000084,
   id: 850025057,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251218910",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM HAMEAU DES VIGNES",
   finessju: 850012436,
   id: 850017583,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251655910",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM HAUTE ROCHE",
   finessju: 850012436,
   id: 850009960,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251510730",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM HENRY MURAIL",
   finessju: 850008905,
   id: 850012360,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251688980",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM HORIZONS",
   finessju: 490535168,
   id: 440042463,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251790184",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM JARDIN D'ALEXANDRE",
   finessju: 720008770,
   id: 720017193,
   commune: "VILLENEUVE EN PERSEIGNE",
   cp: 72600,
   telephone: "243311315",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM JEAN DE LA FONTAINE",
   finessju: 720000140,
   id: 720018019,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243633630",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM L'ETAPE",
   finessju: 530031434,
   id: 530030162,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914330",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA CLAIRIERE",
   finessju: 850012436,
   id: 850020884,
   commune: "POUZAUGES CEDEX",
   cp: 85703,
   telephone: "251570449",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA FAUVETTERIE",
   finessju: 490535184,
   id: 490538691,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241345404",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA FILOUSIERE",
   finessju: 530007186,
   id: 530007962,
   commune: "MAYENNE CEDEX",
   cp: 53104,
   telephone: "243321808",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA GUYONNIERE",
   finessju: 850012436,
   id: 850026618,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251092930",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA HAUTE MITRIE",
   finessju: 440018380,
   id: 440035392,
   commune: "NANTES CEDEX 3",
   cp: 44319,
   telephone: "251896040",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA LARGERE",
   finessju: 850012436,
   id: 850009028,
   commune: "THOUARSAIS BOUILDROUX",
   cp: 85410,
   telephone: "251515873",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA LONGUE CHAUVIERE",
   finessju: 490535192,
   id: 490016193,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241710800",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA MADELEINE",
   finessju: 440004349,
   id: 440050474,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "251165500",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA MADELEINE",
   finessju: 850021486,
   id: 850004938,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251681313",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA MAISON DU VENT D'ESPOIR",
   finessju: 850017237,
   id: 850011263,
   commune: "NOTRE DAME DE MONTS",
   cp: 85690,
   telephone: "251599200",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA MESANGERIE",
   finessju: 490535184,
   id: 490017472,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241691731",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA PASSERELLE",
   finessju: 440031169,
   id: 440045268,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "240805845",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LA PINSONNERIE",
   finessju: 490535184,
   id: 490531720,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241668021",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE BOCAGE",
   finessju: 930712393,
   id: 850007519,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628470",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE GIBERTIN",
   finessju: 490535200,
   id: 490016748,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241303458",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE HAMEAU",
   finessju: 440004315,
   id: 440047165,
   commune: "BOUVRON",
   cp: 44130,
   telephone: "251747605",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE POINT DU JOUR",
   finessju: 490001716,
   id: 490015740,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241713100",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE TEMPS DE VIVRE",
   finessju: 720016724,
   id: 720014349,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "244713279",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE VAL FLEURI",
   finessju: 850006347,
   id: 850007618,
   commune: "COEX",
   cp: 85220,
   telephone: "251551224",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE VERGER",
   finessju: 720008390,
   id: 720017185,
   commune: "COULANS SUR GEE",
   cp: 72550,
   telephone: "253046069",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LE VILLAGE",
   finessju: 850012436,
   id: 850010984,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251069630",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LEJEUNE",
   finessju: 440006294,
   id: 440049963,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251715403",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES BLEUETS",
   finessju: 530008168,
   id: 530033216,
   commune: "HAMBERS",
   cp: 53160,
   telephone: "243379512",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES CEDRES",
   finessju: 720009562,
   id: 720017664,
   commune: "ST GEORGES DU BOIS",
   cp: 72700,
   telephone: "243883350",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES HAUTES FONTAINES",
   finessju: 720009562,
   id: 720018407,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243804029",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES HAUTS DE SEVRE",
   finessju: 850023581,
   id: 850022336,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651230",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES HETRES",
   finessju: 720009562,
   id: 720018548,
   commune: "RUAUDIN",
   cp: 72230,
   telephone: "243783288",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES LOGIS DU BOIS",
   finessju: 490535200,
   id: 490539046,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241530009",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LES LUCINES",
   finessju: 440018380,
   id: 440046878,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "228002940",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM LESIOUR SOULBIEU",
   finessju: 720006022,
   id: 720015460,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72401,
   telephone: "243601270",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM MADELEINE ROCHAS",
   finessju: 490011343,
   id: 490535762,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241785934",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM MAF LE BOIS TISSANDEAU",
   finessju: 850006347,
   id: 850018268,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251647800",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM MAISON DE L'ELAN",
   finessju: 720001445,
   id: 720017896,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243558040",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM MAISON PERCE NEIGE",
   finessju: 920809829,
   id: 850010992,
   commune: "CHAUCHE",
   cp: 85140,
   telephone: "251248668",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM MAPHAV",
   finessju: 850012436,
   id: 850017633,
   commune: "ST MICHEL LE CLOUCQ",
   cp: 85200,
   telephone: "251503129",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM NOTRE DAME DE TERRE NEUVE",
   finessju: 750720245,
   id: 440035988,
   commune: "CHAUVE",
   cp: 44320,
   telephone: "240643800",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM ORGHANDI",
   finessju: 850013087,
   id: 850004888,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404525",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM PASTEL DE LOIRE",
   finessju: 490535168,
   id: 490016417,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241376260",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM PERCE NEIGE",
   finessju: 920809829,
   id: 490016425,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241544191",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM RES CATHERINE DE THOUARS",
   finessju: 850025867,
   id: 850020173,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251635800",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM RES COMTESSE D'ASNIERES",
   finessju: 850025867,
   id: 850010398,
   commune: "ST PIERRE DU CHEMIN",
   cp: 85120,
   telephone: "251507900",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM SESAME",
   finessju: 440033884,
   id: 440037810,
   commune: "SUCE SUR ERDRE",
   cp: 44240,
   telephone: "251811805",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM ST AMADOUR",
   finessju: 750721334,
   id: 530008424,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "241730102",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM THERESE VOHL",
   finessju: 750719239,
   id: 530007251,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243592626",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM THERESE VOHL SITE DU TERTRE",
   finessju: 750719239,
   id: 530007418,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914330",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FAM TOPAZE",
   finessju: 440004315,
   id: 440044519,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "FV FAM LA CHARMELI�RE",
   finessju: 440018380,
   id: 440044857,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240921247",
   categorie: "Foyer d'Accueil Medicalise pour Adultes Handicapes (F.A.M.)"
 },
 {
   finess: "FINESS",
   name: "CEF LA ROUVELLIERE",
   finessju: 720008705,
   id: 720020932,
   commune: "ALLONNES CEDEX",
   cp: 72703,
   telephone: "243806258",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "CER SILLAGE",
   finessju: 440056109,
   id: 440056117,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225845",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "EPE MAINE ANJOU",
   finessju: 490539194,
   id: 490019700,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "-",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "FOYER D'ACTION EDUCATIVE",
   finessju: 440028561,
   id: 440005189,
   commune: "NANTES",
   cp: 44100,
   telephone: "240697811",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "FOYER D'ACTION EDUCATIVE",
   finessju: 490539194,
   id: 530032143,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243304060",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "UHD TERRITORIAL",
   finessju: 440028561,
   id: 440034296,
   commune: "REZE",
   cp: 44400,
   telephone: "240356930",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "UNITE EDUCATIVE HEBERGEMENT DIVERSIFIE",
   finessju: 490539194,
   id: 720020254,
   commune: "LE MANS",
   cp: 72100,
   telephone: "-",
   categorie: "Foyer d'Action Educative (F.A.E.)"
 },
 {
   finess: "FINESS",
   name: "APHJ",
   finessju: 440048148,
   id: 440048155,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240111611",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "ASSO HABITAT JEUNES EN PAYS DE GD LIEU",
   finessju: 440048163,
   id: 440048171,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240111611",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "F.J.T.",
   finessju: 850013095,
   id: 850009069,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251222398",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "F.J.T. LE PETIT MONTRON",
   finessju: 440019008,
   id: 530004209,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243376588",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "F.J.T. MARGUERITE D'ANJOU",
   finessju: 490001542,
   id: 490003084,
   commune: "ANGERS CEDEX 02",
   cp: 49106,
   telephone: "241202400",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT",
   finessju: 850013095,
   id: 850006719,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "228104331",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT ANCENIS",
   finessju: 440048189,
   id: 440048197,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240832348",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT BARBUSSE",
   finessju: 440018653,
   id: 440024438,
   commune: "NANTES",
   cp: 44000,
   telephone: "240206363",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT CHANTECLERC",
   finessju: 440018653,
   id: 440024859,
   commune: "NANTES CEDEX 3",
   cp: 44319,
   telephone: "251836640",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT COPAINVILLE",
   finessju: 530000827,
   id: 530029578,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243302500",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT DU PONT DE MAYENNE",
   finessju: 530000678,
   id: 530028497,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243597280",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT EMBARCADERE",
   finessju: 440003275,
   id: 440008290,
   commune: "NANTES",
   cp: 44100,
   telephone: "240734146",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT ERNEE",
   finessju: 530031251,
   id: 530031756,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243057118",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT ESCALE RIVOLI",
   finessju: 850013095,
   id: 850002288,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251051234",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT GRAND VOILE",
   finessju: 440003275,
   id: 440048205,
   commune: "REZE",
   cp: 44400,
   telephone: "240734146",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT HABITAT JEUNES DU SAUMUROIS",
   finessju: 490001534,
   id: 490003068,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241510553",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT LE CHENAIE",
   finessju: 440018653,
   id: 440051233,
   commune: "NANTES",
   cp: 44300,
   telephone: "228200760",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT LE FLORE",
   finessju: 720001452,
   id: 720006147,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243812755",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT LE RELAIS",
   finessju: 720001460,
   id: 720006154,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243612424",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT LES HAUTS DE ST AIGNAN",
   finessju: 440018653,
   id: 440042752,
   commune: "NANTES",
   cp: 44100,
   telephone: "240122405",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT LES LIARDS",
   finessju: 440018653,
   id: 440051266,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240185710",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT LES TROIS PORTES",
   finessju: 850006214,
   id: 850003971,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85206,
   telephone: "251691344",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT OCEANE",
   finessju: 440003275,
   id: 440029718,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251805859",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT PORT BEAULIEU",
   finessju: 440018653,
   id: 440003879,
   commune: "NANTES",
   cp: 44200,
   telephone: "240122400",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT PORTE NEUVE",
   finessju: 440018653,
   id: 440053668,
   commune: "NANTES",
   cp: 44000,
   telephone: "251729870",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT RESIDENCE BEAUPREAU",
   finessju: 490001526,
   id: 490019890,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241713636",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT RESIDENCE DARWIN ANGERS",
   finessju: 490001575,
   id: 490003217,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241226120",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FJT RESIDENCE HABITAT JEUNES CHOLETAIS",
   finessju: 490001526,
   id: 490003050,
   commune: "CHOLET CEDEX",
   cp: 49303,
   telephone: "241713636",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER  JEUNES TRAVAILLEURS LE NYMPHEA",
   finessju: 530003243,
   id: 530028539,
   commune: "EVRON",
   cp: 53600,
   telephone: "243016535",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER COMP DEVOIR TOUR FRANCE",
   finessju: 750721110,
   id: 490525045,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241272170",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER COMPAG DEVOIR TOUR FRANCE",
   finessju: 750721110,
   id: 490003142,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241272170",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER COMPAG DEVOIR TOUR FRANCE",
   finessju: 750721110,
   id: 490531571,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241513264",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER DE JEUNES TRAVAILLEURS",
   finessju: 850013095,
   id: 850002262,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251053424",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER DE JEUNES TRAVAILLEURS",
   finessju: 850007139,
   id: 850007378,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "970808221",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER DE JEUNES TRAVAILLEURS",
   finessju: 850007139,
   id: 850011123,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "970808221",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER JEUNES TRAVAILLEURS",
   finessju: 440019008,
   id: 440011385,
   commune: "NANTES",
   cp: 44000,
   telephone: "240470418",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER JEUNES TRAVAILLEURS",
   finessju: 440004562,
   id: 440017663,
   commune: "NANTES",
   cp: 44000,
   telephone: "240474813",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER JEUNES TRAVAILLEURS ILIADE",
   finessju: 530000686,
   id: 530028521,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243072705",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER LES COMPAGNONS DU DEVOIR",
   finessju: 750721110,
   id: 720006691,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243248795",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "FOYER LES RESIDENCES SOLEIL",
   finessju: 440003366,
   id: 440009702,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240734146",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE DES JEUNES",
   finessju: 440024958,
   id: 440025088,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251760500",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE FJT DAVID ANGERS",
   finessju: 490001963,
   id: 490531530,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241243737",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE FJT HARMATTAN",
   finessju: 490001963,
   id: 490019882,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241243737",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE FRANCOIS PESLIER",
   finessju: 530000678,
   id: 530028513,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243680600",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE JULES VERNE",
   finessju: 440003275,
   id: 440054054,
   commune: "REZE",
   cp: 44400,
   telephone: "252200669",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE L'ODYSSEE",
   finessju: 440048122,
   id: 440048130,
   commune: "NOZAY",
   cp: 44170,
   telephone: "228050115",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA TOUR D'AUVERGNE",
   finessju: 440018653,
   id: 440056067,
   commune: "NANTES",
   cp: 44200,
   telephone: "251110687",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE PIERRE DE COUBERTIN",
   finessju: 530000678,
   id: 530028505,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531798",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE ALJC",
   finessju: 440034213,
   id: 440048056,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240814695",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE SOCIALE LES GLYCINES MAMERS",
   finessju: 720018118,
   id: 720020122,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243978079",
   categorie: "Foyer de Jeunes Travailleurs (residence sociale ou non)"
 },
 {
   finess: "FINESS",
   name: "CDEF",
   finessju: 440023620,
   id: 440045953,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240802600",
   categorie: "Foyer de l'Enfance"
 },
 {
   finess: "FINESS",
   name: "FOYER DE L ENFANCE - CENTRE MATERNEL",
   finessju: 720011188,
   id: 720006121,
   commune: "LE MANS CEDEX 1",
   cp: 72005,
   telephone: "243406420",
   categorie: "Foyer de l'Enfance"
 },
 {
   finess: "FINESS",
   name: "FOYER DEP ENFANCE GILBERT DE GUERRY",
   finessju: 850018581,
   id: 850004029,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251360262",
   categorie: "Foyer de l'Enfance"
 },
 {
   finess: "FINESS",
   name: "FOYER DEPARTEMENTAL DE L ENFANCE 49",
   finessju: 490535697,
   id: 490003191,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241214050",
   categorie: "Foyer de l'Enfance"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR GRAINES DES VENTS",
   finessju: 850006347,
   id: 850025040,
   commune: "MOUILLERON LE CAPTIF",
   cp: 85000,
   telephone: "228979631",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR L OCEAN",
   finessju: 850006347,
   id: 850025008,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251228109",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LA PLAINE",
   finessju: 850006347,
   id: 850025016,
   commune: "LUCON",
   cp: 85400,
   telephone: "251284531",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "ACCUEIL DE JOUR LES 3 LACS",
   finessju: 850006347,
   id: 850024985,
   commune: "COEX",
   cp: 85220,
   telephone: "251689659",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU FOYER DE VIE LA MADELEINE",
   finessju: 440004349,
   id: 440042703,
   commune: "MISSILLAC",
   cp: 44780,
   telephone: "251165500",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "ANNEXE DU FOYER DE VIE LA MADELEINE",
   finessju: 440004349,
   id: 440042711,
   commune: "STE REINE DE BRETAGNE",
   cp: 44160,
   telephone: "251165500",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "C2A LAVAL",
   finessju: 530031434,
   id: 530033026,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243538497",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ",
   finessju: 530031434,
   id: 530003326,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53205,
   telephone: "243705858",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ",
   finessju: 530033000,
   id: 530032838,
   commune: "GORRON",
   cp: 53120,
   telephone: "243084122",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ BEAU SOLEIL",
   finessju: 530033000,
   id: 530003532,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243089925",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ IONESCO",
   finessju: 530000710,
   id: 530006048,
   commune: "LA CHAPELLE ANTHENAISE",
   cp: 53950,
   telephone: "243011071",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ LA BELLE OUVRAGE",
   finessju: 530000702,
   id: 530008499,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660444",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ LES CHARMILLES",
   finessju: 530031939,
   id: 530008549,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "243060038",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAAJ PONTMAIN",
   finessju: 930019484,
   id: 530008556,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243301880",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAJ LA HAIE VIVE APAHRC AUTONOME",
   finessju: 490536877,
   id: 490015963,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241494646",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CAJ ROBIDA",
   finessju: 530031913,
   id: 530002914,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688016",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CENTRE ACCUEIL DE JOUR ESPACE AUTONOME",
   finessju: 490003563,
   id: 490009578,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241926028",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CENTRE ACCUEIL DE JOUR MAISON ROCHAS",
   finessju: 490011343,
   id: 490011335,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241785930",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CENTRE BASILE MOREAU",
   finessju: 720000728,
   id: 720011063,
   commune: "PRECIGNE",
   cp: 72300,
   telephone: "243622121",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CTRE ACCUEIL ACTIVITES DE BLAIN",
   finessju: 440018380,
   id: 440048627,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240796632",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FO ANNE DE LA GIROUARDIERE",
   finessju: 490000882,
   id: 490016631,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891220",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FO LA LONGUE CHAUVIERE",
   finessju: 490535192,
   id: 490538709,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241710800",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FO LA MAISON DES PINS",
   finessju: 490535192,
   id: 490011483,
   commune: "LA BREILLE LES PINS",
   cp: 49390,
   telephone: "241528784",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FO LE JONCHERAY",
   finessju: 490543600,
   id: 490016557,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "241339393",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FO LES LOGIS DU BOIS",
   finessju: 490535200,
   id: 490016441,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241530009",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FO MAF LE BOIS TISSANDEAU",
   finessju: 850006347,
   id: 850011586,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251647800",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE",
   finessju: 440018380,
   id: 440036309,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240963960",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE  LA LARGERE",
   finessju: 850012436,
   id: 850000340,
   commune: "THOUARSAIS BOUILDROUX",
   cp: 85410,
   telephone: "251515873",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ACC JOUR LA SOUBRETIERE",
   finessju: 440004315,
   id: 440040483,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ALAIN DAUBIAN",
   finessju: 720008762,
   id: 720018555,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243452600",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ANTOINE DE ST EXUPERY",
   finessju: 720000140,
   id: 720014802,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243356060",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE BEAU SOLEIL",
   finessju: 530033000,
   id: 530033398,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243088244",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE BEAUSEJOUR",
   finessju: 440018398,
   id: 440053957,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240621095",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE BEL AIR",
   finessju: 440006294,
   id: 440053999,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "257154000",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE BLANCHE NEIGE",
   finessju: 530008168,
   id: 530002625,
   commune: "BAIS",
   cp: 53160,
   telephone: "243379507",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE BLEU",
   finessju: 920809829,
   id: 440036069,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240725858",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE BONHEUR EIFFEL",
   finessju: 440031169,
   id: 440041820,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240033233",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE CATHERINE DE THOUARS",
   finessju: 850025867,
   id: 850027129,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251635800",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE CTRE HOSP MAMERS",
   finessju: 610780082,
   id: 720006576,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243331212",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE DE LA HAUTE MITRIE",
   finessju: 440018380,
   id: 440053932,
   commune: "NANTES CEDEX 3",
   cp: 44319,
   telephone: "251896040",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE DE STE PAZANNE",
   finessju: 440018380,
   id: 440017937,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240325230",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE DE VALLET",
   finessju: 440018380,
   id: 440049229,
   commune: "VALLET",
   cp: 44330,
   telephone: "240690239",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE DES HERBIERS",
   finessju: 850012436,
   id: 850027053,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251655910",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ERDRE ET CENS",
   finessju: 440018661,
   id: 440038040,
   commune: "NANTES",
   cp: 44300,
   telephone: "240501827",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE GABRIEL FAURE",
   finessju: 440018398,
   id: 440053981,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240704581",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE GEORGES GODET",
   finessju: 850000084,
   id: 850027012,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251218850",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE HAUTE ROCHE",
   finessju: 850012436,
   id: 850027111,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251510730",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE IONESCO",
   finessju: 530000710,
   id: 530008523,
   commune: "LA CHAPELLE ANTHENAISE",
   cp: 53950,
   telephone: "243011071",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE IONESCO PHV",
   finessju: 530000710,
   id: 530008457,
   commune: "LA CHAPELLE ANTHENAISE",
   cp: 53950,
   telephone: "243011071",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE JULES VERNE",
   finessju: 440018380,
   id: 440048668,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240949934",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE KERGESTIN",
   finessju: 440018398,
   id: 440049260,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "240113399",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE L ARTIMON",
   finessju: 720000058,
   id: 720016229,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243435773",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE L ETOILE DU BERGER",
   finessju: 440002012,
   id: 440045136,
   commune: "PETIT AUVERNE",
   cp: 44670,
   telephone: "240076106",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE L'OREE DU PIN",
   finessju: 440006294,
   id: 440033181,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251715400",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA BELLE OUVRAGE",
   finessju: 530000702,
   id: 530008358,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660444",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA BORDERIE",
   finessju: 850012436,
   id: 850005067,
   commune: "POUZAUGES CEDEX",
   cp: 85703,
   telephone: "251570449",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA CABANIERE",
   finessju: 850012436,
   id: 850025800,
   commune: "LUCON",
   cp: 85400,
   telephone: "251561799",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA CHEVALERIE",
   finessju: 490003514,
   id: 490535606,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241185890",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA FERME",
   finessju: 490016524,
   id: 490016532,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241703916",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA GUYONNIERE",
   finessju: 850012436,
   id: 850026360,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251092930",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA MADELEINE",
   finessju: 440004349,
   id: 440012748,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "251165500",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA PASSERELLE",
   finessju: 440031169,
   id: 440032696,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "240805845",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA PASSERELLE",
   finessju: 530033000,
   id: 530008275,
   commune: "GORRON",
   cp: 53120,
   telephone: "243084140",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA RABINAIE",
   finessju: 850012436,
   id: 850025792,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478720",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA RABOTIERE",
   finessju: 440018380,
   id: 440034874,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240430480",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA ROSERAIE",
   finessju: 720001981,
   id: 720018290,
   commune: "ST MARS D OUTILLE",
   cp: 72220,
   telephone: "243427096",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA SOUBRETIERE",
   finessju: 440004315,
   id: 440021103,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LA SOURCE DE GUIDAS",
   finessju: 440018398,
   id: 440035939,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "228550270",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LE CEDRE",
   finessju: 490534831,
   id: 490015336,
   commune: "LYS HAUT LAYON",
   cp: 49560,
   telephone: "241595351",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LE CHENE VERT",
   finessju: 750720245,
   id: 440040517,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "240045956",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LE POINT DU JOUR",
   finessju: 490001716,
   id: 490008851,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241713100",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES 4 SAISONS",
   finessju: 440031169,
   id: 440032837,
   commune: "TEILLE",
   cp: 44440,
   telephone: "240977343",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES ABRIS DE JADE",
   finessju: 440041135,
   id: 440032753,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "251747250",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES AJONCS",
   finessju: 440003531,
   id: 440042471,
   commune: "STE REINE DE BRETAGNE",
   cp: 44160,
   telephone: "240011023",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES AMARRES",
   finessju: 750720245,
   id: 440049815,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "240428880",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES CEDRES",
   finessju: 720009562,
   id: 720018340,
   commune: "ST GEORGES DU BOIS",
   cp: 72700,
   telephone: "243883350",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES CHARMILLES",
   finessju: 530031939,
   id: 530008309,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "243060038",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES CLAVERIES",
   finessju: 490538642,
   id: 490002656,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241603462",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES HAUTES FONTAINES",
   finessju: 720009562,
   id: 720018308,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243804029",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES HAUTES ROCHES",
   finessju: 440034734,
   id: 440034742,
   commune: "BOUSSAY",
   cp: 44190,
   telephone: "240068912",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES HETRES",
   finessju: 720009562,
   id: 720020353,
   commune: "RUAUDIN",
   cp: 72230,
   telephone: "243783288",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES MACHAONS",
   finessju: 440018380,
   id: 440044782,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "251110597",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES MAGNOLIAS",
   finessju: 750719239,
   id: 440042364,
   commune: "NANTES",
   cp: 44300,
   telephone: "240491778",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES MUGUETS",
   finessju: 850012436,
   id: 850024175,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251666025",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES SORELLES",
   finessju: 720009562,
   id: 720005974,
   commune: "THOIRE SUR DINAN",
   cp: 72500,
   telephone: "243441486",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LES TOURNESOLS",
   finessju: 440031169,
   id: 440035509,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240265714",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE LOROUX BOTTEREAU",
   finessju: 440018380,
   id: 440044824,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "228001872",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE MARCON",
   finessju: 610000754,
   id: 720015445,
   commune: "MARCON",
   cp: 72340,
   telephone: "243460794",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE MAZURE",
   finessju: 530031434,
   id: 530008333,
   commune: "AZE",
   cp: 53200,
   telephone: "243091231",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE NOTRE-DAME-DE-TERRE NEUVE",
   finessju: 750720245,
   id: 440001154,
   commune: "CHAUVE",
   cp: 44320,
   telephone: "240643800",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE OASIS",
   finessju: 530000710,
   id: 530003458,
   commune: "NUILLE SUR VICOIN",
   cp: 53970,
   telephone: "243641280",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ORGHANDI",
   finessju: 850013087,
   id: 850012410,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404525",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE PASTEL DE LOIRE",
   finessju: 490535168,
   id: 490539301,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241376260",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE PERCE NEIGE",
   finessju: 920809829,
   id: 850009523,
   commune: "GIVRAND",
   cp: 85800,
   telephone: "228129530",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE PERCE NEIGE",
   finessju: 920809829,
   id: 850027079,
   commune: "CHAUCHE",
   cp: 85140,
   telephone: "251248668",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE PIERRE GUICHENEY",
   finessju: 530000330,
   id: 530033349,
   commune: "LE BOURGNEUF LA FORET",
   cp: 53410,
   telephone: "243377131",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE PONTMAIN",
   finessju: 930019484,
   id: 530008374,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243301880",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE POUR ADULTES HANDICAPES",
   finessju: 850012436,
   id: 850022922,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251212112",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE RESIDENCE ERDAM",
   finessju: 440018380,
   id: 440052637,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240334744",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE RICHEBOURG",
   finessju: 440042653,
   id: 440042661,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "241926285",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ROBIDA",
   finessju: 530031913,
   id: 530008267,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688016",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE SENEVE",
   finessju: 440006278,
   id: 440032993,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "251716679",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE SESAME POUR AUTISTES",
   finessju: 440033884,
   id: 440036606,
   commune: "SUCE SUR ERDRE",
   cp: 44240,
   telephone: "251811805",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ST AMADOUR",
   finessju: 750721334,
   id: 530003235,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "243061043",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ST DONATIEN",
   finessju: 440018380,
   id: 440035699,
   commune: "NANTES",
   cp: 44000,
   telephone: "240089560",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE ST MICHEL LE CLOUCQ",
   finessju: 850012436,
   id: 850027103,
   commune: "ST MICHEL LE CLOUCQ",
   cp: 85200,
   telephone: "251503129",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE VIE THERESE VOHL",
   finessju: 750719239,
   id: 530002583,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243592626",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HAND VIEILLISSANTS AIZENAY",
   finessju: 850012436,
   id: 850008889,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251453600",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER LE BOCAGE",
   finessju: 930712393,
   id: 850014291,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628470",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER LES HAUTS DE SEVRE",
   finessju: 850023581,
   id: 850011560,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651230",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER LES QUATRE VENTS",
   finessju: 850013277,
   id: 850012279,
   commune: "L EPINE",
   cp: 85740,
   telephone: "251358800",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL BEAUMONT SUR SARTH",
   finessju: 610000754,
   id: 720014315,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243338092",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL CHANTONNAY",
   finessju: 850012436,
   id: 850023714,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "228150570",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL DE JOUR L'ENVOL",
   finessju: 720008762,
   id: 720020585,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243774508",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL DE ST PAVACE",
   finessju: 610000754,
   id: 720017698,
   commune: "ST PAVACE",
   cp: 72190,
   telephone: "243811078",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL ESPACES",
   finessju: 490003563,
   id: 490537529,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241926028",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LA CASTINE",
   finessju: 610000754,
   id: 720014323,
   commune: "SOUGE LE GANELON",
   cp: 72130,
   telephone: "243330808",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LA GAUTELLERIE",
   finessju: 610000754,
   id: 720008663,
   commune: "THORIGNE SUR DUE",
   cp: 72160,
   telephone: "243890564",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LA ROGERIE",
   finessju: 490535200,
   id: 490543626,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241642627",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LE GIBERTIN",
   finessju: 490535200,
   id: 490539145,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241303458",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LE PONT DE VARENNE",
   finessju: 490542586,
   id: 490542594,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49250,
   telephone: "241570266",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LE TEMPS DE VIVRE",
   finessju: 720016724,
   id: 720011360,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "244713279",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LES VIGNES",
   finessju: 610000754,
   id: 720014307,
   commune: "LE LUART",
   cp: 72390,
   telephone: "243711612",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL LUCHE PRINGE",
   finessju: 610000754,
   id: 720016872,
   commune: "LUCHE PRINGE",
   cp: 72800,
   telephone: "243480180",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL MOULIN DE LA COUR",
   finessju: 720001999,
   id: 720013556,
   commune: "ST PIERRE DU LOROUER",
   cp: 72150,
   telephone: "243440618",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL PERCE NEIGE",
   finessju: 920809829,
   id: 490015625,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241831110",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL PERCE NEIGE",
   finessju: 920809829,
   id: 490542230,
   commune: "BARACE",
   cp: 49430,
   telephone: "241769933",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER OCCUPATIONNEL ST GEORGES DE LISL",
   finessju: 750056368,
   id: 530032127,
   commune: "ST FRAIMBAULT DE PRIERES",
   cp: 53300,
   telephone: "243008764",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER VIE DU ROUET PERSONNES AGEES",
   finessju: 440001881,
   id: 440042877,
   commune: "GUENROUET",
   cp: 44530,
   telephone: "240871980",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER VIE PH VIEILLISSANTES ROEZE",
   finessju: 720008762,
   id: 720018324,
   commune: "ROEZE SUR SARTHE",
   cp: 72210,
   telephone: "243215325",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYERS SAINT JOSEPH",
   finessju: 440001790,
   id: 440030666,
   commune: "LES TOUCHES",
   cp: 44390,
   telephone: "251128970",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV FAH RESIDENCE KORIA",
   finessju: 440018380,
   id: 440052181,
   commune: "COUERON",
   cp: 44220,
   telephone: "240463071",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV HEBAC ST JEAN DE DIEU",
   finessju: 750052037,
   id: 440032589,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240626000",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV RESIDENCE DE LA GAUDINAIS",
   finessju: 440030229,
   id: 440056091,
   commune: "ST AUBIN DES CHATEAUX",
   cp: 44110,
   telephone: "240815980",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV RESIDENCE DU MARTRAIS",
   finessju: 440030229,
   id: 440044766,
   commune: "LE GAVRE",
   cp: 44130,
   telephone: "240815980",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV RESIDENCE JEAN SEROUX",
   finessju: 440030229,
   id: 440032670,
   commune: "CHATEAUBRIANT CEDEX",
   cp: 44144,
   telephone: "240815980",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV RESIDENCE SEVRIA",
   finessju: 440018380,
   id: 440033306,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251718826",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FV UPHV ST JOSEPH",
   finessju: 490000692,
   id: 490015971,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241637163",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "HEBAC LES DEUX VALLEES",
   finessju: 440012664,
   id: 440052678,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569444",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "HEBAC LES JONQUILLES",
   finessju: 440031169,
   id: 440041549,
   commune: "NANTES",
   cp: 44300,
   telephone: "240020730",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "HEBAC LES PRIMEVERES",
   finessju: 440031169,
   id: 440051852,
   commune: "NANTES",
   cp: 44000,
   telephone: "251792113",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "LE VAL FLEURI",
   finessju: 850006347,
   id: 850006354,
   commune: "COEX",
   cp: 85220,
   telephone: "251551224",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE COMTESSE D'ASNIERES",
   finessju: 850025867,
   id: 850003062,
   commune: "ST PIERRE DU CHEMIN",
   cp: 85120,
   telephone: "251507900",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE HENRY SIMON",
   finessju: 850008905,
   id: 850023847,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251688980",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE L'ESCALE PERSONNES AGEES",
   finessju: 440002038,
   id: 440044774,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "240398850",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA MADELEINE",
   finessju: 850021486,
   id: 850012428,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251687408",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LE BOIS MOCQUA",
   finessju: 850000019,
   id: 850025081,
   commune: "LUCON CEDEX",
   cp: 85407,
   telephone: "251283394",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LE PRE BAILLY",
   finessju: 850025867,
   id: 850019589,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251526851",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT BALLON",
   finessju: 720009562,
   id: 720020890,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243273991",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT CATMANOR",
   finessju: 720008820,
   id: 720006840,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243242248",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT CHANTONNAY",
   finessju: 850012436,
   id: 850025412,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251485800",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT CHATEAUBRIANT",
   finessju: 440018380,
   id: 440056042,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "-",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT DU LAC",
   finessju: 720009562,
   id: 720017383,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243719020",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT LA GUYONNIERE",
   finessju: 850012436,
   id: 850025404,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251488383",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SA ESAT LES OISEAUX",
   finessju: 720009562,
   id: 720017177,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243441486",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAAJ DE CHANTEMESLE",
   finessju: 720008804,
   id: 720014554,
   commune: "SOLESMES",
   cp: 72300,
   telephone: "243957899",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SACAT LA TOURNIERE",
   finessju: 440018646,
   id: 440037919,
   commune: "CARQUEFOU CEDEX",
   cp: 44475,
   telephone: "240509586",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT",
   finessju: 440018380,
   id: 440054088,
   commune: "LEGE",
   cp: 44650,
   telephone: "631702243",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT",
   finessju: 530031434,
   id: 530008341,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243091190",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT ADAPEI 72",
   finessju: 720009562,
   id: 720021096,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243399939",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT APAJH 72-53",
   finessju: 720008762,
   id: 720021070,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631150",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT D'ANCENIS",
   finessju: 440018380,
   id: 440035368,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240963965",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT DE BLAIN",
   finessju: 440018380,
   id: 440037216,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240791781",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT DE LA SOUBRETIERE",
   finessju: 440004315,
   id: 440054013,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT DE SAILLE",
   finessju: 440018398,
   id: 440032977,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240151020",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT DE VERTOU",
   finessju: 440011484,
   id: 440035608,
   commune: "VERTOU CEDEX",
   cp: 44121,
   telephone: "251710969",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT DU BRIVET",
   finessju: 440018398,
   id: 440036184,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240456655",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT GETIGNE",
   finessju: 440018380,
   id: 440051829,
   commune: "GETIGNE",
   cp: 44190,
   telephone: "240540199",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT HORTICAT",
   finessju: 440018380,
   id: 440053734,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213600",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LA CHAUFFETIERE",
   finessju: 440033884,
   id: 440041911,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "240658524",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LA CHOLIERE",
   finessju: 440018380,
   id: 440035459,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240630923",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LA FLECHE",
   finessju: 720008762,
   id: 720021146,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243947822",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LANCHENEIL",
   finessju: 530000710,
   id: 530008366,
   commune: "NUILLE SUR VICOIN",
   cp: 53970,
   telephone: "243641280",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LE GINKGO",
   finessju: 490538642,
   id: 490020062,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "141347005",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LE GRAND CHENE ALPHA",
   finessju: 490011343,
   id: 490016300,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241505683",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT LES 3 PAROISSES",
   finessju: 490538642,
   id: 490020070,
   commune: "LA POSSONNIERE",
   cp: 49170,
   telephone: "241728333",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT MAROLLES LES BRAUL",
   finessju: 720008762,
   id: 720021138,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243311414",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT NANTEST",
   finessju: 440018380,
   id: 440045102,
   commune: "NANTES CEDEX 3",
   cp: 44335,
   telephone: "228081605",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT OCEANIS",
   finessju: 440018398,
   id: 440036465,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251765570",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT VAL DE LOIR ADAPEI 72",
   finessju: 720009562,
   id: 720021294,
   commune: "LE BAILLEUL",
   cp: 72200,
   telephone: "243453434",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAESAT VAY",
   finessju: 440018661,
   id: 440054104,
   commune: "VAY",
   cp: 44170,
   telephone: "240879380",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SAJ ESAT LA GUYONNIERE",
   finessju: 850012436,
   id: 850024837,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251488383",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SECTION ANNEXE ESAT DE PESCHERAY",
   finessju: 750720591,
   id: 720015387,
   commune: "LE BREIL SUR MERIZE",
   cp: 72370,
   telephone: "243898362",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SECTION OCCUPATIONNELL LA MOTHE ACHARD",
   finessju: 850012436,
   id: 850009127,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251056579",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SECTION OCCUPATIONNELLE ESAT CHALLANS",
   finessju: 850012436,
   id: 850023482,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251351873",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SECTION OCCUPATIONNELLE ESAT LA ROCHE",
   finessju: 850012436,
   id: 850009135,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372859",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "SECTION OCCUPATIONNELLE LES HERBIERS",
   finessju: 850012436,
   id: 850021643,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251655910",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "UPHV LA HAIE VIVE",
   finessju: 490536877,
   id: 490020781,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49110,
   telephone: "241494646",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "UPHV LE GINKGO",
   finessju: 490538642,
   id: 490017068,
   commune: "LA POSSONNIERE",
   cp: 49170,
   telephone: "241728333",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "UPHV LES ACACIAS",
   finessju: 490001518,
   id: 490020799,
   commune: "LES HAUTS D ANJOU",
   cp: 49330,
   telephone: "-",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "UPHV VAL FLEURI",
   finessju: 490001864,
   id: 490020617,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241473135",
   categorie: "Foyer de Vie pour Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'HABITAT HAUTE ROCHE",
   finessju: 850012436,
   id: 850012022,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251510730",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH BEAUSEJOUR",
   finessju: 440018398,
   id: 440053965,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240621095",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH BOUGUENAIS",
   finessju: 440033884,
   id: 440048593,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240655178",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH CHEMERE",
   finessju: 440018380,
   id: 440026946,
   commune: "CHAUMES EN RETZ",
   cp: 44680,
   telephone: "240648433",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH COET ROZIC",
   finessju: 440018398,
   id: 440034387,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240450665",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH DE BLAIN",
   finessju: 440018380,
   id: 440031540,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240798200",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH DE VALLET",
   finessju: 440018380,
   id: 440033793,
   commune: "VALLET",
   cp: 44330,
   telephone: "240362901",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH ERDRE ET CENS",
   finessju: 440018661,
   id: 440046639,
   commune: "NANTES",
   cp: 44300,
   telephone: "240501827",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH GABRIEL FAURE",
   finessju: 440018398,
   id: 440007557,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240704581",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440017895,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240150655",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH LA GILARDERIE",
   finessju: 440018380,
   id: 440032035,
   commune: "NANTES",
   cp: 44200,
   telephone: "240033442",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH LA SOUBRETIERE",
   finessju: 440004315,
   id: 440021376,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH LES HESPERIDES",
   finessju: 440018380,
   id: 440042687,
   commune: "LEGE",
   cp: 44650,
   telephone: "240049416",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH LES SIX FRENES",
   finessju: 440018380,
   id: 440026938,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240963960",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH MAISON BLEUE",
   finessju: 440031169,
   id: 440045730,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240587932",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH MAISON SESAME",
   finessju: 440033884,
   id: 440034817,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240655178",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH OASIS",
   finessju: 440031169,
   id: 440048577,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240138630",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RENAC",
   finessju: 440018380,
   id: 440011823,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240814691",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RESIDENCE CANTENI",
   finessju: 440018380,
   id: 440053114,
   commune: "NANTES",
   cp: 44100,
   telephone: "240468574",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RESIDENCE ERDAM",
   finessju: 440018380,
   id: 440049237,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "251718826",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RESIDENCE LE TILLAY",
   finessju: 440018380,
   id: 440007508,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240582771",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RESIDENCE LE TILLAY",
   finessju: 440018380,
   id: 440053122,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RESIDENCE LES LUCIOLES",
   finessju: 440031169,
   id: 440041309,
   commune: "NANTES",
   cp: 44300,
   telephone: "240491231",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH RESIDENCE SEVRIA",
   finessju: 440018380,
   id: 440007516,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251718824",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH REVE",
   finessju: 490535168,
   id: 440051845,
   commune: "NANTES",
   cp: 44000,
   telephone: "240412777",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAH SENEVE",
   finessju: 440006278,
   id: 440032985,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "240369839",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAI SAHIC L ETAPE",
   finessju: 440018646,
   id: 440017671,
   commune: "NANTES",
   cp: 44300,
   telephone: "240144338",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FAI VILLA PALALDA",
   finessju: 440000966,
   id: 440017911,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240150655",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FH LES 3 PAROISSES",
   finessju: 490538642,
   id: 490539152,
   commune: "LA POSSONNIERE",
   cp: 49170,
   telephone: "241728333",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FH LES RESIDENCES",
   finessju: 490535192,
   id: 490002631,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241494540",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FH MONT DE VIE",
   finessju: 490536877,
   id: 490011400,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241631752",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FH SEMI AUTONOME JEAN BRATIERES",
   finessju: 720008762,
   id: 720014208,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243455481",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FH UPHV ARCEAU ANJOU",
   finessju: 490535168,
   id: 490541299,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241229930",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FHSA CHATEAU DU LOIR",
   finessju: 720009562,
   id: 720020361,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243441486",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FHSA LA FERTE BERNARD BALLON",
   finessju: 720009562,
   id: 720020379,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243719020",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FHSA LE MANS",
   finessju: 720009562,
   id: 720018332,
   commune: "LE MANS CEDEX 2",
   cp: 72027,
   telephone: "243436080",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT BEAUSOLEIL",
   finessju: 530033000,
   id: 530008564,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243088244",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT CHARMILLES",
   finessju: 530031939,
   id: 530032010,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "243060038",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT DU 8 MAI",
   finessju: 530031434,
   id: 530029461,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53205,
   telephone: "243091830",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT KIEFFER",
   finessju: 530031434,
   id: 530008291,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243900876",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT LA BELLE OUVRAGE",
   finessju: 530000702,
   id: 530003292,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660444",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT LA MAZURE",
   finessju: 530031434,
   id: 530029479,
   commune: "AZE",
   cp: 53200,
   telephone: "243091839",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT LA PASSERELLE",
   finessju: 530033000,
   id: 530032036,
   commune: "GORRON",
   cp: 53120,
   telephone: "243084140",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT LES ERABLES",
   finessju: 720009562,
   id: 720020296,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243883720",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT LES PRIMEVERES",
   finessju: 720009562,
   id: 720011048,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243441815",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT M ET R BURON",
   finessju: 930019484,
   id: 530031996,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243301880",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D HEBERGEMENT ROBIDA",
   finessju: 530031913,
   id: 530002906,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688016",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT AIZENAY",
   finessju: 850012436,
   id: 850008798,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251453600",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT DES HERBIERS",
   finessju: 850012436,
   id: 850018284,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251655910",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT IONESCO",
   finessju: 530000710,
   id: 530032028,
   commune: "LA CHAPELLE ANTHENAISE",
   cp: 53950,
   telephone: "243011071",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT JEAN BRATIERES",
   finessju: 720008762,
   id: 720014216,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243455481",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT L ORANGER",
   finessju: 850012436,
   id: 850006784,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478720",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT L'ABRI",
   finessju: 490002011,
   id: 490531951,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241427112",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT L'ACCUEIL",
   finessju: 490535192,
   id: 490534948,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241597494",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HeBERGEMENT L'ALBATROS",
   finessju: 850012436,
   id: 850026519,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251212112",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA FONTAINE",
   finessju: 610000754,
   id: 720005719,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243768819",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA HAIE VIVE",
   finessju: 490536877,
   id: 490537578,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241494646",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA LARGERE",
   finessju: 850012436,
   id: 850005133,
   commune: "THOUARSAIS BOUILDROUX",
   cp: 85410,
   telephone: "251515873",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA MAISON DU LAC",
   finessju: 720009562,
   id: 720014166,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243719020",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA MAISON DU LAC",
   finessju: 850012436,
   id: 850003708,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251092930",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA MAISONNERAIE",
   finessju: 720009562,
   id: 720014299,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243746868",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA REBELLERIE",
   finessju: 490534831,
   id: 490531589,
   commune: "LYS HAUT LAYON",
   cp: 49560,
   telephone: "241595351",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA RESIDENCE",
   finessju: 720009562,
   id: 720016591,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243482020",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LA TOUR AUX FEES",
   finessju: 720009562,
   id: 720007079,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243805080",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LANCHENEIL",
   finessju: 530000710,
   id: 530032002,
   commune: "NUILLE SUR VICOIN",
   cp: 53970,
   telephone: "243641280",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LE FIGUIER",
   finessju: 720001981,
   id: 720005990,
   commune: "ST MARS D OUTILLE",
   cp: 72220,
   telephone: "243427096",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LE PETIT PLESSIS",
   finessju: 490536885,
   id: 490538600,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241617360",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LES BANCHAIS",
   finessju: 490538642,
   id: 490535143,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241347005",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LES FEUILLANTINES",
   finessju: 720008762,
   id: 720003425,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243311130",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LES GOELANDS",
   finessju: 750720591,
   id: 720017375,
   commune: "LE BREIL SUR MERIZE",
   cp: 72370,
   telephone: "243760668",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT LOUIS AUTISSIER",
   finessju: 720008762,
   id: 720011030,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "972449627",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT ODILE CHALOPIN",
   finessju: 490535754,
   id: 490531886,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241305867",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER D'HEBERGEMENT SEMI AUTONOME",
   finessju: 720008762,
   id: 720017441,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243311130",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE SEMAINE ARC EN CIEL",
   finessju: 850012436,
   id: 850011537,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251655910",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE SEMAINE LE PATENIT",
   finessju: 850012436,
   id: 850023771,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "228104200",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE SEMAINE LES ALYSEES",
   finessju: 850012436,
   id: 850011867,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251212112",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER DE SEMAINE LES GENETS D'OR",
   finessju: 850012436,
   id: 850022906,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251092930",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HABITAT REGROUPE L ETAPE",
   finessju: 440018646,
   id: 440052660,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240127960",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HAND VIEILLISSANTS CHANTONNAY",
   finessju: 850012436,
   id: 850010612,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "228150570",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HBGT SEMI AUTONOME PESCHERAY",
   finessju: 750720591,
   id: 720018829,
   commune: "LE BREIL SUR MERIZE",
   cp: 72370,
   telephone: "243898707",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT FARADOR",
   finessju: 440031169,
   id: 440036259,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "228074952",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT LA CABANIERE",
   finessju: 850012436,
   id: 850022708,
   commune: "LUCON",
   cp: 85400,
   telephone: "251561799",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT LE BOCAGE",
   finessju: 930712393,
   id: 850027061,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628470",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT LES 4 VENTS",
   finessju: 850013277,
   id: 850027095,
   commune: "L EPINE",
   cp: 85740,
   telephone: "251358806",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT LES MUGUETS",
   finessju: 850012436,
   id: 850012311,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251666025",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT MAISON BELLE BEILLE",
   finessju: 490535192,
   id: 490538550,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241731166",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT PARC ET PATIO",
   finessju: 720009562,
   id: 720020387,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243166390",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER RESIDENCE DE LA NIEL",
   finessju: 490543600,
   id: 490016540,
   commune: "LES HAUTS D ANJOU",
   cp: 49330,
   telephone: "241339393",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER RESIDENCE DU GRAND CHENE",
   finessju: 490011343,
   id: 490531936,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241505683",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "FOYER SEMI AUTONOME LES ROSES",
   finessju: 720008762,
   id: 720016484,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "972449627",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ACCOMPAGNES BECK",
   finessju: 530031434,
   id: 530008283,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243671360",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ACCOMPAGNES LA BELLE OUVRAGE",
   finessju: 530000702,
   id: 530008507,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660444",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ACCOMPAGNES LES CHARMILLES",
   finessju: 530031939,
   id: 530008531,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "243060038",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "LOGEMENTS ACCOMPAGNES MAZURE",
   finessju: 530031434,
   id: 530008515,
   commune: "AZE",
   cp: 53200,
   telephone: "243091831",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "MAISON SAINT DAMIEN",
   finessju: 720018274,
   id: 720020288,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243542902",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LE CEDRE",
   finessju: 720008804,
   id: 720018357,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243921927",
   categorie: "Foyer Hebergement Adultes Handicapes"
 },
 {
   finess: "FINESS",
   name: "INTERNAT ANNE DE BRETAGNE",
   finessju: 440018612,
   id: 440020865,
   commune: "NANTES",
   cp: 44100,
   telephone: "240891872",
   categorie: "Foyer Hebergement Enfants et Adolescents Handicapes"
 },
 {
   finess: "FINESS",
   name: "FTM RESIDENCE LA RICHARDIERE CHOLET",
   finessju: 750808511,
   id: 490003274,
   commune: "CHOLET",
   cp: 49300,
   telephone: "240762251",
   categorie: "Foyer Travailleurs Migrants non transforme en Residence Soc."
 },
 {
   finess: "FINESS",
   name: "FTM RESIDENCE LES MOULINS ANGERS",
   finessju: 750808511,
   id: 490003258,
   commune: "ANGERS",
   cp: 49100,
   telephone: "240762251",
   categorie: "Foyer Travailleurs Migrants non transforme en Residence Soc."
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ADOMA PONT DU CENS",
   finessju: 750808511,
   id: 440009579,
   commune: "NANTES",
   cp: 44300,
   telephone: "240762251",
   categorie: "Foyer Travailleurs Migrants non transforme en Residence Soc."
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ADOMA ROBERT SCHUMAN",
   finessju: 750808511,
   id: 440054849,
   commune: "NANTES",
   cp: 44300,
   telephone: "240761402",
   categorie: "Foyer Travailleurs Migrants non transforme en Residence Soc."
 },
 {
   finess: "FINESS",
   name: "GCS BIOLOGIE 85 ET SIEGE",
   finessju: 850025958,
   id: 850025966,
   commune: "LA ROCHE SUR YON CEDEX 9",
   cp: 85925,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS BIOLOGIE 85 LBM CH CHALLANS",
   finessju: 850025958,
   id: 850025990,
   commune: "CHALLANS CEDEX",
   cp: 85302,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS BIOLOGIE 85 LBM CH FONTENAY",
   finessju: 850025958,
   id: 850025982,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS BIOLOGIE 85 LBM CH LA ROCHE/YON",
   finessju: 850025958,
   id: 850025974,
   commune: "LA ROCHE SUR YON CEDEX 9",
   cp: 85925,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS BIOLOGIE 85 LBM CH LUCON",
   finessju: 850025958,
   id: 850026113,
   commune: "LUCON CEDEX",
   cp: 85407,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS BLANCHISSERIE ANGEVINE ET SIEGE",
   finessju: 490019684,
   id: 490019692,
   commune: "LES PONTS DE CE CEDEX",
   cp: 49137,
   telephone: "241807911",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS CANCEROLOGIE PDL ET SIEGE",
   finessju: 440052405,
   id: 440052413,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS COORD DEPT PSYCHIATRIE - ET SIEGE",
   finessju: 440053098,
   id: 440053106,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS CTRE CHIMIO AMBUL MAYENNE ET SIEGE",
   finessju: 530008390,
   id: 530008408,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243663751",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS DE LA MAYENNE ET SIEGE",
   finessju: 530007970,
   id: 530007988,
   commune: "LAVAL CEDEX",
   cp: 53015,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS DE LA MAYENNE LBM CH LAVAL",
   finessju: 530007970,
   id: 530008127,
   commune: "LAVAL CEDEX",
   cp: 53015,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS DE LA MAYENNE LBM CH NORD MAYENNE",
   finessju: 530007970,
   id: 530008135,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS ENERGELOG - ET SIEGE",
   finessju: 850017856,
   id: 850022971,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85109,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS ES DOUESSINS - ET SIEGE",
   finessju: 490019585,
   id: 490019593,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS HUGO - ET SIEGE",
   finessju: 490018934,
   id: 490019130,
   commune: "ANGERS",
   cp: 49100,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS LOGISTIQUE CH SAINT CALAIS ET SIEG",
   finessju: 720020239,
   id: 720020247,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS MIS CH DE SAINT CALAIS ET SIEGE",
   finessju: 720020262,
   id: 720020270,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PHARMACIE",
   finessju: 440055713,
   id: 440055721,
   commune: "NANTES",
   cp: 44200,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PHARMACIE LIGERIENNE",
   finessju: 490019353,
   id: 490019379,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PHARMACIE LIGERIENNE ET SIEGE",
   finessju: 490019353,
   id: 490019361,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PHARMACIE LOIRE VENDEE ET SIEGE",
   finessju: 440051803,
   id: 440051811,
   commune: "NANTES CEDEX 2",
   cp: 44202,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS POLE READAPT MAUBREUIL TOURMALINE",
   finessju: 440049567,
   id: 440050136,
   commune: "ST HERBLAIN CEDEX",
   cp: 44818,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS POLE SANTE DES OLONNES ET SIEGE",
   finessju: 850017898,
   id: 850018524,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PRESTATIONS DE SERVICE - ET SIEGE",
   finessju: 440052314,
   id: 440052322,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240515151",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PROCREALIS ET SIEGE",
   finessju: 850025453,
   id: 850025461,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PUI ANJOU - ET SIEGE",
   finessju: 490018801,
   id: 490019122,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PUI SOINS DE SUITE NANTES ATLANTIQ",
   finessju: 440052876,
   id: 440052892,
   commune: "ST HERBLAIN CEDEX",
   cp: 44814,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS PUI SOINS DE SUITE NANTES ET SIEGE",
   finessju: 440052876,
   id: 440052884,
   commune: "ST HERBLAIN CEDEX",
   cp: 44814,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS RVSM ET SIEGE",
   finessju: 850023169,
   id: 850023599,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85026,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS STER VENDEE ET SIEGE",
   finessju: 850023680,
   id: 850023706,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS BELLINIERE",
   finessju: 490017365,
   id: 490017571,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS BLOC OPERATOIRE",
   finessju: 490017415,
   id: 490017605,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS CENTRE DE CORONAROGRAPHIE",
   finessju: 440047538,
   id: 440050078,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS CITE SANITAIRE NAZAIRIENNE",
   finessju: 440045177,
   id: 440050110,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "272278900",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS COSALI",
   finessju: 850017880,
   id: 850018532,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS DE MPR DE ST NAZAIRE",
   finessju: 440049559,
   id: 440050128,
   commune: "ST NAZAIRE CEDEX",
   cp: 44606,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS DU PAYS DE RETZ",
   finessju: 440047553,
   id: 440050060,
   commune: "PORNIC CEDEX",
   cp: 44213,
   telephone: "251747878",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS E-SANTE PAYS LOIRE",
   finessju: 440050409,
   id: 440050417,
   commune: "NANTES CEDEX 2",
   cp: 44262,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS ETABLISSEMENTS ST SAUVEUR",
   finessju: 490017373,
   id: 490017530,
   commune: "ANGERS",
   cp: 49100,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS ETS SANTE SITE ORGEMONT",
   finessju: 490017407,
   id: 490017522,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS HAD DU SAUMUROIS",
   finessju: 490017423,
   id: 490017589,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS IFSI",
   finessju: 490017431,
   id: 490017597,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS INST REG CANCEROLOGIE",
   finessju: 490017381,
   id: 490017548,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS JULES VERNE",
   finessju: 440044220,
   id: 440050086,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS MARAIS BRETON VENDEEN",
   finessju: 850017864,
   id: 850018417,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS MAUGES BOCAGE CHOLETAIS",
   finessju: 490017399,
   id: 490017563,
   commune: "CHOLET CEDEX",
   cp: 49325,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS NANTES SOINS SUITE",
   finessju: 440049245,
   id: 440050094,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS NEMO",
   finessju: 440049575,
   id: 440050144,
   commune: "ST HERBLAIN CEDEX",
   cp: 44818,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS OFFRE SOINS PAYS D'ANCENIS",
   finessju: 440049583,
   id: 440050151,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS POLE DE SANTE",
   finessju: 530007434,
   id: 530007566,
   commune: "RENAZE",
   cp: 53800,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS POLE SANITAIRE",
   finessju: 720018795,
   id: 720018902,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS POLE SANTE LOIRE ET SILLON",
   finessju: 440048965,
   id: 440050102,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS POLE SANTE SUD",
   finessju: 720018803,
   id: 720018910,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "GCS-MOYENS POLE SANTE SUD VENDEE",
   finessju: 850017849,
   id: 850018516,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "IRCAM MEDECINE NUCLEAIRE CH CHOLET",
   finessju: 490020732,
   id: 490020989,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241353295",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "IRCAM SITE CHU ANGERS",
   finessju: 490020732,
   id: 490020997,
   commune: "ANGERS CEDEX 9",
   cp: 49933,
   telephone: "240679915",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "TOMOGRAPHE A POSITONS - SITE CONFLUENT",
   finessju: 440045656,
   id: 440053155,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "TOMOGRAPHES A POSITONS - R. GAUDUCHEAU",
   finessju: 440045037,
   id: 440053148,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "TOMOGRAPHES A POSITONS CHU 44 HOT DIEU",
   finessju: 440045037,
   id: 440055960,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "240165773",
   categorie: "Groupement de cooperation sanitaire de moyens"
 },
 {
   finess: "FINESS",
   name: "HAD D'ANGERS ET SA ReGION",
   finessju: 490012176,
   id: 490000312,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241246000",
   categorie: "Hospitalisation a Domicile"
 },
 {
   finess: "FINESS",
   name: "HAD MAUGES BOCAGE CHOLETAIS",
   finessju: 490016326,
   id: 490016334,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241496275",
   categorie: "Hospitalisation a Domicile"
 },
 {
   finess: "FINESS",
   name: "HAD OUEST ANJOU SAUMUROIS",
   finessju: 440052041,
   id: 490016870,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "-",
   categorie: "Hospitalisation a Domicile"
 },
 {
   finess: "FINESS",
   name: "HOPITAL A DOMICILE DE VENDEE",
   finessju: 850005968,
   id: 850006008,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Hospitalisation a Domicile"
 },
 {
   finess: "FINESS",
   name: "HOPITAL A DOMICILE NANTES & REGION",
   finessju: 440003960,
   id: 440012128,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240160470",
   categorie: "Hospitalisation a Domicile"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'HAD :LE MANS ET ANTENNES",
   finessju: 720008390,
   id: 720016856,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243503257",
   categorie: "Hospitalisation a Domicile"
 },
 {
   finess: "FINESS",
   name: "FOYER DE SEMAINE GRANDE NOUE",
   finessju: 750719239,
   id: 440013258,
   commune: "NANTES",
   cp: 44000,
   telephone: "251890427",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "FOYER DE SEMAINE LA HALVEQUE",
   finessju: 750719239,
   id: 440013266,
   commune: "NANTES",
   cp: 44300,
   telephone: "240491498",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM DE JOUR",
   finessju: 850012436,
   id: 850025198,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251414636",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM JEAN YVES GUITTON",
   finessju: 720008770,
   id: 720000371,
   commune: "SAVIGNE L EVEQUE",
   cp: 72460,
   telephone: "243239091",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM LA BUISSONNIERE",
   finessju: 750719239,
   id: 440000222,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240729280",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM LA DURANTIERE APAHJ 44",
   finessju: 440018612,
   id: 440000768,
   commune: "NANTES",
   cp: 44100,
   telephone: "251806049",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM LA GRILLONNAIS",
   finessju: 750719239,
   id: 440000230,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240035666",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM LA GUIBERDIERE",
   finessju: 490535184,
   id: 490000551,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241210721",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM LA MARRIERE",
   finessju: 750719239,
   id: 440000750,
   commune: "NANTES CEDEX 3",
   cp: 44319,
   telephone: "240494050",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "IEM LES TOURNESOLS",
   finessju: 490535184,
   id: 490542974,
   commune: "ANGERS CEDEX 02",
   cp: 49017,
   telephone: "241608288",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "SECTION D'EDUCATION MOTRICE APF",
   finessju: 750719239,
   id: 530007301,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243983541",
   categorie: "Institut d'education motrice"
 },
 {
   finess: "FINESS",
   name: "ANNEXE IME VALLET",
   finessju: 440018380,
   id: 440044170,
   commune: "MOUZILLON",
   cp: 44330,
   telephone: "240339631",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE IME VALLET",
   finessju: 440018380,
   id: 440044188,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240543088",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME ALEXIS RICORDEAU",
   finessju: 440018661,
   id: 440000032,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240093333",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME AR MOR",
   finessju: 440018380,
   id: 440000172,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240633525",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME AREAMS AIZENAY",
   finessju: 850020413,
   id: 850027251,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251361816",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME AREAMS LA ROCHE",
   finessju: 850020413,
   id: 850000167,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "228977901",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME AREAMS RIVES DE L'YON",
   finessju: 850020413,
   id: 850000159,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "251478500",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME BORDAGE FONTAINE",
   finessju: 490535192,
   id: 490000775,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241491717",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CENRO",
   finessju: 440000156,
   id: 440000206,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240030301",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CHAMPFLEURY",
   finessju: 490535192,
   id: 490000528,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241892049",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CHANTEMERLE",
   finessju: 490535192,
   id: 490000510,
   commune: "SAUMUR CEDEX",
   cp: 49427,
   telephone: "241501046",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CHANZY",
   finessju: 440018380,
   id: 440000149,
   commune: "NANTES",
   cp: 44000,
   telephone: "240743440",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CLAIRVAL",
   finessju: 490535192,
   id: 490000502,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241921595",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CLAIRVAL",
   finessju: 490535192,
   id: 490543154,
   commune: "SEGRE CEDEX",
   cp: 49503,
   telephone: "241921142",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME CLEMENCE ROYER",
   finessju: 440018612,
   id: 440003911,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240220419",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME DE BRIANCON",
   finessju: 920718459,
   id: 490000064,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241661188",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME DU VAL DE LOIR",
   finessju: 720009562,
   id: 720000330,
   commune: "BAZOUGES CRE SUR LOIR",
   cp: 72200,
   telephone: "243481130",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME EPIONE",
   finessju: 490020310,
   id: 720007129,
   commune: "THORIGNE SUR DUE",
   cp: 72160,
   telephone: "243890507",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME EUROPE",
   finessju: 490535192,
   id: 490000536,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241448008",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME GUENOUVRY",
   finessju: 440000966,
   id: 440049278,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792311",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME HORS LES MURS",
   finessju: 440001485,
   id: 440049682,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240498000",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME ILE DE NANTES",
   finessju: 440018380,
   id: 440050995,
   commune: "NANTES",
   cp: 44300,
   telephone: "251843860",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME JB MESSAGER",
   finessju: 530031434,
   id: 530000199,
   commune: "LAVAL CEDEX 9",
   cp: 53022,
   telephone: "243914800",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440024586,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602364",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME L HARDANGERE",
   finessju: 720008762,
   id: 720000322,
   commune: "ALLONNES CEDEX",
   cp: 72703,
   telephone: "243805193",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME L'ASTROLABE",
   finessju: 720008390,
   id: 720000421,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243501280",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME L'ESTUAIRE",
   finessju: 440041101,
   id: 440023836,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "251747200",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA BAUCHE DE ROUET",
   finessju: 440018380,
   id: 440000115,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240312111",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA CHALOUERE",
   finessju: 490535184,
   id: 490002557,
   commune: "ANGERS CEDEX 02",
   cp: 49017,
   telephone: "241201100",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA FLEURIAYE",
   finessju: 440018661,
   id: 440055937,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240472020",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA GOUPILLAIS",
   finessju: 440018380,
   id: 440000164,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240790265",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA GUERINIERE",
   finessju: 850012436,
   id: 850003633,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251320816",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA MAILLARDIERE",
   finessju: 530031434,
   id: 530029149,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243704478",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA MONNERAIE",
   finessju: 490535200,
   id: 490002490,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241303455",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LA RIVIERE SAUVAGE",
   finessju: 490535192,
   id: 490000791,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241620262",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE COTEAU",
   finessju: 490534849,
   id: 490000486,
   commune: "SAUMUR CEDEX",
   cp: 49426,
   telephone: "241832483",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE GRACALOU",
   finessju: 490536828,
   id: 490000544,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241368000",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE GUe BRAUD",
   finessju: 850012436,
   id: 850003617,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251694019",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE HAMEAU DU GRAND FIEF",
   finessju: 850012436,
   id: 850003625,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251648750",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE MARAIS",
   finessju: 850012436,
   id: 850016734,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251353553",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE TILLAY",
   finessju: 440018380,
   id: 440000636,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240582805",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LE VAL DE SEVRE",
   finessju: 440018661,
   id: 440000040,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240344035",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LEON DOUDARD",
   finessju: 530033000,
   id: 530002070,
   commune: "MONTAUDIN",
   cp: 53220,
   telephone: "243053326",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LEONCE MALECOT",
   finessju: 720009562,
   id: 720000298,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243541160",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES BARBUSSIERES",
   finessju: 440018380,
   id: 440002350,
   commune: "ST HILAIRE DE CHALEONS",
   cp: 44680,
   telephone: "240317373",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES DORICES",
   finessju: 440018380,
   id: 440000214,
   commune: "VALLET",
   cp: 44330,
   telephone: "240364500",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES OCEANIDES-SITE BELIERE",
   finessju: 490020310,
   id: 490000072,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241271632",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES OCEANIDES-SITE PROVINS",
   finessju: 490020310,
   id: 490020237,
   commune: "ECOUFLANT",
   cp: 49000,
   telephone: "241730102",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES PERRI�RES",
   finessju: 440018380,
   id: 440000131,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240281523",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES TERRES DE MONTAIGU",
   finessju: 850012436,
   id: 850003641,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251940152",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES TERRES NOIRES",
   finessju: 850012436,
   id: 850000217,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85018,
   telephone: "251473333",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LES TROIS MOULINS",
   finessju: 850012436,
   id: 850008707,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692516",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME LUCIEN DESMONTS",
   finessju: 440018398,
   id: 440000990,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240701652",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME MARIE MOREAU",
   finessju: 440001352,
   id: 440001105,
   commune: "ST NAZAIRE CEDEX",
   cp: 44612,
   telephone: "240531616",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME MONPLAISIR",
   finessju: 490535184,
   id: 490002565,
   commune: "ANGERS CEDEX 02",
   cp: 49017,
   telephone: "241439476",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME MONTVAL SUR LOIR",
   finessju: 720009562,
   id: 720021286,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243388380",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME PAUL ELUARD",
   finessju: 440018380,
   id: 440000123,
   commune: "ANCENIS CEDEX",
   cp: 44153,
   telephone: "240830488",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME PAUL GAUGUIN",
   finessju: 490535184,
   id: 490015385,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241956771",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME PERRAY JOUANNET",
   finessju: 750721334,
   id: 490000478,
   commune: "TERRANJOU",
   cp: 49540,
   telephone: "241594254",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME PIERRE DE LUNE",
   finessju: 440041101,
   id: 440049971,
   commune: "ST JEAN DE BOISEAU",
   cp: 44640,
   telephone: "960063663",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME SAINT MICHEL",
   finessju: 720008804,
   id: 720002013,
   commune: "SOLESMES",
   cp: 72300,
   telephone: "243951019",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME VAL LORIE",
   finessju: 440018612,
   id: 440000198,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240300699",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME VALLEE DE L'ANJOU",
   finessju: 490535200,
   id: 490000015,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241839960",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IME VAUROUZE",
   finessju: 720009562,
   id: 720000280,
   commune: "LE MANS CEDEX 2",
   cp: 72018,
   telephone: "243470580",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "IMEP LES SABLES",
   finessju: 490016979,
   id: 490525029,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241797940",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "INTERNAT POLE NANTAIS",
   finessju: 440018380,
   id: 440052348,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240310303",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "SATED LES CERISIERS",
   finessju: 530031434,
   id: 530005917,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243495975",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "SIPFP IME JB  MESSAGER",
   finessju: 530031434,
   id: 530032432,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243592070",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "SIPFP JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440028819,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240423137",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "UE MATERNELLE AUTISME",
   finessju: 440018380,
   id: 440052355,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240582805",
   categorie: "Institut Medico-Educatif (I.M.E.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE CHARLOTTE BLOUIN",
   finessju: 490535168,
   id: 490000098,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241368585",
   categorie: "Institut pour Deficients Auditifs"
 },
 {
   finess: "FINESS",
   name: "IES-AUDITIFS LA DURANTIERE APAJH 44",
   finessju: 440018612,
   id: 440029890,
   commune: "NANTES",
   cp: 44100,
   telephone: "251806049",
   categorie: "Institut pour Deficients Auditifs"
 },
 {
   finess: "FINESS",
   name: "INSTITUT LA PERSAGOTIERE",
   finessju: 440000073,
   id: 440000107,
   commune: "NANTES CEDEX 2",
   cp: 44262,
   telephone: "240756315",
   categorie: "Institut pour Deficients Auditifs"
 },
 {
   finess: "FINESS",
   name: "IES-VISUELS LA DURANTIERE APAJH 44",
   finessju: 440018612,
   id: 440029700,
   commune: "NANTES",
   cp: 44100,
   telephone: "251806049",
   categorie: "Institut pour Deficients Visuels"
 },
 {
   finess: "FINESS",
   name: "INSTITUT MONTECLAIR",
   finessju: 490535168,
   id: 490525011,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241733818",
   categorie: "Institut pour Deficients Visuels"
 },
 {
   finess: "FINESS",
   name: "IPHV LES HAUTS THEBAUDIERES",
   finessju: 440002467,
   id: 440003747,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251795000",
   categorie: "Institut pour Deficients Visuels"
 },
 {
   finess: "FINESS",
   name: "ANNEXE INSTITUT LAMORICIERE",
   finessju: 690793435,
   id: 440017614,
   commune: "NANTES",
   cp: 44000,
   telephone: "240291984",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ANNEXE INSTITUT LAMORICIERE",
   finessju: 690793435,
   id: 440017622,
   commune: "NANTES",
   cp: 44300,
   telephone: "240505550",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "CASIM ARRIA",
   finessju: 440001485,
   id: 440046886,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240186065",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "DITEP LES AUBRYS",
   finessju: 720007418,
   id: 720000355,
   commune: "CHAMPAGNE",
   cp: 72470,
   telephone: "243541230",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "DITEP PRO LE JALLU",
   finessju: 720007418,
   id: 720018399,
   commune: "ST COSME EN VAIRAIS",
   cp: 72110,
   telephone: "243541230",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "INTERNAT INSTITUT LAMORICIERE",
   finessju: 690793435,
   id: 440013498,
   commune: "NANTES",
   cp: 44300,
   telephone: "240495955",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP AZUR TREPIED",
   finessju: 440000966,
   id: 440050300,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240152484",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP FELIX JEAN MARCHAIS",
   finessju: 530000256,
   id: 530000215,
   commune: "ANDOUILLE",
   cp: 53240,
   telephone: "243697519",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP GESVRES",
   finessju: 750720831,
   id: 440000743,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "240946401",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP HENRI WALLON",
   finessju: 590799730,
   id: 850019696,
   commune: "BELLEVIGNY",
   cp: 85170,
   telephone: "251248900",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP KER RIVAUD",
   finessju: 440000966,
   id: 440035087,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240152484",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP L'ALOUETTE",
   finessju: 440042844,
   id: 850000332,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251241400",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LA PAPOTIERE MOISSONS NOUVELLES",
   finessju: 750720831,
   id: 440000099,
   commune: "NANTES",
   cp: 44300,
   telephone: "240492538",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LA PERDRIERE",
   finessju: 530000256,
   id: 530002658,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914730",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LA TREMBLAIE",
   finessju: 490536836,
   id: 490000825,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241650485",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LA TURMELIERE",
   finessju: 490020310,
   id: 490543634,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240091515",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LAMORICIERE",
   finessju: 690793435,
   id: 440024693,
   commune: "NANTES",
   cp: 44100,
   telephone: "240710830",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LE CARDO",
   finessju: 440001485,
   id: 440003812,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240407777",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LE COLOMBIER",
   finessju: 490534849,
   id: 490000148,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241185000",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LE THOUET",
   finessju: 490536828,
   id: 490017514,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241533737",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LES CHESNAIES",
   finessju: 490536828,
   id: 490000577,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241211480",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LES OLIVIERS",
   finessju: 490536836,
   id: 490015351,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241741580",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP LES PERRINES",
   finessju: 440001485,
   id: 440002343,
   commune: "NANTES",
   cp: 44300,
   telephone: "240498000",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP MARIE MOREAU",
   finessju: 440001352,
   id: 440033249,
   commune: "ST NAZAIRE CEDEX",
   cp: 44612,
   telephone: "240531616",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "ITEP PAYS DE RETZ",
   finessju: 440001352,
   id: 440049369,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "240531616",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "LISEC ITEP ARRIA",
   finessju: 440001485,
   id: 440048916,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240407777",
   categorie: "Institut Therapeutique educatif et Pedagogique (I.T.E.P.)"
 },
 {
   finess: "FINESS",
   name: "LABM ISO",
   finessju: 440022648,
   id: 440032654,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240303024",
   categorie: "Laboratoire d'Analyses"
 },
 {
   finess: "FINESS",
   name: "LABM TOXILABO",
   finessju: 440042935,
   id: 440042943,
   commune: "NANTES CEDEX 3",
   cp: 44328,
   telephone: "251777082",
   categorie: "Laboratoire d'Analyses"
 },
 {
   finess: "FINESS",
   name: "LABORATOIRE BIOFORTIS",
   finessju: 440052959,
   id: 440052967,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240205799",
   categorie: "Laboratoire d'Analyses"
 },
 {
   finess: "FINESS",
   name: "LABORATOIRE BIOLANCE CLINIQUE BRETECHE",
   finessju: 440005114,
   id: 440053445,
   commune: "NANTES",
   cp: 44000,
   telephone: "251683600",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LABORATOIRE MEDILAB GROUP (1)",
   finessju: 790018352,
   id: 850026055,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696306",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ACTIV'BIOLAB AIZENAY",
   finessju: 850021239,
   id: 850021288,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251947060",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ACTIV'BIOLAB BRETIGNOLLES",
   finessju: 850021239,
   id: 850027574,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "251947060",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ACTIV'BIOLAB CHALLANS",
   finessju: 850021239,
   id: 850021387,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251931095",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ACTIV'BIOLAB ST GILLES CROIX DE VI",
   finessju: 850021239,
   id: 850017211,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251550336",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - BECLARD ANGERS",
   finessju: 490017266,
   id: 490017290,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241241870",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - BESSONNEAU 2 ANGERS",
   finessju: 490017266,
   id: 490019288,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241875306",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - JONCHERES ANGERS",
   finessju: 490017266,
   id: 490017282,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241663311",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - LAFAYETTE ANGERS",
   finessju: 490017266,
   id: 490017274,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241885046",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - MEIGNANNE ANGERS",
   finessju: 490017266,
   id: 490017332,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241470608",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - MONTREUIL BELLAY",
   finessju: 490017266,
   id: 490019270,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241663104",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - PASTEUR ANGERS",
   finessju: 490017266,
   id: 490017324,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241439842",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - SAUMUROISE ANGERS",
   finessju: 490017266,
   id: 490017340,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241470000",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - SEGRE",
   finessju: 490017266,
   id: 490017456,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241921899",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - ST BARTHELEMY",
   finessju: 490017266,
   id: 490019239,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241939087",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - ST SYLVAIN",
   finessju: 490017266,
   id: 490019296,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241767172",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - TASSIGNY ANGERS",
   finessju: 490017266,
   id: 490017316,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241661245",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ANDEBIO - TRELAZE",
   finessju: 490017266,
   id: 490017308,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241424781",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440022473,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240615086",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440049831,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225551",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440049849,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240705037",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440049864,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240568625",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440049880,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240568625",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440051555,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "240225551",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440051878,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240249674",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440052017,
   commune: "NANTES",
   cp: 44000,
   telephone: "240477150",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440052025,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLAM",
   finessju: 440049823,
   id: 440053049,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240615086",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 490018702,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 490018710,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 490018736,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241886421",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 490018744,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891032",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 490018751,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241890137",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 530007509,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243592900",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 530007517,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243661515",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 530007533,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243701494",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 530007541,
   commune: "CRAON",
   cp: 53400,
   telephone: "243063628",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 530007558,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243056666",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 720019694,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243442786",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 720019702,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940523",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLARIS",
   finessju: 530007491,
   id: 720019710,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950482",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049377,
   commune: "REZE",
   cp: 44400,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049393,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049401,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049419,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049427,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049435,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049443,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049450,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049468,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049476,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049484,
   commune: "COUERON",
   cp: 44220,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049492,
   commune: "REZE",
   cp: 44400,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049500,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049518,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440049526,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051464,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240030003",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051472,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240032640",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051480,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240032621",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051530,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051548,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051605,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051613,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051621,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051639,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 440051647,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 850017823,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 850026147,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLIANCE",
   finessju: 440005114,
   id: 850026154,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440050029,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240463024",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440050037,
   commune: "PONT ST MARTIN",
   cp: 44860,
   telephone: "240463056",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440050045,
   commune: "REZE",
   cp: 44400,
   telephone: "228255130",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440050052,
   commune: "NANTES",
   cp: 44000,
   telephone: "240471337",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440051399,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440051407,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240857040",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOLOIRE",
   finessju: 440050011,
   id: 440051993,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMEDILAM",
   finessju: 440049690,
   id: 440049708,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240811121",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMEDILAM",
   finessju: 440049690,
   id: 440049716,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240815880",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMEDILAM",
   finessju: 440049690,
   id: 440049724,
   commune: "HERIC",
   cp: 44810,
   telephone: "240812980",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMEDILAM",
   finessju: 440049690,
   id: 490017449,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241925990",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMELIS - CHALONNES",
   finessju: 490018496,
   id: 490018512,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241781208",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMELIS - CHEMILLE",
   finessju: 490018496,
   id: 490018504,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241303021",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOMELIS - CHOLET",
   finessju: 490018496,
   id: 490018520,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241626145",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850017674,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251217831",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850018128,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371136",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850018136,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251370591",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850018144,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371381",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850018151,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251444650",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850018169,
   commune: "LUCON",
   cp: 85400,
   telephone: "251560635",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850018177,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "251989424",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850020199,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251237500",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850020272,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251215826",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIORYLIS",
   finessju: 850018110,
   id: 850026998,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251273892",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - AVRILLE",
   finessju: 490017167,
   id: 490017175,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241692686",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - BEAUPREAU",
   finessju: 490017167,
   id: 490017688,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241752870",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - CAMBELL ANGERS",
   finessju: 490017167,
   id: 490017217,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241481232",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - CHALOUERE ANGERS",
   finessju: 490017167,
   id: 490017225,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241348888",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - DOLBEAU ANGERS",
   finessju: 490017167,
   id: 490017209,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241810424",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - DUMESNIL ANGERS",
   finessju: 490017167,
   id: 490017191,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241733310",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - LE LION D'ANGERS",
   finessju: 490017167,
   id: 490017233,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241956132",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - MONTREUIL JUIGNE",
   finessju: 490017167,
   id: 490018231,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241423070",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - RIOBE ANGERS",
   finessju: 490017167,
   id: 490017183,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241730040",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BIOSITES - TIERCE",
   finessju: 490017167,
   id: 490019650,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241420958",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440049757,
   commune: "PORNIC",
   cp: 44210,
   telephone: "251740000",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440049765,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320988",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440049773,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "228250235",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440049781,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240659615",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440049799,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240274459",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440049807,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240024370",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BMPR",
   finessju: 440049732,
   id: 440051290,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM BPMR",
   finessju: 440049732,
   id: 440049740,
   commune: "CHAUMES EN RETZ",
   cp: 44680,
   telephone: "285935010",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM C+ BIO LA FERTE B",
   finessju: 280006453,
   id: 720018779,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930764",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM CYTOGEN",
   finessju: 440051977,
   id: 440051985,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251841717",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440046837,
   commune: "NANTES",
   cp: 44000,
   telephone: "251828351",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440050326,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440050334,
   commune: "VALLET",
   cp: 44330,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440050342,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440050912,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440050920,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440050938,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440051019,
   commune: "BLAIN",
   cp: 44130,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 440051027,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM ISOSEL",
   finessju: 440050318,
   id: 490017696,
   commune: "CANDE",
   cp: 49440,
   telephone: "241927184",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE",
   finessju: 720018662,
   id: 490017654,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241561441",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE",
   finessju: 720018662,
   id: 490017662,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "241578840",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE ALLONNES",
   finessju: 720018662,
   id: 720018738,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE BEAUCOUZE",
   finessju: 720018662,
   id: 490018892,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "241912111",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE BEAUVERGER LE MANS",
   finessju: 720018662,
   id: 720018696,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243784060",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE BRISSAC QUINCE",
   finessju: 720018662,
   id: 490018900,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241912111",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE COULAINES",
   finessju: 720018662,
   id: 720018704,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243764678",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE DOUE LA FONTAINE",
   finessju: 720018662,
   id: 490019312,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241591113",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE ECOMMOY",
   finessju: 720018662,
   id: 720019553,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE GAMBETTA LE MANS",
   finessju: 720018662,
   id: 720019017,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243880880",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE GUETTELOUP LE MANS",
   finessju: 720018662,
   id: 720018670,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243783838",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE JAURES LE MANS",
   finessju: 720018662,
   id: 720018720,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243780780",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE LAENNEC LE MANS",
   finessju: 720018662,
   id: 720018688,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243775432",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE LE LUDE",
   finessju: 720018662,
   id: 720019546,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE LES HERBIERS",
   finessju: 720018662,
   id: 850024845,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251648574",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE MITTERRAND LE MANS",
   finessju: 720018662,
   id: 720018712,
   commune: "LE MANS",
   cp: 72000,
   telephone: "253560331",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE PONTS DE CE",
   finessju: 720018662,
   id: 490018918,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241449699",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE ST CALAIS",
   finessju: 720018662,
   id: 720019025,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631112",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LABOMAINE TRELAZE",
   finessju: 720018662,
   id: 490019320,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241699625",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LEMAIRE",
   finessju: 440050946,
   id: 440050953,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602751",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LEMAIRE",
   finessju: 440050946,
   id: 440050961,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251161080",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LEMAIRE",
   finessju: 440050946,
   id: 440050979,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240601361",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LOGIC BIO",
   finessju: 850017740,
   id: 850017781,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251931855",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LOGIC BIO",
   finessju: 850017740,
   id: 850017799,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390999",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM LOGIC BIO",
   finessju: 850017740,
   id: 850017807,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "251590915",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM NORMABIO - VILLAINES LA JUHEL",
   finessju: 610008609,
   id: 530007830,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440049609,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "251127888",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440049617,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240949034",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440049625,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240764848",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440049633,
   commune: "NANTES",
   cp: 44000,
   telephone: "240767644",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440050359,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440050367,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM RESEAU BIO",
   finessju: 440049591,
   id: 440053064,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "240639220",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM SEVRE BIOLOGIE",
   finessju: 850018540,
   id: 440050169,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240547500",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM SEVRE BIOLOGIE",
   finessju: 850018540,
   id: 850018557,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651212",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM SEVRE BIOLOGIE",
   finessju: 850018540,
   id: 850018599,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251919319",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM SEVRE BIOLOGIE",
   finessju: 850018540,
   id: 850018615,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910745",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM SUD LOIRE BIOLOGIE",
   finessju: 440049641,
   id: 440049658,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240805012",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM SUD LOIRE BIOLOGIE",
   finessju: 440049641,
   id: 440049666,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240344698",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM VERDUN DE LORE",
   finessju: 610008609,
   id: 530007475,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243009900",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM VERDUN DE LORE",
   finessju: 610008609,
   id: 530007939,
   commune: "EVRON",
   cp: 53600,
   telephone: "243012501",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM XLABS - BEAUPREAU",
   finessju: 490017779,
   id: 490020922,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "-",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM XLABS - FONTENAY LE COMTE",
   finessju: 490017779,
   id: 850019654,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690016",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM XLABS - GAMBETTA CHOLET",
   finessju: 490017779,
   id: 490017795,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241623073",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LBM XLABS - LES SABLES CHOLET",
   finessju: 490017779,
   id: 490017787,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241625869",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LMB C+ BIO MAMERS",
   finessju: 280006453,
   id: 720018761,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243334882",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LMB XLABS - ST MACAIRE",
   finessju: 490017779,
   id: 490017803,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241466688",
   categorie: "Laboratoire de Biologie Medicale"
 },
 {
   finess: "FINESS",
   name: "LDVA LE MOULIN RAMBIS",
   finessju: 850021569,
   id: 850021577,
   commune: "LE LANGON",
   cp: 85370,
   telephone: "960181639",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU D'ACCUEIL ET DE VIE",
   finessju: 850008608,
   id: 850008749,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251507521",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE ACCUEIL DYNAMIQUE EN OASIS",
   finessju: 490015781,
   id: 490015807,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "241635368",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE ARLEQUIN 44",
   finessju: 440043685,
   id: 440043693,
   commune: "ST ANDRE DES EAUX",
   cp: 44117,
   telephone: "240914509",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE ARZ BEVAN",
   finessju: 440044048,
   id: 440044055,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240289325",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE AVENIR",
   finessju: 720017490,
   id: 720017508,
   commune: "CHEVILLE",
   cp: 72350,
   telephone: "243925831",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE BEL AIR",
   finessju: 440054526,
   id: 440054534,
   commune: "GUENROUET",
   cp: 44530,
   telephone: "240877202",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE DE CAYENNE",
   finessju: 440045888,
   id: 440045896,
   commune: "LA LIMOUZINIERE",
   cp: 44310,
   telephone: "240780122",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE DE FRELIGNE",
   finessju: 440047066,
   id: 440047074,
   commune: "TOUVOIS",
   cp: 44650,
   telephone: "240047223",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE ESCALE 44",
   finessju: 440054773,
   id: 440054781,
   commune: "LE CELLIER",
   cp: 44850,
   telephone: "973134451",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE G2L",
   finessju: 850027194,
   id: 850027202,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "983387367",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE GAIA",
   finessju: 440043909,
   id: 440043917,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240244697",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE L AIR MARIN",
   finessju: 850027210,
   id: 850027228,
   commune: "ST ANDRE GOULE D OIE",
   cp: 85250,
   telephone: "629644898",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE L ODYSSEE MARINE",
   finessju: 850027137,
   id: 850027145,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "-",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE L'ANCRE",
   finessju: 440043545,
   id: 440043552,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "953996819",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE L'ESTRAN",
   finessju: 440054542,
   id: 440054559,
   commune: "CHAUVE",
   cp: 44320,
   telephone: "240212591",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE L'ETRIER",
   finessju: 440043925,
   id: 440043933,
   commune: "LA LIMOUZINIERE",
   cp: 44310,
   telephone: "240787625",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE L'HIRONDELLE BREVINOISE",
   finessju: 440044006,
   id: 440044014,
   commune: "VILLENEUVE EN RETZ",
   cp: 44580,
   telephone: "240820117",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA BASSE FOUCHERIE",
   finessju: 530004019,
   id: 530004068,
   commune: "ST GEORGES SUR ERVE",
   cp: 53600,
   telephone: "243016808",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA BAUCHE MALO",
   finessju: 440045789,
   id: 440045797,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240130518",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA BEGAUDERIE",
   finessju: 440048353,
   id: 440048361,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240787811",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA BONNE HOTTE",
   finessju: 850027152,
   id: 850027160,
   commune: "LA CHAPELLE PALLUAU",
   cp: 85670,
   telephone: "967664769",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA CABANE MAGIQUE",
   finessju: 440054567,
   id: 440054575,
   commune: "LEGE",
   cp: 44650,
   telephone: "253976380",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA COLOMBE",
   finessju: 440045847,
   id: 440045854,
   commune: "LA PLAINE SUR MER",
   cp: 44770,
   telephone: "240219054",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA FARFOLIERE",
   finessju: 720021211,
   id: 720021229,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243556977",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA ROSE DES VENTS",
   finessju: 720019629,
   id: 720019637,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243752161",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LA VINCENDIERE",
   finessju: 440054583,
   id: 440054591,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "689919886",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LE LOGIS",
   finessju: 490008448,
   id: 490008489,
   commune: "MAUGES SUR LOIRE",
   cp: 49570,
   telephone: "241390209",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LE TRAIT D'UNION",
   finessju: 720019645,
   id: 720019652,
   commune: "CHAUFOUR NOTRE DAME",
   cp: 72550,
   telephone: "243874218",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LE TREMPLIN",
   finessju: 850027178,
   id: 850027186,
   commune: "LE CHAMP ST PERE",
   cp: 85540,
   telephone: "251053755",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LES ALIZES",
   finessju: 490010899,
   id: 490010949,
   commune: "MAUGES SUR LOIRE",
   cp: 49570,
   telephone: "241360841",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LES COMET'S",
   finessju: 440047801,
   id: 440047819,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240796127",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LES PASS-SENTIERS",
   finessju: 530005099,
   id: 530005149,
   commune: "ST HILAIRE DU MAINE",
   cp: 53380,
   telephone: "971539551",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE LICORNE",
   finessju: 440047082,
   id: 440047090,
   commune: "TOUVOIS",
   cp: 44650,
   telephone: "240780549",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE MAIN DANS LA MAIN",
   finessju: 530004878,
   id: 530004928,
   commune: "PRE EN PAIL ST SAMSON",
   cp: 53140,
   telephone: "243042049",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE MAISON ST DAMIEN",
   finessju: 720018274,
   id: 720018282,
   commune: "LE MANS",
   cp: 72000,
   telephone: "678745690",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE NE-MOT",
   finessju: 440052850,
   id: 440052868,
   commune: "REZE",
   cp: 44400,
   telephone: "983565059",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE PASTINE",
   finessju: 850011651,
   id: 850011669,
   commune: "FAYMOREAU",
   cp: 85240,
   telephone: "251004513",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE PHENIX",
   finessju: 440047108,
   id: 440047116,
   commune: "ST MALO DE GUERSAC",
   cp: 44550,
   telephone: "240195707",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE PLEIN PHARE",
   finessju: 440043867,
   id: 440043875,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "251769821",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE ROC O CERF",
   finessju: 850011677,
   id: 850011685,
   commune: "ROCHESERVIERE",
   cp: 85620,
   telephone: "251949972",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE TRABOULE",
   finessju: 440047124,
   id: 440047132,
   commune: "INDRE",
   cp: 44610,
   telephone: "240380795",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE TY FERMANOU",
   finessju: 440054609,
   id: 440054617,
   commune: "ST VINCENT DES LANDES",
   cp: 44590,
   telephone: "672692518",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE TY GOLENN",
   finessju: 440045904,
   id: 440045912,
   commune: "FEGREAC",
   cp: 44460,
   telephone: "240176926",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE TY MAMM",
   finessju: 850011719,
   id: 850011727,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251238027",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE UN BOUT DE CHEMIN ENSEMBLE",
   finessju: 440045862,
   id: 440045870,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240895440",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU DE VIE VIVRE ENSEMBLE",
   finessju: 720017516,
   id: 720017524,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243401476",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LIEU VIE LE BOUT DE CHEMIN",
   finessju: 490013638,
   id: 490013679,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "952518727",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LVA L'HOMMELAIS",
   finessju: 440043586,
   id: 440043594,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240780073",
   categorie: "Lieux de vie"
 },
 {
   finess: "FINESS",
   name: "LAM VERTOU",
   finessju: 440026482,
   id: 440054062,
   commune: "VERTOU",
   cp: 44120,
   telephone: "-",
   categorie: "Lits d'Accueil Medicalises (L.A.M.)"
 },
 {
   finess: "FINESS",
   name: "LHSS ACCUEIL CENOMAN",
   finessju: 720019207,
   id: 720017847,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243287667",
   categorie: "Lits Halte Soins Sante (L.H.S.S.)"
 },
 {
   finess: "FINESS",
   name: "LHSS L'ATRE",
   finessju: 440026482,
   id: 440046704,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240800202",
   categorie: "Lits Halte Soins Sante (L.H.S.S.)"
 },
 {
   finess: "FINESS",
   name: "LHSS L'ATRE",
   finessju: 440026482,
   id: 440046977,
   commune: "NANTES",
   cp: 44000,
   telephone: "251822866",
   categorie: "Lits Halte Soins Sante (L.H.S.S.)"
 },
 {
   finess: "FINESS",
   name: "LHSS PASSERELLES",
   finessju: 850013236,
   id: 850018292,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624343",
   categorie: "Lits Halte Soins Sante (L.H.S.S.)"
 },
 {
   finess: "FINESS",
   name: "LHSS SAINT-NAZAIRE",
   finessju: 440018422,
   id: 440053163,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Lits Halte Soins Sante (L.H.S.S.)"
 },
 {
   finess: "FINESS",
   name: "FOYER HEBERGEMENT FRATERNITE",
   finessju: 440003358,
   id: 440009538,
   commune: "NANTES",
   cp: 44100,
   telephone: "240693763",
   categorie: "Logement Foyer non Specialise"
 },
 {
   finess: "FINESS",
   name: "MAS BLANCHE NEIGE",
   finessju: 530008168,
   id: 530002716,
   commune: "BAIS",
   cp: 53160,
   telephone: "243379507",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS CAA NORT/ERDRE",
   finessju: 440018380,
   id: 440056018,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "251126538",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS CENTRE BASILE MOREAU",
   finessju: 720000728,
   id: 720012228,
   commune: "PRECIGNE",
   cp: 72300,
   telephone: "243622121",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS CESAME PORT THIBAULT",
   finessju: 490000163,
   id: 490016680,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241807718",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS CHS G MAZURELLE",
   finessju: 850000092,
   id: 850009168,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85026,
   telephone: "251369410",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS CHS MAZURELLE SITE LONGEVILLE",
   finessju: 850000092,
   id: 850017906,
   commune: "LONGEVILLE SUR MER",
   cp: 85560,
   telephone: "251906213",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS DE BRIAN�ON",
   finessju: 920718459,
   id: 490017498,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241661188",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS DE L'HaPITAL S�VRE ET LOIRE",
   finessju: 440042141,
   id: 440017960,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "240715100",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS DE L'HUISNE",
   finessju: 720000058,
   id: 720007558,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243503780",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS DE L'OUDON",
   finessju: 490016979,
   id: 490008752,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241949200",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS DE LA SEVRE",
   finessju: 440018612,
   id: 440037836,
   commune: "REZE",
   cp: 44400,
   telephone: "240131812",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS DIAPASON",
   finessju: 440048767,
   id: 440048775,
   commune: "GRANDCHAMPS DES FONTAINES",
   cp: 44119,
   telephone: "240140739",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS ESPACES",
   finessju: 490016979,
   id: 490016961,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241797940",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS ESPOIR ET VIE",
   finessju: 610000754,
   id: 440037844,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240964242",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS FRAICHE PASQUIER",
   finessju: 440004711,
   id: 440017952,
   commune: "COUERON",
   cp: 44220,
   telephone: "240854050",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS HANDI VILLAGE",
   finessju: 720000454,
   id: 720008473,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243511300",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS HELIOPE",
   finessju: 720009562,
   id: 720014562,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243768100",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS HORIZONS",
   finessju: 490535168,
   id: 440042430,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251790184",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS L'OCEANE",
   finessju: 530007186,
   id: 530005834,
   commune: "MAYENNE CEDEX",
   cp: 53104,
   telephone: "243321808",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LA FRAGONNETTE",
   finessju: 850020413,
   id: 850016973,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "251088979",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LA MADELEINE",
   finessju: 850021486,
   id: 850021312,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251687408",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LA PALOMBERIE",
   finessju: 490535184,
   id: 490012069,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241608484",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LA ROGERIE",
   finessju: 490535200,
   id: 490542982,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241642627",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LE BEL AUBEPIN",
   finessju: 530006618,
   id: 530029156,
   commune: "EVRON",
   cp: 53600,
   telephone: "243016254",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LE GIBERTIN",
   finessju: 490535200,
   id: 490003241,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241303458",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LES AMARYLLIS",
   finessju: 720000058,
   id: 720007509,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243390050",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LES CHANTERELLES",
   finessju: 850012436,
   id: 850024423,
   commune: "MOUILLERON LE CAPTIF",
   cp: 85000,
   telephone: "251380666",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LES COLLINES",
   finessju: 720008770,
   id: 720008382,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243201943",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LES HAUTS DE SEVRE",
   finessju: 850023581,
   id: 850017641,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651230",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LES MELISSES",
   finessju: 720008770,
   id: 720016617,
   commune: "MULSANNE",
   cp: 72230,
   telephone: "243889400",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LES ROMANS",
   finessju: 490015856,
   id: 490543303,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241832222",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS LESIOUR SOULBIEU",
   finessju: 720006022,
   id: 720015452,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243601270",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS MADELEINE ROCHAS",
   finessju: 490011343,
   id: 490532033,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241785934",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS OCEANE",
   finessju: 440041127,
   id: 440032738,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "251747100",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS OPALINE",
   finessju: 440004315,
   id: 440040566,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240588100",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS PASTEL DE LOIRE",
   finessju: 490535168,
   id: 490016516,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241376260",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS ROBIN DES BOIS",
   finessju: 720009562,
   id: 720016328,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243292900",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS SESAME AUTISME",
   finessju: 440033884,
   id: 440046126,
   commune: "SUCE SUR ERDRE",
   cp: 44240,
   telephone: "251811805",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS SIMONE VEIL",
   finessju: 490020310,
   id: 720018886,
   commune: "BOULOIRE",
   cp: 72440,
   telephone: "243631200",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS ST AMADOUR",
   finessju: 750721334,
   id: 530032473,
   commune: "LA SELLE CRAONNAISE",
   cp: 53800,
   telephone: "251730102",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS ST JEAN DE DIEU",
   finessju: 750052037,
   id: 440032597,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240626000",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS SUD LOIRE - L'EPEAU",
   finessju: 440018380,
   id: 440017945,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320345",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS SUD LOIRE - LES LOGES",
   finessju: 440018380,
   id: 440046035,
   commune: "MONTBERT",
   cp: 44140,
   telephone: "258009955",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS THERESE VOHL",
   finessju: 750719239,
   id: 530005966,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243592626",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS THERESE VOHL SITE DU TERTRE",
   finessju: 750719239,
   id: 530008432,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243592626",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "MAS YOLAINE DE KEPPER",
   finessju: 490001971,
   id: 490013778,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241226060",
   categorie: "Maison d'Accueil Specialisee (M.A.S.)"
 },
 {
   finess: "FINESS",
   name: "HEMISPHERE SANTE",
   finessju: 440052488,
   id: 440052496,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240265121",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE BENET- MAILLEZAIS",
   finessju: 850027707,
   id: 850027715,
   commune: "BENET",
   cp: 85490,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE D'AIZENAY",
   finessju: 850022351,
   id: 850022369,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251946783",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE BALLON - STE JAMME",
   finessju: 720020205,
   id: 720020213,
   commune: "MONTBIZOT",
   cp: 72380,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE BEAUMONT SUR SARTHE",
   finessju: 720020320,
   id: 720020338,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243330733",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE CANDE",
   finessju: 490020120,
   id: 490020138,
   commune: "CANDE",
   cp: 49440,
   telephone: "241920072",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE CHAILLE LES MARAIS",
   finessju: 850020751,
   id: 850020801,
   commune: "CHAILLE LES MARAIS",
   cp: 85450,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE CUGAND",
   finessju: 850025891,
   id: 850025909,
   commune: "CUGAND",
   cp: 85610,
   telephone: "251981030",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE L'ESTUAIRE",
   finessju: 440056026,
   id: 440056034,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "688987722",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE L'ESTUAIRE",
   finessju: 850027665,
   id: 850027673,
   commune: "L AIGUILLON SUR MER",
   cp: 85460,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE L'HERMENAULT",
   finessju: 850025875,
   id: 850025883,
   commune: "L HERMENAULT",
   cp: 85570,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE LA HAUTE SARTHE",
   finessju: 720020510,
   id: 720020528,
   commune: "FYE",
   cp: 72610,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE LA JOUANNE",
   finessju: 530007897,
   id: 530007905,
   commune: "MONTSURS ST CENERE",
   cp: 53150,
   telephone: "243696572",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE LOUe",
   finessju: 720020148,
   id: 720020155,
   commune: "LOUE",
   cp: 72540,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE MAROLLES LES BRAULT",
   finessju: 720019520,
   id: 720019538,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243296438",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE MORANNES",
   finessju: 490019429,
   id: 490019437,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE ROCHESERVI�RE",
   finessju: 850025834,
   id: 850025842,
   commune: "ROCHESERVIERE",
   cp: 85620,
   telephone: "251419197",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DE SEGRe",
   finessju: 490019460,
   id: 490019478,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241947540",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE VAIGES",
   finessju: 530008572,
   id: 530008580,
   commune: "VAIGES",
   cp: 53480,
   telephone: "243905098",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE VERNOIL VERNANTES",
   finessju: 490019106,
   id: 490019114,
   commune: "VERNOIL LE FOURRIER",
   cp: 49390,
   telephone: "241515709",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DE VILLAINES-JAVRON",
   finessju: 530008713,
   id: 530008721,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243032212",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DES ACHARDS",
   finessju: 850020926,
   id: 850021171,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251088608",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DES FORGES",
   finessju: 850025719,
   id: 850025727,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DIVATTE SANTE",
   finessju: 440055978,
   id: 440055986,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "607243469",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DU BIGNON",
   finessju: 440052124,
   id: 440052132,
   commune: "LE BIGNON",
   cp: 44140,
   telephone: "240781590",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DU NORD LAVALLOIS",
   finessju: 530008465,
   id: 530008473,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243671515",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DU PAYS DE LOIRON",
   finessju: 530008077,
   id: 530008085,
   commune: "ST PIERRE LA COUR",
   cp: 53410,
   telephone: "243018406",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DU POIRE SUR VIE",
   finessju: 850026006,
   id: 850026014,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251318990",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe DU SUD-SAUMUR (MI3S)",
   finessju: 490020047,
   id: 490020054,
   commune: "CHACE",
   cp: 49400,
   telephone: "241529873",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE DU VAL DE BRAYE",
   finessju: 720020486,
   id: 720020494,
   commune: "VIBRAYE",
   cp: 72320,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE ESCULAPE",
   finessju: 850022377,
   id: 850022393,
   commune: "LUCON",
   cp: 85400,
   telephone: "251272207",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE GASTON RAMON",
   finessju: 850026626,
   id: 850026634,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251361547",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE LA FONTAINE",
   finessju: 440055994,
   id: 440056000,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240705131",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe LA�NNEC",
   finessju: 440052249,
   id: 440052256,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240170017",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe LAYON AUBANCE",
   finessju: 490019833,
   id: 490019841,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "241594204",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE LE MANS OUEST",
   finessju: 720020536,
   id: 720020544,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243230202",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe NANTES OUEST",
   finessju: 440053395,
   id: 440053403,
   commune: "NANTES",
   cp: 44100,
   telephone: "240582121",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTe OReE D'ANJOU",
   finessju: 490020146,
   id: 490020153,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240090713",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE THAU EVRE ET LOIRE",
   finessju: 490019932,
   id: 490019940,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MAISON DE SANTE VAL DE LOIRE",
   finessju: 490020526,
   id: 490020534,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP BECON LES GRANITS",
   finessju: 490018538,
   id: 490018546,
   commune: "BECON LES GRANITS",
   cp: 49370,
   telephone: "619875649",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP CaTE SAUVAGE",
   finessju: 440055655,
   id: 440055663,
   commune: "BATZ SUR MER",
   cp: 44740,
   telephone: "685105796",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE BAUGE",
   finessju: 490018967,
   id: 490018975,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE BEAUPREAU",
   finessju: 490018983,
   id: 490018991,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630104",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE BONCHAMP LES LAVAL",
   finessju: 530008051,
   id: 530008069,
   commune: "BONCHAMP LES LAVAL",
   cp: 53960,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE BRETIGNOLLES SUR MER",
   finessju: 850027442,
   id: 850027459,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE BRULON",
   finessju: 720019801,
   id: 720019819,
   commune: "BRULON",
   cp: 72350,
   telephone: "243621639",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE CHANTENAY SAINTE ANNE",
   finessju: 440055226,
   id: 440055234,
   commune: "NANTES",
   cp: 44100,
   telephone: "633455912",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE CLISSON",
   finessju: 440051076,
   id: 440051084,
   commune: "CLISSON",
   cp: 44190,
   telephone: "608240463",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE GUeMENe-PENFAO",
   finessju: 440051670,
   id: 440051688,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792013",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE GUERANDE",
   finessju: 440055192,
   id: 440055200,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "684975534",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE MAYET",
   finessju: 720019843,
   id: 720019850,
   commune: "MAYET",
   cp: 72360,
   telephone: "243490196",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE MONTAIGU",
   finessju: 850027269,
   id: 850027277,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE MONTREVAULT",
   finessju: 490019049,
   id: 490019056,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE MORTAGNE - LA GAUBRETIERE",
   finessju: 850025818,
   id: 850025826,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251651159",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE NOIRMOUTIER",
   finessju: 850022534,
   id: 850022773,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251358989",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE NOYANT",
   finessju: 490019064,
   id: 490019072,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241896068",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE NOZAY",
   finessju: 440051696,
   id: 440051704,
   commune: "NOZAY",
   cp: 44170,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE SAINT CALAIS",
   finessju: 720019868,
   id: 720019876,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631393",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE SAVENAY",
   finessju: 440051050,
   id: 440051068,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569006",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE TORFOU",
   finessju: 490019080,
   id: 490019098,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "249104394",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DE VIHIERS",
   finessju: 490018553,
   id: 490018561,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "608498521",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DES 3 CAPS",
   finessju: 850026428,
   id: 850026436,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251954292",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DU LION D'ANGERS",
   finessju: 490019023,
   id: 490019031,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241953145",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DU PAYS DOUESSIN",
   finessju: 490020633,
   id: 490020641,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP DU PAYS SABOLIEN",
   finessju: 720021153,
   id: 720021161,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP ECOUFLANT",
   finessju: 490020542,
   id: 490020559,
   commune: "ECOUFLANT",
   cp: 49000,
   telephone: "241437041",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP HAUT ANJOU SANTE",
   finessju: 530009059,
   id: 530009067,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243703975",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP NANTES NORD LE CHENE ET LE ROSEAU",
   finessju: 440053346,
   id: 440053353,
   commune: "NANTES",
   cp: 44300,
   telephone: "240940555",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP QUARTIERS SUD DU MANS",
   finessju: 720021112,
   id: 720021120,
   commune: "LE MANS",
   cp: 72100,
   telephone: "608490306",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP RENAZE CRAON",
   finessju: 530007871,
   id: 530007889,
   commune: "RENAZE",
   cp: 53800,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP RIVES DE L'YON",
   finessju: 850027038,
   id: 850027046,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "251313140",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "MSP VAL DE MOINE",
   finessju: 490020294,
   id: 490020302,
   commune: "SEVREMOINE",
   cp: 49230,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "PaLE DE SANTe DE CH�TEAU DU LOIR",
   finessju: 720020080,
   id: 720020098,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "PaLE DE SANTE DE CONLIE",
   finessju: 720019827,
   id: 720019835,
   commune: "CONLIE",
   cp: 72240,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTe DE SILLe LE GUILLAUME",
   finessju: 720019884,
   id: 720019892,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243520452",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTe DU CLION",
   finessju: 440051712,
   id: 440051720,
   commune: "PORNIC",
   cp: 44210,
   telephone: "661867953",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "PaLE DE SANTe DU HAUT ANJOU",
   finessju: 490019445,
   id: 490019452,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTe DU MARAIS",
   finessju: 850026402,
   id: 850026410,
   commune: "SALLERTAINE",
   cp: 85300,
   telephone: "251355167",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTe JEAN BERNARD",
   finessju: 490019007,
   id: 490019015,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241621011",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "PaLE DE SANTe LAVAL CENTRE",
   finessju: 530008762,
   id: 530008770,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243534296",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTE LIBERAL DU PAYS DE",
   finessju: 530007913,
   id: 530007921,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "685666719",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTE MESLAY-GREZ",
   finessju: 530008093,
   id: 530008101,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243984064",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE DE SANTE SUD ESTUAIRE (PSSE)",
   finessju: 440051035,
   id: 440051043,
   commune: "CORSEPT",
   cp: 44560,
   telephone: "686627734",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE SANTE ATLANTIQUE BRIERE",
   finessju: 440053718,
   id: 440053726,
   commune: "LA CHAPELLE DES MARAIS",
   cp: 44410,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE SANTE DU LONGUeEN",
   finessju: 490020021,
   id: 490020039,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "614754650",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "POLE SANTE DU NORD OUEST MAYENNAIS",
   finessju: 530008226,
   id: 530008234,
   commune: "MONTAUDIN",
   cp: 53220,
   telephone: "243002054",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "PaLE SANTe LIBeRAL DU SAOSNOIS",
   finessju: 720020304,
   id: 720020312,
   commune: "MAMERS",
   cp: 72600,
   telephone: "684240111",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "SISA BOSSEBA",
   finessju: 490018942,
   id: 490018959,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241343233",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "SISA SANTe AVENIR",
   finessju: 440051654,
   id: 440051662,
   commune: "BLAIN",
   cp: 44130,
   telephone: "-",
   categorie: "Maison de sante (L.6223-3)"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE DU PARC",
   finessju: 440001246,
   id: 440000800,
   commune: "NANTES",
   cp: 44000,
   telephone: "251860700",
   categorie: "Maison de Sante pour Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE LA  BRIERE",
   finessju: 440001998,
   id: 440003390,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240621550",
   categorie: "Maison de Sante pour Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE NOTRE DAME DE PRITZ",
   finessju: 530000249,
   id: 530000124,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243536730",
   categorie: "Maison de Sante pour Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "CLINIQUE SAINT-DIDIER",
   finessju: 490000130,
   id: 490000247,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241333440",
   categorie: "Maison de Sante pour Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "INST. PSYCHOTHERAPIQUE LA MENANTIERE",
   finessju: 490535705,
   id: 490002979,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241756100",
   categorie: "Maison de Sante pour Maladies Mentales"
 },
 {
   finess: "FINESS",
   name: "ANJOU INSERTION JEUNES",
   finessju: 490016037,
   id: 490011988,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241864800",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "APAECH - POLE ADOLESCENT",
   finessju: 490539509,
   id: 490000809,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241580611",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "APAECH - POLE ENFANCE",
   finessju: 490539509,
   id: 490003209,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241580611",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "AT HOME AEMINA ASAMEH",
   finessju: 440026482,
   id: 440052843,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240730952",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE EDUCATIF DE JOUR MONTJOIE",
   finessju: 720008705,
   id: 720005784,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243827098",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE EDUCATIF SCOLAIRE MONTJOIE CESM",
   finessju: 720008705,
   id: 720006758,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243631313",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "CENTRE EDUCATIF TREMEAC",
   finessju: 440002566,
   id: 440044915,
   commune: "NANTES",
   cp: 44100,
   telephone: "240698371",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "CESP DU DESPA LA MARMITIERE",
   finessju: 490534849,
   id: 490002508,
   commune: "ST BARTHELEMY D ANJOU CEDE",
   cp: 49180,
   telephone: "241961870",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF D'ACCUEIL MIE",
   finessju: 750720526,
   id: 720021237,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243540110",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "ENVOL LOIRE ATLANTIQUE",
   finessju: 440045946,
   id: 440052785,
   commune: "NANTES",
   cp: 44300,
   telephone: "240497842",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "EP FELIX GUILLOUX - AU FIL DE L'AUX",
   finessju: 440001493,
   id: 440002376,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240659393",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "ETABLISSEMENT EDUCATIF MONTJOIE ETEM",
   finessju: 720008705,
   id: 720011444,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243248814",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "ETABLISSEMENTS SOCIAUX SAINT MARTIN",
   finessju: 750720526,
   id: 720006832,
   commune: "ST PAVACE",
   cp: 72190,
   telephone: "243540110",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "ETAPE JEUNES  LE PHARE",
   finessju: 440018646,
   id: 440017853,
   commune: "NANTES",
   cp: 44100,
   telephone: "240353516",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "ETAPE JEUNES ADJINA",
   finessju: 440018646,
   id: 440054633,
   commune: "NANTES",
   cp: 44200,
   telephone: "240033437",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "ETAPE JEUNES SEMA",
   finessju: 440018646,
   id: 440050219,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240940532",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER ALADIN",
   finessju: 490001260,
   id: 490002607,
   commune: "ANGERS CEDEX 02",
   cp: 49102,
   telephone: "241348211",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER APB",
   finessju: 490534849,
   id: 490002573,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241186930",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER EDUCATIF DE L'ANEF-FERRER",
   finessju: 440018422,
   id: 440002129,
   commune: "NANTES",
   cp: 44200,
   telephone: "240480533",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER EDUCATIF DE LA JAUNAIE",
   finessju: 440007573,
   id: 440002442,
   commune: "ST SEBASTIEN SUR LOIRE CED",
   cp: 44234,
   telephone: "251790579",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER L'OASIS",
   finessju: 440001907,
   id: 440003176,
   commune: "NANTES CEDEX 1",
   cp: 44005,
   telephone: "240477412",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER LA RIVE BLEUE",
   finessju: 490542255,
   id: 490002599,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241363366",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER LES TOURELLES",
   finessju: 490001278,
   id: 490002615,
   commune: "SAUMUR CEDEX",
   cp: 49414,
   telephone: "241402390",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "FOYER TOURNEMINE",
   finessju: 490542255,
   id: 490000023,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241483668",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "M.E.C.S. LE HAVRE ROSE DE LIMA",
   finessju: 750720526,
   id: 530003508,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243569314",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ACCUEIL D ARGENTRE",
   finessju: 530031285,
   id: 530008978,
   commune: "ARGENTRE",
   cp: 53210,
   telephone: "243664588",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ACCUEIL DE LOUVIGNE",
   finessju: 530031285,
   id: 530008986,
   commune: "LOUVIGNE",
   cp: 53210,
   telephone: "243664589",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ENFANTS JEANNE DELANOUE",
   finessju: 720001445,
   id: 720006113,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243459615",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ENFANTS LE BELVEDERE",
   finessju: 720001445,
   id: 720019793,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243311860",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ENFANTS LES PLEIADES",
   finessju: 720001445,
   id: 720006105,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243620210",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ENFANTS SAINT JOSEPH",
   finessju: 440043081,
   id: 440002525,
   commune: "LA BERNERIE EN RETZ",
   cp: 44760,
   telephone: "240827081",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D ENFANTS SAINT VINCENT DE PAUL",
   finessju: 440001469,
   id: 440002145,
   commune: "NANTES",
   cp: 44000,
   telephone: "240480239",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D'ENFANTS LES CEDRES BLEUS",
   finessju: 440018596,
   id: 440002483,
   commune: "PORNIC CEDEX",
   cp: 44214,
   telephone: "240820603",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON D'ENFTS NOTRE DAME DE BETHLEEM",
   finessju: 440002319,
   id: 440024578,
   commune: "NANTES CEDEX 3",
   cp: 44322,
   telephone: "240743302",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISON DANIEL BROTTIER",
   finessju: 750720526,
   id: 440026367,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240652990",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAISONS D'ENFANTS SAINT PAVIN",
   finessju: 720008853,
   id: 720005883,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243810016",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS AAE LES AMARRES",
   finessju: 440007573,
   id: 440054625,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240427969",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS ANJOU",
   finessju: 750720526,
   id: 490020955,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "972654701",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS CASSIOPEE",
   finessju: 490016037,
   id: 490016045,
   commune: "CANDE",
   cp: 49440,
   telephone: "241612930",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS CHARLES DE FOUCAULD",
   finessju: 750720526,
   id: 850021593,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "228104270",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS D ERNEE",
   finessju: 530031426,
   id: 530008945,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243268990",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS ETAPES",
   finessju: 720008705,
   id: 720002344,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243817880",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS L ECLAIRCIE",
   finessju: 490535192,
   id: 490016433,
   commune: "ANGERS CEDEX 01",
   cp: 49018,
   telephone: "241683633",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LA COURTE ECHELLE",
   finessju: 490001260,
   id: 490020849,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "-",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LA COURTE ECHELLE",
   finessju: 850017773,
   id: 850027244,
   commune: "LUCON",
   cp: 85400,
   telephone: "251214541",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LA MAISON DE BEAUVAIS",
   finessju: 530031426,
   id: 530008937,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243268990",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LA MAISON DES ALPES",
   finessju: 530031426,
   id: 530004829,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243053514",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LE CAP",
   finessju: 490001260,
   id: 490012168,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "-",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LE FOSCO",
   finessju: 530031426,
   id: 530033372,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243531746",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LE PASSAGE APSH",
   finessju: 850017773,
   id: 850021627,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251408108",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LE POURQUOI PAS",
   finessju: 720008853,
   id: 720005875,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243762148",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LES ECUREUILS",
   finessju: 490020310,
   id: 490008398,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241429993",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS LES LAURIERS",
   finessju: 750720526,
   id: 850021759,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251378309",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MECS UPASE 85",
   finessju: 720008705,
   id: 850027236,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251952114",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MINEURS JEUNES MAJEURS NON ACCOMPAGNES",
   finessju: 490544277,
   id: 490020591,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241191062",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POLE ACCUEIL DIVERSIFIE ADOLESCENTS",
   finessju: 720008853,
   id: 530008960,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243670589",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POLE GRAND VAL",
   finessju: 440018604,
   id: 440002111,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "251252841",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POLE GREZILLIERES",
   finessju: 440018604,
   id: 440002400,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240549900",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POLE HEBERGEMENTS",
   finessju: 440018737,
   id: 440003242,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251100090",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POLE JULES VERNE",
   finessju: 440018604,
   id: 440002392,
   commune: "NANTES CEDEX 3",
   cp: 44319,
   telephone: "228236860",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "RELAIS PARENTAL LA COURTE ECHELLE",
   finessju: 750721334,
   id: 440027779,
   commune: "NANTES",
   cp: 44100,
   telephone: "240581578",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "RELAIS PARENTAL SAINT NAZAIRE",
   finessju: 750721334,
   id: 440045524,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240227420",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "SAJE",
   finessju: 720008853,
   id: 530008952,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243670140",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "SERVICE ACCUEIL MINEURS NON ACCOMPAGNE",
   finessju: 440054641,
   id: 440054658,
   commune: "NANTES",
   cp: 44300,
   telephone: "240940605",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "SERVICE LAPARTE",
   finessju: 530031426,
   id: 530007947,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243641470",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "SERVICE PLACEMENT EDUCATIF A DOMICILE",
   finessju: 720001445,
   id: 720021195,
   commune: "LA FLECHE CEDEX",
   cp: 72203,
   telephone: "243455680",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "UNITE MOBILE ACCOMPAGNEMENT SPECIFIQUE",
   finessju: 530031285,
   id: 530008994,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243591871",
   categorie: "Maison d'Enfants a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "MAH LE FIGUIER",
   finessju: 490017118,
   id: 490017126,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241725900",
   categorie: "Maisons d'accueil hospitali�res (M.A.H.)"
 },
 {
   finess: "FINESS",
   name: "MAH MAISON LES SOLEILS",
   finessju: 720021179,
   id: 720021187,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243247080",
   categorie: "Maisons d'accueil hospitali�res (M.A.H.)"
 },
 {
   finess: "FINESS",
   name: "MAH ST JEAN",
   finessju: 750061319,
   id: 440055853,
   commune: "NANTES CEDEX 1",
   cp: 44093,
   telephone: "251838306",
   categorie: "Maisons d'accueil hospitali�res (M.A.H.)"
 },
 {
   finess: "FINESS",
   name: "ASSOCIATION TRAJET",
   finessju: 440002533,
   id: 440047991,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "251110000",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "HABITAT HUMANISME",
   finessju: 440047967,
   id: 440047975,
   commune: "NANTES",
   cp: 44100,
   telephone: "240432324",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS",
   finessju: 440026482,
   id: 440048221,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240800202",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS  ADOMA  ANGERS",
   finessju: 750808511,
   id: 490018033,
   commune: "ANGERS",
   cp: 49000,
   telephone: "240762251",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS  AIDE ACCUEIL",
   finessju: 490004231,
   id: 490016953,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241253560",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS - L'ECOCHERE",
   finessju: 440048064,
   id: 440048080,
   commune: "ST GEREON",
   cp: 44150,
   telephone: "240970868",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS ABRI DES CORDELIERS",
   finessju: 490004223,
   id: 490019734,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "950298301",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS ABRI PROVIDENCE SOURCES",
   finessju: 490544277,
   id: 490019726,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241944278",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS ACEL",
   finessju: 530008838,
   id: 530008002,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243530803",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS ADOMA SAUMUR",
   finessju: 750808511,
   id: 490018041,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "240762251",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS ANJOU INSERTION HABITAT",
   finessju: 490016904,
   id: 490016912,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241434674",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS BRIN ESPOIR",
   finessju: 490544277,
   id: 490016664,
   commune: "ERDRE EN ANJOU",
   cp: 49220,
   telephone: "241414100",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS DE NANTES",
   finessju: 440018422,
   id: 440048262,
   commune: "NANTES",
   cp: 44000,
   telephone: "240353312",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS DE ST NAZAIRE",
   finessju: 440018422,
   id: 440048270,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240480533",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS HABITAT HUMANISME",
   finessju: 490016938,
   id: 490016946,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241349125",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS JEUNE EN ERRANCE",
   finessju: 440052769,
   id: 440052538,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LA ROCHE-LES HERBIERS",
   finessju: 850012436,
   id: 850016395,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85035,
   telephone: "251449700",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LE RELAI FLEURI",
   finessju: 850013657,
   id: 850014069,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251497503",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LE ROCHER - SAVENAY",
   finessju: 440012664,
   id: 440048601,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569444",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LES BLEUETS",
   finessju: 750721334,
   id: 720019660,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943283",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LES JONQUILLES",
   finessju: 440031169,
   id: 440048098,
   commune: "NANTES",
   cp: 44300,
   telephone: "240020730",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LES PRIMEVERES",
   finessju: 440031169,
   id: 440041499,
   commune: "NANTES",
   cp: 44200,
   telephone: "240020730",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS LES TOITS BLEUS",
   finessju: 440048064,
   id: 440048379,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970868",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS MONTJOIE",
   finessju: 720008705,
   id: 720018472,
   commune: "LE MANS",
   cp: 72100,
   telephone: "272880766",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS MUMIA ABU JAMAL",
   finessju: 720018118,
   id: 720018126,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "963265303",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS NELSON MANDELA MAMERS",
   finessju: 720018118,
   id: 720020114,
   commune: "MAMERS",
   cp: 72600,
   telephone: "967388050",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISON RELAIS STUDIOVIVES",
   finessju: 440012664,
   id: 440052512,
   commune: "NANTES",
   cp: 44000,
   telephone: "682553861",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "MAISONS RELAIS APSH",
   finessju: 850017773,
   id: 850013517,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251214541",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "PENSION DE FAMILLE LA SALICORNE APSH",
   finessju: 850017773,
   id: 850021502,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251497690",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "PENSION DE FAMILLE OLYMPE DE GOUGE",
   finessju: 530000819,
   id: 530008176,
   commune: "LAVAL CEDEX",
   cp: 53014,
   telephone: "243668855",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL   LA GAUTRECHE",
   finessju: 750720591,
   id: 490019403,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241631031",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL BOIS COLOMBES",
   finessju: 440031169,
   id: 440048106,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240020730",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL CH HAUT ANJOU",
   finessju: 530000025,
   id: 530008010,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243090677",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL KER OCEANE",
   finessju: 440031169,
   id: 440048114,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240020730",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL LE GUE DES SABLES",
   finessju: 720008820,
   id: 720018530,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243779007",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL LES MESANGES",
   finessju: 440012664,
   id: 440044873,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569444",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL MAYA ANGELOU",
   finessju: 720018118,
   id: 720020106,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "964450406",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL SAUMUR",
   finessju: 490017878,
   id: 490019924,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241365100",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE ACCUEIL- UDAF 49",
   finessju: 490017878,
   id: 490019411,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241365100",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE DU CLOS TOREAU",
   finessju: 440048783,
   id: 440048791,
   commune: "NANTES",
   cp: 44200,
   telephone: "228015322",
   categorie: "Maisons Relais - Pensions de Famille"
 },
 {
   finess: "FINESS",
   name: "CADUCEE",
   finessju: 850003468,
   id: 850016098,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "251581715",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "ESPACE SANTE DU BOCAGE",
   finessju: 850002932,
   id: 850015959,
   commune: "ST FULGENT",
   cp: 85250,
   telephone: "251426017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "GRANDE PHARMACIE",
   finessju: 440011856,
   id: 440016855,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224735",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "GRANDE PHARMACIE",
   finessju: 490004702,
   id: 490532223,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241435001",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "GRANDE PHARMACIE DE LA MOTTE",
   finessju: 440009090,
   id: 440015162,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810261",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "GRANDE PHARMACIE DES COLLINES",
   finessju: 850004391,
   id: 850016338,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910294",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "GRANDE PHARMACIE DU PILORI",
   finessju: 490004710,
   id: 490532231,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241874959",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "GRANDE PHARMACIE THOUARE CENTRE",
   finessju: 440013225,
   id: 440017242,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240773348",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "LA GRANDE PHARMACIE",
   finessju: 490006764,
   id: 490534575,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241656324",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "LA GRANDE PHARMACIE",
   finessju: 850002973,
   id: 850015991,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251550105",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "LA GRANDE PHARMACIE DES MINIMES",
   finessju: 720002443,
   id: 720009018,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243243888",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "LA NOUVELLE PHARMACIE",
   finessju: 720002922,
   id: 720009497,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243287723",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "LA PHARMACIE DE TOUTES AIDES",
   finessju: 440007128,
   id: 440014397,
   commune: "NANTES",
   cp: 44000,
   telephone: "240496412",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "NOUVELLE PHARMACIE NANTAISE",
   finessju: 440010908,
   id: 440016285,
   commune: "REZE",
   cp: 44400,
   telephone: "240756431",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "NOUVELLE PHARMACIE PIERRE LISE",
   finessju: 490005097,
   id: 490532611,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241875215",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHAMACIE DE CHAMPTOCEAUX",
   finessju: 490005451,
   id: 490532983,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240835226",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE   BABAULT - LEROYER",
   finessju: 720005545,
   id: 720013879,
   commune: "CERANS FOULLETOURTE",
   cp: 72330,
   telephone: "243878025",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE   BENDAVID",
   finessju: 490007168,
   id: 490537560,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241553021",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE   GELLUSSEAU",
   finessju: 440006658,
   id: 440013910,
   commune: "NANTES",
   cp: 44100,
   telephone: "240463668",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE   GILET",
   finessju: 440012672,
   id: 440017036,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240787036",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  BEAUVAIS",
   finessju: 490006491,
   id: 490534187,
   commune: "LA SEGUINIERE",
   cp: 49280,
   telephone: "241569034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  BENOIST",
   finessju: 720005289,
   id: 720010867,
   commune: "ST MARS D OUTILLE",
   cp: 72220,
   telephone: "243427386",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  BISIAU",
   finessju: 490006657,
   id: 490534351,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241758039",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  BISQUAY",
   finessju: 440008217,
   id: 440014835,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240607879",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  BOURBON",
   finessju: 530001882,
   id: 530030972,
   commune: "PRE EN PAIL ST SAMSON",
   cp: 53140,
   telephone: "243031766",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  BOUVET",
   finessju: 530001726,
   id: 530030816,
   commune: "LOUVERNE",
   cp: 53950,
   telephone: "243011087",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  CAYRON",
   finessju: 720005404,
   id: 720010982,
   commune: "VAAS",
   cp: 72500,
   telephone: "243467013",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  CHARRIER",
   finessju: 490005790,
   id: 490533478,
   commune: "MAULEVRIER",
   cp: 49360,
   telephone: "241555708",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  COCHARD-GUENON",
   finessju: 440009892,
   id: 440015428,
   commune: "GENESTON",
   cp: 44140,
   telephone: "240267017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  COUPEAU",
   finessju: 530001155,
   id: 530030246,
   commune: "ARGENTRE",
   cp: 53210,
   telephone: "243373225",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  DENET",
   finessju: 720005495,
   id: 720011121,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243476259",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  DRAPEAU",
   finessju: 440010684,
   id: 440016061,
   commune: "PONT ST MARTIN",
   cp: 44860,
   telephone: "240268019",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  EVRARD",
   finessju: 530001189,
   id: 530030279,
   commune: "BALLOTS",
   cp: 53350,
   telephone: "243065223",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  FIOLLEAU",
   finessju: 440010262,
   id: 440015634,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "240037728",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  GALOU",
   finessju: 530001684,
   id: 530030774,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537757",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  GICQUEL",
   finessju: 440017531,
   id: 440017408,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240265173",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  HERBET",
   finessju: 440007623,
   id: 440014645,
   commune: "NANTES",
   cp: 44200,
   telephone: "240478635",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  ISRAEL ALEXANDRE",
   finessju: 720004845,
   id: 720010461,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243946053",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  JUDAIS",
   finessju: 530001593,
   id: 530030683,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243669096",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  KASSIAN",
   finessju: 720005511,
   id: 720011147,
   commune: "MEZERAY",
   cp: 72270,
   telephone: "243451328",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  KERJAN",
   finessju: 850001736,
   id: 850014820,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251527701",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  LAMBERT - BREMENT",
   finessju: 440009736,
   id: 440015394,
   commune: "FAY DE BRETAGNE",
   cp: 44130,
   telephone: "240874141",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  LE JOUBIOUX",
   finessju: 850002049,
   id: 850015116,
   commune: "FOUSSAIS PAYRE",
   cp: 85240,
   telephone: "251514516",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  MAILLET",
   finessju: 440008142,
   id: 440014785,
   commune: "INDRE",
   cp: 44610,
   telephone: "240380703",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  MAUROCORDATO - PIGNARD PHAR",
   finessju: 440022606,
   id: 440032522,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240031595",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  MONFORT",
   finessju: 720002666,
   id: 720009232,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243819118",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  MONNIER",
   finessju: 440008241,
   id: 440014843,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240603466",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  MOREAU",
   finessju: 440010874,
   id: 440016251,
   commune: "REZE",
   cp: 44400,
   telephone: "240756530",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  NGUYEN",
   finessju: 440020980,
   id: 440026805,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240258975",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  ORDAS",
   finessju: 440011153,
   id: 440016483,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240380500",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  PODEVIN",
   finessju: 440011310,
   id: 440016640,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240541380",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  PRIOU",
   finessju: 440020014,
   id: 440026193,
   commune: "ERBRAY",
   cp: 44110,
   telephone: "240550581",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  RABREAU",
   finessju: 440010783,
   id: 440016160,
   commune: "PORT ST PERE",
   cp: 44710,
   telephone: "240315020",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  RECULEAU - BIZON",
   finessju: 850002510,
   id: 850015363,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251488898",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  SEMELIN",
   finessju: 850002965,
   id: 850015983,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251551952",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  SOREL",
   finessju: 440012367,
   id: 440016970,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240611928",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  SORNICLE",
   finessju: 720002575,
   id: 720009141,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243282148",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  THIERCELIN",
   finessju: 440011104,
   id: 440016434,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240869048",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  THIREAU",
   finessju: 440008076,
   id: 440014710,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240830519",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  TURPIN",
   finessju: 850002478,
   id: 850015322,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251305420",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  VAILLANT",
   finessju: 720005529,
   id: 720013853,
   commune: "LA BAZOGE",
   cp: 72650,
   telephone: "243254027",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  VALLEE",
   finessju: 440011021,
   id: 440016343,
   commune: "SAFFRE",
   cp: 44390,
   telephone: "240772612",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  VERHAEGHE-GRILLO",
   finessju: 850001959,
   id: 850015041,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251693010",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  VICENTE",
   finessju: 850002551,
   id: 850015405,
   commune: "MOUILLERON ST GERMAIN",
   cp: 85390,
   telephone: "251003507",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE  YAOUANC",
   finessju: 440006518,
   id: 440013779,
   commune: "NANTES",
   cp: 44100,
   telephone: "240767225",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ABU AITA",
   finessju: 850002692,
   id: 850015546,
   commune: "LE PERRIER",
   cp: 85300,
   telephone: "251683140",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ACTI-SUD",
   finessju: 850000746,
   id: 850015678,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251374448",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ALBIOL",
   finessju: 490004967,
   id: 490532488,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241884097",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ALLAIN - PERICHOU",
   finessju: 490005576,
   id: 490533254,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241591058",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ALLIE-BOUTARD",
   finessju: 440009678,
   id: 440015360,
   commune: "DERVAL",
   cp: 44590,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ALLONNEAU",
   finessju: 490005055,
   id: 490532579,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241439622",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AMRAOUI",
   finessju: 720004613,
   id: 720010396,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243972001",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ANGERS MILLOT",
   finessju: 490004934,
   id: 490532454,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241800800",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE APPRIOU",
   finessju: 440012458,
   id: 440016988,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240903365",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AQUITAINE",
   finessju: 850002775,
   id: 850015793,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320479",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ASTIER - TRICOIRE",
   finessju: 440019156,
   id: 440024164,
   commune: "LEGE",
   cp: 44650,
   telephone: "240049261",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ATLANTIQUE",
   finessju: 850003450,
   id: 850016080,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ATTIAS-LEVY",
   finessju: 440007342,
   id: 440014561,
   commune: "NANTES",
   cp: 44000,
   telephone: "240733850",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ATTICUS",
   finessju: 850004573,
   id: 850016379,
   commune: "SALLERTAINE",
   cp: 85300,
   telephone: "251930140",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AUBINEAU",
   finessju: 490006897,
   id: 490534708,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241675446",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AUBRETON",
   finessju: 490005907,
   id: 490533585,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241523023",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AUBRON",
   finessju: 440010171,
   id: 440015543,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "240548019",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AUDEBAULT",
   finessju: 490005535,
   id: 490533213,
   commune: "LOIRE AUTHION",
   cp: 49630,
   telephone: "241450028",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE AVRIL -  VALARCHER",
   finessju: 440010072,
   id: 440015501,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240248118",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BACHIMONT",
   finessju: 490005683,
   id: 490533361,
   commune: "JARZE VILLAGES",
   cp: 49140,
   telephone: "241954029",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAGUE",
   finessju: 440016020,
   id: 440017366,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240032897",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAH",
   finessju: 720002641,
   id: 720009216,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243840979",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAILLIARD - LAMBERT",
   finessju: 490006772,
   id: 490534583,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241623482",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAIXES",
   finessju: 850004615,
   id: 850016429,
   commune: "STE FLAIVE DES LOUPS",
   cp: 85150,
   telephone: "251340016",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BALASAKIS - CARTIER",
   finessju: 720002559,
   id: 720009125,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243853530",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAMMERT",
   finessju: 440010346,
   id: 440015717,
   commune: "MISSILLAC",
   cp: 44780,
   telephone: "240883024",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BARANGER-BATARDIERE",
   finessju: 490006012,
   id: 490533692,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49110,
   telephone: "241700032",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BARRE-HUSSON",
   finessju: 850002270,
   id: 850015223,
   commune: "LES LANDES GENUSSON",
   cp: 85130,
   telephone: "251916051",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BARRO",
   finessju: 530002096,
   id: 530032861,
   commune: "BAIS",
   cp: 53160,
   telephone: "243379068",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BARTHELEMY",
   finessju: 850002718,
   id: 850015561,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251318815",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BASCK",
   finessju: 490006426,
   id: 490534112,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241511163",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BASSET",
   finessju: 850000829,
   id: 850015751,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251370272",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAUDET",
   finessju: 850004805,
   id: 850023722,
   commune: "CHAMBRETAUD",
   cp: 85500,
   telephone: "251675458",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAUDRY-PARCE",
   finessju: 490005857,
   id: 490533536,
   commune: "LA MENITRE",
   cp: 49250,
   telephone: "241456134",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAUMARD",
   finessju: 720004308,
   id: 720010354,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940035",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAZIN-GEFFARD",
   finessju: 850002668,
   id: 850015512,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251953015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BAZOGE - FERRAN",
   finessju: 720003094,
   id: 720009968,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243273039",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAL-TUFFIER",
   finessju: 490005618,
   id: 490533296,
   commune: "FENEU",
   cp: 49460,
   telephone: "241320509",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAU SOLEIL",
   finessju: 440019081,
   id: 440024016,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAUCHAMPS-GOUJON",
   finessju: 850002684,
   id: 850015538,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "251985515",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAUDOUIN",
   finessju: 440006898,
   id: 440014165,
   commune: "NANTES",
   cp: 44300,
   telephone: "240494184",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAUDRON-BOUVET",
   finessju: 440010429,
   id: 440015790,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240722036",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAULANDE",
   finessju: 440007201,
   id: 440014470,
   commune: "NANTES",
   cp: 44000,
   telephone: "240740328",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEAUREGARD",
   finessju: 720002864,
   id: 720009430,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243242377",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BECHAMBES",
   finessju: 720005503,
   id: 720011139,
   commune: "VILLENEUVE EN PERSEIGNE",
   cp: 72600,
   telephone: "243337564",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BECHIEAU-NICOLLEAU",
   finessju: 850002577,
   id: 850015421,
   commune: "MOUTIERS LES MAUXFAITS",
   cp: 85540,
   telephone: "251989017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEDON-BODIN",
   finessju: 490006566,
   id: 490534260,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241690027",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEGO",
   finessju: 440009694,
   id: 440015386,
   commune: "DONGES",
   cp: 44480,
   telephone: "240910356",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BELBEOCH-NEAU",
   finessju: 850002601,
   id: 850015454,
   commune: "NIEUL LE DOLENT",
   cp: 85430,
   telephone: "251079194",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BELLANGER",
   finessju: 720005081,
   id: 720010651,
   commune: "MONTFORT LE GESNOIS",
   cp: 72450,
   telephone: "243767017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BELLEVUE",
   finessju: 490006558,
   id: 490534252,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241690160",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BENABID",
   finessju: 850001868,
   id: 850014952,
   commune: "LES EPESSES",
   cp: 85590,
   telephone: "251573010",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BENOIT",
   finessju: 440022044,
   id: 440032175,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240527340",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BENVENUTI",
   finessju: 850001611,
   id: 850014705,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251683002",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BERNARD - PILARD",
   finessju: 490007119,
   id: 490537354,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241580799",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BESNARD-OLLIVE",
   finessju: 440011294,
   id: 440016624,
   commune: "ST JOACHIM",
   cp: 44720,
   telephone: "240885311",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BESNIER",
   finessju: 440011211,
   id: 440016541,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240769876",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BESSE",
   finessju: 440010031,
   id: 440015477,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792865",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEVILLON",
   finessju: 850001884,
   id: 850014978,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628038",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BEZOMBES",
   finessju: 440013175,
   id: 440017234,
   commune: "TEILLE",
   cp: 44440,
   telephone: "240977403",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BIBOCO",
   finessju: 720003409,
   id: 720010289,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930028",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BIGET - REVEILLON",
   finessju: 490005758,
   id: 490533437,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241521035",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BIGNON-PICHON",
   finessju: 720005685,
   id: 720014000,
   commune: "VILLAINES SOUS MALICORNE",
   cp: 72270,
   telephone: "243458831",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BIGOT-FOUGEROUSE",
   finessju: 490007127,
   id: 490537362,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241563433",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BIRGAND",
   finessju: 440008951,
   id: 440015030,
   commune: "BOUVRON",
   cp: 44130,
   telephone: "240563790",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BIRON-ALLARY",
   finessju: 850000696,
   id: 850015629,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371299",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BLANCHARD-HAMELIN",
   finessju: 530001718,
   id: 530030808,
   commune: "LOIRON RUILLE",
   cp: 53320,
   telephone: "243024573",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BLANCHER-POLLET",
   finessju: 440006740,
   id: 440014017,
   commune: "NANTES",
   cp: 44000,
   telephone: "240202483",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BLANCHOT",
   finessju: 440011328,
   id: 440016657,
   commune: "ST JULIEN DE VOUVANTES",
   cp: 44670,
   telephone: "240555018",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BLANDEAU",
   finessju: 720002492,
   id: 720009067,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243840681",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BLEUE D'ANJOU",
   finessju: 440011682,
   id: 440016806,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225350",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BLOHORN - MOUVIER",
   finessju: 850001702,
   id: 850014796,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943068",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOBINET",
   finessju: 490005493,
   id: 490533023,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241306061",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BODIC-RIPAYRE",
   finessju: 440017986,
   id: 440017473,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOIS-SAULNIER",
   finessju: 720004787,
   id: 720010446,
   commune: "LUCHE PRINGE",
   cp: 72800,
   telephone: "243454421",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOISSIERE - HIPPODROME",
   finessju: 440006799,
   id: 440014066,
   commune: "NANTES",
   cp: 44300,
   telephone: "240765063",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BONAL",
   finessju: 440006468,
   id: 440013720,
   commune: "NANTES",
   cp: 44000,
   telephone: "240479908",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BONNAFY",
   finessju: 440042281,
   id: 440042307,
   commune: "CORSEPT",
   cp: 44560,
   telephone: "240276868",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BONNELLE - BECQUART",
   finessju: 490006988,
   id: 490536307,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241584950",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BORE-CHENE",
   finessju: 490005634,
   id: 490533312,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49270,
   telephone: "241705378",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOSSARD",
   finessju: 490005212,
   id: 490532736,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891217",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOTHOREL",
   finessju: 440011351,
   id: 440016681,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240256707",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUARD",
   finessju: 440010676,
   id: 440016053,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240456242",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUCHAT",
   finessju: 440008308,
   id: 440014892,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240969049",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUCHERIT",
   finessju: 850000670,
   id: 850014465,
   commune: "L AIGUILLON SUR MER",
   cp: 85460,
   telephone: "251564003",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUCLE",
   finessju: 490005980,
   id: 490533668,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241895046",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUDARD",
   finessju: 440006583,
   id: 440013845,
   commune: "NANTES",
   cp: 44100,
   telephone: "240735981",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUDROT-GARNIER",
   finessju: 440018968,
   id: 440018232,
   commune: "NANTES",
   cp: 44300,
   telephone: "240499596",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUFFARD-DESPRETZ",
   finessju: 490006210,
   id: 490533890,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241667820",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOUILLAUD - FOUCAULT",
   finessju: 490006707,
   id: 490534401,
   commune: "YZERNAY",
   cp: 49360,
   telephone: "241555792",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOULIERE",
   finessju: 530001163,
   id: 530030253,
   commune: "ARON",
   cp: 53440,
   telephone: "243321400",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOURDAIS",
   finessju: 720002476,
   id: 720009042,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243842149",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOURDIEL",
   finessju: 530001981,
   id: 530031079,
   commune: "ST PIERRE LA COUR",
   cp: 53410,
   telephone: "243018233",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOURGEOIS",
   finessju: 490005626,
   id: 490533304,
   commune: "FONTEVRAUD L ABBAYE",
   cp: 49590,
   telephone: "241517113",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOURIEL-PIVERT",
   finessju: 530001437,
   id: 530030527,
   commune: "LE GENEST ST ISLE",
   cp: 53940,
   telephone: "243021299",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BOYER-SUIRE",
   finessju: 490007051,
   id: 490536455,
   commune: "MAUGES SUR LOIRE",
   cp: 49410,
   telephone: "241789244",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRACQUEMOND",
   finessju: 720003342,
   id: 720010222,
   commune: "COURDEMANCHE",
   cp: 72150,
   telephone: "243448022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRANDET",
   finessju: 850001777,
   id: 850014861,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251951774",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRASSART",
   finessju: 440021640,
   id: 440029957,
   commune: "CHAUMES EN RETZ",
   cp: 44680,
   telephone: "240212380",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRASSIER",
   finessju: 440013407,
   id: 440017267,
   commune: "TOUVOIS",
   cp: 44650,
   telephone: "240316411",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRAULT-CANDELIER",
   finessju: 720005263,
   id: 720010842,
   commune: "ST JEAN D ASSE",
   cp: 72380,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRESSON-JAMALI",
   finessju: 490005840,
   id: 490533528,
   commune: "LONGUENEE EN ANJOU",
   cp: 49770,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRETON",
   finessju: 850002494,
   id: 850015348,
   commune: "MONSIREIGNE",
   cp: 85110,
   telephone: "251664108",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRIANCEAU-ANGEBAULT",
   finessju: 720005552,
   id: 720013887,
   commune: "LA CHAPELLE D ALIGNE",
   cp: 72300,
   telephone: "243455400",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRIAND",
   finessju: 440021467,
   id: 440028652,
   commune: "MARSAC SUR DON",
   cp: 44170,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRIEAU-PICHAUD",
   finessju: 850006222,
   id: 850006255,
   commune: "BOUFFERE",
   cp: 85600,
   telephone: "251488239",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRISSIEUX - GABILLARD",
   finessju: 530001619,
   id: 530030709,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243536098",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BROCHARD",
   finessju: 490006194,
   id: 490533874,
   commune: "MAUGES SUR LOIRE",
   cp: 49410,
   telephone: "241727067",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRODU - CHARPENTIER",
   finessju: 440012227,
   id: 440016947,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240223101",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BROSSARD",
   finessju: 440010882,
   id: 440016269,
   commune: "REZE",
   cp: 44400,
   telephone: "240756922",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BROSSEAU",
   finessju: 850002064,
   id: 850015132,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251910704",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BROSSET - LABAT-CAMY",
   finessju: 850003823,
   id: 850016197,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "251906270",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BROUILLET - LUMINEAU",
   finessju: 850002650,
   id: 850015504,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251327913",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BROUSSEAU",
   finessju: 440006815,
   id: 440014082,
   commune: "NANTES",
   cp: 44100,
   telephone: "240463708",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BRUNACCI-REVERDY",
   finessju: 490005709,
   id: 490533387,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240987219",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BURGAUD - TAFZA",
   finessju: 850002981,
   id: 850016007,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251559112",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BUTAUD",
   finessju: 440008985,
   id: 440015063,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240508272",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BUTREAU - VERDON",
   finessju: 850006420,
   id: 850006446,
   commune: "JARD SUR MER",
   cp: 85520,
   telephone: "251335899",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE BUTTAVAND",
   finessju: 850004565,
   id: 850016361,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAGIN - GODARD",
   finessju: 440011427,
   id: 440016723,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970023",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAILLON",
   finessju: 440007284,
   id: 440014538,
   commune: "NANTES",
   cp: 44000,
   telephone: "240760613",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CALLEROT-LEROY",
   finessju: 490007200,
   id: 490539061,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241342520",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAMBRONNE",
   finessju: 440012805,
   id: 440017077,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240805523",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAMENEN",
   finessju: 440011302,
   id: 440016632,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240368368",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAMILLERI-ZANNETTI",
   finessju: 720002625,
   id: 720009190,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243842969",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAMUT",
   finessju: 490006467,
   id: 490534153,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241921441",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CANET",
   finessju: 530001254,
   id: 530030345,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243071058",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAP OCEAN",
   finessju: 850001652,
   id: 850014747,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251682107",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CAQUINEAU",
   finessju: 440020881,
   id: 440026227,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240034979",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CARLIN",
   finessju: 720005099,
   id: 720010669,
   commune: "PONTVALLAIN",
   cp: 72510,
   telephone: "243463108",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CARNOT",
   finessju: 720002427,
   id: 720008994,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243246166",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CASTELNAU",
   finessju: 850002817,
   id: 850015835,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320292",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTR'HALLES",
   finessju: 440010288,
   id: 440015659,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 440012854,
   id: 440017093,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240805514",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 440012912,
   id: 440017143,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569067",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 440012987,
   id: 440017200,
   commune: "SOUDAN",
   cp: 44110,
   telephone: "240812511",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 440018356,
   id: 440017515,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240790010",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 530001536,
   id: 530030626,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243532137",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 720003151,
   id: 720010024,
   commune: "BESSE SUR BRAYE",
   cp: 72310,
   telephone: "243353024",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE",
   finessju: 850002791,
   id: 850015819,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251951266",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE DE SAINT PATERNE",
   finessju: 720005297,
   id: 720010875,
   commune: "ST PATERNE LE CHEVAIN",
   cp: 72610,
   telephone: "233317703",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRALE LA COLLEGIALE",
   finessju: 440010163,
   id: 440015535,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240249088",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CENTRE COMMERCIAL POLE SUD",
   finessju: 440018745,
   id: 440017580,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240034268",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAGNEAU",
   finessju: 490006954,
   id: 490536273,
   commune: "SOUCELLES",
   cp: 49140,
   telephone: "241322304",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAILLET-COSSON",
   finessju: 850006297,
   id: 850006305,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251377921",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAILLOU",
   finessju: 440012961,
   id: 440017184,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240312108",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAMINANT",
   finessju: 440009454,
   id: 440015287,
   commune: "COUERON",
   cp: 44220,
   telephone: "240861044",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAMPION - GENDRON",
   finessju: 440016749,
   id: 440017382,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240032070",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHANTEGUET-LIBOTTE",
   finessju: 850002833,
   id: 850015850,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320467",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHARBONNIER",
   finessju: 440010825,
   id: 440016202,
   commune: "PRINQUIAU",
   cp: 44260,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHARNEAU",
   finessju: 850003435,
   id: 850016072,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "251580207",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHATEIGNER",
   finessju: 850002916,
   id: 850015934,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "251467474",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAUFFAILLE-BARON",
   finessju: 440006781,
   id: 440014058,
   commune: "NANTES",
   cp: 44300,
   telephone: "240766071",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAUMEIL-CHARPENTIER",
   finessju: 720002518,
   id: 720009083,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243815681",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAUVAT",
   finessju: 720003078,
   id: 720009943,
   commune: "AUBIGNE RACAN",
   cp: 72800,
   telephone: "243462554",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAUVEAU",
   finessju: 850003054,
   id: 850016031,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251273550",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAUVEL-LECOMTE",
   finessju: 490006129,
   id: 490533809,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "241380187",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHAUVET",
   finessju: 850001934,
   id: 850015025,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690246",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHENAY",
   finessju: 440007078,
   id: 440014348,
   commune: "NANTES",
   cp: 44000,
   telephone: "240400312",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHENE DES ANGLAIS",
   finessju: 440006559,
   id: 440013811,
   commune: "NANTES",
   cp: 44300,
   telephone: "240769059",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHENU",
   finessju: 440006609,
   id: 440013860,
   commune: "NANTES",
   cp: 44000,
   telephone: "240740715",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHESNEAU - DURAND",
   finessju: 440010726,
   id: 440016103,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820020",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHEVILLON",
   finessju: 850001629,
   id: 850014713,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251356939",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHEYMOL-GUILLERAULT",
   finessju: 440013282,
   id: 440017259,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240726069",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHIRON",
   finessju: 440006724,
   id: 440013993,
   commune: "NANTES",
   cp: 44000,
   telephone: "240476632",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHIRON",
   finessju: 440019131,
   id: 440024123,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "240888610",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHOBLET-CARVAL",
   finessju: 440037455,
   id: 440037463,
   commune: "VERTOU",
   cp: 44120,
   telephone: "251712062",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHOGNOT",
   finessju: 850004581,
   id: 850016387,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251543271",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHOLETAISE",
   finessju: 490006293,
   id: 490533973,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241553142",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHOMBART-AMIOT",
   finessju: 440010486,
   id: 440015857,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240768020",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHRISTENSEN",
   finessju: 720002781,
   id: 720009356,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243245906",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHRISTHEL",
   finessju: 530002062,
   id: 530032077,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243670283",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CHUPIN",
   finessju: 490006582,
   id: 490534286,
   commune: "CHEMILLE EN ANJOU",
   cp: 49670,
   telephone: "241454328",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CLAIREFONTAINE",
   finessju: 490006780,
   id: 490534591,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241628551",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CLAIREFONTAINE",
   finessju: 720002690,
   id: 720009265,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243815668",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CLEMOT",
   finessju: 850002759,
   id: 850015603,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251570103",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CLERC",
   finessju: 720005032,
   id: 720010602,
   commune: "NEUVILLE SUR SARTHE",
   cp: 72190,
   telephone: "243256382",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COINTEREAU",
   finessju: 490006996,
   id: 490536315,
   commune: "MOZE SUR LOUET",
   cp: 49610,
   telephone: "241457033",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COLLIN",
   finessju: 440021632,
   id: 440029742,
   commune: "COUFFE",
   cp: 44521,
   telephone: "240965180",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CONNOIR",
   finessju: 850001454,
   id: 850014549,
   commune: "BEAULIEU SOUS LA ROCHE",
   cp: 85190,
   telephone: "251988126",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CORDIER",
   finessju: 440010395,
   id: 440015766,
   commune: "MONTOIR DE BRETAGNE",
   cp: 44550,
   telephone: "240886329",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CORMERAIS",
   finessju: 440010577,
   id: 440015949,
   commune: "LE PALLET",
   cp: 44330,
   telephone: "240804017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COULON-DOYETTE",
   finessju: 720003136,
   id: 720010008,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970013",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COURDENT - PENAUD",
   finessju: 850001553,
   id: 850014648,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "251901522",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COUSEIN",
   finessju: 490005246,
   id: 490532769,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241803059",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COUSIN",
   finessju: 720005487,
   id: 720011113,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243724501",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COUTABLE",
   finessju: 720003391,
   id: 720010271,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930175",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE COUTELEAU",
   finessju: 490007226,
   id: 490541059,
   commune: "LOIRE AUTHION",
   cp: 49140,
   telephone: "241451500",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CRAON SANTE SERVICE",
   finessju: 530001320,
   id: 530030410,
   commune: "CRAON",
   cp: 53400,
   telephone: "243061377",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE CROCQFER",
   finessju: 440011369,
   id: 440016699,
   commune: "ST LYPHARD",
   cp: 44410,
   telephone: "240914319",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ABBARETZ",
   finessju: 440007748,
   id: 440014686,
   commune: "ABBARETZ",
   cp: 44170,
   telephone: "240870020",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ALLONNES",
   finessju: 490005147,
   id: 490532660,
   commune: "ALLONNES",
   cp: 49650,
   telephone: "241520007",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ANDARD",
   finessju: 490006962,
   id: 490536281,
   commune: "LOIRE AUTHION",
   cp: 49800,
   telephone: "241549634",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ANJOU",
   finessju: 490005659,
   id: 490533338,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ARCONNAY",
   finessju: 720003045,
   id: 720009919,
   commune: "ARCONNAY",
   cp: 72610,
   telephone: "233317897",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ATLANTIS",
   finessju: 440011187,
   id: 440016517,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240922028",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'AUBIGNY",
   finessju: 850004235,
   id: 850016288,
   commune: "AUBIGNY LES CLOUZEAUX",
   cp: 85430,
   telephone: "251987038",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'AVESSAC",
   finessju: 440008118,
   id: 440014751,
   commune: "AVESSAC",
   cp: 44460,
   telephone: "299910051",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'AZE",
   finessju: 530001171,
   id: 530030261,
   commune: "AZE",
   cp: 53200,
   telephone: "243072961",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ETIVAL LES LE MANS",
   finessju: 720003383,
   id: 720010263,
   commune: "ETIVAL LES LE MANS",
   cp: 72700,
   telephone: "243471201",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'EUROPE",
   finessju: 490005022,
   id: 490532546,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241436640",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'ISSE",
   finessju: 440010213,
   id: 440015584,
   commune: "ISSE",
   cp: 44520,
   telephone: "240551241",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'OUTRE-MAINE",
   finessju: 490004892,
   id: 490532413,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241887547",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'YVRE L'EVEQUE",
   finessju: 720005438,
   id: 720011014,
   commune: "YVRE L EVEQUE",
   cp: 72530,
   telephone: "243896029",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE D'YVRE LE POLIN",
   finessju: 720005420,
   id: 720011006,
   commune: "YVRE LE POLIN",
   cp: 72330,
   telephone: "243874404",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DABIN",
   finessju: 440021673,
   id: 440031466,
   commune: "VALLET",
   cp: 44330,
   telephone: "240363655",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAHERON",
   finessju: 850003732,
   id: 850016171,
   commune: "SOULLANS",
   cp: 85300,
   telephone: "251680140",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAHMANE",
   finessju: 720002773,
   id: 720009349,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243285082",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DALBY",
   finessju: 440006922,
   id: 440014199,
   commune: "NANTES",
   cp: 44000,
   telephone: "240741022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DALIGAULT",
   finessju: 720002963,
   id: 720009539,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243243509",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DALIGAUT - SAVIGNAC",
   finessju: 440010593,
   id: 440015964,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAMAR-LIMOUZINEAU",
   finessju: 490005485,
   id: 490533015,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241610933",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DANOT - JURQUET",
   finessju: 850001678,
   id: 850014762,
   commune: "LE CHAMP ST PERE",
   cp: 85540,
   telephone: "251409330",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAUDIBON",
   finessju: 720004639,
   id: 720010412,
   commune: "GUECELARD",
   cp: 72230,
   telephone: "243871101",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAULT-LE CONNETABLE",
   finessju: 490006830,
   id: 490534641,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241623151",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAUNEAU",
   finessju: 440009942,
   id: 440015444,
   commune: "GORGES",
   cp: 44190,
   telephone: "240069235",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DAVID-CAZALAS",
   finessju: 440018711,
   id: 440017572,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224610",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BAZOUGES",
   finessju: 530001197,
   id: 530030287,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243071782",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BEAULIEU",
   finessju: 440006732,
   id: 440014009,
   commune: "NANTES",
   cp: 44200,
   telephone: "240474847",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BENET",
   finessju: 850004821,
   id: 850023920,
   commune: "BENET",
   cp: 85490,
   telephone: "251009310",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BOUCHEMAINE",
   finessju: 490005311,
   id: 490532843,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241771011",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BOULOIRE",
   finessju: 720003177,
   id: 720010040,
   commune: "BOULOIRE",
   cp: 72440,
   telephone: "243354042",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BOUZILLE",
   finessju: 490007036,
   id: 490536430,
   commune: "OREE D ANJOU",
   cp: 49530,
   telephone: "240981004",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BRAINS",
   finessju: 440019073,
   id: 440023984,
   commune: "BRAINS",
   cp: 44830,
   telephone: "240326140",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE BRULON",
   finessju: 720003193,
   id: 720010065,
   commune: "BRULON",
   cp: 72350,
   telephone: "243956023",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CHAMPAGNE",
   finessju: 850001660,
   id: 850014754,
   commune: "CHAMPAGNE LES MARAIS",
   cp: 85450,
   telephone: "251566483",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CHAMPIGNE",
   finessju: 490005436,
   id: 490532967,
   commune: "LES HAUTS D ANJOU",
   cp: 49330,
   telephone: "241420026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CHAUDRON",
   finessju: 490006939,
   id: 490536240,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241701027",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CHAUVE",
   finessju: 440018778,
   id: 440018190,
   commune: "CHAUVE",
   cp: 44320,
   telephone: "240828242",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CHEMAZE",
   finessju: 530001296,
   id: 530030386,
   commune: "CHEMAZE",
   cp: 53200,
   telephone: "243704327",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CORCOUE",
   finessju: 440009363,
   id: 440015261,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "240058691",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE CUILLE",
   finessju: 530001353,
   id: 530030444,
   commune: "CUILLE",
   cp: 53540,
   telephone: "243065319",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE DOLLON",
   finessju: 720003359,
   id: 720010230,
   commune: "DOLLON",
   cp: 72390,
   telephone: "243934222",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE FOURCHES",
   finessju: 530001700,
   id: 530030790,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243680257",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE FROMENTINE",
   finessju: 850001439,
   id: 850014523,
   commune: "LA BARRE DE MONTS",
   cp: 85550,
   telephone: "251685023",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE GASTINES",
   finessju: 720005164,
   id: 720010743,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950027",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE GAZONFIER",
   finessju: 720002799,
   id: 720009364,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243861385",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE GESVRINE",
   finessju: 440009066,
   id: 440015139,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240935106",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE JALLAIS",
   finessju: 490005675,
   id: 490533353,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49510,
   telephone: "241641013",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ABBAYE",
   finessju: 850002619,
   id: 850015462,
   commune: "NIEUL SUR L AUTISE",
   cp: 85240,
   telephone: "251524518",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ANILLE",
   finessju: 720005214,
   id: 720010792,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243350222",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ANJOU",
   finessju: 530001270,
   id: 530030360,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243704476",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'AUBANCE",
   finessju: 490005378,
   id: 490532900,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241912215",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'AUTHION",
   finessju: 490005337,
   id: 490532868,
   commune: "LOIRE AUTHION",
   cp: 49800,
   telephone: "241804355",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'AVORT",
   finessju: 490005642,
   id: 490533320,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "241518137",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'EGLISE",
   finessju: 440010718,
   id: 440016095,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820037",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'EGLISE",
   finessju: 440021137,
   id: 440026813,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "240045945",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'EGLISE",
   finessju: 720005396,
   id: 720010974,
   commune: "TUFFE VAL DE LA CHERONNE",
   cp: 72160,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ERAUDIERE",
   finessju: 440007367,
   id: 440014587,
   commune: "NANTES",
   cp: 44300,
   telephone: "240496728",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ERDRE",
   finessju: 440007029,
   id: 440014298,
   commune: "NANTES",
   cp: 44000,
   telephone: "240740226",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ESPLANADE",
   finessju: 490005188,
   id: 490532702,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241330404",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'EUROPE",
   finessju: 440012995,
   id: 440017218,
   commune: "SUCE SUR ERDRE",
   cp: 44240,
   telephone: "240779600",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'EUROPE",
   finessju: 720003326,
   id: 720010206,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243811973",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'HERMENAULT",
   finessju: 850004409,
   id: 850016346,
   commune: "L HERMENAULT",
   cp: 85570,
   telephone: "251001106",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'HORLOGE",
   finessju: 490007184,
   id: 490537669,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241731533",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'HOTEL DE VILLE",
   finessju: 530001304,
   id: 530030394,
   commune: "COSSE LE VIVIEN",
   cp: 53230,
   telephone: "243989080",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'HOTEL DE VILLE",
   finessju: 850003864,
   id: 850016205,
   commune: "LA TRANCHE SUR MER",
   cp: 85360,
   telephone: "251304751",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'HUISSERIE",
   finessju: 530001478,
   id: 530030568,
   commune: "L HUISSERIE",
   cp: 53970,
   telephone: "243696161",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ILE",
   finessju: 440007656,
   id: 440014652,
   commune: "NANTES",
   cp: 44200,
   telephone: "240697459",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ILE",
   finessju: 720005180,
   id: 720010768,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950008",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'ISAC",
   finessju: 440008670,
   id: 440014926,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240878787",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'OCEAN",
   finessju: 440011674,
   id: 440016798,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240664654",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'OCEAN",
   finessju: 850001546,
   id: 850014630,
   commune: "BREM SUR MER",
   cp: 85470,
   telephone: "251905786",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'OCEAN",
   finessju: 850002247,
   id: 850015215,
   commune: "JARD SUR MER",
   cp: 85520,
   telephone: "251334297",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE L'UNIVERSITE",
   finessju: 720013028,
   id: 720009158,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BAIE",
   finessju: 440010775,
   id: 440016152,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240611090",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BASILIQUE",
   finessju: 530001510,
   id: 530030600,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537059",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BEAUJOIRE",
   finessju: 440006757,
   id: 440014025,
   commune: "NANTES",
   cp: 44300,
   telephone: "240303511",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BERNERIE",
   finessju: 440008472,
   id: 440014900,
   commune: "LA BERNERIE EN RETZ",
   cp: 44760,
   telephone: "240827066",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BOTTIERE",
   finessju: 440006765,
   id: 440014033,
   commune: "NANTES",
   cp: 44300,
   telephone: "240492956",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BOURGEONNIERE",
   finessju: 440006435,
   id: 440013696,
   commune: "NANTES",
   cp: 44300,
   telephone: "240764052",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BOUSSOLE",
   finessju: 850001744,
   id: 850014838,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251951874",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BRIERE",
   finessju: 440011047,
   id: 440016368,
   commune: "ST ANDRE DES EAUX",
   cp: 44117,
   telephone: "240012454",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BUGALLIERE",
   finessju: 440010510,
   id: 440015881,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240947727",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA BUTTE",
   finessju: 720002740,
   id: 720009315,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243854194",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA CHASSE ROYALE",
   finessju: 720002880,
   id: 720009455,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243282738",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA CHESNAIE",
   finessju: 490006061,
   id: 490533742,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241448080",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA CHOLIERE",
   finessju: 440010528,
   id: 440015899,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240633768",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA COLMONT",
   finessju: 530001445,
   id: 530030535,
   commune: "GORRON",
   cp: 53120,
   telephone: "243086304",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA CROIX BONNEAU",
   finessju: 440006476,
   id: 440013738,
   commune: "NANTES",
   cp: 44100,
   telephone: "240465432",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA CROIX DE REZE",
   finessju: 440011880,
   id: 440016871,
   commune: "REZE",
   cp: 44400,
   telephone: "240754900",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA DAUVERSIERE",
   finessju: 720004563,
   id: 720010370,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943219",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA DEZIERE",
   finessju: 490007028,
   id: 490536422,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241692860",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA FERRIERE",
   finessju: 440010502,
   id: 440015873,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240760760",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA FLEURIAYE",
   finessju: 440043222,
   id: 440043230,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240509970",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA FONTAINE",
   finessju: 440046662,
   id: 440046670,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240939277",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA FONTAINE Y.  MAIGNER",
   finessju: 530001429,
   id: 530030519,
   commune: "EVRON",
   cp: 53600,
   telephone: "243012500",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA FUTAIE",
   finessju: 530001825,
   id: 530030915,
   commune: "MONTAUDIN",
   cp: 53220,
   telephone: "243053068",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GARE",
   finessju: 440010767,
   id: 440016145,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240610552",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GARE",
   finessju: 490006343,
   id: 490534021,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241673679",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GARE",
   finessju: 490006863,
   id: 490534674,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241620312",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GARE",
   finessju: 850002825,
   id: 850015843,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251955623",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GARENNE",
   finessju: 850000704,
   id: 850015637,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371530",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GRAMMOIRE",
   finessju: 440042893,
   id: 440042901,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240800207",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GRANDE RUE",
   finessju: 720004977,
   id: 720010537,
   commune: "MAYET",
   cp: 72360,
   telephone: "243466037",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA GUILLEBOTTE",
   finessju: 490006723,
   id: 490534534,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241472815",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA JOUANNE",
   finessju: 530001833,
   id: 530030923,
   commune: "MONTSURS ST CENERE",
   cp: 53150,
   telephone: "243010026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA LIBERATION",
   finessju: 440009470,
   id: 440015295,
   commune: "COUERON",
   cp: 44220,
   telephone: "240383812",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA LOIRE",
   finessju: 490005410,
   id: 490532942,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MADELEINE",
   finessju: 440022143,
   id: 440032183,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240619584",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MADELEINE",
   finessju: 490004678,
   id: 490532199,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241665041",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MAIRIE",
   finessju: 440012656,
   id: 440017028,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240217034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MAIRIE",
   finessju: 440013472,
   id: 440017275,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "240946171",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MAIRIE",
   finessju: 490005717,
   id: 490533395,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241953120",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MARTELLIERE",
   finessju: 440012813,
   id: 440017085,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240331818",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MAYENNE",
   finessju: 530002187,
   id: 530032929,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243690003",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MILESSE",
   finessju: 720004985,
   id: 720010545,
   commune: "LA MILESSE",
   cp: 72650,
   telephone: "243253275",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA MITRIE",
   finessju: 440006450,
   id: 440013712,
   commune: "NANTES",
   cp: 44300,
   telephone: "240491700",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PAIX",
   finessju: 720003318,
   id: 720010198,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243810289",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PIRONNIERE",
   finessju: 850001108,
   id: 850020264,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251210646",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PLACE",
   finessju: 720004191,
   id: 720010305,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PLACE LECHAT",
   finessju: 440007219,
   id: 440014488,
   commune: "NANTES",
   cp: 44100,
   telephone: "240697188",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PLACE NEY",
   finessju: 490004793,
   id: 490532314,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241436475",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PLACE ZOLA",
   finessju: 440007086,
   id: 440014355,
   commune: "NANTES",
   cp: 44100,
   telephone: "240468182",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PLANCHE",
   finessju: 440010635,
   id: 440016004,
   commune: "LA PLANCHE",
   cp: 44140,
   telephone: "240319245",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA POINTE - BATIGNOLLES",
   finessju: 720017367,
   id: 720009422,
   commune: "LE MANS",
   cp: 72100,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA POSTE",
   finessju: 440011781,
   id: 440016830,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224615",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA POSTE",
   finessju: 850001710,
   id: 850014804,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943073",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA POSTE",
   finessju: 850000852,
   id: 850015785,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251622320",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PREFECTURE",
   finessju: 530001668,
   id: 530030758,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537692",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PRESQU'ILE",
   finessju: 440010338,
   id: 440015709,
   commune: "MESQUER",
   cp: 44420,
   telephone: "240426145",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA PRIERE",
   finessju: 440006526,
   id: 440013787,
   commune: "NANTES",
   cp: 44100,
   telephone: "240463011",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA REPUBLIQUE",
   finessju: 440006666,
   id: 440013928,
   commune: "NANTES",
   cp: 44200,
   telephone: "240890126",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA RIVE",
   finessju: 850009697,
   id: 850009705,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "228120730",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA ROCADE",
   finessju: 720002815,
   id: 720009380,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243843417",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA ROE",
   finessju: 490004983,
   id: 490532504,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241874728",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA ROSERAIE",
   finessju: 490005030,
   id: 490532553,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241665786",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA ROSERAIE",
   finessju: 530001502,
   id: 530030592,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243047447",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA SOURCE",
   finessju: 720005347,
   id: 720010925,
   commune: "SOUGE LE GANELON",
   cp: 72130,
   telephone: "243973009",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA TORTIERE",
   finessju: 440006906,
   id: 440014173,
   commune: "NANTES",
   cp: 44300,
   telephone: "240747741",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA TREBALE",
   finessju: 440011872,
   id: 440016863,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240703380",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA VALLEE",
   finessju: 850000662,
   id: 850014457,
   commune: "L AIGUILLON SUR VIE",
   cp: 85220,
   telephone: "251228451",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA VENDEE",
   finessju: 850001967,
   id: 850015058,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690323",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LA VICTOIRE",
   finessju: 440013951,
   id: 440017358,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240983302",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LIRE",
   finessju: 490007143,
   id: 490537545,
   commune: "OREE D ANJOU",
   cp: 49530,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE LONGCHAMP",
   finessju: 440007359,
   id: 440014579,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MALAKOFF",
   finessju: 440007177,
   id: 440014447,
   commune: "NANTES",
   cp: 44000,
   telephone: "240475337",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MALVILLE",
   finessju: 440010296,
   id: 440015667,
   commune: "MALVILLE",
   cp: 44260,
   telephone: "240564458",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MAROLLES",
   finessju: 720004969,
   id: 720010529,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974042",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MARTIGNE",
   finessju: 530001734,
   id: 530030824,
   commune: "MARTIGNE SUR MAYENNE",
   cp: 53470,
   telephone: "243025367",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MAUPERTUIS",
   finessju: 720002849,
   id: 720009414,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243816029",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MAUVES",
   finessju: 440010304,
   id: 440015675,
   commune: "MAUVES SUR LOIRE",
   cp: 44470,
   telephone: "240255034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MELLINET",
   finessju: 440006500,
   id: 440013761,
   commune: "NANTES",
   cp: 44100,
   telephone: "240203592",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MINDIN",
   finessju: 440020956,
   id: 440026276,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240271843",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MIRE",
   finessju: 490005865,
   id: 490533544,
   commune: "MIRE",
   cp: 49330,
   telephone: "241328097",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MONTBAZON",
   finessju: 440018125,
   id: 440017499,
   commune: "NANTES",
   cp: 44300,
   telephone: "240497153",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MONTREUX",
   finessju: 720005156,
   id: 720010735,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE MOUCHAMPS",
   finessju: 850002544,
   id: 850015397,
   commune: "MOUCHAMPS",
   cp: 85640,
   telephone: "251662366",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE NANTILLY",
   finessju: 490006376,
   id: 490534054,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241510513",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE NESMY",
   finessju: 850002593,
   id: 850015447,
   commune: "NESMY",
   cp: 85310,
   telephone: "251076553",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PARCE",
   finessju: 720005057,
   id: 720010628,
   commune: "PARCE SUR SARTHE",
   cp: 72300,
   telephone: "243953927",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PARIGNE",
   finessju: 720005065,
   id: 720010636,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243758007",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PARIS",
   finessju: 440008258,
   id: 440014850,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PAULX",
   finessju: 440010585,
   id: 440015956,
   commune: "PAULX",
   cp: 44270,
   telephone: "240260493",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PETIT MARS",
   finessju: 440010601,
   id: 440015972,
   commune: "PETIT MARS",
   cp: 44390,
   telephone: "240727308",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PONTLIEUE",
   finessju: 720002468,
   id: 720009034,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243842147",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PRECIGNE",
   finessju: 720005107,
   id: 720010677,
   commune: "PRECIGNE",
   cp: 72300,
   telephone: "243954012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE PREUX",
   finessju: 440011245,
   id: 440016574,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE ROUGE",
   finessju: 440011013,
   id: 440016335,
   commune: "ROUGE",
   cp: 44660,
   telephone: "240288527",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE RUILLE",
   finessju: 720005131,
   id: 720010719,
   commune: "LOIR EN VALLEE",
   cp: 72340,
   telephone: "243790422",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINT AIGNAN",
   finessju: 440011039,
   id: 440016350,
   commune: "ST AIGNAN GRANDLIEU",
   cp: 44860,
   telephone: "240264838",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINT COSME",
   finessju: 720005230,
   id: 720010818,
   commune: "ST COSME EN VAIRAIS",
   cp: 72110,
   telephone: "243975915",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINT JOSEPH",
   finessju: 440018752,
   id: 440018158,
   commune: "NANTES",
   cp: 44300,
   telephone: "240301000",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINT MARC SUR MER",
   finessju: 440011450,
   id: 440016756,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240910905",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINT PAVACE",
   finessju: 720005628,
   id: 720013952,
   commune: "ST PAVACE",
   cp: 72190,
   telephone: "243760030",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINT SATURNIN",
   finessju: 720014984,
   id: 720015007,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243259259",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINTE JAMME SUR SARTHE",
   finessju: 720005255,
   id: 720010834,
   commune: "STE JAMME SUR SARTHE",
   cp: 72380,
   telephone: "243276040",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SAINTE MARIE",
   finessju: 440010692,
   id: 440016079,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820786",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SARGE LES LE MANS",
   finessju: 720005305,
   id: 720010883,
   commune: "SARGE LES LE MANS",
   cp: 72190,
   telephone: "243765110",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SEVRES",
   finessju: 440007185,
   id: 440014454,
   commune: "NANTES",
   cp: 44200,
   telephone: "240343897",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SILLE",
   finessju: 720005321,
   id: 720010909,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243201279",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE SION",
   finessju: 440012953,
   id: 440017176,
   commune: "SION LES MINES",
   cp: 44590,
   telephone: "240289510",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE ST LAMBERT DU LATTAY",
   finessju: 490006251,
   id: 490533932,
   commune: "VAL DU LAYON",
   cp: 49750,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE TALMONT",
   finessju: 850003757,
   id: 850016189,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "251906363",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE THARON PLAGE",
   finessju: 440011443,
   id: 440016731,
   commune: "ST MICHEL CHEF CHEF",
   cp: 44730,
   telephone: "240278227",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE THOUARCE",
   finessju: 490006525,
   id: 490534211,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "241541402",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE VENANSAULT",
   finessju: 850004037,
   id: 850016239,
   commune: "VENANSAULT",
   cp: 85190,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE VERDUN",
   finessju: 440007060,
   id: 440014330,
   commune: "NANTES",
   cp: 44000,
   telephone: "240476249",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DE VIGNEUX",
   finessju: 440017549,
   id: 440017416,
   commune: "VIGNEUX DE BRETAGNE",
   cp: 44360,
   telephone: "240571360",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DECOSSE",
   finessju: 850001850,
   id: 850014945,
   commune: "DOMPIERRE SUR YON",
   cp: 85170,
   telephone: "251075285",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DECOSSE - LAMBERT",
   finessju: 850002056,
   id: 850015124,
   commune: "LA GARNACHE",
   cp: 85710,
   telephone: "251681001",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DEDRON-VICCA",
   finessju: 440011336,
   id: 440016665,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240256076",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DELAIRE",
   finessju: 440012979,
   id: 440017192,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240044368",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DELDALLE",
   finessju: 530001692,
   id: 530030782,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243535440",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DELHOMMEAU",
   finessju: 440018414,
   id: 440017556,
   commune: "ST MICHEL CHEF CHEF",
   cp: 44730,
   telephone: "240278697",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DELIBES",
   finessju: 850001926,
   id: 850015017,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251572217",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DELPORTE",
   finessju: 850004789,
   id: 850023011,
   commune: "AUBIGNY LES CLOUZEAUX",
   cp: 85430,
   telephone: "251403449",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DEMANGE",
   finessju: 850002072,
   id: 850015140,
   commune: "LA GUERINIERE",
   cp: 85680,
   telephone: "251398151",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DENECHERE",
   finessju: 850004839,
   id: 850024027,
   commune: "TIFFAUGES",
   cp: 85130,
   telephone: "251657432",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DENIEUL",
   finessju: 440006674,
   id: 440013936,
   commune: "NANTES",
   cp: 44300,
   telephone: "240741721",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DENYSE-GABARD",
   finessju: 850004771,
   id: 850022930,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251577575",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DEQUESNE",
   finessju: 440012888,
   id: 440017135,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "240631713",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DER KHATCHADOURIAN",
   finessju: 850004649,
   id: 850016452,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251527829",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DEROCHE",
   finessju: 720005115,
   id: 720010685,
   commune: "ROEZE SUR SARTHE",
   cp: 72210,
   telephone: "243773999",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DEROUET-PILLET",
   finessju: 530001999,
   id: 530031087,
   commune: "STE SUZANNE ET CHAMMES",
   cp: 53270,
   telephone: "243014026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES 2 LACS",
   finessju: 490006517,
   id: 490534203,
   commune: "LA TESSOUALLE",
   cp: 49280,
   telephone: "241563418",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES 3 MOULINS",
   finessju: 440010858,
   id: 440016236,
   commune: "REZE",
   cp: 44400,
   telephone: "240756310",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ACACIAS",
   finessju: 850002361,
   id: 850015280,
   commune: "LUCON",
   cp: 85400,
   telephone: "251560220",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ALPES MANCELLES",
   finessju: 720004589,
   id: 720010388,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243972076",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ARCADES",
   finessju: 440010437,
   id: 440015808,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240794418",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ARCADES",
   finessju: 440018802,
   id: 440018216,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240850323",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES AVALOIRS",
   finessju: 530001973,
   id: 530031061,
   commune: "ST PIERRE DES NIDS",
   cp: 53370,
   telephone: "243035027",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES BANCHAIS",
   finessju: 490011566,
   id: 490011574,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241272710",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES BEAUX ARTS",
   finessju: 490004694,
   id: 490532215,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241877403",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES BROUZILS",
   finessju: 850001561,
   id: 850014655,
   commune: "LES BROUZILS",
   cp: 85260,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES CALINS",
   finessju: 490006806,
   id: 490534617,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241291025",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES CHAMPS",
   finessju: 490005295,
   id: 490532819,
   commune: "BECON LES GRANITS",
   cp: 49370,
   telephone: "241779009",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES CHATAIGNIERS",
   finessju: 440019099,
   id: 440024040,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240461348",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES CORDELIERS",
   finessju: 530001585,
   id: 530030675,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243668606",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES CaTEAUX",
   finessju: 490005253,
   id: 490532777,
   commune: "BEAULIEU SUR LAYON",
   cp: 49750,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES DEUX CLOCHERS",
   finessju: 490006616,
   id: 490534310,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241515007",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ECHOPPES",
   finessju: 440008712,
   id: 440014942,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "240654061",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ENFANTS NANTAIS",
   finessju: 440006484,
   id: 440013746,
   commune: "NANTES",
   cp: 44000,
   telephone: "240740440",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ERABLES",
   finessju: 490007077,
   id: 490536711,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241421010",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES FORGES",
   finessju: 440013688,
   id: 440017341,
   commune: "VALLET",
   cp: 44330,
   telephone: "240339275",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES GLYCINES",
   finessju: 850003690,
   id: 850016155,
   commune: "ST PHILBERT DE BOUAINE",
   cp: 85660,
   telephone: "251419233",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES GRANDS JARDINS",
   finessju: 530001809,
   id: 530030899,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243984015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES HALLES",
   finessju: 440007847,
   id: 440014702,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240830080",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES HALLES",
   finessju: 440009249,
   id: 440015253,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240540092",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES HALLES",
   finessju: 490004785,
   id: 490532306,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241248400",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES HALLES",
   finessju: 850000837,
   id: 850015769,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371044",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES HAUTES METAIRIES",
   finessju: 720003037,
   id: 720009901,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243808385",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES HAUTS QUARTIERS",
   finessju: 490006459,
   id: 490534146,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241511090",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES JACOBINS",
   finessju: 720002898,
   id: 720009463,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243284185",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES JARDINS",
   finessju: 440011658,
   id: 440016780,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240701912",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES JARDINS",
   finessju: 850003344,
   id: 850016049,
   commune: "ST HILAIRE DE LOULAY",
   cp: 85600,
   telephone: "251940836",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES JAULNIERES",
   finessju: 850001132,
   id: 850020876,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624696",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES JUSTICES",
   finessju: 490004926,
   id: 490532447,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241667557",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES LANCES",
   finessju: 720003201,
   id: 720010073,
   commune: "CHAMPAGNE",
   cp: 72470,
   telephone: "243892890",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES LANDES",
   finessju: 440008134,
   id: 440014777,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240060034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES LILAS",
   finessju: 720005479,
   id: 720011105,
   commune: "ROUILLON",
   cp: 72700,
   telephone: "243478100",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES MAHONIAS",
   finessju: 720002948,
   id: 720009513,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243844755",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES MAINES",
   finessju: 850002940,
   id: 850015967,
   commune: "ST GEORGES DE MONTAIGU",
   cp: 85600,
   telephone: "251420374",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES MOUETTES",
   finessju: 850011610,
   id: 850011628,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES MOULINS",
   finessju: 850002031,
   id: 850015108,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251691253",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ORMEAUX",
   finessju: 530002047,
   id: 530031293,
   commune: "MONTENAY",
   cp: 53500,
   telephone: "243051154",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PICTONS",
   finessju: 850002460,
   id: 850015314,
   commune: "MAILLEZAIS",
   cp: 85420,
   telephone: "251007128",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PINS",
   finessju: 440011062,
   id: 440016392,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240272034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PLAGES",
   finessju: 440011070,
   id: 440016400,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240272276",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PLANTES",
   finessju: 440009868,
   id: 440015402,
   commune: "FEGREAC",
   cp: 44460,
   telephone: "240912043",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PLANTES",
   finessju: 490005121,
   id: 490532645,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241336678",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PONTS",
   finessju: 440006690,
   id: 440013969,
   commune: "NANTES",
   cp: 44200,
   telephone: "240478048",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES PRESIDENTS",
   finessju: 850002783,
   id: 850015801,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251952193",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES RAINETTES",
   finessju: 440008977,
   id: 440015055,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240508818",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ROCHES",
   finessju: 490006889,
   id: 490534690,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241622286",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES ROGELINS",
   finessju: 490006608,
   id: 490534302,
   commune: "VARRAINS",
   cp: 49400,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES SALINES",
   finessju: 850002858,
   id: 850015876,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320272",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES SPORTS",
   finessju: 530003342,
   id: 530003367,
   commune: "EVRON",
   cp: 53600,
   telephone: "243017111",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES TANNEURS",
   finessju: 440007599,
   id: 440014637,
   commune: "NANTES",
   cp: 44000,
   telephone: "240471702",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES THEBAUDIERES",
   finessju: 440011203,
   id: 440016533,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240635903",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES THERMES",
   finessju: 530001379,
   id: 530030469,
   commune: "ENTRAMMES",
   cp: 53260,
   telephone: "243980188",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES THERMES",
   finessju: 850003724,
   id: 850016163,
   commune: "ST PIERRE DU CHEMIN",
   cp: 85120,
   telephone: "251517705",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES TROIS RIVIERES",
   finessju: 440019065,
   id: 440019164,
   commune: "SEVERAC",
   cp: 44530,
   telephone: "240887232",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES VIGNES",
   finessju: 440013605,
   id: 440017333,
   commune: "VALLET",
   cp: 44330,
   telephone: "240339259",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DES VIGNES",
   finessju: 440037372,
   id: 440037380,
   commune: "PONT ST MARTIN",
   cp: 44860,
   telephone: "240268397",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DESJOBERT",
   finessju: 530001262,
   id: 530030352,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243072183",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DESNOES",
   finessju: 490006947,
   id: 490536257,
   commune: "LA POSSONNIERE",
   cp: 49170,
   telephone: "241722026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DESPREZ-DUTE",
   finessju: 490007234,
   id: 490542776,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DETALLANTE",
   finessju: 490005915,
   id: 490533593,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241423043",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DEVINEAU-CHOLLET",
   finessju: 440007011,
   id: 440014280,
   commune: "NANTES",
   cp: 44100,
   telephone: "240464872",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DOSSMANN - FORCE",
   finessju: 440007383,
   id: 440014603,
   commune: "NANTES",
   cp: 44000,
   telephone: "240487736",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DOUET - GAZEAU",
   finessju: 440019149,
   id: 440024156,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240023535",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DRENO - HEINE",
   finessju: 530001916,
   id: 530031004,
   commune: "RENAZE",
   cp: 53800,
   telephone: "243064006",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DROUIN",
   finessju: 440008639,
   id: 440014918,
   commune: "BESNE",
   cp: 44160,
   telephone: "240013031",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DRUAIS",
   finessju: 440009553,
   id: 440015329,
   commune: "COUERON",
   cp: 44220,
   telephone: "240861020",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BIEN ETRE",
   finessju: 440010734,
   id: 440016111,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820278",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOCAGE",
   finessju: 530002146,
   id: 530032895,
   commune: "FOUGEROLLES DU PLESSIS",
   cp: 53190,
   telephone: "243055616",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOCAGE",
   finessju: 850004557,
   id: 850016353,
   commune: "MONTOURNAIS",
   cp: 85700,
   telephone: "251579230",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOIS DU ROY",
   finessju: 490005162,
   id: 490532686,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOIS JAUNI",
   finessju: 440008084,
   id: 440014728,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOULEVARD CAMUS",
   finessju: 490004918,
   id: 490532439,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241731696",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOULEVARD DU MASSACRE",
   finessju: 440011252,
   id: 440016582,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240761518",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOURG",
   finessju: 850000803,
   id: 850015736,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371781",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOURG DE PAILLE",
   finessju: 490005220,
   id: 490532744,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "241481117",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BOURNY",
   finessju: 530001601,
   id: 530030691,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243681625",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU BREIL",
   finessju: 720003185,
   id: 720010057,
   commune: "LE BREIL SUR MERIZE",
   cp: 72370,
   telephone: "243898319",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CANAL",
   finessju: 440017689,
   id: 440017424,
   commune: "ROUANS",
   cp: 44640,
   telephone: "240642855",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 440010403,
   id: 440015774,
   commune: "MONTOIR DE BRETAGNE",
   cp: 44550,
   telephone: "240886345",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 440010551,
   id: 440015923,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "240275013",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 440010791,
   id: 440016178,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240423136",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 490005386,
   id: 490532918,
   commune: "CANDE",
   cp: 49440,
   telephone: "241927372",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 490005808,
   id: 490533486,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "241638240",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 490005832,
   id: 490533510,
   commune: "MAZE MILON",
   cp: 49630,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 490006699,
   id: 490534393,
   commune: "VIVY",
   cp: 49680,
   telephone: "241525111",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 490007192,
   id: 490539053,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 530001411,
   id: 530030501,
   commune: "EVRON",
   cp: 53600,
   telephone: "243016776",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 530001742,
   id: 530030832,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243041691",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 720003250,
   id: 720010131,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 850001694,
   id: 850014788,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CENTRE",
   finessju: 850002726,
   id: 850015579,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251318022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHAMP DE  ROSES",
   finessju: 720012848,
   id: 720010438,
   commune: "LOUE",
   cp: 72540,
   telephone: "243884040",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHAMP DE FOIRE",
   finessju: 850002502,
   id: 850015355,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251940632",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHAPEAU DE GENDARME",
   finessju: 490005113,
   id: 490532637,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241667237",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHATEAU",
   finessju: 440010965,
   id: 440016301,
   commune: "REZE",
   cp: 44400,
   telephone: "240754567",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHATEAU",
   finessju: 490005345,
   id: 490532876,
   commune: "BREZE",
   cp: 49260,
   telephone: "241516866",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHATEAU",
   finessju: 850001413,
   id: 850014507,
   commune: "APREMONT",
   cp: 85220,
   telephone: "251557014",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHENE",
   finessju: 440021996,
   id: 440031672,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240803003",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHENE VERT",
   finessju: 440011161,
   id: 440016491,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240769238",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CHENE VERT",
   finessju: 720005560,
   id: 720013895,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243794278",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CLOCHER",
   finessju: 850004300,
   id: 850016304,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "251901678",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU COEUR DE VILLE",
   finessju: 490006160,
   id: 490533841,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241937010",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU COMMERCE",
   finessju: 490006624,
   id: 490534328,
   commune: "ERDRE EN ANJOU",
   cp: 49220,
   telephone: "241614145",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CONTI",
   finessju: 720004209,
   id: 720010313,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930025",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU CORMIER",
   finessju: 440012870,
   id: 440017127,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "240631190",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU DONJON",
   finessju: 850001447,
   id: 850014531,
   commune: "BAZOGES EN PAREDS",
   cp: 85390,
   telephone: "251512784",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU GOIS",
   finessju: 850001462,
   id: 850014556,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251687536",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU GRAND MAINE",
   finessju: 490006913,
   id: 490536125,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241733340",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU GRAND MARCHE",
   finessju: 490005204,
   id: 490532728,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241898107",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU GRAND PALAIS",
   finessju: 850001637,
   id: 850014721,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251931448",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU HAUT ANJOU",
   finessju: 490005725,
   id: 490533403,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241953053",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU LAC",
   finessju: 440012698,
   id: 440017044,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240787003",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU LAYON-MARTIGNE",
   finessju: 490005782,
   id: 490533460,
   commune: "TERRANJOU",
   cp: 49540,
   telephone: "241594259",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU LOIR",
   finessju: 720003102,
   id: 720009976,
   commune: "BAZOUGES CRE SUR LOIR",
   cp: 72200,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU LOUET",
   finessju: 490005949,
   id: 490533627,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "241577204",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU LYCEE",
   finessju: 490004819,
   id: 490532330,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241874409",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU LYS",
   finessju: 490005543,
   id: 490533221,
   commune: "CORON",
   cp: 49690,
   telephone: "241558179",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MAINE",
   finessju: 720005198,
   id: 720010776,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE",
   finessju: 440008175,
   id: 440014819,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602186",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE",
   finessju: 440010569,
   id: 440015931,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "240275025",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE",
   finessju: 440010742,
   id: 440016129,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240610694",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE",
   finessju: 490005741,
   id: 490533429,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241521055",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE",
   finessju: 720004274,
   id: 720010347,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940204",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE",
   finessju: 720005412,
   id: 720010990,
   commune: "VIBRAYE",
   cp: 72320,
   telephone: "243601840",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHe",
   finessju: 720005370,
   id: 720010958,
   commune: "LA SUZE SUR SARTHE",
   cp: 72210,
   telephone: "243773052",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MARCHE AUX HERBES",
   finessju: 850002999,
   id: 850016015,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251551055",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MENHIR",
   finessju: 850001421,
   id: 850014515,
   commune: "AVRILLE",
   cp: 85440,
   telephone: "251223206",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MIROIR",
   finessju: 720002658,
   id: 720009224,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243727556",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MOULIN",
   finessju: 440009181,
   id: 440015238,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240039744",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MOULIN ROUGE",
   finessju: 850000811,
   id: 850015744,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251360900",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU MOULINET",
   finessju: 850001686,
   id: 850014770,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943129",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU NOUVEAU BOURG",
   finessju: 440011179,
   id: 440016509,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240860221",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PARC",
   finessju: 490006152,
   id: 490533833,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241938086",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PAYS BLANC",
   finessju: 440010122,
   id: 440015527,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240249037",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PAYS DE RETZ",
   finessju: 440008936,
   id: 440015014,
   commune: "VILLENEUVE EN RETZ",
   cp: 44580,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PILORI",
   finessju: 440006971,
   id: 440014249,
   commune: "NANTES",
   cp: 44000,
   telephone: "240474835",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU POLE",
   finessju: 530001791,
   id: 530030881,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243000909",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PORT",
   finessju: 440009603,
   id: 440015345,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240230667",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PORT",
   finessju: 440010809,
   id: 440016186,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240423371",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PORT",
   finessju: 850000688,
   id: 850015199,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251583506",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PORTEAU",
   finessju: 850001975,
   id: 850015066,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251695104",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PRIEURE",
   finessju: 440012649,
   id: 440017010,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240217004",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PRIEURE",
   finessju: 720003086,
   id: 720009950,
   commune: "AUVERS LE HAMON",
   cp: 72300,
   telephone: "243951680",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU PRIEURe",
   finessju: 850002767,
   id: 850015611,
   commune: "ROCHESERVIERE",
   cp: 85620,
   telephone: "251065398",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU RALLIEMENT",
   finessju: 490004736,
   id: 490532256,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241876951",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU REMBLAI",
   finessju: 850002882,
   id: 850015900,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320681",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU ROND POINT DE PARIS",
   finessju: 440006492,
   id: 440013753,
   commune: "NANTES",
   cp: 44300,
   telephone: "240493228",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU ROND POINT DE VANNES",
   finessju: 440006708,
   id: 440013977,
   commune: "NANTES",
   cp: 44000,
   telephone: "240765282",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU SACRE COEUR",
   finessju: 490006756,
   id: 490534567,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU SILLON",
   finessju: 440011229,
   id: 440016558,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240634861",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU SQUARE",
   finessju: 530001460,
   id: 530030550,
   commune: "GREZ EN BOUERE",
   cp: 53290,
   telephone: "243705008",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU TERRE FORT",
   finessju: 850004730,
   id: 850019787,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251541163",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU THEATRE",
   finessju: 440007250,
   id: 440014520,
   commune: "NANTES",
   cp: 44000,
   telephone: "240693514",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU THEATRE",
   finessju: 530001650,
   id: 530030741,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537309",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU THEATRE",
   finessju: 720004944,
   id: 720010503,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976132",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VAL D'OR",
   finessju: 490005170,
   id: 490532694,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241693675",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VAL D'ORNAY",
   finessju: 850004672,
   id: 850019720,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251362430",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VAL DE LOIRE",
   finessju: 490006301,
   id: 490533981,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241570068",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VAL DE SARTHE",
   finessju: 720005362,
   id: 720010941,
   commune: "LA SUZE SUR SARTHE",
   cp: 72210,
   telephone: "243773187",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VIADUC",
   finessju: 720002930,
   id: 720009505,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243842162",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VIEUX DOULON",
   finessju: 440006914,
   id: 440014181,
   commune: "NANTES",
   cp: 44300,
   telephone: "240493726",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DU VILLARET",
   finessju: 720002542,
   id: 720009117,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243817924",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUBOIS",
   finessju: 850002635,
   id: 850015488,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251391337",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUBOIS PIGEANNE",
   finessju: 850002643,
   id: 850015496,
   commune: "NOTRE DAME DE MONTS",
   cp: 85690,
   telephone: "251597740",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUCHENE",
   finessju: 720004951,
   id: 720010511,
   commune: "MANSIGNE",
   cp: 72510,
   telephone: "243461046",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUDOIGNON",
   finessju: 490005329,
   id: 490532850,
   commune: "BRAIN SUR ALLONNES",
   cp: 49650,
   telephone: "241520414",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUFOUR - BUFFET - BESNIER",
   finessju: 530002054,
   id: 530032069,
   commune: "BONCHAMP LES LAVAL",
   cp: 53960,
   telephone: "243903939",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUMAS",
   finessju: 850000753,
   id: 850015686,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251370166",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUMOULIN",
   finessju: 530001122,
   id: 530030212,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243049584",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUMOULIN - MAURY - DUMOULIN",
   finessju: 850002536,
   id: 850015389,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251386012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUNAYE-LEBRUN",
   finessju: 490006269,
   id: 490533940,
   commune: "MAUGES SUR LOIRE",
   cp: 49290,
   telephone: "241781780",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUPIN",
   finessju: 850004623,
   id: 850016437,
   commune: "STE CECILE",
   cp: 85110,
   telephone: "251402097",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DURAND",
   finessju: 850001587,
   id: 850014671,
   commune: "LA CAILLERE ST HILAIRE",
   cp: 85410,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUVAL",
   finessju: 490006079,
   id: 490533759,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241924003",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUVAL",
   finessju: 720004837,
   id: 720010453,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243946030",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE DUVARD-TOUZE",
   finessju: 440010353,
   id: 440015725,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240076101",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE EFFALA",
   finessju: 720004480,
   id: 720010362,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940155",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ELIZE",
   finessju: 720017557,
   id: 720010727,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950025",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE EPELBAUM-BOUTTIER",
   finessju: 720002823,
   id: 720009398,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243283115",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ERMANN",
   finessju: 490005923,
   id: 490533601,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241301102",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ESPACE 23",
   finessju: 440011112,
   id: 440016442,
   commune: "ST GEREON",
   cp: 44150,
   telephone: "240830816",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ESPACE MARNE",
   finessju: 490006848,
   id: 490534658,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241621486",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ESPACE SANTE",
   finessju: 850001397,
   id: 850014481,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251946027",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ETIENNE-RAMBAUD",
   finessju: 720002633,
   id: 720009208,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243841136",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE EVEN",
   finessju: 440006682,
   id: 440013944,
   commune: "NANTES",
   cp: 44000,
   telephone: "240734578",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FABO-LALLEMAND",
   finessju: 440006534,
   id: 440013795,
   commune: "NANTES",
   cp: 44100,
   telephone: "240465310",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FAIVRE",
   finessju: 850000712,
   id: 850015645,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372421",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FAKHFAKH",
   finessju: 440046225,
   id: 440046233,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240803861",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FARDIN-GRASLON",
   finessju: 440008159,
   id: 440014793,
   commune: "INDRE",
   cp: 44610,
   telephone: "240860922",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FAUQUEUR-MERRIEN",
   finessju: 490006905,
   id: 490536117,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241690259",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FERON-COUSIN",
   finessju: 440006716,
   id: 440013985,
   commune: "NANTES",
   cp: 44200,
   telephone: "240345909",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FIEVRE-RAUTUREAU",
   finessju: 850002528,
   id: 850015371,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651222",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FILLOUX SANTE",
   finessju: 850001264,
   id: 850021718,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251668500",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FLOTTE",
   finessju: 720005040,
   id: 720010610,
   commune: "NOYEN SUR SARTHE",
   cp: 72430,
   telephone: "243957023",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FLOTTE-COUILLEAUX",
   finessju: 720005016,
   id: 720010578,
   commune: "MULSANNE",
   cp: 72230,
   telephone: "243420864",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FONTENAY",
   finessju: 490006038,
   id: 490533718,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FONTENEAU",
   finessju: 490006574,
   id: 490534278,
   commune: "TREMENTINES",
   cp: 49340,
   telephone: "241624724",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FORCADET",
   finessju: 490005824,
   id: 490533502,
   commune: "MAZE MILON",
   cp: 49630,
   telephone: "241806023",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FOUCAUD",
   finessju: 440006880,
   id: 440014157,
   commune: "NANTES",
   cp: 44000,
   telephone: "240470042",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FOUCQUIER",
   finessju: 440009660,
   id: 440015352,
   commune: "CROSSAC",
   cp: 44160,
   telephone: "240010061",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FRANCHOT-THOMAS",
   finessju: 440008183,
   id: 440014827,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240242839",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FREIZEFFOND",
   finessju: 440018117,
   id: 440017481,
   commune: "NANTES",
   cp: 44000,
   telephone: "240742651",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FRETEAULT-AGUILLE",
   finessju: 720005677,
   id: 720013994,
   commune: "THORIGNE SUR DUE",
   cp: 72160,
   telephone: "243890493",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE FREULON-PILLET",
   finessju: 490006715,
   id: 490534526,
   commune: "LE PLESSIS GRAMMOIRE",
   cp: 49124,
   telephone: "241767707",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GABORIAU-THOUZEAU",
   finessju: 850004755,
   id: 850022211,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251415324",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GAISNON",
   finessju: 720004621,
   id: 720010404,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243409053",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GALIEN",
   finessju: 490006475,
   id: 490534161,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241922308",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GALLAIS - GEFFRAY",
   finessju: 440009124,
   id: 440015196,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GANIVET-POTTIER",
   finessju: 720003219,
   id: 720010099,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243400316",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GARBACCIO",
   finessju: 440011690,
   id: 440016814,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224905",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GARDIES",
   finessju: 440011708,
   id: 440016822,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225614",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GARE ANGERS",
   finessju: 490004959,
   id: 490532470,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241876662",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GASTAL",
   finessju: 850002353,
   id: 850015272,
   commune: "LUCON",
   cp: 85400,
   telephone: "251562387",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GAUTIER",
   finessju: 720003110,
   id: 720009984,
   commune: "BEAUFAY",
   cp: 72110,
   telephone: "243293212",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GAUTRAIS",
   finessju: 440006807,
   id: 440014074,
   commune: "NANTES",
   cp: 44100,
   telephone: "240581716",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GEANT-GUILLOT",
   finessju: 440009934,
   id: 440015436,
   commune: "GETIGNE",
   cp: 44190,
   telephone: "240540263",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GEFFROY-MEANCE",
   finessju: 440037406,
   id: 440037414,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GELLARD",
   finessju: 440019016,
   id: 440018240,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240536117",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GENAUDEAU",
   finessju: 850003534,
   id: 850016122,
   commune: "ST MARTIN DES NOYERS",
   cp: 85140,
   telephone: "251078380",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GENDRON-MURAIL",
   finessju: 530001924,
   id: 530031012,
   commune: "ST AIGNAN SUR ROE",
   cp: 53390,
   telephone: "243065134",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GENEVOIS - AUGER",
   finessju: 850004631,
   id: 850016445,
   commune: "LA FERRIERE",
   cp: 85280,
   telephone: "251406022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GERGAUD",
   finessju: 440013522,
   id: 440017317,
   commune: "LA TURBALLE",
   cp: 44420,
   telephone: "240233012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GICQUEL-LECOMTE",
   finessju: 490006483,
   id: 490534179,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241921148",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIFFARD",
   finessju: 490005071,
   id: 490532595,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241882218",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIQUELLO - SICLON",
   finessju: 440011054,
   id: 440016384,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240272071",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIRARD",
   finessju: 440011088,
   id: 440016418,
   commune: "ST COLOMBAN",
   cp: 44310,
   telephone: "240058926",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIRARD - TERRIEN",
   finessju: 850003369,
   id: 850016056,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251543038",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIRAUD",
   finessju: 850002627,
   id: 850015470,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIRAULT",
   finessju: 490005964,
   id: 490533643,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241895025",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GIRAULT",
   finessju: 490006590,
   id: 490534294,
   commune: "VARENNES SUR LOIRE",
   cp: 49730,
   telephone: "241517228",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOBRON",
   finessju: 490004884,
   id: 490532405,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241874221",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GODARD",
   finessju: 490007044,
   id: 490536448,
   commune: "SEVREMOINE",
   cp: 49230,
   telephone: "241704433",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GODART-POLLET",
   finessju: 440012862,
   id: 440017119,
   commune: "ST VINCENT DES LANDES",
   cp: 44590,
   telephone: "240553814",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GODET",
   finessju: 850001918,
   id: 850015009,
   commune: "LA FERRIERE",
   cp: 85280,
   telephone: "251984141",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOETZ",
   finessju: 490005402,
   id: 490532934,
   commune: "CANTENAY EPINARD",
   cp: 49460,
   telephone: "241321083",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOILAUMANE",
   finessju: 440007045,
   id: 440014314,
   commune: "NANTES",
   cp: 44000,
   telephone: "240484898",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOINARD-BENOIT",
   finessju: 440018497,
   id: 440017564,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240702694",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GORE-RENAIS",
   finessju: 530001148,
   id: 530030238,
   commune: "ANDOUILLE",
   cp: 53240,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GORNAY-JOUET",
   finessju: 850002106,
   id: 850015165,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910050",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOUABAU - PIHOUEE",
   finessju: 490005691,
   id: 490533379,
   commune: "LES GARENNES SUR LOIRE",
   cp: 49610,
   telephone: "241919490",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOUBAULT GUICHARD",
   finessju: 440007102,
   id: 440014371,
   commune: "NANTES",
   cp: 44300,
   telephone: "240498545",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOUIN",
   finessju: 850004813,
   id: 850023730,
   commune: "L ILE D OLONNE",
   cp: 85340,
   telephone: "251331820",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOURDON",
   finessju: 440037422,
   id: 440037430,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251809683",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOUSSE-ASTOUL",
   finessju: 440006575,
   id: 440013837,
   commune: "NANTES",
   cp: 44000,
   telephone: "240202511",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GOUTE-MELCHER",
   finessju: 490005592,
   id: 490533270,
   commune: "DURTAL",
   cp: 49430,
   telephone: "241763033",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRAN",
   finessju: 850004383,
   id: 850016320,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRAND VAL",
   finessju: 440010452,
   id: 440015824,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240407166",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRANDE OUCHE",
   finessju: 440021483,
   id: 440029577,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "980373758",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRANET",
   finessju: 720003060,
   id: 720009935,
   commune: "ARNAGE",
   cp: 72230,
   telephone: "243211041",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRANET-HUNAULT",
   finessju: 720012889,
   id: 720010552,
   commune: "MONCE EN BELIN",
   cp: 72230,
   telephone: "243428422",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRENON-HUBERDEAU",
   finessju: 490005154,
   id: 490532678,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241565328",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRIMARD",
   finessju: 490006822,
   id: 490534633,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241625266",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GRIMAUD",
   finessju: 490006749,
   id: 490534559,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "241325821",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUERIN-BOBIN",
   finessju: 490004850,
   id: 490532371,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241875839",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUERIN-GUILLON",
   finessju: 850002320,
   id: 850015249,
   commune: "LONGEVILLE SUR MER",
   cp: 85560,
   telephone: "251333047",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUERIN-RIGAUD",
   finessju: 490005444,
   id: 490532975,
   commune: "CHAMPTOCE SUR LOIRE",
   cp: 49123,
   telephone: "241399111",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUICHAOUA",
   finessju: 440006864,
   id: 440014132,
   commune: "NANTES",
   cp: 44100,
   telephone: "240731245",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILBERT",
   finessju: 440009082,
   id: 440015154,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240720041",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILLARD-LIGNON",
   finessju: 440008969,
   id: 440015048,
   commune: "CAMPBON",
   cp: 44750,
   telephone: "240565579",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILLEMET",
   finessju: 440008274,
   id: 440014876,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILLEMET",
   finessju: 440022796,
   id: 440033918,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240824266",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILLEMET-JAFFRY",
   finessju: 440012920,
   id: 440017150,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569064",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILLEMOT-SAUVESTRE",
   finessju: 490006178,
   id: 490533858,
   commune: "SEVREMOINE",
   cp: 49230,
   telephone: "241704016",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILLER",
   finessju: 850002486,
   id: 850015330,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251305588",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUILMAULT-GALIVEL",
   finessju: 490005527,
   id: 490533205,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "241942180",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUINEBAULT",
   finessju: 490006434,
   id: 490534120,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502651",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUIOCHET - MADIER",
   finessju: 440010700,
   id: 440016087,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUIOT",
   finessju: 850001835,
   id: 850014929,
   commune: "CUGAND",
   cp: 85610,
   telephone: "251421523",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUITTET",
   finessju: 530001361,
   id: 530030451,
   commune: "DAON",
   cp: 53200,
   telephone: "243069101",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUITTON",
   finessju: 850004086,
   id: 850016247,
   commune: "LA VERRIE",
   cp: 85130,
   telephone: "251659221",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUIVARCH - BESNARD",
   finessju: 720003003,
   id: 720009877,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243807861",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUYON",
   finessju: 850002205,
   id: 850015181,
   commune: "L ILE D ELLE",
   cp: 85770,
   telephone: "251520225",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUYOT",
   finessju: 440010643,
   id: 440016012,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240796126",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE GUYOT OLIVIER",
   finessju: 490006400,
   id: 490534096,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502121",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HAIE-NICOLLEAU",
   finessju: 440007094,
   id: 440014363,
   commune: "NANTES",
   cp: 44300,
   telephone: "240400405",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HALGAND",
   finessju: 850001538,
   id: 850014622,
   commune: "BOURNEZEAU",
   cp: 85480,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HALLEY-BOIVINEAU",
   finessju: 850001405,
   id: 850014499,
   commune: "ANGLES",
   cp: 85750,
   telephone: "251975445",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HAMON",
   finessju: 490006640,
   id: 490534344,
   commune: "VEZINS",
   cp: 49340,
   telephone: "241644561",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HARDOUIN",
   finessju: 490006665,
   id: 490534369,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241758049",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HARIE",
   finessju: 440007292,
   id: 440014546,
   commune: "NANTES",
   cp: 44000,
   telephone: "240487435",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HAUDEBOURG  - LIBEAU",
   finessju: 850001470,
   id: 850014564,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251687003",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HAUDEBOURG - GUEZO",
   finessju: 490005394,
   id: 490532926,
   commune: "CANDE",
   cp: 49440,
   telephone: "241927043",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HAURAY",
   finessju: 440010197,
   id: 440015568,
   commune: "HERIC",
   cp: 44810,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HAVET",
   finessju: 490005279,
   id: 490532793,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630013",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HEES",
   finessju: 850001728,
   id: 850014812,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696330",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HEMONT",
   finessju: 720002435,
   id: 720009000,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243816282",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HENJAJULA",
   finessju: 530001940,
   id: 530031038,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243692555",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HENRI IV",
   finessju: 720004258,
   id: 720010321,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940041",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HERBRETEAU",
   finessju: 850004656,
   id: 850019704,
   commune: "BEAUREPAIRE",
   cp: 85500,
   telephone: "251673798",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HERVE",
   finessju: 530001866,
   id: 530030956,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688356",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HERY-FRIOUX",
   finessju: 440011906,
   id: 440016889,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240535019",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HETTE-TOP",
   finessju: 440010668,
   id: 440016046,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240016241",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HEULIN",
   finessju: 720002567,
   id: 720009133,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243280135",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HEULINOISE",
   finessju: 440009041,
   id: 440015113,
   commune: "LA CHAPELLE HEULIN",
   cp: 44330,
   telephone: "240067155",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HEUZE",
   finessju: 720002526,
   id: 720009091,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243283621",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HILLERITEAU",
   finessju: 850001900,
   id: 850014994,
   commune: "LE FENOUILLER",
   cp: 85800,
   telephone: "251552828",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HILLERITEAU - DRAPEAU",
   finessju: 850002080,
   id: 850015157,
   commune: "L HERBERGEMENT",
   cp: 85260,
   telephone: "251428507",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HILY",
   finessju: 440011401,
   id: 440016715,
   commune: "ST MARS DU DESERT",
   cp: 44850,
   telephone: "240774518",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HOCDE",
   finessju: 440010759,
   id: 440016137,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240610188",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HOCQUET-BEIGNET",
   finessju: 440011641,
   id: 440016772,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224649",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HUART",
   finessju: 530002179,
   id: 530032911,
   commune: "LANDIVY",
   cp: 53190,
   telephone: "243054242",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HUBERT - PAUVERT",
   finessju: 850001272,
   id: 850022567,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251328551",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HUET",
   finessju: 490006541,
   id: 490534237,
   commune: "SEVREMOINE",
   cp: 49660,
   telephone: "241466097",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HUMEAU",
   finessju: 490006236,
   id: 490533916,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241391239",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HURLUPE",
   finessju: 850002908,
   id: 850015926,
   commune: "ST DENIS LA CHEVASSE",
   cp: 85170,
   telephone: "251418144",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE HY-BENETEAU",
   finessju: 490006137,
   id: 490533817,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241553215",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE IKERIOKE-POIRIER",
   finessju: 490004942,
   id: 490532462,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241877647",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE IMBERT - BOYER",
   finessju: 440010494,
   id: 440015865,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240630731",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ISAURE",
   finessju: 720002732,
   id: 720009307,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243855818",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JADEAU",
   finessju: 850001983,
   id: 850015074,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251510440",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JARROSSAY-LE BIHAN",
   finessju: 440010239,
   id: 440015600,
   commune: "LEGE",
   cp: 44650,
   telephone: "240263040",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JAUNAIT-HERMOUET",
   finessju: 490007085,
   id: 490536729,
   commune: "ST MARTIN DU FOUILLOUX",
   cp: 49170,
   telephone: "241395455",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JEAN VILAR",
   finessju: 490004835,
   id: 490532355,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241662848",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JEANMAIRE",
   finessju: 850000738,
   id: 850015660,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251622492",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JOHAN",
   finessju: 530001213,
   id: 530030303,
   commune: "LE BOURGNEUF LA FORET",
   cp: 53410,
   telephone: "243377117",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JOUANDET",
   finessju: 440009074,
   id: 440015147,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240778015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JOURDAN-NEAU",
   finessju: 440011971,
   id: 440016905,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JOYAU - MEAS",
   finessju: 850002585,
   id: 850015439,
   commune: "NALLIERS",
   cp: 85370,
   telephone: "251307016",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JOYE-VAUTHIER",
   finessju: 530001486,
   id: 530030576,
   commune: "JUVIGNE",
   cp: 53380,
   telephone: "243685262",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JULES VERNE",
   finessju: 440006633,
   id: 440013894,
   commune: "NANTES",
   cp: 44300,
   telephone: "240497015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JURION LE NIVEZ",
   finessju: 530001932,
   id: 530031020,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243690178",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE JURION LE NIVEZ-LEBOSSE",
   finessju: 530005842,
   id: 530005867,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243660814",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE KER ANNA",
   finessju: 440008100,
   id: 440014744,
   commune: "ASSERAC",
   cp: 44410,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE KOENIG-FOGEL",
   finessju: 490004686,
   id: 490532207,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241343490",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE KOUASSI",
   finessju: 720003334,
   id: 720010214,
   commune: "COULANS SUR GEE",
   cp: 72550,
   telephone: "243888215",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE L'ARBORETUM",
   finessju: 490004728,
   id: 490532249,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241663336",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LA FAYETTE",
   finessju: 490005105,
   id: 490532629,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241876716",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LA MARLE",
   finessju: 530001957,
   id: 530031046,
   commune: "ST DENIS D ANJOU",
   cp: 53290,
   telephone: "243705217",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LA MOTTE VAUVERT",
   finessju: 530001288,
   id: 530030378,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LABARRIERE - CHEYMOL",
   finessju: 720005313,
   id: 720010891,
   commune: "SAVIGNE L EVEQUE",
   cp: 72460,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LABRUSSE",
   finessju: 440011633,
   id: 440016764,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224312",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LAFAYETTE DE LA PAIX",
   finessju: 530001544,
   id: 530030634,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243670876",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LAMBERT",
   finessju: 490005899,
   id: 490533577,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241523028",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LAMOTTE",
   finessju: 720005446,
   id: 720011071,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243401322",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LANGELOT",
   finessju: 440043024,
   id: 440043032,
   commune: "COUERON",
   cp: 44220,
   telephone: "251800800",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LANGLOIS",
   finessju: 490004876,
   id: 490532397,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241201657",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LAQUEUILLE",
   finessju: 440010544,
   id: 440015915,
   commune: "OUDON",
   cp: 44521,
   telephone: "240836150",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LARDEUX - ORAIN",
   finessju: 440010361,
   id: 440015733,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240656252",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LAVAL MURAT",
   finessju: 530001551,
   id: 530030642,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243491816",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LAVALLEE",
   finessju: 720003128,
   id: 720009992,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970009",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE BIGNON",
   finessju: 440018141,
   id: 440017507,
   commune: "LE BIGNON",
   cp: 44140,
   telephone: "240781363",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE BIHEN",
   finessju: 440013480,
   id: 440017283,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "240903972",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE BORGNE",
   finessju: 530001759,
   id: 530030840,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243041583",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE BOULANGER",
   finessju: 720005123,
   id: 720010701,
   commune: "RUAUDIN",
   cp: 72230,
   telephone: "243757320",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE BOULCH",
   finessju: 440010320,
   id: 440015691,
   commune: "MESANGER",
   cp: 44522,
   telephone: "240967012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE COZ",
   finessju: 720017342,
   id: 720009927,
   commune: "ARNAGE",
   cp: 72230,
   telephone: "243211038",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE DIVELEC-MAILLET",
   finessju: 440019552,
   id: 440024198,
   commune: "LE TEMPLE DE BRETAGNE",
   cp: 44360,
   telephone: "240570651",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE FLEM-VAUCHELET",
   finessju: 440022663,
   id: 440032829,
   commune: "ST AUBIN DES CHATEAUX",
   cp: 44110,
   telephone: "240284129",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE FORESTIER",
   finessju: 440007755,
   id: 440014694,
   commune: "AIGREFEUILLE SUR MAINE",
   cp: 44140,
   telephone: "240066105",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE GALL",
   finessju: 490004900,
   id: 490532421,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241874071",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE GUEN",
   finessju: 720004910,
   id: 720010479,
   commune: "MALICORNE SUR SARTHE",
   cp: 72270,
   telephone: "243948051",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE JOUBIOUX",
   finessju: 850003419,
   id: 850016064,
   commune: "ST HILAIRE DES LOGES",
   cp: 85240,
   telephone: "251521022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE NAOUR",
   finessju: 440010080,
   id: 440015519,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240609100",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE PEMP",
   finessju: 440009884,
   id: 440015410,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "240397407",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE RESTE",
   finessju: 440007235,
   id: 440014504,
   commune: "NANTES",
   cp: 44300,
   telephone: "240251580",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE ROL",
   finessju: 440013506,
   id: 440017291,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE SCRILL - CORBEL",
   finessju: 490005873,
   id: 490533551,
   commune: "SEVREMOINE",
   cp: 49230,
   telephone: "241647068",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LE VOURCH",
   finessju: 490006186,
   id: 490533866,
   commune: "MAUGES SUR LOIRE",
   cp: 49410,
   telephone: "241725013",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEBOEUF",
   finessju: 490007135,
   id: 490537453,
   commune: "LE COUDRAY MACOUARD",
   cp: 49260,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LECERF-JAILLET",
   finessju: 440009413,
   id: 440015279,
   commune: "CORDEMAIS",
   cp: 44360,
   telephone: "240578588",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEEUWS-GUILLOTON",
   finessju: 850001751,
   id: 850014846,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251325096",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEGAULT",
   finessju: 530001569,
   id: 530030659,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243537115",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEGRAND-COTTALORDA",
   finessju: 440020709,
   id: 440026201,
   commune: "MAISDON SUR SEVRE",
   cp: 44690,
   telephone: "240038929",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEGROS",
   finessju: 720003144,
   id: 720010016,
   commune: "BESSE SUR BRAYE",
   cp: 72310,
   telephone: "243353012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LELIEVRE",
   finessju: 440009686,
   id: 440015378,
   commune: "DONGES",
   cp: 44480,
   telephone: "240457031",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEMAIRE",
   finessju: 440007227,
   id: 440014496,
   commune: "NANTES",
   cp: 44300,
   telephone: "240405925",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEMARIE",
   finessju: 440007664,
   id: 440014660,
   commune: "NANTES",
   cp: 44200,
   telephone: "240755291",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEMEILLET - BARBIN",
   finessju: 440008092,
   id: 440014736,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213008",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEMERCIER - PINON",
   finessju: 720002765,
   id: 720009331,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243280356",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEMETAYER",
   finessju: 530001841,
   id: 530030931,
   commune: "OISSEAU",
   cp: 53300,
   telephone: "243001074",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEMOINE-COSSON",
   finessju: 440006856,
   id: 440014124,
   commune: "NANTES",
   cp: 44000,
   telephone: "240480048",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LENOBLE",
   finessju: 720005701,
   id: 720014455,
   commune: "LAIGNE EN BELIN",
   cp: 72220,
   telephone: "243234159",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEPAGE CARQUEFOU",
   finessju: 440037497,
   id: 440037505,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LERIVEREND-LANGOT",
   finessju: 720005537,
   id: 720013861,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243293032",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEROI",
   finessju: 720003169,
   id: 720010032,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243293008",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LES COU�TS",
   finessju: 440008910,
   id: 440014991,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240651396",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LES HAUTS DE SAINT AUBIN",
   finessju: 490004777,
   id: 490532298,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241875445",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LES OCEANES",
   finessju: 850001769,
   id: 850014853,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LESOIF-GRIMAULT",
   finessju: 530001965,
   id: 530031053,
   commune: "ST DENIS DE GASTINES",
   cp: 53500,
   telephone: "243005183",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LETOURNEUX",
   finessju: 440037323,
   id: 440037331,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240620000",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LEVASSEUR",
   finessju: 530002153,
   id: 530032903,
   commune: "JAVRON LES CHAPELLES",
   cp: 53250,
   telephone: "243034084",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LIBERTE",
   finessju: 850006313,
   id: 850006321,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251370857",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LIEMKEO",
   finessju: 490007069,
   id: 490536463,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241584341",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LIMOUSIN-LUSSEAU",
   finessju: 490007150,
   id: 490537552,
   commune: "LA ROMAGNE",
   cp: 49740,
   telephone: "241703272",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LLACUNA",
   finessju: 440018794,
   id: 440018208,
   commune: "STE ANNE SUR BRIVET",
   cp: 44160,
   telephone: "240669285",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LOCTEAU-OUVRARD",
   finessju: 850011792,
   id: 850019779,
   commune: "ST ETIENNE DU BOIS",
   cp: 85670,
   telephone: "251345181",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LOESCH-CAMUS",
   finessju: 440037307,
   id: 440037315,
   commune: "LE GAVRE",
   cp: 44130,
   telephone: "240512500",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LORETAINE",
   finessju: 490005774,
   id: 490533452,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241774180",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LOVICONI",
   finessju: 440006567,
   id: 440013829,
   commune: "NANTES",
   cp: 44300,
   telephone: "240504905",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LUCAS-CARRIO",
   finessju: 440011963,
   id: 440016897,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225690",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE LUQUET DE SAINT GERMAIN",
   finessju: 530001387,
   id: 530030477,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243051884",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MA CAMPAGNE",
   finessju: 490005477,
   id: 490533007,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "241694847",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MACE",
   finessju: 490007242,
   id: 490543071,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "241488221",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAECHLER-BRIDGE",
   finessju: 490005139,
   id: 490532652,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241877206",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAGGI",
   finessju: 720005453,
   id: 720011089,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243710284",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAHEU",
   finessju: 720002831,
   id: 720009406,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243816735",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAILLIART",
   finessju: 720004928,
   id: 720010487,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976092",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAISON-COLLIN",
   finessju: 440010312,
   id: 440015683,
   commune: "LA MEILLERAYE DE BRETAGNE",
   cp: 44520,
   telephone: "240552177",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MALGARINI",
   finessju: 850001488,
   id: 850014572,
   commune: "BELLEVIGNY",
   cp: 85170,
   telephone: "251411136",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MALHERRE",
   finessju: 440019172,
   id: 440024172,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240720005",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MALOUINE",
   finessju: 440011377,
   id: 440016707,
   commune: "ST MALO DE GUERSAC",
   cp: 44550,
   telephone: "240911413",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAMMIFERI - TAPON",
   finessju: 720003284,
   id: 720010164,
   commune: "CONLIE",
   cp: 72240,
   telephone: "243205042",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARCHAND",
   finessju: 490005360,
   id: 490532892,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241912228",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARCHAND P.",
   finessju: 850003880,
   id: 850016213,
   commune: "LA TRANCHE SUR MER",
   cp: 85360,
   telephone: "251303898",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARCHAND SANDRINE",
   finessju: 850003591,
   id: 850016148,
   commune: "ST MICHEL EN L HERM",
   cp: 85580,
   telephone: "251302019",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARINE",
   finessju: 720003367,
   id: 720010248,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARINE",
   finessju: 850001876,
   id: 850014960,
   commune: "L EPINE",
   cp: 85740,
   telephone: "251393045",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARTIN-TOUTIN",
   finessju: 720005388,
   id: 720010966,
   commune: "TELOCHE",
   cp: 72220,
   telephone: "243420029",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MARTY",
   finessju: 490006053,
   id: 490533734,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241446043",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MASEK",
   finessju: 440011120,
   id: 440016459,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "240014030",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MASSIEUX",
   finessju: 850002676,
   id: 850015520,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251951508",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MASSON - BELLION",
   finessju: 490006350,
   id: 490534039,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241511007",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MATHEVET",
   finessju: 850001793,
   id: 850014887,
   commune: "CHAVAGNES EN PAILLERS",
   cp: 85250,
   telephone: "251982017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MATHIEU - PRENEAU",
   finessju: 440006823,
   id: 440014090,
   commune: "NANTES",
   cp: 44100,
   telephone: "240462278",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAUFFREY",
   finessju: 850004201,
   id: 850016262,
   commune: "VOUVANT",
   cp: 85120,
   telephone: "251008015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAUPETIT-LE FLOC'H",
   finessju: 440009496,
   id: 440015303,
   commune: "COUERON",
   cp: 44220,
   telephone: "240383559",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAUQUEST",
   finessju: 490007010,
   id: 490536414,
   commune: "ST LAMBERT LA POTHERIE",
   cp: 49070,
   telephone: "241775660",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAURICE-BELOEIL",
   finessju: 850004706,
   id: 850019753,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251392355",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MAZILLER",
   finessju: 440006948,
   id: 440014215,
   commune: "NANTES",
   cp: 44000,
   telephone: "240202690",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MEDICIS",
   finessju: 720002989,
   id: 720009851,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243804804",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MENAGER",
   finessju: 720005354,
   id: 720010933,
   commune: "SPAY",
   cp: 72700,
   telephone: "243219626",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MENDES",
   finessju: 850004110,
   id: 850016254,
   commune: "VIX",
   cp: 85770,
   telephone: "251006033",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MENEUX",
   finessju: 440009199,
   id: 440015246,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240540059",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MERCERON",
   finessju: 440009132,
   id: 440015204,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810035",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MERCIER",
   finessju: 720003029,
   id: 720009893,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243807707",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MERCIER",
   finessju: 850002866,
   id: 850015884,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320476",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MEZERETTE",
   finessju: 440010023,
   id: 440015469,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MEZIERE - CHEPEAUX",
   finessju: 440011005,
   id: 440016327,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "240978423",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MICHARD-ERRARD",
   finessju: 490006145,
   id: 490533825,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241938730",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MIGNEN - CHARBEAUX",
   finessju: 440012524,
   id: 440017002,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240024107",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MILLET",
   finessju: 440007326,
   id: 440014553,
   commune: "NANTES",
   cp: 44200,
   telephone: "240343905",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MINOZA-GENTIL",
   finessju: 850004607,
   id: 850016411,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251660099",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MISLER",
   finessju: 440011344,
   id: 440016673,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240256287",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MOJTABI",
   finessju: 490004363,
   id: 490540648,
   commune: "BEGROLLES EN MAUGES",
   cp: 49122,
   telephone: "241631656",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MONIER-LEBOUCHER",
   finessju: 530001890,
   id: 530030980,
   commune: "QUELAINES ST GAULT",
   cp: 53360,
   telephone: "243985613",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MONNIER - MAURY",
   finessju: 440012110,
   id: 440016921,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240224808",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MONTESQUIEU",
   finessju: 490005006,
   id: 490532520,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241482319",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MOQUET",
   finessju: 440010650,
   id: 440016038,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240016094",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MORAND",
   finessju: 490005766,
   id: 490533445,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241521390",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MOREL",
   finessju: 490006681,
   id: 490534385,
   commune: "VILLEVEQUE",
   cp: 49140,
   telephone: "241695120",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MORIN",
   finessju: 720017466,
   id: 720011097,
   commune: "NEUFCHATEL EN SAOSNOIS",
   cp: 72600,
   telephone: "243333673",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MORIN-LAVIGNE",
   finessju: 490006020,
   id: 490533700,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241777448",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MOTTE BRIEC",
   finessju: 850002122,
   id: 850015173,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251911891",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MOUGIN",
   finessju: 440020964,
   id: 440026789,
   commune: "MOUZILLON",
   cp: 44330,
   telephone: "240362051",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MOUSNIER",
   finessju: 490005089,
   id: 490532603,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241482283",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MULLER",
   finessju: 440040525,
   id: 440040533,
   commune: "ST MOLF",
   cp: 44350,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MURAIL",
   finessju: 490006673,
   id: 490534377,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49450,
   telephone: "241309079",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MURIER",
   finessju: 490006871,
   id: 490534682,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241625813",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE NAHAN",
   finessju: 440011096,
   id: 440016426,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240869061",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE NAULET",
   finessju: 850003005,
   id: 850016023,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251273663",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE NEAU",
   finessju: 490005998,
   id: 490533676,
   commune: "LYS HAUT LAYON",
   cp: 49560,
   telephone: "241595056",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE NICOLLEAU - DILE",
   finessju: 490005501,
   id: 490533031,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241307578",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE NOTRE DAME",
   finessju: 530001130,
   id: 530030220,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243088018",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OCEANE",
   finessju: 850004698,
   id: 850019746,
   commune: "LA FAUTE SUR MER",
   cp: 85460,
   telephone: "251970808",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OCEANIS",
   finessju: 440011815,
   id: 440016848,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240703631",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OLIVEIRA",
   finessju: 440008746,
   id: 440014959,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "240654068",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OLIVIER-MOUCHE",
   finessju: 440010817,
   id: 440016194,
   commune: "PREFAILLES",
   cp: 44770,
   telephone: "240216565",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OLLIVIER",
   finessju: 440021657,
   id: 440030153,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240421510",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OLONA",
   finessju: 850002874,
   id: 850015892,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251211343",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ORIO-MAZERAT",
   finessju: 440013530,
   id: 440017325,
   commune: "LA TURBALLE",
   cp: 44420,
   telephone: "240234015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OSSELIN",
   finessju: 440008928,
   id: 440015006,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320599",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OUVRARD",
   finessju: 850004599,
   id: 850016403,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404050",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE OUVRARD-MASSON",
   finessju: 440010015,
   id: 440015451,
   commune: "GRANDCHAMPS DES FONTAINES",
   cp: 44119,
   telephone: "240771414",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PABOEUF-LEBOEUF",
   finessju: 440008282,
   id: 440014884,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240240081",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PACAUD",
   finessju: 440029759,
   id: 440030039,
   commune: "ST MARS DE COUTAIS",
   cp: 44680,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PACAUD-HUGE",
   finessju: 850004797,
   id: 850023698,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251328736",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PAGEOT-GUILLET",
   finessju: 440018364,
   id: 440017523,
   commune: "COUERON",
   cp: 44220,
   telephone: "240861050",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PAPIN - EL KOURI",
   finessju: 440010478,
   id: 440015840,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240768418",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PAQUEREAU",
   finessju: 530002104,
   id: 530032879,
   commune: "LA BACONNIERE",
   cp: 53240,
   telephone: "243026310",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PARERE",
   finessju: 440010841,
   id: 440016228,
   commune: "REZE",
   cp: 44400,
   telephone: "240754546",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PARIDIS",
   finessju: 440019826,
   id: 440025849,
   commune: "NANTES CEDEX 3",
   cp: 44305,
   telephone: "240939715",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PARIS",
   finessju: 530001346,
   id: 530030436,
   commune: "CRAON",
   cp: 53400,
   telephone: "243061172",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PARIS'S",
   finessju: 530001247,
   id: 530030337,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243071110",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PASQUIER",
   finessju: 850002742,
   id: 850015595,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PASQUIER-LALANNE",
   finessju: 490005816,
   id: 490533494,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "241638012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PASTEUR",
   finessju: 440007151,
   id: 440014421,
   commune: "NANTES",
   cp: 44100,
   telephone: "240732105",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PASTEUR",
   finessju: 440010189,
   id: 440015550,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "240889007",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PASTEUR",
   finessju: 490004744,
   id: 490532264,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241436969",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PAUL - TRANCART",
   finessju: 440013043,
   id: 440017226,
   commune: "SUCE SUR ERDRE",
   cp: 44240,
   telephone: "240777016",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PAVAGEAU",
   finessju: 850004243,
   id: 850016296,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251687417",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PELLOIS - JOUDON",
   finessju: 440009058,
   id: 440015121,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PENDU",
   finessju: 850001579,
   id: 850014663,
   commune: "LA BRUFFIERE",
   cp: 85530,
   telephone: "251425281",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PENEAU",
   finessju: 440009504,
   id: 440015311,
   commune: "COUERON",
   cp: 44220,
   telephone: "240861632",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PENFORNIS - BURBAN",
   finessju: 440011138,
   id: 440016467,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240462705",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PENN-AUFFRET",
   finessju: 530001221,
   id: 530030311,
   commune: "CHAILLAND",
   cp: 53420,
   telephone: "243027033",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PEPION-NOURY",
   finessju: 440019727,
   id: 440024206,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240527036",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PERAUDEAU - GUILBAUD",
   finessju: 850001645,
   id: 850014739,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251682327",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PERIDY-GABARD",
   finessju: 850003542,
   id: 850016130,
   commune: "ST MESMIN",
   cp: 85700,
   telephone: "251919758",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PERIER",
   finessju: 440021335,
   id: 440027019,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "240036667",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PERINEAU - FABRE",
   finessju: 440010866,
   id: 440016244,
   commune: "REZE",
   cp: 44400,
   telephone: "240754899",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PERRIN-ACCAOUI",
   finessju: 490006319,
   id: 490533999,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241757016",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PETARD",
   finessju: 440010411,
   id: 440015782,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240722030",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PETER",
   finessju: 440012938,
   id: 440017168,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PETIT",
   finessju: 490006632,
   id: 490534336,
   commune: "VERNOIL LE FOURRIER",
   cp: 49390,
   telephone: "241515128",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PETITGAS",
   finessju: 440006963,
   id: 440014231,
   commune: "NANTES",
   cp: 44300,
   telephone: "240495331",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PETORIN",
   finessju: 440010379,
   id: 440015741,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240656004",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PHILIPPE",
   finessju: 440008266,
   id: 440014868,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602036",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PIAU",
   finessju: 440021509,
   id: 440029585,
   commune: "LA CHEVROLIERE",
   cp: 44118,
   telephone: "240043557",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PIERRE HIDREAU",
   finessju: 850001942,
   id: 850015033,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690816",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PINCON",
   finessju: 530001239,
   id: 530030329,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243535797",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PLACE BICHON",
   finessju: 490004868,
   id: 490532389,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241482872",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PLAISANCE",
   finessju: 440010460,
   id: 440015832,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240766501",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PLANCHET",
   finessju: 490004769,
   id: 490532280,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241436397",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POINCARE",
   finessju: 440007193,
   id: 440014462,
   commune: "NANTES",
   cp: 44100,
   telephone: "240461206",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POINCELET - BOCHER",
   finessju: 850001157,
   id: 850021007,
   commune: "LA VERRIE",
   cp: 85130,
   telephone: "251659405",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POIRIER",
   finessju: 490005287,
   id: 490532801,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630014",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POITEVIN-ANGEBAULT",
   finessju: 490007093,
   id: 490536745,
   commune: "ECOUFLANT",
   cp: 49000,
   telephone: "241434422",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POITOU",
   finessju: 720002872,
   id: 720009448,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243750098",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POTHELUNE",
   finessju: 720005693,
   id: 720014018,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243820102",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE POTIER-JULIEN",
   finessju: 440006773,
   id: 440014041,
   commune: "NANTES",
   cp: 44000,
   telephone: "240474000",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PRIEUR",
   finessju: 490006418,
   id: 490534104,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241501102",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PRIN",
   finessju: 440012474,
   id: 440016996,
   commune: "ST NICOLAS DE REDON",
   cp: 44460,
   telephone: "299714355",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PRINCIPALE",
   finessju: 720003227,
   id: 720010107,
   commune: "LA CHARTRE SUR LE LOIR",
   cp: 72340,
   telephone: "243444258",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PRINCIPALE C. ET Y. HAREL",
   finessju: 850002023,
   id: 850015090,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251690115",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PRIOUZEAU",
   finessju: 490006277,
   id: 490533957,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240837179",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE PRISSET-PANOUZE",
   finessju: 440006625,
   id: 440013886,
   commune: "NANTES",
   cp: 44100,
   telephone: "240462798",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE QUANCARD",
   finessju: 440007243,
   id: 440014512,
   commune: "NANTES",
   cp: 44100,
   telephone: "240760301",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RABILLER-CHATELIER",
   finessju: 850001512,
   id: 850014606,
   commune: "LA BOISSIERE DE MONTAIGU",
   cp: 85600,
   telephone: "251416869",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RAGUIDEAU",
   finessju: 440006930,
   id: 440014207,
   commune: "NANTES",
   cp: 44000,
   telephone: "240493236",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RAHOU",
   finessju: 440011237,
   id: 440016566,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240461778",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RAMBAUD",
   finessju: 850001892,
   id: 850014986,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628004",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RAULINE-MICHAUX",
   finessju: 530001494,
   id: 530030584,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243047020",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RAYMOND-TAUGOURDEAU",
   finessju: 490006004,
   id: 490533684,
   commune: "VERRIERES EN ANJOU",
   cp: 49112,
   telephone: "241767028",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE REGIONALE",
   finessju: 850000761,
   id: 850015694,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624175",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE REMY-HERBERT",
   finessju: 850002734,
   id: 850015587,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251570190",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RENARD",
   finessju: 720002682,
   id: 720009257,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243840276",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RENAUD-CHOLLET",
   finessju: 440011195,
   id: 440016525,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240765762",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RETAILLEAU",
   finessju: 850003989,
   id: 850016221,
   commune: "TREIZE SEPTIERS",
   cp: 85600,
   telephone: "251417148",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RETAILLEAU-DAVID",
   finessju: 440046985,
   id: 440046993,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240781515",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE REULIER-MAITRE",
   finessju: 490006533,
   id: 490534229,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241426269",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RIBAULT",
   finessju: 440012235,
   id: 440016954,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240700441",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RICHARD - GARDIC",
   finessju: 440009108,
   id: 440015170,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810948",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RICHARDEAU-BOISSEAUX",
   finessju: 850002890,
   id: 850015918,
   commune: "ST CHRISTOPHE DU LIGNERON",
   cp: 85670,
   telephone: "251683577",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RICHELIEU",
   finessju: 850002411,
   id: 850015298,
   commune: "LUCON",
   cp: 85400,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RICHIER-VINCENT LINDER",
   finessju: 440008753,
   id: 440014967,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240650804",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RIOLI",
   finessju: 850002809,
   id: 850015827,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251320429",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RITOUET-POIRIER",
   finessju: 490005303,
   id: 490532835,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241480592",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RIVE SUD",
   finessju: 490005956,
   id: 490533635,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "241577690",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RIVES",
   finessju: 490006087,
   id: 490533767,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241924001",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROBERT - CHEVRIER",
   finessju: 440007136,
   id: 440014405,
   commune: "NANTES",
   cp: 44000,
   telephone: "240486448",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROBIN",
   finessju: 850004847,
   id: 850024431,
   commune: "GROSBREUIL",
   cp: 85440,
   telephone: "251226427",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROBIN - BLANC",
   finessju: 490006103,
   id: 490533783,
   commune: "LE PUY NOTRE DAME",
   cp: 49260,
   telephone: "241522644",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROBIN - GOUY",
   finessju: 440010916,
   id: 440016293,
   commune: "REZE",
   cp: 44400,
   telephone: "240756823",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROBINET",
   finessju: 490005881,
   id: 490533569,
   commune: "MAUGES SUR LOIRE",
   cp: 49570,
   telephone: "241390572",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROCHEFORT - AUDU",
   finessju: 440012300,
   id: 440016962,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240225985",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROCHET",
   finessju: 490007598,
   id: 490007648,
   commune: "ST JEAN DE LINIERES",
   cp: 49070,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RODAS",
   finessju: 440010627,
   id: 440015998,
   commune: "LA PLAINE SUR MER",
   cp: 44770,
   telephone: "240215860",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RODRIGUES - BAHIER",
   finessju: 440011278,
   id: 440016608,
   commune: "ST JEAN DE BOISEAU",
   cp: 44640,
   telephone: "240656658",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RODRIGUEZ",
   finessju: 440011260,
   id: 440016590,
   commune: "VAIR SUR LOIRE",
   cp: 44150,
   telephone: "240980084",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RODRIGUEZ - GRASSEAU",
   finessju: 720005206,
   id: 720010784,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243350033",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RONDEAU - VERON",
   finessju: 720005669,
   id: 720013986,
   commune: "SOLESMES",
   cp: 72300,
   telephone: "243950134",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RONDEAU-GUILLOT",
   finessju: 440043057,
   id: 440043065,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RONSIN",
   finessju: 720002500,
   id: 720009075,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243815979",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUANET",
   finessju: 530001858,
   id: 530030949,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243050734",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUCHER",
   finessju: 490005550,
   id: 490533239,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUCHER-LENA",
   finessju: 490007259,
   id: 490543097,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241599336",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROULLAND - DUTET",
   finessju: 530001767,
   id: 530030857,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243004466",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUSSEAU",
   finessju: 850002569,
   id: 850015413,
   commune: "MOUILLERON LE CAPTIF",
   cp: 85000,
   telephone: "251380198",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUSSEAU-GUITTENY",
   finessju: 440010247,
   id: 440015618,
   commune: "LIGNE",
   cp: 44850,
   telephone: "240770509",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUSSIN",
   finessju: 850000787,
   id: 850015710,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251371537",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROUXEL-ARSICAULT",
   finessju: 850001819,
   id: 850014903,
   commune: "COEX",
   cp: 85220,
   telephone: "251546010",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ROY-BONEU",
   finessju: 440021921,
   id: 440031664,
   commune: "LES TOUCHES",
   cp: 44390,
   telephone: "240724354",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE RUBILLARD",
   finessju: 720002807,
   id: 720009372,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SABIC-MEILLEROUX",
   finessju: 490006046,
   id: 490533726,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241446150",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAIDI-MENARD",
   finessju: 440010064,
   id: 440015493,
   commune: "GUENROUET",
   cp: 44530,
   telephone: "240876118",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT CHRISTOPHE DU BOIS",
   finessju: 490007176,
   id: 490537651,
   commune: "ST CHRISTOPHE DU BOIS",
   cp: 49280,
   telephone: "241568666",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT COME",
   finessju: 440010221,
   id: 440015592,
   commune: "LE LANDREAU",
   cp: 44430,
   telephone: "240064058",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT DENIS",
   finessju: 720005008,
   id: 720010560,
   commune: "MONTMIRAIL",
   cp: 72320,
   telephone: "243937022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT FELIX",
   finessju: 440006872,
   id: 440014140,
   commune: "NANTES",
   cp: 44000,
   telephone: "240742506",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT GEORGES DU BOIS",
   finessju: 720005610,
   id: 720013945,
   commune: "ST GEORGES DU BOIS",
   cp: 72700,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT GUeNOLe",
   finessju: 440008167,
   id: 440014801,
   commune: "BATZ SUR MER",
   cp: 44740,
   telephone: "240238510",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT JEAN",
   finessju: 440010254,
   id: 440015626,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "240338016",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT JOSEPH",
   finessju: 720002617,
   id: 720009182,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243246481",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT JULIEN",
   finessju: 850004680,
   id: 850019738,
   commune: "ST JULIEN DES LANDES",
   cp: 85150,
   telephone: "251466236",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT LEGEOISE",
   finessju: 490006285,
   id: 490533965,
   commune: "ST LEGER SOUS CHOLET",
   cp: 49280,
   telephone: "241581403",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT MARS",
   finessju: 720005271,
   id: 720010859,
   commune: "ST MARS LA BRIERE",
   cp: 72470,
   telephone: "243897103",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT MARTIN",
   finessju: 490005261,
   id: 490532785,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630597",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT MARTIN",
   finessju: 490006731,
   id: 490534542,
   commune: "GENNES VAL DE LOIRE",
   cp: 49160,
   telephone: "241384018",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT MICHEL",
   finessju: 530001635,
   id: 530030725,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243535863",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT NICOLAS",
   finessju: 440006617,
   id: 440013878,
   commune: "NANTES",
   cp: 44000,
   telephone: "240486259",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT NICOLAS",
   finessju: 530001676,
   id: 530030766,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243670298",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT PIERRE",
   finessju: 490006368,
   id: 490534047,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241512504",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT VINCENT",
   finessju: 440007110,
   id: 440014389,
   commune: "NANTES",
   cp: 44000,
   telephone: "240747156",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT VITAL",
   finessju: 440020873,
   id: 440026219,
   commune: "ST VIAUD",
   cp: 44320,
   telephone: "240396090",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINT-ANDRE",
   finessju: 720005578,
   id: 720013903,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943001",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAINTE THERESE",
   finessju: 440006591,
   id: 440013852,
   commune: "NANTES",
   cp: 44100,
   telephone: "240769584",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SANNIER - LUDEAU",
   finessju: 490006442,
   id: 490534138,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241513125",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SANTE NATURE",
   finessju: 850001520,
   id: 850014614,
   commune: "LE BOUPERE",
   cp: 85510,
   telephone: "251914196",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SANTOS DUMONT",
   finessju: 440006641,
   id: 440013902,
   commune: "NANTES",
   cp: 44300,
   telephone: "240760334",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAUPIN",
   finessju: 850004748,
   id: 850022120,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251347373",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAUVAGET - GIRAUD",
   finessju: 490006202,
   id: 490533882,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241928411",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAUZEREAU - ETOUBLEAU",
   finessju: 440009025,
   id: 440015105,
   commune: "LA CHAPELLE DES MARAIS",
   cp: 44410,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SAVARIS",
   finessju: 850004375,
   id: 850016312,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251910055",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SCELLIER",
   finessju: 440012219,
   id: 440016939,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240700639",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SCHWOOB",
   finessju: 490005048,
   id: 490532561,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241887551",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SCOTTO",
   finessju: 440010619,
   id: 440015980,
   commune: "PIRIAC SUR MER",
   cp: 44420,
   telephone: "240235034",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SEBAG - CHAS",
   finessju: 490006335,
   id: 490534013,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241503522",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SEGUIN",
   finessju: 850004714,
   id: 850019761,
   commune: "BARBATRE",
   cp: 85630,
   telephone: "251393848",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SEILLER",
   finessju: 440009009,
   id: 440015089,
   commune: "LE CELLIER",
   cp: 44850,
   telephone: "240254227",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SELLIER-MONTAIGNE",
   finessju: 490005972,
   id: 490533650,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241617339",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SELO",
   finessju: 440006443,
   id: 440013704,
   commune: "NANTES",
   cp: 44000,
   telephone: "240760114",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SERDOT-GUERIN",
   finessju: 720005594,
   id: 720013929,
   commune: "LOMBRON",
   cp: 72450,
   telephone: "243766275",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SERRAULT",
   finessju: 490005238,
   id: 490532751,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241803015",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SERVIN",
   finessju: 440040350,
   id: 440040376,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "240945330",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SEVAUX",
   finessju: 720003375,
   id: 720010255,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "243421297",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SEVRE ET MAINE",
   finessju: 440009140,
   id: 440015212,
   commune: "CHATEAU THEBAUD",
   cp: 44690,
   telephone: "240065280",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SIMON",
   finessju: 440012789,
   id: 440017051,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240345805",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SIMON",
   finessju: 490005667,
   id: 490533346,
   commune: "INGRANDES LE FRESNE SUR LO",
   cp: 49123,
   telephone: "241392012",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SIMON",
   finessju: 530002088,
   id: 530032853,
   commune: "AHUILLE",
   cp: 53940,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SOMNOLET - ROLAND",
   finessju: 440017069,
   id: 440017390,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240342053",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SOULARD",
   finessju: 490006111,
   id: 490533791,
   commune: "ROCHEFORT SUR LOIRE",
   cp: 49190,
   telephone: "241787014",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SOULARD-MORIN",
   finessju: 490006244,
   id: 490533924,
   commune: "SEVREMOINE",
   cp: 49230,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SOURDEAU DE BEAUREGARD",
   finessju: 490006392,
   id: 490534088,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241673564",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ST NICOLAS",
   finessju: 440009116,
   id: 440015188,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240810678",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ST NICOLAS",
   finessju: 530002021,
   id: 530031103,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243032089",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE ST-NICOLAS",
   finessju: 490004751,
   id: 490532272,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241874487",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE SYLVIE RENAUD",
   finessju: 530002138,
   id: 530032887,
   commune: "EVRON",
   cp: 53600,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TAILLEFAIT",
   finessju: 490005733,
   id: 490533411,
   commune: "SEVREMOINE",
   cp: 49710,
   telephone: "241465782",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TERLAIN",
   finessju: 490006327,
   id: 490534005,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241767533",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TERLAIN-REGULIER",
   finessju: 490008190,
   id: 490008216,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "-",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THETIOT",
   finessju: 850001389,
   id: 850014473,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251946449",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THIBAULT",
   finessju: 720005636,
   id: 720013960,
   commune: "ST REMY DE SILLE",
   cp: 72140,
   telephone: "243200849",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THIEBAUT - LARDIERE",
   finessju: 440009587,
   id: 440015337,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240230220",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THOBY-HEMERY",
   finessju: 440019834,
   id: 440026185,
   commune: "LA CHAPELLE LAUNAY",
   cp: 44260,
   telephone: "240568081",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THOMAS",
   finessju: 720003300,
   id: 720010180,
   commune: "CONNERRE",
   cp: 72160,
   telephone: "243890031",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THORETTE - ORIEUX",
   finessju: 440020972,
   id: 440026797,
   commune: "NANTES",
   cp: 44200,
   telephone: "240894333",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THOURET",
   finessju: 530001395,
   id: 530030485,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243051517",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE THOUZEAU - POIRIER",
   finessju: 440007037,
   id: 440014306,
   commune: "NANTES",
   cp: 44000,
   telephone: "240203334",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TILLY",
   finessju: 440010387,
   id: 440015758,
   commune: "MONTBERT",
   cp: 44140,
   telephone: "240047584",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TISNE - PIEDNOIR",
   finessju: 490005014,
   id: 490532538,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241666236",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TOUCHARD",
   finessju: 720002914,
   id: 720009489,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243845959",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TOUFFLIN-RIOLI",
   finessju: 850001827,
   id: 850014911,
   commune: "COMMEQUIERS",
   cp: 85220,
   telephone: "251548051",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TOUGERON-BONDER",
   finessju: 440007409,
   id: 440014629,
   commune: "NANTES",
   cp: 44000,
   telephone: "240741404",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TOUZALIN",
   finessju: 850002452,
   id: 850015306,
   commune: "LES LUCS SUR BOULOGNE",
   cp: 85170,
   telephone: "251312107",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TOUZE",
   finessju: 440011146,
   id: 440016475,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240463497",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TROUSSELLE - FERNANDEZ",
   finessju: 490004991,
   id: 490532512,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241482916",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TURBAN - BARRE",
   finessju: 440010833,
   id: 440016210,
   commune: "REZE",
   cp: 44400,
   telephone: "240756886",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE TURMEL",
   finessju: 720002716,
   id: 720009281,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243240991",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VALENTIN",
   finessju: 720004704,
   id: 720010420,
   commune: "LOUE",
   cp: 72540,
   telephone: "243884004",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VAN VLIET-BELIN",
   finessju: 850001843,
   id: 850014937,
   commune: "DAMVIX",
   cp: 85420,
   telephone: "251871425",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VANNIER",
   finessju: 850001991,
   id: 850015082,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251691650",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VARINOT-ARBAUD",
   finessju: 490005352,
   id: 490532884,
   commune: "BRIOLLAY",
   cp: 49125,
   telephone: "241425654",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VERHAEGHE",
   finessju: 850002957,
   id: 850015975,
   commune: "ST GERVAIS",
   cp: 85230,
   telephone: "251687311",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VERTE LES HERBES DU CHATEAU",
   finessju: 440007375,
   id: 440014595,
   commune: "NANTES",
   cp: 44000,
   telephone: "240740222",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VERTOU CENTRE",
   finessju: 440016376,
   id: 440017374,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240344026",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VIALATOU",
   finessju: 490005931,
   id: 490533619,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "241422081",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VIAULT-BIORET",
   finessju: 490005519,
   id: 490533049,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241306021",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VICOMTAISE - MME RETAILLEAU",
   finessju: 850001603,
   id: 850014697,
   commune: "LA CHAIZE LE VICOMTE",
   cp: 85310,
   telephone: "251057005",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VICTOR HUGO",
   finessju: 490006921,
   id: 490536232,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241424142",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VILLERS",
   finessju: 440010205,
   id: 440015576,
   commune: "JOUE SUR ERDRE",
   cp: 44440,
   telephone: "240723547",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VIRMOUT-MARIE",
   finessju: 440008944,
   id: 440015022,
   commune: "BOUSSAY",
   cp: 44190,
   telephone: "240068146",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VITRE",
   finessju: 490007002,
   id: 490536323,
   commune: "LONGUENEE EN ANJOU",
   cp: 49770,
   telephone: "241326110",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VIV'ERDRE",
   finessju: 440022564,
   id: 440032191,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240297927",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE VULLIEMIN-KASTNER",
   finessju: 490005600,
   id: 490533288,
   commune: "ECOUFLANT",
   cp: 49000,
   telephone: "241437041",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE WACHOWIAK",
   finessju: 720002609,
   id: 720009174,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243244082",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE WAYMEL",
   finessju: 530006378,
   id: 530030667,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243534158",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE XAVIER SERRAULT",
   finessju: 490005428,
   id: 490532959,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241780056",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE XIONG",
   finessju: 720002674,
   id: 720009240,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243840511",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHCIE MONTASSIER-GALLET DE ST AURIN",
   finessju: 850001785,
   id: 850014879,
   commune: "CHAUCHE",
   cp: 85140,
   telephone: "251418433",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHIE  ENGERBEAUD - BENNETON - BOYER",
   finessju: 850002346,
   id: 850015264,
   commune: "LUCON",
   cp: 85400,
   telephone: "251279763",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "PHIE FOUCHE - HERBRETEAU - FOUNINI",
   finessju: 440010999,
   id: 440016319,
   commune: "REZE",
   cp: 44400,
   telephone: "240841852",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL BARREAUD FLORENCE",
   finessju: 850003476,
   id: 850016106,
   commune: "ST LAURENT SUR SEVRE",
   cp: 85290,
   telephone: "251678938",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL MARTELLI POTEREAU",
   finessju: 850001595,
   id: 850014689,
   commune: "CHAILLE LES MARAIS",
   cp: 85450,
   telephone: "251567014",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL NELLY LE GALLOUDEC",
   finessju: 490004975,
   id: 490532496,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241437742",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL PHARMACIE ADAM",
   finessju: 530002005,
   id: 530031095,
   commune: "VAIGES",
   cp: 53480,
   telephone: "243905017",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL PHARMACIE BEAUTE",
   finessju: 490007218,
   id: 490539319,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241350350",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL PHARMACIE DES LANDES",
   finessju: 440021343,
   id: 440028645,
   commune: "NOTRE DAME DES LANDES",
   cp: 44130,
   telephone: "240572022",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL PHARMACIE MELIOT",
   finessju: 440019123,
   id: 440024115,
   commune: "STE REINE DE BRETAGNE",
   cp: 44160,
   telephone: "240011964",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELARL S.S.L.",
   finessju: 490006509,
   id: 490534195,
   commune: "SEICHES SUR LE LOIR",
   cp: 49140,
   telephone: "241762057",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "SELEURL POUVREAU",
   finessju: 850001504,
   id: 850014598,
   commune: "BOIS DE CENE",
   cp: 85710,
   telephone: "251684129",
   categorie: "Pharmacie d'Officine"
 },
 {
   finess: "FINESS",
   name: "OFFICINE BEL AIR DE COMBREE",
   finessju: 490538584,
   id: 490532827,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "241615048",
   categorie: "Pharmacie Mini�re"
 },
 {
   finess: "FINESS",
   name: "PHARMACIE MUTUALISTE",
   finessju: 490535168,
   id: 490534716,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Pharmacie Mutualiste"
 },
 {
   finess: "FINESS",
   name: "MFAM POUPONNIERE",
   finessju: 490535168,
   id: 490020823,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Pouponni�re a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "PEAD 49 SAUVEGARDE MAYENNE SARTHE",
   finessju: 720008853,
   id: 490020815,
   commune: "ANGERS",
   cp: 49000,
   telephone: "252750285",
   categorie: "Pouponni�re a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POUPONNIERE A PETITS PAS",
   finessju: 490539509,
   id: 490013729,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241580611",
   categorie: "Pouponni�re a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POUPONNIERE LE CAP",
   finessju: 490001260,
   id: 490020807,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "-",
   categorie: "Pouponni�re a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "POUPONNIERE SOCIALE LE PERQUOI",
   finessju: 720014844,
   id: 720006956,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243405420",
   categorie: "Pouponni�re a Caract�re Social"
 },
 {
   finess: "FINESS",
   name: "PROPHARMACIE MONSIEUR ETIENNE CONVERSY",
   finessju: 720016773,
   id: 720016781,
   commune: "TRESSON",
   cp: 72440,
   telephone: "243351843",
   categorie: "Propharmacie"
 },
 {
   finess: "FINESS",
   name: "SERVICE DEPART. DE PMI",
   finessju: 440023620,
   id: 440040624,
   commune: "NANTES",
   cp: 44000,
   telephone: "251172000",
   categorie: "Protection Maternelle et Infantile (P.M.I.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DEPARTEMENTAL DE P.M.I.",
   finessju: 490535697,
   id: 490007846,
   commune: "ANGERS CEDEX 9",
   cp: 49941,
   telephone: "241814818",
   categorie: "Protection Maternelle et Infantile (P.M.I.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DEPARTEMENTAL DE P.M.I.",
   finessju: 720011188,
   id: 720016476,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243817272",
   categorie: "Protection Maternelle et Infantile (P.M.I.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DEPARTEMENTAL P.M.I.",
   finessju: 530031285,
   id: 530003565,
   commune: "LAVAL CEDEX 9",
   cp: 53030,
   telephone: "243591456",
   categorie: "Protection Maternelle et Infantile (P.M.I.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DEPARTEMENTAL P.M.I.",
   finessju: 850018581,
   id: 850023805,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85021,
   telephone: "251344848",
   categorie: "Protection Maternelle et Infantile (P.M.I.)"
 },
 {
   finess: "FINESS",
   name: "FOYER LES BARRONNIERES",
   finessju: 720009737,
   id: 720004761,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243976060",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "FOYER LES CAMPANULES",
   finessju: 720019058,
   id: 720019066,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970021",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "FOYER LOGEMENT",
   finessju: 530003433,
   id: 530003441,
   commune: "RENAZE",
   cp: 53800,
   telephone: "243064014",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "FOYER LOGEMENT",
   finessju: 530031160,
   id: 530029339,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243041809",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "FOYER LOGEMENT",
   finessju: 530031210,
   id: 530031764,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243058490",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "FOYER LOGEMENT RESID LES HORTENSIAS",
   finessju: 530001072,
   id: 530033273,
   commune: "LE HORPS",
   cp: 53640,
   telephone: "243039703",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "FOYER SOLEIL",
   finessju: 720009612,
   id: 720008192,
   commune: "BOULOIRE",
   cp: 72440,
   telephone: "243354932",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LE MOTTAIS LOGEMENT FOYER",
   finessju: 530007038,
   id: 530007079,
   commune: "QUELAINES ST GAULT",
   cp: 53360,
   telephone: "243988402",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER AMBROISE CROIZAT",
   finessju: 720009729,
   id: 720013200,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243161967",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER CHATEAU DU LOIR",
   finessju: 720009620,
   id: 720004688,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243448975",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER CLEMENT GEORGET",
   finessju: 530032945,
   id: 530032960,
   commune: "LA CROIXILLE",
   cp: 53380,
   telephone: "243685278",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER D'ARNAGE",
   finessju: 720009588,
   id: 720008184,
   commune: "ARNAGE",
   cp: 72230,
   telephone: "243218087",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER D'ECOMMOY",
   finessju: 720009653,
   id: 720004712,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "243421014",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER DE CONNERRE",
   finessju: 720009638,
   id: 720004696,
   commune: "CONNERRE",
   cp: 72160,
   telephone: "243890266",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER DE SAINT PATERNE",
   finessju: 720013689,
   id: 720013697,
   commune: "ST PATERNE LE CHEVAIN",
   cp: 72610,
   telephone: "233294112",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER DE VAAS",
   finessju: 720013168,
   id: 720013192,
   commune: "VAAS",
   cp: 72500,
   telephone: "243385310",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER FRONT DE SARTHE",
   finessju: 750803587,
   id: 720013671,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243245577",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER GEORGES BRASSENS",
   finessju: 720009729,
   id: 720008614,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243399350",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER JEAN DUCHESNE",
   finessju: 720009570,
   id: 720004654,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243805522",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LA BAZOGE",
   finessju: 720009679,
   id: 720008218,
   commune: "LA BAZOGE",
   cp: 72650,
   telephone: "243254007",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LA TANNERIE",
   finessju: 720011329,
   id: 720019041,
   commune: "LA SUZE SUR SARTHE",
   cp: 72210,
   telephone: "243255499",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LE CLOSEAU",
   finessju: 720009687,
   id: 720004506,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243930473",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LE GUE DE MAULNY",
   finessju: 720009729,
   id: 720015742,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243849539",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LE PLESSIS",
   finessju: 720009646,
   id: 720008440,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243814478",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES CARMES",
   finessju: 720009695,
   id: 720008226,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243947980",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES ERABLES",
   finessju: 720009661,
   id: 720008200,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243973442",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES FLEURS SALINES",
   finessju: 850012915,
   id: 850024043,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251222924",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES GENETS D'OR",
   finessju: 850012915,
   id: 850012048,
   commune: "LES SABLES D OLONNE CEDEX",
   cp: 85103,
   telephone: "251959903",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES HEURES CLAIRES",
   finessju: 720002054,
   id: 720008176,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970390",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES LILAS",
   finessju: 720009711,
   id: 720008234,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243946242",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER LES TAMARIS",
   finessju: 490537222,
   id: 490537230,
   commune: "VERNOIL LE FOURRIER",
   cp: 49390,
   telephone: "241515417",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER RENE DE BRANCHE",
   finessju: 530033224,
   id: 530033257,
   commune: "CHAILLAND",
   cp: 53420,
   telephone: "243026450",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER RESIDENCE DU PARC",
   finessju: 720009695,
   id: 720004746,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243943492",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER RESIDENCE DU PARC",
   finessju: 720013143,
   id: 720013150,
   commune: "YVRE L EVEQUE",
   cp: 72530,
   telephone: "243896780",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER RESIDENCE SAINT MARC",
   finessju: 720011865,
   id: 720011873,
   commune: "BRULON",
   cp: 72350,
   telephone: "243924577",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER SAINT DENIS",
   finessju: 720009752,
   id: 720004795,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243953792",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER SILLE LE GUILLAUME",
   finessju: 720009778,
   id: 720004522,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243521515",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "LOGEMENT FOYER STE COLOMBE",
   finessju: 720009695,
   id: 720004738,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243940121",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MAISON DE VIE LA RESIDENCE DU PARC",
   finessju: 850025743,
   id: 850025925,
   commune: "LA RABATELIERE",
   cp: 85250,
   telephone: "251986615",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MAISON DE VIE LE LOGIS DE PAHU",
   finessju: 850025743,
   id: 850017278,
   commune: "LONGEVES",
   cp: 85200,
   telephone: "251510978",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MAISON DE VIE LE TAIL FLEURI",
   finessju: 850009242,
   id: 850026121,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MAISON DE VIE LES GRANDS PARENTS",
   finessju: 850025362,
   id: 850023995,
   commune: "STE CECILE",
   cp: 85110,
   telephone: "251402852",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MAISON DE VIE RESIDENCE LES TAMARIS",
   finessju: 850026162,
   id: 850026170,
   commune: "BOIS DE CENE",
   cp: 85710,
   telephone: "228109699",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA ARC EN CIEL",
   finessju: 850006628,
   id: 850006669,
   commune: "ST MESMIN",
   cp: 85700,
   telephone: "251576930",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA AU VENT DES MARAIS",
   finessju: 850025693,
   id: 850025701,
   commune: "SALLERTAINE",
   cp: 85300,
   telephone: "251350909",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA BALLEE",
   finessju: 530008796,
   id: 530033380,
   commune: "VAL DU MAINE",
   cp: 53340,
   telephone: "243641110",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA CHATILLON SUR COLMONT",
   finessju: 530003888,
   id: 530003938,
   commune: "CHATILLON SUR COLMONT",
   cp: 53100,
   telephone: "243001492",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA CLAIR DE LUNE",
   finessju: 850011248,
   id: 850011255,
   commune: "VENDRENNES",
   cp: 85250,
   telephone: "251661151",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA CLAIRE FONTAINE",
   finessju: 850026972,
   id: 850018235,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251610400",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA DES HAUTS DE L'ABBAYE",
   finessju: 850025743,
   id: 850022955,
   commune: "MOREILLES",
   cp: 85450,
   telephone: "228141400",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LA FRAGONNETTE",
   finessju: 850006172,
   id: 850011594,
   commune: "ST HILAIRE DE VOUST",
   cp: 85120,
   telephone: "251501919",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LA JOSINETTE",
   finessju: 850026022,
   id: 850026030,
   commune: "ST GERVAIS",
   cp: 85230,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LA VALLEE VERTE",
   finessju: 850021510,
   id: 850021528,
   commune: "STE FOY",
   cp: 85150,
   telephone: "251225530",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LE CLOS D'ALINE",
   finessju: 530002666,
   id: 530002674,
   commune: "ST OUEN DES TOITS",
   cp: 53410,
   telephone: "243377238",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LE COTEAU DU LAY",
   finessju: 850026188,
   id: 850026196,
   commune: "REAUMUR",
   cp: 85700,
   telephone: "251655820",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LE TEMPS DE VIVRE",
   finessju: 850027293,
   id: 850027301,
   commune: "LA BOISSIERE DES LANDES",
   cp: 85430,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LES CHARMILLES",
   finessju: 850025354,
   id: 850023987,
   commune: "MOUCHAMPS",
   cp: 85640,
   telephone: "251662142",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LES LAVANDIERES",
   finessju: 850024035,
   id: 850024092,
   commune: "ST PAUL EN PAREDS",
   cp: 85500,
   telephone: "251922929",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LES MYOSOTIS",
   finessju: 850023938,
   id: 850023946,
   commune: "LES EPESSES",
   cp: 85590,
   telephone: "251573938",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LES NENUPHARS",
   finessju: 850026451,
   id: 850013251,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251571050",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LES ROSEAUX",
   finessju: 850004979,
   id: 850005018,
   commune: "LE FENOUILLER",
   cp: 85800,
   telephone: "251549950",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LES TILLEULS",
   finessju: 850018078,
   id: 850023912,
   commune: "ST MAURICE LE GIRARD",
   cp: 85390,
   telephone: "251696944",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA LOGIS DU BOIS",
   finessju: 850012972,
   id: 850023110,
   commune: "TIFFAUGES",
   cp: 85130,
   telephone: "251657393",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA RESIDENCE BERNADETTE",
   finessju: 850004862,
   id: 850004870,
   commune: "STE FLAIVE DES LOUPS",
   cp: 85150,
   telephone: "251340238",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA RESIDENCE HARMONIE",
   finessju: 850027723,
   id: 850026105,
   commune: "CHAMBRETAUD",
   cp: 85500,
   telephone: "251665740",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA RESIDENCE LES COTEAUX DU LAC",
   finessju: 850017559,
   id: 850017575,
   commune: "POIROUX",
   cp: 85440,
   telephone: "251905336",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA RESIDENCE SOURIRE D'AUTOMNE",
   finessju: 850009051,
   id: 850009085,
   commune: "LA MEILLERAIE TILLAY",
   cp: 85700,
   telephone: "251635180",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "MARPA SAINT SATURNIN",
   finessju: 720014117,
   id: 720014158,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243253743",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "PUV LES CHARMES DE L'YON",
   finessju: 850025610,
   id: 850026303,
   commune: "NESMY",
   cp: 85310,
   telephone: "251981616",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "ReSIDENCE LA SOURCE",
   finessju: 850016577,
   id: 850027616,
   commune: "BREM SUR MER",
   cp: 85470,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOM. L'AMANDIER",
   finessju: 490540598,
   id: 490540606,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241390682",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE  SAINT MICHEL",
   finessju: 490534732,
   id: 490531977,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241433333",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BEAULIEU",
   finessju: 440018620,
   id: 440053833,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BEL AIR",
   finessju: 440018620,
   id: 440009389,
   commune: "LA CHAPELLE SUR ERDRE",
   cp: 44240,
   telephone: "240297520",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BEL AIR",
   finessju: 440018620,
   id: 440009405,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "240655024",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BELLEFONTAINE",
   finessju: 490534732,
   id: 490531860,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241875511",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BELLEVUE",
   finessju: 720009604,
   id: 720004670,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243293017",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BOLE EDEN",
   finessju: 440018463,
   id: 440009397,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240600834",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BON ACCUEIL",
   finessju: 490536620,
   id: 490003969,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241777578",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE BOUT DES LANDES",
   finessju: 440018406,
   id: 440013308,
   commune: "NANTES",
   cp: 44000,
   telephone: "240764427",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE CLAIR SOLEIL",
   finessju: 490534740,
   id: 490004009,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241502429",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE CLAIRE FONTAINE",
   finessju: 490008125,
   id: 490542552,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241704760",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "ReSIDENCE AUTONOMIE CROISSANT",
   finessju: 440018406,
   id: 440013365,
   commune: "NANTES",
   cp: 44300,
   telephone: "240496097",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE DES JARDINS",
   finessju: 440055218,
   id: 440009652,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240705144",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE DU PARC",
   finessju: 490004272,
   id: 490539392,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241926797",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE DU VALBOYER",
   finessju: 490543279,
   id: 490543295,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241898151",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE DU VIGNEAU",
   finessju: 440001634,
   id: 440053858,
   commune: "ST MARS DE COUTAIS",
   cp: 44680,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE GINKGO BILOBA",
   finessju: 750721334,
   id: 440051498,
   commune: "NANTES",
   cp: 44000,
   telephone: "240575169",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE GREGOIRE BORDILLON",
   finessju: 490534732,
   id: 490536489,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241860070",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE HENRI DOUET",
   finessju: 490537149,
   id: 490537156,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "241615767",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE L'AVRESNE",
   finessju: 490536646,
   id: 490016391,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241490980",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA DAVRAYS",
   finessju: 440013068,
   id: 440024719,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "240962560",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA GIRARDIERE",
   finessju: 490018488,
   id: 490003902,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241625032",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA GRAND MAISON",
   finessju: 490535168,
   id: 490540416,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241427109",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA GRANDE FONTAINE",
   finessju: 490018488,
   id: 490537172,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "241632597",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA MARRIERE",
   finessju: 440018620,
   id: 440013449,
   commune: "NANTES",
   cp: 44300,
   telephone: "240506444",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA PERRIERE",
   finessju: 490004314,
   id: 490540408,
   commune: "LES GARENNES SUR LOIRE",
   cp: 49610,
   telephone: "241546615",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LA ROSE DE NOEL",
   finessju: 490534732,
   id: 490003852,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241483361",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LE BEGUINAGE",
   finessju: 440002038,
   id: 440053841,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LE BOSQUET",
   finessju: 490018488,
   id: 490003910,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241622336",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LE BOSQUET",
   finessju: 490536646,
   id: 490016169,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241701994",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LE CLOS DES LILAS",
   finessju: 490541133,
   id: 490541141,
   commune: "LE COUDRAY MACOUARD",
   cp: 49260,
   telephone: "241679810",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LE PARADIS",
   finessju: 720000942,
   id: 720004530,
   commune: "TENNIE",
   cp: 72240,
   telephone: "243205234",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES AUBEPINES",
   finessju: 720008846,
   id: 720004647,
   commune: "ST VINCENT DU LOROUER",
   cp: 72150,
   telephone: "243448475",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES BLES D'OR",
   finessju: 490536661,
   id: 490003985,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241767541",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES CAMELIAS",
   finessju: 490542198,
   id: 490542206,
   commune: "TUFFALUN",
   cp: 49700,
   telephone: "241593722",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES CEDRES",
   finessju: 490020716,
   id: 490003944,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241826311",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES CHAMPS FLEURIS",
   finessju: 490535796,
   id: 490003977,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241449027",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES CHARMES",
   finessju: 530000066,
   id: 530030170,
   commune: "EVRON",
   cp: 53600,
   telephone: "243666666",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES CHARMILLES",
   finessju: 490020724,
   id: 490003936,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241521030",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES FONTAINES",
   finessju: 490535804,
   id: 490004025,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "241518686",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES GRILLONS",
   finessju: 490540622,
   id: 490530953,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241774030",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES HORTENSIAS",
   finessju: 440037851,
   id: 440037877,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240626600",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES JONCHERES",
   finessju: 490539160,
   id: 490539178,
   commune: "MOZE SUR LOUET",
   cp: 49610,
   telephone: "241457245",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES JONQUILLES",
   finessju: 920718459,
   id: 490539376,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241451546",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES JUSTICES",
   finessju: 490534732,
   id: 490535861,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241440040",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES MIMOSAS",
   finessju: 490536646,
   id: 490016409,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241304533",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES NOELLES",
   finessju: 440018620,
   id: 440018893,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240631433",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES ROSIERS",
   finessju: 490534757,
   id: 490539368,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241344438",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES ROSIERS",
   finessju: 530032952,
   id: 530032978,
   commune: "BONCHAMP LES LAVAL",
   cp: 53960,
   telephone: "243903404",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES SABLEAUX",
   finessju: 440018620,
   id: 440017721,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240271484",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES SAULNIERS",
   finessju: 440018620,
   id: 440018901,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240248790",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES TOITS BLEUS",
   finessju: 490536695,
   id: 490537180,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241215858",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LES TROIS MOULINS",
   finessju: 490536679,
   id: 490531266,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241441245",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "ReSIDENCE AUTONOMIE LES TULIPES",
   finessju: 850022401,
   id: 850027467,
   commune: "LA TRANCHE SUR MER",
   cp: 85360,
   telephone: "251277225",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LOUIS MARIE CADIC",
   finessju: 490535960,
   id: 490535978,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241426088",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE LOUISE MICHEL",
   finessju: 440018620,
   id: 440017713,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240152255",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE MAISON D'ACCUEIL",
   finessju: 490001708,
   id: 490003993,
   commune: "LA SEGUINIERE",
   cp: 49280,
   telephone: "241569305",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE MARCEL LEBRETON",
   finessju: 490536588,
   id: 490539186,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241476030",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE MARCEL PAGNOL",
   finessju: 440012268,
   id: 440029494,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240583940",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE MARPA LES GENETS",
   finessju: 490543238,
   id: 490541125,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49440,
   telephone: "241929058",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE NOTRE DAME",
   finessju: 490018488,
   id: 490532025,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241628579",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE PAUL DELMET",
   finessju: 440055218,
   id: 440018927,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240708158",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE PIRMIL",
   finessju: 440018406,
   id: 440013373,
   commune: "NANTES",
   cp: 44200,
   telephone: "240769577",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE PORT BOYER",
   finessju: 440018406,
   id: 440013340,
   commune: "NANTES",
   cp: 44300,
   telephone: "240492535",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE PORT LA VALLEE",
   finessju: 490538667,
   id: 490538675,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241570257",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE ROBERT ROBIN",
   finessju: 490534732,
   id: 490003860,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241685577",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE ROGER SALMON",
   finessju: 490001302,
   id: 490002706,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241669037",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE SAINT JEAN",
   finessju: 490019825,
   id: 490003894,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630206",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE SAINTE SOPHIE",
   finessju: 850006560,
   id: 850027368,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251910481",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE SOLEIL DE LOIRE",
   finessju: 490536604,
   id: 490003951,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241782339",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE SOLEINE",
   finessju: 490008778,
   id: 490008828,
   commune: "LES CERQUEUX",
   cp: 49360,
   telephone: "241554587",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE SYLVAIN ROYE",
   finessju: 440018406,
   id: 440013324,
   commune: "NANTES",
   cp: 44100,
   telephone: "240464639",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE VAL DE L'ISLE",
   finessju: 490536968,
   id: 490536976,
   commune: "LONGUENEE EN ANJOU",
   cp: 49770,
   telephone: "241327827",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE VAUGUYON",
   finessju: 720009729,
   id: 720004472,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243852892",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE AUTONOMIE VILLAGE DU PARC",
   finessju: 490535945,
   id: 490535952,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241549065",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE BAZINIERES LOGT FOYER",
   finessju: 720009752,
   id: 720004803,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950717",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE BELLEVUE",
   finessju: 720000868,
   id: 720020817,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974046",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE DE L'HERM",
   finessju: 850021981,
   id: 850021999,
   commune: "ST MICHEL EN L HERM",
   cp: 85580,
   telephone: "251973636",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE FLEURIE LOGEMENT FOYER",
   finessju: 720009752,
   id: 720008168,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243950928",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA CORBEILLE D'ARGENT",
   finessju: 490534732,
   id: 490003845,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241436343",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA COUTURE",
   finessju: 720019082,
   id: 720019090,
   commune: "ST REMY DE SILLE",
   cp: 72140,
   telephone: "243201382",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA JEULINIERE",
   finessju: 720012004,
   id: 720008721,
   commune: "LE LUART",
   cp: 72390,
   telephone: "243711908",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA PISCINE LOGEMENT FOYER",
   finessju: 720009752,
   id: 720004811,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243951177",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LA SAULINIERE LOGEMENT FOYER",
   finessju: 720013127,
   id: 720013135,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243358623",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES CYTHISES",
   finessju: 720009737,
   id: 720019074,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243978648",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES METIERS",
   finessju: 720001593,
   id: 720008242,
   commune: "NOYEN SUR SARTHE",
   cp: 72430,
   telephone: "243957360",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES PRIMEVERES",
   finessju: 850026477,
   id: 850026485,
   commune: "ST MAIXENT SUR VIE",
   cp: 85220,
   telephone: "255650020",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES SAISONNALES",
   finessju: 850018185,
   id: 850018193,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251219400",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES SAISONNALES",
   finessju: 850018185,
   id: 850026048,
   commune: "ST FULGENT",
   cp: 85250,
   telephone: "251080710",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES SAISONNALES",
   finessju: 850018185,
   id: 850026949,
   commune: "COMMEQUIERS",
   cp: 85220,
   telephone: "228177000",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE LES SAISONNALES",
   finessju: 850018185,
   id: 850026956,
   commune: "GROSBREUIL",
   cp: 85440,
   telephone: "251205000",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE OXALIS",
   finessju: 850026527,
   id: 850017245,
   commune: "LA BOISSIERE DE MONTAIGU",
   cp: 85600,
   telephone: "251247750",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "RESIDENCE VERTE VALLEE",
   finessju: 490018488,
   id: 490013919,
   commune: "LA ROMAGNE",
   cp: 49740,
   telephone: "241585019",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "SQUARE LES CHENES",
   finessju: 850012733,
   id: 850026980,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "-",
   categorie: "Residences autonomie"
 },
 {
   finess: "FINESS",
   name: "AED MONJOIE",
   finessju: 720008705,
   id: 850024944,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251329298",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "DISMO",
   finessju: 720008853,
   id: 490016573,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241188830",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "DISPOSITIF EDUCATIF DE MILIEU OUVERT",
   finessju: 440007573,
   id: 440029601,
   commune: "ST SEBASTIEN SUR LOIRE CED",
   cp: 44234,
   telephone: "251791618",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SAEIMF",
   finessju: 440018737,
   id: 440048338,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "228559880",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SAEMO",
   finessju: 490534849,
   id: 490011525,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241203161",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SEMO",
   finessju: 720008853,
   id: 530029388,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243661063",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SEMO 72",
   finessju: 720008853,
   id: 720005867,
   commune: "LE MANS CEDEX 1",
   cp: 72005,
   telephone: "243161660",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D ACCOMPAGNEMENT SEQUENTIEL",
   finessju: 530031426,
   id: 530005198,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243567565",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'ACTION EDUCATIVE",
   finessju: 530031426,
   id: 530003300,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243268990",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE EDUCATIF MILIEU OUVERT",
   finessju: 440018737,
   id: 440012730,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240229393",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE SOCIAL MILIEU OUVERT",
   finessju: 850020413,
   id: 850004326,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85018,
   telephone: "251445070",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE SOCIAL PROTECTION ENFANCE",
   finessju: 440007300,
   id: 440029643,
   commune: "NANTES",
   cp: 44200,
   telephone: "251729300",
   categorie: "Service Action Educative en Milieu Ouvert (A.E.M.O.)"
 },
 {
   finess: "FINESS",
   name: "ANAIS SAVS DU MANS",
   finessju: 610000754,
   id: 720018928,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243768819",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC",
   finessju: 440018661,
   id: 440037109,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240510061",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC ANCENIS",
   finessju: 440018380,
   id: 440053882,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240963960",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC APEI OUEST 44",
   finessju: 440018398,
   id: 440053973,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240704581",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC ARTA",
   finessju: 490535168,
   id: 440046654,
   commune: "ST SEBASTIEN SUR LOIRE CED",
   cp: 44233,
   telephone: "251790909",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC CHAUMES EN RETZ",
   finessju: 440018380,
   id: 440053916,
   commune: "CHAUMES EN RETZ",
   cp: 44680,
   telephone: "240648434",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC DE BLAIN",
   finessju: 440018380,
   id: 440053890,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240122290",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC DE CHATEAUBRIANT",
   finessju: 440018380,
   id: 440053908,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240814691",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC DE REZE",
   finessju: 440018380,
   id: 440052199,
   commune: "REZE",
   cp: 44400,
   telephone: "251703708",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC DE VALLET",
   finessju: 440018380,
   id: 440044816,
   commune: "VALLET",
   cp: 44330,
   telephone: "240362901",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC L ETAPE",
   finessju: 440018646,
   id: 440052652,
   commune: "NANTES",
   cp: 44000,
   telephone: "240500501",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC LA SOUBRETIERE",
   finessju: 440004315,
   id: 440054005,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC LA VERTONNE",
   finessju: 440011484,
   id: 440051860,
   commune: "VERTOU CEDEX",
   cp: 44121,
   telephone: "251710969",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC LES HESPERIDES",
   finessju: 440018380,
   id: 440053924,
   commune: "LEGE",
   cp: 44650,
   telephone: "240122290",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC LES MAHAUDIERES",
   finessju: 440031169,
   id: 440048585,
   commune: "REZE",
   cp: 44400,
   telephone: "240041547",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "LOGAC MARIE MOREAU",
   finessju: 440001352,
   id: 440044790,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240531616",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAPFI SAVS",
   finessju: 720008762,
   id: 720018316,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243500474",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SASP",
   finessju: 440000966,
   id: 440041358,
   commune: "GUERANDE CEDEX",
   cp: 44351,
   telephone: "240150655",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SASP",
   finessju: 440030229,
   id: 440051928,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240815980",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SASP DE REZE",
   finessju: 440018380,
   id: 440052207,
   commune: "REZE",
   cp: 44400,
   telephone: "240974729",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SASP L ETAPE",
   finessju: 440018646,
   id: 440052645,
   commune: "NANTES",
   cp: 44000,
   telephone: "240507686",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS",
   finessju: 440018380,
   id: 440054070,
   commune: "CHAUMES EN RETZ",
   cp: 44680,
   telephone: "240648434",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS",
   finessju: 440018380,
   id: 440054096,
   commune: "LEGE",
   cp: 44650,
   telephone: "240049946",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS",
   finessju: 530007186,
   id: 530003094,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243320754",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS",
   finessju: 530033000,
   id: 530003250,
   commune: "GORRON",
   cp: 53120,
   telephone: "243086997",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS",
   finessju: 530031434,
   id: 530007376,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243671360",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS AAPAI",
   finessju: 490538642,
   id: 490543204,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241435422",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ADAPEI",
   finessju: 490535192,
   id: 490544269,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241707833",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ADAPEI CHALLANS",
   finessju: 850012436,
   id: 850017716,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "228104200",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ADAPEI LUCON",
   finessju: 850012436,
   id: 850017724,
   commune: "LUCON",
   cp: 85400,
   telephone: "251561799",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ADAPEI SARTHE",
   finessju: 720009562,
   id: 720021104,
   commune: "LE MANS CEDEX 2",
   cp: 72027,
   telephone: "243436080",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS AIZENAY",
   finessju: 850012436,
   id: 850008848,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251453600",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ALPHA",
   finessju: 490011343,
   id: 490015484,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241890510",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS APAJH 44",
   finessju: 440018612,
   id: 440048551,
   commune: "NANTES",
   cp: 44100,
   telephone: "272010052",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS APAJH 72-53",
   finessju: 720008762,
   id: 720021088,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243808963",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS APEI OUEST 44",
   finessju: 440018398,
   id: 440041648,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240704581",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS APEPI",
   finessju: 440048684,
   id: 440048692,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "251859305",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS APF",
   finessju: 750719239,
   id: 490014628,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241564919",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS AREAMS",
   finessju: 850020413,
   id: 850009440,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478518",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ARIA",
   finessju: 850012436,
   id: 850008558,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251473777",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ARTA",
   finessju: 490535168,
   id: 440042604,
   commune: "ST SEBASTIEN SUR LOIRE CED",
   cp: 44233,
   telephone: "251790909",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS CHATEAU DU LOIR",
   finessju: 720009562,
   id: 720018597,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243441486",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS CHATEAUBRIANT",
   finessju: 440018380,
   id: 440033470,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240282936",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS CRUCY FOURE",
   finessju: 440018620,
   id: 440052686,
   commune: "NANTES CEDEX 2",
   cp: 44201,
   telephone: "424041277",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS D'ANCENIS",
   finessju: 440018380,
   id: 440033512,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240963960",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE BLAIN",
   finessju: 440018380,
   id: 440042315,
   commune: "BLAIN",
   cp: 44130,
   telephone: "228050054",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE BRISSAC QUINCE",
   finessju: 490538642,
   id: 490014719,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241917212",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE L ARGERIE",
   finessju: 490020310,
   id: 490010998,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241867069",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE LA TARDIERE",
   finessju: 850025867,
   id: 850020496,
   commune: "LA TARDIERE",
   cp: 85120,
   telephone: "251635800",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE PEN BRON",
   finessju: 440018661,
   id: 440049120,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240510061",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE REZE",
   finessju: 440018380,
   id: 440027407,
   commune: "REZE",
   cp: 44400,
   telephone: "240040649",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DE SABLE SUR SARTHE",
   finessju: 720008804,
   id: 720014224,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243922744",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DU HAUT ANJOU",
   finessju: 490536885,
   id: 490014768,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49520,
   telephone: "241947360",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS DU JONCHERAY",
   finessju: 490543600,
   id: 490011434,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "241699892",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS EQUINOXE",
   finessju: 440031169,
   id: 440044840,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240704738",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ESPACES",
   finessju: 490003563,
   id: 490016284,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241926028",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ETAPE",
   finessju: 440018646,
   id: 440038354,
   commune: "NANTES",
   cp: 44000,
   telephone: "240500501",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS FOYER HEBERGEMENT LE FIEF BLANC",
   finessju: 850012436,
   id: 850010646,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "228150570",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS GERARD CORRE",
   finessju: 490538642,
   id: 490540572,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "241347932",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS HANDI ESPOIR",
   finessju: 850006347,
   id: 850024977,
   commune: "COEX",
   cp: 85220,
   telephone: "251353570",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS HANDISUP",
   finessju: 440051142,
   id: 440051159,
   commune: "REZE",
   cp: 44400,
   telephone: "251840398",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS HAUTE ROCHE",
   finessju: 850012436,
   id: 850026501,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251510730",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS JEAN BRATIERES",
   finessju: 720008762,
   id: 720006345,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243455481",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440049146,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240150655",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS L'ABRI",
   finessju: 490002011,
   id: 490007564,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241427112",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA CHAPELLE ST AUBIN",
   finessju: 720008820,
   id: 720018563,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243242248",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA FERTE BERNARD BALLON",
   finessju: 720009562,
   id: 720018589,
   commune: "LA FERTE BERNARD CEDEX",
   cp: 72405,
   telephone: "243719020",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA FLECHE",
   finessju: 720009562,
   id: 720015858,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243451345",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA GUYONNIERE",
   finessju: 850012436,
   id: 850009994,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251092930",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA HAIE VIVE",
   finessju: 490536877,
   id: 490014529,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241494646",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA LARGERE",
   finessju: 850012436,
   id: 850009341,
   commune: "THOUARSAIS BOUILDROUX",
   cp: 85410,
   telephone: "251515873",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA PERSAGOTIERE",
   finessju: 440000073,
   id: 440051738,
   commune: "NANTES CEDEX 2",
   cp: 44262,
   telephone: "240757816",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA SOUBRETIERE",
   finessju: 440004315,
   id: 440033637,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240589016",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LA VERTONNE",
   finessju: 440011484,
   id: 440036531,
   commune: "VERTOU CEDEX",
   cp: 44121,
   telephone: "251710969",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LE CORAIL",
   finessju: 850012436,
   id: 850010638,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251242670",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LE QUAI DE L'ESPOIR",
   finessju: 440031169,
   id: 440041598,
   commune: "NANTES",
   cp: 44000,
   telephone: "240487592",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LE QUINCONCE",
   finessju: 490001542,
   id: 490014438,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241202408",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LE ROBINSON",
   finessju: 850012436,
   id: 850017708,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251332145",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LES 3 PAROISSES",
   finessju: 490538642,
   id: 490540564,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241435422",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LES 4 VENTS",
   finessju: 850013277,
   id: 850017732,
   commune: "L EPINE",
   cp: 85740,
   telephone: "251358806",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LES BEJONNIERES",
   finessju: 490538642,
   id: 490538659,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241347005",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LES HERBIERS",
   finessju: 850012436,
   id: 850016619,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251655910",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LES MUGUETS",
   finessju: 850012436,
   id: 850011800,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251666025",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LES TILLEULS",
   finessju: 440031169,
   id: 440048932,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240467525",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS LUCON",
   finessju: 850006347,
   id: 850026493,
   commune: "LUCON",
   cp: 85400,
   telephone: "251353570",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS MARIE MOREAU",
   finessju: 440001352,
   id: 440040491,
   commune: "ST NAZAIRE CEDEX",
   cp: 44612,
   telephone: "240531616",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS MAROLLES LES BRAULTS",
   finessju: 720008762,
   id: 720006097,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243311130",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ODILE CHALOPIN",
   finessju: 490535754,
   id: 490007572,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241292571",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ORGHANDI",
   finessju: 850013087,
   id: 850017666,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404027",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS PASSERELLEPOUR L'EMPLOI",
   finessju: 440018380,
   id: 440048569,
   commune: "NANTES",
   cp: 44000,
   telephone: "251250640",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS PHARE",
   finessju: 530000850,
   id: 530005859,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243499503",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS PHV",
   finessju: 720008762,
   id: 720017433,
   commune: "ALLONNES CEDEX",
   cp: 72703,
   telephone: "243808663",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS POLE ADULTES 44 APF",
   finessju: 750719239,
   id: 440053874,
   commune: "NANTES CEDEX 3",
   cp: 44323,
   telephone: "251899800",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS PONTMAIN",
   finessju: 930019484,
   id: 530008382,
   commune: "PONTMAIN",
   cp: 53220,
   telephone: "243301880",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS RESIDENCE ROSA PARKS",
   finessju: 490535192,
   id: 490011442,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241220095",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ROBIDA",
   finessju: 530031913,
   id: 530008259,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688016",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS SENEVE",
   finessju: 490011798,
   id: 490014388,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241484906",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS SENIOR",
   finessju: 440006278,
   id: 440054039,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "240369839",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS SERVICE D'AIDE AUX AIDANTS",
   finessju: 440004059,
   id: 440045169,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240020730",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS ST CALAIS",
   finessju: 720008762,
   id: 720006030,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "972449627",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAVS YON ET BOCAGE",
   finessju: 930712393,
   id: 850017260,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628470",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX AIDANTS",
   finessju: 440006294,
   id: 440045144,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251715415",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX AIDANTS FAMILIAUX",
   finessju: 440018398,
   id: 440045151,
   commune: "ST NAZAIRE CEDEX",
   cp: 44615,
   telephone: "240539999",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE SUITE PESCHERAY",
   finessju: 750720591,
   id: 720017425,
   commune: "LE BREIL SUR MERIZE",
   cp: 72370,
   telephone: "243760668",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SOUTIEN A DOMICILE FOYER ST PIERRE",
   finessju: 490004355,
   id: 490015724,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "241577139",
   categorie: "Service d'Accompagnement a la Vie Sociale (S.A.V.S.)"
 },
 {
   finess: "FINESS",
   name: "SAMSAH",
   finessju: 530007186,
   id: 530006808,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243002765",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH ADAPEI 49 ANGERS",
   finessju: 490535192,
   id: 490019783,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241220095",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH ADGESTI",
   finessju: 720008820,
   id: 720017151,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243242248",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH APAJH",
   finessju: 440018612,
   id: 440045045,
   commune: "REZE",
   cp: 44400,
   telephone: "240131812",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH ARCEAU ANJOU",
   finessju: 490535168,
   id: 490540382,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241318600",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH AREAMS",
   finessju: 850020413,
   id: 850016551,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478518",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH DE L'ARCHE",
   finessju: 720000454,
   id: 720017334,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243517272",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH DI DJINH",
   finessju: 530000850,
   id: 530006329,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243499765",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH DU BORD DE LOIRE",
   finessju: 490011343,
   id: 490014818,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "241761567",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH EPSMS DU PAYS DE CHALLANS",
   finessju: 850008905,
   id: 850026204,
   commune: "CHALLANS CEDEX",
   cp: 85304,
   telephone: "251688980",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH GATE ARGENT HABITAT SERVICE",
   finessju: 490001971,
   id: 490008745,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241961430",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH HANDI ESPOIR",
   finessju: 850006347,
   id: 850011578,
   commune: "COEX",
   cp: 85220,
   telephone: "251353570",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH ILE DE NANTES",
   finessju: 440018646,
   id: 440052280,
   commune: "NANTES",
   cp: 44200,
   telephone: "249108880",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH LA CROIX D'OR",
   finessju: 720008770,
   id: 720014398,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243241658",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH LE BOCAGE",
   finessju: 930712393,
   id: 850027285,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251628470",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH LE MANS METROPOLE",
   finessju: 720009562,
   id: 720019140,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243143070",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH LES HAUTS THEBAUDIERES",
   finessju: 440002467,
   id: 440036598,
   commune: "VERTOU CEDEX",
   cp: 44122,
   telephone: "251795000",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH ORGHANDI",
   finessju: 850013087,
   id: 850017336,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404027",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH POLE ADULTES 44 APF",
   finessju: 750719239,
   id: 440035228,
   commune: "NANTES CEDEX 3",
   cp: 44323,
   telephone: "251899800",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH PSY SAPHIR",
   finessju: 530000850,
   id: 530006279,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243499768",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH SAPFI",
   finessju: 720008762,
   id: 720017912,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243500474",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "SAMSAH VIE A DOMICILE",
   finessju: 490536554,
   id: 490014099,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241666800",
   categorie: "Service d'accompagnement medico-social adultes handicapes"
 },
 {
   finess: "FINESS",
   name: "A.D.M.R.",
   finessju: 440037075,
   id: 440037083,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240852576",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "A.D.T. 44",
   finessju: 440006369,
   id: 440034130,
   commune: "NANTES CEDEX 4",
   cp: 44105,
   telephone: "251806120",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "A.D.T. 44",
   finessju: 440006369,
   id: 440041432,
   commune: "NANTES CEDEX 4",
   cp: 44105,
   telephone: "251806120",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "A.N.A.F.",
   finessju: 440006351,
   id: 440034122,
   commune: "NANTES",
   cp: 44000,
   telephone: "240476250",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "A2MICILE REGION CENTRE",
   finessju: 670017920,
   id: 440053759,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240839422",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "A2MICILE REGION ILE DE FRANCE",
   finessju: 670017938,
   id: 440053742,
   commune: "NANTES",
   cp: 44000,
   telephone: "240756055",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "AD SENIORS PAYS DES OLONNES",
   finessju: 850026857,
   id: 850026865,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ADME DE MONSIREIGNE",
   finessju: 850013699,
   id: 850008525,
   commune: "LA MEILLERAIE TILLAY",
   cp: 85700,
   telephone: "251674037",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ADMR DE CHANTONNAY",
   finessju: 850024506,
   id: 850005620,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ADMR DE L OIE",
   finessju: 850013798,
   id: 850007733,
   commune: "STE CECILE",
   cp: 85110,
   telephone: "251660074",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ADMR DE LA GAUBRETIERE",
   finessju: 850005851,
   id: 850005877,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ADMR DE MAILLEZAIS AIDE MENAG ET FAMIL",
   finessju: 850013673,
   id: 850008475,
   commune: "MAILLEZAIS",
   cp: 85420,
   telephone: "251007523",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ADOM VENDEE",
   finessju: 850027418,
   id: 850027426,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251551347",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "AIDE A DOMICILE DU LITTORAL",
   finessju: 440054179,
   id: 440054187,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "240019931",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "AISANCE DOMICILE SERVICE",
   finessju: 440054500,
   id: 440054518,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "AS DOMICILE",
   finessju: 440053544,
   id: 440053551,
   commune: "LE POULIGUEN",
   cp: 44510,
   telephone: "251820683",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ASSOC. AIDE FAMILIALE A DOMICIL",
   finessju: 850011206,
   id: 850014077,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692411",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ASSOCIATION L'ETAPE",
   finessju: 440018646,
   id: 440050235,
   commune: "NANTES",
   cp: 44000,
   telephone: "240507686",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ASSOCIATION NANTAISE D'AIDE FAMILIALE",
   finessju: 440006351,
   id: 440045185,
   commune: "NANTES CEDEX 4",
   cp: 44187,
   telephone: "240737309",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "BIEN A LA MAISON",
   finessju: 920029329,
   id: 440052926,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "BIEN A LA MAISON",
   finessju: 920029329,
   id: 440053080,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "BIEN A LA MAISON",
   finessju: 920029329,
   id: 720020395,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "CONFIEZ-NOUS",
   finessju: 440054682,
   id: 440054690,
   commune: "NANTES",
   cp: 44300,
   telephone: "228228357",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "DOMICILE ASSISTANCE",
   finessju: 440055671,
   id: 440055689,
   commune: "NANTES",
   cp: 44300,
   telephone: "251842060",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "ELICS SERVICES 44100",
   finessju: 440053817,
   id: 440053825,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "G2L SAINT-HERBLAIN",
   finessju: 720021062,
   id: 440054765,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "800111300",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "LA FEE SERVICES",
   finessju: 440053528,
   id: 440053536,
   commune: "NANTES",
   cp: 44300,
   telephone: "251820683",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "LA GIRANDIERE DE L'HERSEAU",
   finessju: 770020824,
   id: 850027517,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251499700",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "LA GIRANDIERE NANTES",
   finessju: 770020824,
   id: 440055697,
   commune: "NANTES",
   cp: 44100,
   telephone: "684690622",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "LITTONER",
   finessju: 440053791,
   id: 440053809,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "MENAGE SERVICE AEF",
   finessju: 490011996,
   id: 490012002,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241882526",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "NORD LOIRE DEPENDANCE",
   finessju: 440054195,
   id: 440054203,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "PORTAGE DES REPAS PERSONNES AGEES",
   finessju: 440018406,
   id: 440018851,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "PRUAD",
   finessju: 440018406,
   id: 440026243,
   commune: "NANTES",
   cp: 44000,
   telephone: "240205656",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "RESIDENCES HOME SERVICES",
   finessju: 770020824,
   id: 720020668,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243743640",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "S.A.D.",
   finessju: 850001173,
   id: 850010851,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251443720",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD",
   finessju: 490538857,
   id: 490011970,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891454",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD",
   finessju: 490539012,
   id: 490539020,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241969393",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD",
   finessju: 490535226,
   id: 490543311,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241680488",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD",
   finessju: 720014646,
   id: 720015817,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243242675",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD A A Z SERVICES",
   finessju: 720020866,
   id: 720020874,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD A2MICILE REGION CENTRE",
   finessju: 670017920,
   id: 530008812,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243536944",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ACAFPA",
   finessju: 530000967,
   id: 530003201,
   commune: "LE BOURGNEUF LA FORET",
   cp: 53410,
   telephone: "243374439",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ACTION SANTE",
   finessju: 490535242,
   id: 490543345,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241692720",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR",
   finessju: 720016427,
   id: 720016435,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243237903",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR  MAYET",
   finessju: 720016211,
   id: 720016237,
   commune: "VAAS",
   cp: 72500,
   telephone: "243467076",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR AHUILLE-BONCHAMP-LAVAL",
   finessju: 530009075,
   id: 530009083,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243564985",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR AMBRIERES LES VALLEES",
   finessju: 530003573,
   id: 530003805,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243046908",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ANDOUILLE",
   finessju: 530003607,
   id: 530003813,
   commune: "ANDOUILLE",
   cp: 53240,
   telephone: "243681044",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ARGENTRE",
   finessju: 530003623,
   id: 530003821,
   commune: "LOUVIGNE",
   cp: 53210,
   telephone: "243681541",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ARON",
   finessju: 530009091,
   id: 530009109,
   commune: "ARON",
   cp: 53440,
   telephone: "243320187",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BAIS",
   finessju: 530003656,
   id: 530003847,
   commune: "BAIS",
   cp: 53160,
   telephone: "243370517",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BALLEE",
   finessju: 530004670,
   id: 530004696,
   commune: "VAL DU MAINE",
   cp: 53340,
   telephone: "243698680",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BALLOTS",
   finessju: 530009117,
   id: 530009125,
   commune: "BALLOTS",
   cp: 53350,
   telephone: "243090387",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BAUGE EN ANJOU",
   finessju: 490012010,
   id: 490012036,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241811212",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BEAUFORT EN ANJOU",
   finessju: 490012077,
   id: 490021045,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BEAUMONT-S/SARTHE",
   finessju: 720015890,
   id: 720015916,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243978361",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BELANJOUS",
   finessju: 490012457,
   id: 490012481,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BOCAGE ST REMY MAUGES",
   finessju: 490008497,
   id: 490008505,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BOIS D'ANJOU",
   finessju: 490008067,
   id: 490008026,
   commune: "LA PLAINE",
   cp: 49360,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR BOUCHEMAINE BEAUCOUZE",
   finessju: 490012242,
   id: 490012267,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CANTON DE ST GEORGES",
   finessju: 490012630,
   id: 490012663,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CHAILLAND",
   finessju: 530009133,
   id: 530009141,
   commune: "CHAILLAND",
   cp: 53420,
   telephone: "243024490",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CHAMPAGNE",
   finessju: 720015965,
   id: 720015973,
   commune: "CHAMPAGNE",
   cp: 72470,
   telephone: "243895526",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CHANGE",
   finessju: 720015981,
   id: 720016005,
   commune: "CHANGE",
   cp: 72560,
   telephone: "243400615",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CHANTENAY VILLEDIEU",
   finessju: 720016013,
   id: 720016021,
   commune: "LOUE",
   cp: 72540,
   telephone: "243884154",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CHATEAU DU LOIR",
   finessju: 720016062,
   id: 720016070,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243792012",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CHEMAZE",
   finessju: 530009158,
   id: 530009166,
   commune: "CHEMAZE",
   cp: 53200,
   telephone: "243075266",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR COIFFES DE MEL",
   finessju: 490004603,
   id: 490543089,
   commune: "ST BARTHELEMY D ANJOU CEDE",
   cp: 49183,
   telephone: "241334812",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CONLIE",
   finessju: 720016096,
   id: 720016104,
   commune: "BERNAY EN CHAMPAGNE",
   cp: 72240,
   telephone: "243207687",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR COSSE LE VIVIEN",
   finessju: 530003771,
   id: 530003870,
   commune: "COSSE LE VIVIEN",
   cp: 53230,
   telephone: "243060660",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR COTEAUX DE L'EVRE",
   finessju: 490012366,
   id: 490012390,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241635489",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR COTEAUX DE LA THAU",
   finessju: 490008034,
   id: 490013471,
   commune: "MAUGES SUR LOIRE",
   cp: 49570,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR COTEAUX DU LOUET",
   finessju: 490013505,
   id: 490013562,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CRAON",
   finessju: 530003896,
   id: 530003912,
   commune: "CRAON",
   cp: 53400,
   telephone: "243060660",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR CUILLE",
   finessju: 530009174,
   id: 530009182,
   commune: "CUILLE",
   cp: 53540,
   telephone: "243709544",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DE BRION",
   finessju: 490012275,
   id: 490012283,
   commune: "LES BOIS D ANJOU",
   cp: 49250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DE L'OREE DE BERCE",
   finessju: 720006519,
   id: 720016385,
   commune: "TELOCHE",
   cp: 72220,
   telephone: "243420452",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DE LA COLMONT",
   finessju: 530003722,
   id: 530003730,
   commune: "CHATILLON SUR COLMONT",
   cp: 53100,
   telephone: "243036561",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DE MAZE",
   finessju: 490014370,
   id: 490014396,
   commune: "MAZE MILON",
   cp: 49630,
   telephone: "241685527",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DE MONTAUDIN",
   finessju: 530004274,
   id: 530004290,
   commune: "MONTAUDIN",
   cp: 53220,
   telephone: "243057312",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DE RENAZE",
   finessju: 530004431,
   id: 530004464,
   commune: "RENAZE",
   cp: 53800,
   telephone: "243705584",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DES COEVRONS",
   finessju: 530003953,
   id: 530003987,
   commune: "EVRON",
   cp: 53600,
   telephone: "243012324",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DOUET & GRAVELLE",
   finessju: 490012895,
   id: 490008091,
   commune: "LE COUDRAY MACOUARD",
   cp: 49260,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DOUET & GRAVELLE",
   finessju: 490012895,
   id: 490012911,
   commune: "LES ULMES",
   cp: 49700,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DU CANDEEN",
   finessju: 490007895,
   id: 490007903,
   commune: "CANDE",
   cp: 49440,
   telephone: "241616967",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR DURTAL",
   finessju: 490015005,
   id: 490015013,
   commune: "DURTAL",
   cp: 49430,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ERNEE",
   finessju: 530003920,
   id: 530009190,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243006074",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ERVE ET CHARNIE",
   finessju: 530004647,
   id: 530004662,
   commune: "STE SUZANNE ET CHAMMES",
   cp: 53270,
   telephone: "243017817",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR EVRE ET MAUGES",
   finessju: 490013422,
   id: 490013430,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR FENEU ET LES ENVIRONS",
   finessju: 490008364,
   id: 490012937,
   commune: "LONGUENEE EN ANJOU",
   cp: 49770,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR FONTAINE GUERIN",
   finessju: 490014404,
   id: 490014412,
   commune: "LES BOIS D ANJOU",
   cp: 49250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR FOUGEROLLES DU PLESSIS",
   finessju: 530004035,
   id: 530004043,
   commune: "FOUGEROLLES DU PLESSIS",
   cp: 53190,
   telephone: "243057095",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR FRESNAY SUR SARTHE",
   finessju: 720016146,
   id: 720016153,
   commune: "ASSE LE BOISNE",
   cp: 72130,
   telephone: "243973069",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR GENNES LES ROSIERS",
   finessju: 490527330,
   id: 490013075,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR GORRON ET SES ENVIRONS",
   finessju: 530003706,
   id: 530003862,
   commune: "GORRON",
   cp: 53120,
   telephone: "243112136",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR HAUT LAYON",
   finessju: 490013570,
   id: 490013604,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR HAUTS DE LA VALLEE",
   finessju: 490013653,
   id: 490013695,
   commune: "LOIRE AUTHION",
   cp: 49800,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR JAVRON LES CHAPELLES",
   finessju: 530004100,
   id: 530004134,
   commune: "JAVRON LES CHAPELLES",
   cp: 53250,
   telephone: "243041678",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR JEU ET AUBANCE",
   finessju: 490013190,
   id: 490013224,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR JUVIGNE LA CROIXILLE",
   finessju: 530004142,
   id: 530004167,
   commune: "JUVIGNE",
   cp: 53380,
   telephone: "243024307",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR L HUISSERIE",
   finessju: 530004084,
   id: 530004092,
   commune: "L HUISSERIE",
   cp: 53970,
   telephone: "243262449",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR L'ANGEVINE",
   finessju: 490021037,
   id: 490021052,
   commune: "ANGERS",
   cp: 49100,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR L'ENTRAIDE",
   finessju: 490013240,
   id: 490013265,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR L'OREE DU BOIS",
   finessju: 490014834,
   id: 490014842,
   commune: "VEZINS",
   cp: 49340,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LA BAZOGE",
   finessju: 720015874,
   id: 720015882,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243276110",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LA CHARTRE SUR LE LOIR",
   finessju: 720016047,
   id: 720016054,
   commune: "LOIR EN VALLEE",
   cp: 72340,
   telephone: "243790071",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LA FERTE BERNARD",
   finessju: 720016112,
   id: 720016120,
   commune: "AVEZE",
   cp: 72400,
   telephone: "243933067",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LA MOINE",
   finessju: 490014677,
   id: 490014685,
   commune: "SEVREMOINE",
   cp: 49230,
   telephone: "241307001",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LA SANGUEZE",
   finessju: 490007952,
   id: 490007960,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241631932",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LA SUZE SUR SARTHE",
   finessju: 720016344,
   id: 720016351,
   commune: "LA SUZE SUR SARTHE",
   cp: 72210,
   telephone: "243773188",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LAIGNE LOIGNE",
   finessju: 530009208,
   id: 530009216,
   commune: "MARIGNE PEUTON",
   cp: 53200,
   telephone: "243062147",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LASSAY LES CHATEAUX",
   finessju: 530003375,
   id: 530003383,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243084696",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LAYON MARTIGNE",
   finessju: 490015146,
   id: 490015203,
   commune: "LYS HAUT LAYON",
   cp: 49540,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LE BREIL SUR MERIZE",
   finessju: 720015940,
   id: 720015957,
   commune: "SOULITRE",
   cp: 72370,
   telephone: "243766309",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LE HORPS",
   finessju: 530004050,
   id: 530004076,
   commune: "LE HORPS",
   cp: 53640,
   telephone: "243003020",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LE LION D ANGERS",
   finessju: 490014537,
   id: 490014545,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LE LOUET",
   finessju: 490013760,
   id: 490013786,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LE MENHIR DES MAUGES",
   finessju: 490014693,
   id: 490014701,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES BASSES VALLEES",
   finessju: 490014057,
   id: 490014065,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241426262",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES GENETS D'OR",
   finessju: 490015260,
   id: 490015294,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES MAUGES",
   finessju: 490015070,
   id: 490015104,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49510,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES MAUGES CHEMILLOISES",
   finessju: 490014420,
   id: 490014446,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES MINES D OR",
   finessju: 490014727,
   id: 490014735,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES PORTES D'ANGERS",
   finessju: 490015211,
   id: 490015245,
   commune: "VERRIERES EN ANJOU",
   cp: 49480,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES TROIS CHENES",
   finessju: 490008687,
   id: 490013976,
   commune: "LA SEGUINIERE",
   cp: 49280,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LES TUFFEAUX",
   finessju: 490013844,
   id: 490013885,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LOIR ET SARTHE",
   finessju: 490014602,
   id: 490014610,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LOIRE ET LAYON",
   finessju: 490014487,
   id: 490014495,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LOIRE PLAINE ET MAUGES",
   finessju: 490013711,
   id: 490013752,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LOIRON",
   finessju: 530004183,
   id: 530004191,
   commune: "LOIRON RUILLE",
   cp: 53320,
   telephone: "243660942",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LONGUE",
   finessju: 490014552,
   id: 490014560,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR LOUVERNE",
   finessju: 530004217,
   id: 530004233,
   commune: "LOUVERNE",
   cp: 53950,
   telephone: "243580291",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MALICORNE",
   finessju: 720016161,
   id: 720016187,
   commune: "VILLAINES SOUS MALICORNE",
   cp: 72270,
   telephone: "243458483",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MAROLLES LES BRAULTS",
   finessju: 720016195,
   id: 720016203,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974047",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MAUCERNAY",
   finessju: 490008257,
   id: 490008265,
   commune: "YZERNAY",
   cp: 49360,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MAYENNE ANGEVINE EST",
   finessju: 530003672,
   id: 530003854,
   commune: "BIERNE",
   cp: 53290,
   telephone: "243123432",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MERAL",
   finessju: 530004605,
   id: 530004621,
   commune: "MERAL",
   cp: 53230,
   telephone: "243692847",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MESLAY DU MAINE",
   finessju: 530031871,
   id: 530004266,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243987307",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MONTREUIL JUIGNE",
   finessju: 490014354,
   id: 490014362,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241424730",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MONTSURS",
   finessju: 530004316,
   id: 530004332,
   commune: "MONTSURS ST CENERE",
   cp: 53150,
   telephone: "243010146",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR MORANNES",
   finessju: 490014321,
   id: 490014347,
   commune: "MORANNES SUR SARTHE DAUMER",
   cp: 49640,
   telephone: "241427197",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR NEUILLY LE VENDIN",
   finessju: 530004340,
   id: 530004357,
   commune: "COUPTRAIN",
   cp: 53250,
   telephone: "243007101",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR NORD SEGREEN",
   finessju: 490012291,
   id: 490012325,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR NOYANT",
   finessju: 490014586,
   id: 490014594,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PARENTALITE +",
   finessju: 530007103,
   id: 530007111,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243591741",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PAYS ALLONNAIS",
   finessju: 490011723,
   id: 490011731,
   commune: "ALLONNES",
   cp: 49650,
   telephone: "241537745",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PAYS DE CHATEAUNEUF",
   finessju: 490012721,
   id: 490012762,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PAYS DE MAYENNE",
   finessju: 530003755,
   id: 530003763,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243080724",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PAYS DOUESSIN",
   finessju: 490014503,
   id: 490014511,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241834644",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PETIT ANJOU",
   finessju: 490014131,
   id: 490014180,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR POUANCE",
   finessju: 490014743,
   id: 490014750,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR PRE EN PAIL",
   finessju: 530004415,
   id: 530004423,
   commune: "PRE EN PAIL ST SAMSON",
   cp: 53140,
   telephone: "243112773",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR QUELAINES",
   finessju: 530009224,
   id: 530009232,
   commune: "QUELAINES ST GAULT",
   cp: 53360,
   telephone: "243372757",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR RIVES DU THOUET",
   finessju: 490014636,
   id: 490014644,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SABLE SUR SARTHE",
   finessju: 720016245,
   id: 720016252,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243926963",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT AIGNAN SUR ROE",
   finessju: 530004472,
   id: 530004506,
   commune: "ST AIGNAN SUR ROE",
   cp: 53390,
   telephone: "243074594",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT BERTHEVIN",
   finessju: 530004514,
   id: 530004522,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243694508",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT CALAIS",
   finessju: 720016260,
   id: 720016286,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243358121",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT DENIS DE GASTINES",
   finessju: 530009240,
   id: 530009257,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243047534",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT PIERRE DES LANDES",
   finessju: 530009265,
   id: 530009273,
   commune: "ST PIERRE DES LANDES",
   cp: 53500,
   telephone: "243050263",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT PIERRE DES NIDS",
   finessju: 530009281,
   id: 530009299,
   commune: "ST PIERRE DES NIDS",
   cp: 53370,
   telephone: "243044279",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAINT PIERRE LA COUR",
   finessju: 530004530,
   id: 530004563,
   commune: "ST PIERRE LA COUR",
   cp: 53410,
   telephone: "243694392",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SAVIGNE L'EVEQUE",
   finessju: 720016294,
   id: 720016302,
   commune: "SAVIGNE L EVEQUE",
   cp: 72460,
   telephone: "243275237",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SECTEUR BEAUFORT",
   finessju: 490012333,
   id: 490012341,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SEICHES SUR LE LOIR",
   finessju: 490013992,
   id: 490014016,
   commune: "SEICHES SUR LE LOIR",
   cp: 49140,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SILLE LE GUILLAUME",
   finessju: 720016310,
   id: 720016336,
   commune: "ST REMY DE SILLE",
   cp: 72140,
   telephone: "243202147",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SOLEIL FAMILLES",
   finessju: 490014271,
   id: 490014297,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ST CHRISTOPHE DU BOIS",
   finessju: 490007986,
   id: 490007994,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ST CHRISTOPHE DU BOIS",
   finessju: 490007986,
   id: 490008323,
   commune: "ST CHRISTOPHE DU BOIS",
   cp: 49280,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ST CHRISTOPHE DU BOIS",
   finessju: 490007986,
   id: 490013836,
   commune: "LA ROMAGNE",
   cp: 49740,
   telephone: "241703400",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ST FLORENT LE VIEIL",
   finessju: 490007861,
   id: 490007887,
   commune: "MAUGES SUR LOIRE",
   cp: 49410,
   telephone: "241879303",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ST GERGES DU BOIS-GEE",
   finessju: 490014305,
   id: 490014313,
   commune: "LES BOIS D ANJOU",
   cp: 49250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR ST SATURNIN",
   finessju: 720002369,
   id: 720014877,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243720500",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR STE GEMMES SUR LOIRE",
   finessju: 490014453,
   id: 490014461,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR SUD SEGREEN",
   finessju: 490011780,
   id: 490011863,
   commune: "ERDRE EN ANJOU",
   cp: 49370,
   telephone: "241952289",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR TORFOU LE LONGERON",
   finessju: 490014792,
   id: 490014800,
   commune: "SEVREMOINE",
   cp: 49660,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VAL DE L'OUDON",
   finessju: 490008653,
   id: 490013356,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VAL DE LOIRE",
   finessju: 490012994,
   id: 490013000,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VAL DU POETE",
   finessju: 490008232,
   id: 490013372,
   commune: "OREE D ANJOU",
   cp: 49530,
   telephone: "240095072",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VAL DU TREZON",
   finessju: 490008513,
   id: 490012432,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VALLE DE L'AUBANCE",
   finessju: 490012515,
   id: 490012531,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VIBRAYE",
   finessju: 720016393,
   id: 720016401,
   commune: "MONTMIRAIL",
   cp: 72320,
   telephone: "243936580",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VIHIERSOIS",
   finessju: 490011707,
   id: 490011715,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241758060",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADMR VILLAINES LA JUHEL",
   finessju: 530004704,
   id: 530004720,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243034504",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADOMI FACIL",
   finessju: 490018488,
   id: 490543352,
   commune: "CHOLET CEDEX",
   cp: 49321,
   telephone: "272772280",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ADVF VENDeE",
   finessju: 850027335,
   id: 850027343,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "768607417",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AFAD",
   finessju: 490543360,
   id: 490538998,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241652929",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AFAD",
   finessju: 490543360,
   id: 490543378,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241652929",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AI'DOM",
   finessju: 720002310,
   id: 720014414,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243813062",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AI'DOM",
   finessju: 720002310,
   id: 720014869,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243813062",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AID A DOM",
   finessju: 530005974,
   id: 530033430,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660528",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AIDE A DOMICILE DU PAYS DE RETZ",
   finessju: 440055879,
   id: 440055887,
   commune: "VILLENEUVE EN RETZ",
   cp: 44580,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AIDE ET MULTI PRESENCE",
   finessju: 490012861,
   id: 490012887,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241587977",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AIDEO DOMIDOM SERVICES",
   finessju: 490020864,
   id: 490020872,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241552601",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AIDIS DOMIDOM SERVICES",
   finessju: 490020880,
   id: 490020898,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241552737",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ALBANE AXEO SERVICES",
   finessju: 850027822,
   id: 850027830,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251225825",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ANJELY SERVICES 49",
   finessju: 490020930,
   id: 490020948,
   commune: "CHOLET",
   cp: 49300,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ANJOU SOINS SERVICES",
   finessju: 490535218,
   id: 490543329,
   commune: "ANGERS CEDEX 01",
   cp: 49010,
   telephone: "241662766",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ANTOCLEM SERVICES",
   finessju: 530009307,
   id: 530009315,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243651252",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD APF",
   finessju: 750719239,
   id: 440020816,
   commune: "NANTES CEDEX 3",
   cp: 44323,
   telephone: "251894500",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD APHMR",
   finessju: 490011533,
   id: 490011541,
   commune: "DURTAL",
   cp: 49430,
   telephone: "241763572",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ASSISTANCE ET PRESENCE",
   finessju: 490020435,
   id: 490020443,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241630034",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD ASSOCIATION PROXIM'SERVICES",
   finessju: 490011921,
   id: 490011947,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241207703",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AXEO SERVICES",
   finessju: 850027780,
   id: 850027798,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251688522",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD AZAE LA ROCHE SUR YON",
   finessju: 850027558,
   id: 850027566,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD BIEN A LA MAISON",
   finessju: 920029329,
   id: 490019775,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD BIEN DANS SA MAISON",
   finessju: 850027533,
   id: 850027541,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD BLANCHE DE CASTILLE BY HEURUS",
   finessju: 440055846,
   id: 490020765,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "249092350",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD C SAGE",
   finessju: 530009323,
   id: 530009331,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243534925",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS ANGERS",
   finessju: 490534732,
   id: 490011897,
   commune: "ANGERS CEDEX 02",
   cp: 49020,
   telephone: "241861010",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS CHATEAU GONTIER",
   finessju: 530031319,
   id: 530029800,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53204,
   telephone: "243095555",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS COULAINES",
   finessju: 720009646,
   id: 720004399,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243743510",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS DU MANS",
   finessju: 720009729,
   id: 720015361,
   commune: "LE MANS CEDEX 2",
   cp: 72015,
   telephone: "243745151",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS LA FERTE BERNARD",
   finessju: 720009687,
   id: 720004381,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243607274",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS LA FLECHE",
   finessju: 720009695,
   id: 720004415,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243485359",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS LAVAL",
   finessju: 530031178,
   id: 530029792,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243494747",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS LES PONTS DE CE",
   finessju: 490535796,
   id: 490543386,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241707572",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS MAYENNE",
   finessju: 530031160,
   id: 530029818,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243302137",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS MONTREUIL BELLAY",
   finessju: 490541018,
   id: 490537198,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241523090",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS SABLE SUR SARTHE",
   finessju: 720009752,
   id: 720004449,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243625076",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS ST BARTHELEMY",
   finessju: 490543428,
   id: 490543436,
   commune: "ST BARTHELEMY D ANJOU CEDE",
   cp: 49180,
   telephone: "241961260",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS ST CLEMENT DE LA PLAGE",
   finessju: 490543451,
   id: 490543469,
   commune: "ST CLEMENT DE LA PLACE",
   cp: 49370,
   telephone: "241779404",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS TRELAZE",
   finessju: 490536695,
   id: 490543477,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241337465",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD CCAS VAAS",
   finessju: 720013168,
   id: 720015833,
   commune: "VAAS",
   cp: 72500,
   telephone: "243468132",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD DOMICIL+ VENDeE",
   finessju: 660010216,
   id: 850027400,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251360115",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD DOMITYS",
   finessju: 750062077,
   id: 850027624,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "247517000",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD DOMITYS LE CLOS ST MARTIN",
   finessju: 750062077,
   id: 530009513,
   commune: "LOUVERNE",
   cp: 53950,
   telephone: "243684500",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD DOMITYS VENDEE",
   finessju: 750062077,
   id: 850027525,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251262800",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD EMELOU SERVICES",
   finessju: 530009356,
   id: 530009364,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243581159",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD FAMILLES RURALES GORRON",
   finessju: 530005123,
   id: 530005131,
   commune: "GORRON",
   cp: 53120,
   telephone: "243036819",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD GIRANDIERE AVRILLE",
   finessju: 770020824,
   id: 490020674,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD GREZ EN BOUERE",
   finessju: 530003995,
   id: 530004027,
   commune: "GREZ EN BOUERE",
   cp: 53290,
   telephone: "243073411",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD HANDISUP",
   finessju: 440051142,
   id: 440052702,
   commune: "REZE",
   cp: 44400,
   telephone: "251840398",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD HEURUS",
   finessju: 440055820,
   id: 440055838,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "249092350",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD HISIA SERVICES CRAON",
   finessju: 530009372,
   id: 530009380,
   commune: "CRAON",
   cp: 53400,
   telephone: "243063437",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD HISIA SERVICES ST FORT",
   finessju: 530009521,
   id: 530009539,
   commune: "ST FORT",
   cp: 53200,
   telephone: "243708910",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD JARDIN D'ARCADIE",
   finessju: 690007489,
   id: 530009398,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243072928",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD KER KALI KOT",
   finessju: 440055895,
   id: 440055903,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD LE PATIO",
   finessju: 530009406,
   id: 530009414,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "256280077",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD LE SABLIER SAUMUROIS",
   finessju: 490020682,
   id: 490020690,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241505364",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD LES SAISONNALES",
   finessju: 850027640,
   id: 850027632,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD MAINTIEN A DOM",
   finessju: 530009422,
   id: 530009430,
   commune: "LAVAL",
   cp: 53000,
   telephone: "256538675",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD MONTVAL SUR LOIR",
   finessju: 720009620,
   id: 720011923,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243448975",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD NELHER SERVICES",
   finessju: 530009448,
   id: 530009455,
   commune: "LAVAL",
   cp: 53000,
   telephone: "985254645",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD RESIDENCE HOME SERVICES",
   finessju: 770020824,
   id: 530008804,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243916900",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD RESIDENCES MAINTIEN ADOM",
   finessju: 530009547,
   id: 530009554,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243564268",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD SAINT-PIERRE",
   finessju: 490004355,
   id: 490540556,
   commune: "MURS ERIGNE",
   cp: 49610,
   telephone: "241577139",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD SARL 02 ANGERS OUEST",
   finessju: 490020500,
   id: 490020518,
   commune: "ANGERS",
   cp: 49100,
   telephone: "775280712",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD SENIORS SANTE 85",
   finessju: 850026832,
   id: 850026840,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD SMS DOMICILE",
   finessju: 530009463,
   id: 530009471,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243560378",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD SOUTIEN A DOMICILE",
   finessju: 720021013,
   id: 720021021,
   commune: "LE MANS CEDEX 2",
   cp: 72015,
   telephone: "243397500",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD VIE A DOMICILE",
   finessju: 490536554,
   id: 490538881,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "241472122",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD VITALLIANCE",
   finessju: 920028537,
   id: 530009489,
   commune: "LAVAL",
   cp: 53000,
   telephone: "667395597",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAAD YOD",
   finessju: 530009497,
   id: 530009505,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "980773145",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAD FEDERATION ADMR DE VENDEE",
   finessju: 850012444,
   id: 850026790,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAD SAJECO AXEO SERVICES",
   finessju: 850027319,
   id: 850027327,
   commune: "ST REVEREND",
   cp: 85220,
   telephone: "251603699",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAP-44",
   finessju: 440053775,
   id: 440053783,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SARL SERVICE DOM",
   finessju: 440054484,
   id: 440054492,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAS DISSARD LANGLOIS",
   finessju: 440054468,
   id: 440054476,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAS MELODY ET HARMONY",
   finessju: 440054443,
   id: 440054450,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAS O SERVICES 44",
   finessju: 440054740,
   id: 440054757,
   commune: "NANTES",
   cp: 44000,
   telephone: "674305354",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SAS VIZEN",
   finessju: 440054427,
   id: 440054435,
   commune: "CLISSON",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERV AIDE FAMILLES EN DIFFICULTE",
   finessju: 440004059,
   id: 440045557,
   commune: "VERTOU CEDEX",
   cp: 44123,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERV AUXILIAIRES VIE MUTUALITE",
   finessju: 850013350,
   id: 850012337,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251621629",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERV INTERVENTION SOCIALE ET FAMILIALE",
   finessju: 440006369,
   id: 440045359,
   commune: "NANTES CEDEX 4",
   cp: 44187,
   telephone: "251806120",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERV REPAS DOMICILE PERS AGEES",
   finessju: 440041515,
   id: 440018844,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251719219",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERV. D'ACCOMPAGNEMENT a LA PROMENADE",
   finessju: 720009729,
   id: 720017821,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243745261",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERV.REPAS DOMICILE PERS.AGEES",
   finessju: 440018455,
   id: 440023976,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "251783100",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE ADMR AIDE AUX FAMILLES",
   finessju: 850012444,
   id: 850011214,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85001,
   telephone: "251443738",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE",
   finessju: 440004521,
   id: 440017606,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "240014801",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE",
   finessju: 440036895,
   id: 440036937,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE",
   finessju: 440036952,
   id: 440036960,
   commune: "MAISDON SUR SEVRE",
   cp: 44690,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE CHAPELLE HEULIN",
   finessju: 440036663,
   id: 440036671,
   commune: "LA HAIE FOUASSIERE",
   cp: 44690,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE DOMICILE",
   finessju: 850013681,
   id: 850007659,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251972293",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE DOMICILE",
   finessju: 850013731,
   id: 850007691,
   commune: "MOUCHAMPS",
   cp: 85640,
   telephone: "251671658",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE DOMICILE",
   finessju: 850012535,
   id: 850007774,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85021,
   telephone: "251053140",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE DOMICILE",
   finessju: 850012667,
   id: 850008939,
   commune: "CHATEAU D OLONNE",
   cp: 85180,
   telephone: "251952612",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE MENAGERE PERS.AGEE",
   finessju: 440037075,
   id: 440017770,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240868051",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDES MENAGERES",
   finessju: 850012782,
   id: 850025297,
   commune: "LUCON",
   cp: 85400,
   telephone: "251290657",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AUXILIAIRES DE VIE",
   finessju: 440036481,
   id: 440036846,
   commune: "GETIGNE",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AUXILIAIRES DE VIE",
   finessju: 440036515,
   id: 440036853,
   commune: "GORGES",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AUXILIAIRES DE VIE",
   finessju: 440036861,
   id: 440036887,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AUXILIAIRES DE VIE",
   finessju: 440036895,
   id: 440036911,
   commune: "LEGE",
   cp: 44650,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AUXILIAIRES DE VIE",
   finessju: 440037786,
   id: 440037802,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AUXILIAIRES DE VIE",
   finessju: 440036614,
   id: 440040020,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE A DOMICILE",
   finessju: 850005356,
   id: 850005364,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251498603",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 490013927,
   id: 490013935,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241672759",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 490014891,
   id: 490014917,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 490014966,
   id: 490014974,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 490014966,
   id: 490014982,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 490014966,
   id: 490014990,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 490541190,
   id: 490015252,
   commune: "SOMLOIRE",
   cp: 49360,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 850020330,
   id: 850009176,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251218745",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 850009184,
   id: 850009192,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 850001173,
   id: 850009200,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE AUX PERSONNES",
   finessju: 850009226,
   id: 850009234,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE FAMILLES EN DIFFICULTE",
   finessju: 440005387,
   id: 440045532,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004075,
   id: 440012292,
   commune: "ST AUBIN DES CHATEAUX",
   cp: 44110,
   telephone: "240812713",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004109,
   id: 440012318,
   commune: "ISSE",
   cp: 44520,
   telephone: "240551923",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004117,
   id: 440012326,
   commune: "LA PLANCHE",
   cp: 44140,
   telephone: "240319276",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004125,
   id: 440012383,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792424",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004174,
   id: 440012417,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240834303",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004182,
   id: 440012425,
   commune: "LA CHEVROLIERE",
   cp: 44118,
   telephone: "240043015",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004240,
   id: 440012490,
   commune: "LE BIGNON",
   cp: 44140,
   telephone: "240267306",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004273,
   id: 440012508,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240816107",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440030914,
   id: 440012557,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240783514",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004307,
   id: 440012565,
   commune: "ROUGE",
   cp: 44660,
   telephone: "240288520",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004612,
   id: 440017762,
   commune: "SOULVACHE",
   cp: 44660,
   telephone: "240288052",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004653,
   id: 440017796,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240834257",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440027456,
   id: 440017804,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240035556",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004687,
   id: 440017820,
   commune: "VAIR SUR LOIRE",
   cp: 44150,
   telephone: "240830070",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440034478,
   id: 440034486,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240963189",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440012284,
   id: 440034528,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "240889001",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004109,
   id: 440034536,
   commune: "ISSE",
   cp: 44520,
   telephone: "240551923",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440012342,
   id: 440034569,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240969570",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440005387,
   id: 440034585,
   commune: "NANTES",
   cp: 44100,
   telephone: "240582158",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004067,
   id: 440034593,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240828805",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440004059,
   id: 440034676,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240020730",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036481,
   id: 440036507,
   commune: "GETIGNE",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036515,
   id: 440036523,
   commune: "GORGES",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036614,
   id: 440036622,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036630,
   id: 440036655,
   commune: "BESNE",
   cp: 44160,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036705,
   id: 440036713,
   commune: "CHATEAU THEBAUD",
   cp: 44690,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036754,
   id: 440036762,
   commune: "DERVAL",
   cp: 44590,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036754,
   id: 440036770,
   commune: "LUSANGER",
   cp: 44590,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036754,
   id: 440036796,
   commune: "ST VINCENT DES LANDES",
   cp: 44590,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036754,
   id: 440036804,
   commune: "SION LES MINES",
   cp: 44590,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036895,
   id: 440036903,
   commune: "LEGE",
   cp: 44650,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036895,
   id: 440036945,
   commune: "TOUVOIS",
   cp: 44650,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036952,
   id: 440036986,
   commune: "MONNIERES",
   cp: 44690,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440036994,
   id: 440037000,
   commune: "MONTBERT",
   cp: 44140,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440037026,
   id: 440037034,
   commune: "POUILLE LES COTEAUX",
   cp: 44522,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440037042,
   id: 440037067,
   commune: "REMOUILLE",
   cp: 44140,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440037133,
   id: 440037141,
   commune: "LE TEMPLE DE BRETAGNE",
   cp: 44360,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440037174,
   id: 440037182,
   commune: "GENESTON",
   cp: 44140,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 440037786,
   id: 440037794,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 530000652,
   id: 530033455,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243911979",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005273,
   id: 850005281,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005307,
   id: 850005315,
   commune: "ANTIGNY",
   cp: 85120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005323,
   id: 850005331,
   commune: "BEAUREPAIRE",
   cp: 85500,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850013434,
   id: 850005372,
   commune: "BENET",
   cp: 85490,
   telephone: "251873754",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005380,
   id: 850005406,
   commune: "LA BERNARDIERE",
   cp: 85610,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005414,
   id: 850005422,
   commune: "BOIS DE CENE",
   cp: 85710,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005430,
   id: 850005455,
   commune: "LA BOISSIERE DE MONTAIGU",
   cp: 85600,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005471,
   id: 850005497,
   commune: "BOUFFERE",
   cp: 85600,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005505,
   id: 850005513,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005521,
   id: 850005547,
   commune: "LA BRUFFIERE",
   cp: 85530,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005554,
   id: 850005562,
   commune: "LA CAILLERE ST HILAIRE",
   cp: 85410,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005570,
   id: 850005596,
   commune: "LA CHAIZE LE VICOMTE",
   cp: 85310,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005604,
   id: 850005612,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005646,
   id: 850005653,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696041",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005661,
   id: 850005687,
   commune: "CHAVAGNES EN PAILLERS",
   cp: 85250,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005695,
   id: 850005703,
   commune: "COEX",
   cp: 85220,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005711,
   id: 850005737,
   commune: "CUGAND",
   cp: 85610,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850013582,
   id: 850005745,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005786,
   id: 850005794,
   commune: "DOIX LES FONTAINES",
   cp: 85200,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005802,
   id: 850005810,
   commune: "FOUSSAIS PAYRE",
   cp: 85240,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005836,
   id: 850005844,
   commune: "FROIDFOND",
   cp: 85300,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005885,
   id: 850005893,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251672077",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850014143,
   id: 850005901,
   commune: "L HERMENAULT",
   cp: 85570,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005927,
   id: 850005935,
   commune: "MAILLE",
   cp: 85420,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005943,
   id: 850005950,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005976,
   id: 850005984,
   commune: "MOUILLERON ST GERMAIN",
   cp: 85390,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850005992,
   id: 850006016,
   commune: "NALLIERS",
   cp: 85370,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850006024,
   id: 850006032,
   commune: "NESMY",
   cp: 85310,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850006040,
   id: 850006057,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850006065,
   id: 850006073,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850006115,
   id: 850006123,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850006156,
   id: 850006164,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850006503,
   id: 850006453,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850012717,
   id: 850025248,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251694648",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850012659,
   id: 850025255,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696041",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850025263,
   id: 850025271,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85001,
   telephone: "251372402",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AIDE MENAGERE",
   finessju: 850012923,
   id: 850025305,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404445",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D AUXILIAIRES DE VIE",
   finessju: 440037026,
   id: 440040046,
   commune: "POUILLE LES COTEAUX",
   cp: 44522,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE A DOMICILE",
   finessju: 850012972,
   id: 850025313,
   commune: "TIFFAUGES",
   cp: 85130,
   telephone: "251657225",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX FAMILLES",
   finessju: 440041960,
   id: 440041986,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440031946,
   id: 440038495,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240450779",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440051126,
   id: 440038594,
   commune: "ST NICOLAS DE REDON",
   cp: 44460,
   telephone: "299722377",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440033835,
   id: 440039675,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240788266",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440018620,
   id: 440039717,
   commune: "NANTES",
   cp: 44200,
   telephone: "240412777",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440005387,
   id: 440040111,
   commune: "NANTES",
   cp: 44100,
   telephone: "251806272",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440040202,
   id: 440040210,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240508681",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440041481,
   id: 440041507,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440041671,
   id: 440041697,
   commune: "NANTES CEDEX 4",
   cp: 44105,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX P.A.",
   finessju: 440005791,
   id: 440041713,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERS. AGEES",
   finessju: 440039907,
   id: 440039915,
   commune: "NANTES CEDEX 1",
   cp: 44007,
   telephone: "240481171",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERS. AGEES",
   finessju: 440039931,
   id: 440039956,
   commune: "VALLET",
   cp: 44330,
   telephone: "240364469",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERS. AGEES",
   finessju: 440040053,
   id: 440040061,
   commune: "NANTES",
   cp: 44000,
   telephone: "240950966",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERS. AGEES",
   finessju: 440040152,
   id: 440040160,
   commune: "PORNIC",
   cp: 44210,
   telephone: "251741101",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERSONNES",
   finessju: 440039832,
   id: 440039840,
   commune: "REZE",
   cp: 44400,
   telephone: "240050084",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERSONNES AGEES",
   finessju: 440006351,
   id: 440039634,
   commune: "NANTES CEDEX 4",
   cp: 44104,
   telephone: "240737309",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERSONNES AGEES",
   finessju: 440039782,
   id: 440039790,
   commune: "ST AIGNAN GRANDLIEU",
   cp: 44860,
   telephone: "240264072",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX PERSONNES AGEES",
   finessju: 440004497,
   id: 440041705,
   commune: "PUCEUL",
   cp: 44390,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440003986,
   id: 440012144,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240022255",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440003994,
   id: 440012151,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240790008",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037760,
   id: 440012169,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970034",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440004000,
   id: 440012185,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240295332",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440041515,
   id: 440012250,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251719219",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440018570,
   id: 440012276,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240032525",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440018489,
   id: 440012334,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240249957",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440018513,
   id: 440012375,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240283804",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440018448,
   id: 440012391,
   commune: "REZE",
   cp: 44400,
   telephone: "251702900",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440004620,
   id: 440017788,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240796014",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037133,
   id: 440022549,
   commune: "VIGNEUX DE BRETAGNE",
   cp: 44360,
   telephone: "240571202",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037711,
   id: 440022895,
   commune: "FERCE",
   cp: 44660,
   telephone: "240288115",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037703,
   id: 440022903,
   commune: "ST HILAIRE DE CHALEONS",
   cp: 44680,
   telephone: "240544212",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037687,
   id: 440022952,
   commune: "ST LEGER LES VIGNES",
   cp: 44710,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037661,
   id: 440022978,
   commune: "ST COLOMBAN",
   cp: 44310,
   telephone: "240058027",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037653,
   id: 440022986,
   commune: "RUFFIGNE",
   cp: 44660,
   telephone: "240288455",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037646,
   id: 440023018,
   commune: "PANNECE",
   cp: 44440,
   telephone: "240972144",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037620,
   id: 440023075,
   commune: "LA LIMOUZINIERE",
   cp: 44310,
   telephone: "240058366",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440036861,
   id: 440023091,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "240549371",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440036556,
   id: 440023125,
   commune: "BOUSSAY",
   cp: 44190,
   telephone: "240068444",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440036721,
   id: 440023166,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213786",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037752,
   id: 440024743,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970357",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440012268,
   id: 440024768,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240569001",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440013076,
   id: 440024826,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240526060",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440037745,
   id: 440025435,
   commune: "ST HILAIRE DE CLISSON",
   cp: 44190,
   telephone: "240780456",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440018562,
   id: 440025641,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320202",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440041721,
   id: 440041747,
   commune: "VALLET",
   cp: 44330,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 440036952,
   id: 440041796,
   commune: "ST LUMINE DE CLISSON",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 490534732,
   id: 490543337,
   commune: "ANGERS CEDEX 01",
   cp: 49035,
   telephone: "241443787",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 720009604,
   id: 720011931,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243293020",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 850005752,
   id: 850005760,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 850012915,
   id: 850025289,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251951221",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE",
   finessju: 850025321,
   id: 850025339,
   commune: "ST HILAIRE DES LOGES",
   cp: 85240,
   telephone: "251521023",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE MENAGERE MOTHE ACHARD",
   finessju: 850006081,
   id: 850006107,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251466946",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AUXILIAIRES DE VIE-ADOMI FAC",
   finessju: 490018488,
   id: 490538899,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241650147",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE LAVAGE DU LINGE",
   finessju: 850012535,
   id: 850020777,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251053140",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE LAVAGE DU LINGE",
   finessju: 850013012,
   id: 850020785,
   commune: "BARBATRE",
   cp: 85630,
   telephone: "251396858",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE LAVAGE DU LINGE",
   finessju: 850012923,
   id: 850020793,
   commune: "ST GERMAIN DE PRINCAY",
   cp: 85110,
   telephone: "251404382",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE PORTAGE DE REPAS A DOMICILE",
   finessju: 720009729,
   id: 720017672,
   commune: "LE MANS CEDEX 2",
   cp: 72015,
   telephone: "243745151",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE REPAS A DOMICILE",
   finessju: 850012782,
   id: 850017617,
   commune: "LUCON",
   cp: 85400,
   telephone: "251290657",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE REPAS A DOMICILE",
   finessju: 850012766,
   id: 850017625,
   commune: "LONGEVILLE SUR MER",
   cp: 85560,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE TELE-ALARME",
   finessju: 850013640,
   id: 850020769,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251583867",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE TRAVAILLEUSES FAMILIA",
   finessju: 440005387,
   id: 440022531,
   commune: "NANTES",
   cp: 44100,
   telephone: "240582158",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DE TRAVAILLEUSES FAMILIA",
   finessju: 850011206,
   id: 850005463,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE REPAS A DOMICILE",
   finessju: 850025867,
   id: 850016460,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE REPAS A DOMICILE",
   finessju: 850012857,
   id: 850017609,
   commune: "NALLIERS",
   cp: 85370,
   telephone: "251307019",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE TRAVAILLEUSES FAMILIALES",
   finessju: 440036812,
   id: 440036820,
   commune: "FEGREAC",
   cp: 44460,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE TRAVAILLEUSES FAMILIALES",
   finessju: 440037174,
   id: 440037190,
   commune: "GENESTON",
   cp: 44140,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE TRAVAILLEUSES FAMILIALES",
   finessju: 440005387,
   id: 440037471,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE TRAVAILLEUSES FAMILIALES",
   finessju: 440037604,
   id: 440037612,
   commune: "CLISSON",
   cp: 44190,
   telephone: "-",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SIDI BLUE",
   finessju: 850027384,
   id: 850027392,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251395964",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SOL'AID",
   finessju: 440054708,
   id: 440054716,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "658921851",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "UNA DU SAMUROIS",
   finessju: 490543394,
   id: 490543402,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241510137",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "VILLAS GINKGOS - BELLES RIVES",
   finessju: 850026444,
   id: 440053619,
   commune: "REZE",
   cp: 44400,
   telephone: "662532775",
   categorie: "Service d'Aide et d'Accompagnement a Domicile (S.A.A.D.)"
 },
 {
   finess: "FINESS",
   name: "A.B.C TRANSPORTS",
   finessju: 720016948,
   id: 720016989,
   commune: "ARNAGE",
   cp: 72230,
   telephone: "243211974",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ABC AMBULANCES OCEANES",
   finessju: 440027845,
   id: 440027852,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240156666",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ABC AMBULANCES OCEANES",
   finessju: 440027845,
   id: 440027860,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240602434",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ABC AMBULANCES OCEANES",
   finessju: 440027845,
   id: 440030807,
   commune: "LE CROISIC",
   cp: 44490,
   telephone: "240230617",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ABC AMBULANCES OCEANES",
   finessju: 440027845,
   id: 440043305,
   commune: "LA TURBALLE",
   cp: 44420,
   telephone: "240234633",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ABM AMBULANCE",
   finessju: 440023273,
   id: 440030302,
   commune: "ISSE",
   cp: 44520,
   telephone: "240551911",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ABM AMBULANCE",
   finessju: 440023273,
   id: 440041937,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240795397",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ACTUELLE AMBULANCE BARON",
   finessju: 440043396,
   id: 440043404,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240331676",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ADS AMBULANCES",
   finessju: 720017763,
   id: 720017771,
   commune: "ST REMY DE SILLE",
   cp: 72140,
   telephone: "243208829",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AIZENAY AMBULANCES",
   finessju: 850019019,
   id: 850006677,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251381376",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALIZE AMBULANCES",
   finessju: 720012632,
   id: 720012657,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243409031",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALIZE AMBULANCES PARIGNE",
   finessju: 720012632,
   id: 720012640,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243758095",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALIZES AMBULANCES (SARL)",
   finessju: 850010109,
   id: 850010117,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251550426",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALIZES AMBULANCES (SARL)",
   finessju: 850010109,
   id: 850010208,
   commune: "LE FENOUILLER",
   cp: 85800,
   telephone: "251541455",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCE",
   finessju: 440045219,
   id: 440045227,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240544545",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCE",
   finessju: 440045219,
   id: 440045961,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "240544545",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCE",
   finessju: 850023755,
   id: 850008160,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251372020",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCES",
   finessju: 720012806,
   id: 720012814,
   commune: "LA CHARTRE SUR LE LOIR",
   cp: 72340,
   telephone: "243444153",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCES",
   finessju: 720012806,
   id: 720012822,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243217955",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCES",
   finessju: 720012806,
   id: 720014026,
   commune: "MULSANNE",
   cp: 72230,
   telephone: "243420553",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALLIANCE AMBULANCES",
   finessju: 720012806,
   id: 720016609,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "243426282",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALPHA AMBULANCES",
   finessju: 720011790,
   id: 720011816,
   commune: "PARIGNE L EVEQUE",
   cp: 72250,
   telephone: "243752131",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ALPHA AMBULANCES LE GD LUCE",
   finessju: 720011790,
   id: 720011857,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243409430",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMAB AMBULANCES COMBREENNES",
   finessju: 490007671,
   id: 490007697,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "241617355",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE 49",
   finessju: 490009024,
   id: 490009032,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241250025",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE AURORE (SARL)",
   finessju: 850010679,
   id: 850008517,
   commune: "ST VINCENT STERLANGES",
   cp: 85110,
   telephone: "251402616",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BARANGER UZUREAU",
   finessju: 490013109,
   id: 490008943,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241307028",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BARANGER UZUREAU",
   finessju: 490013109,
   id: 490011137,
   commune: "TREMENTINES",
   cp: 49340,
   telephone: "241629300",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BEAULIEU SARL",
   finessju: 850010125,
   id: 850010133,
   commune: "BEAULIEU SOUS LA ROCHE",
   cp: 85190,
   telephone: "251982313",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BELLEC",
   finessju: 440046175,
   id: 440033355,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240821009",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BELLION (SARL)",
   finessju: 850021825,
   id: 850008442,
   commune: "ST MESMIN",
   cp: 85700,
   telephone: "251919761",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BELVERINE S.A.R.L.",
   finessju: 850009846,
   id: 850009853,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251687015",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BILY",
   finessju: 440047157,
   id: 440027266,
   commune: "GUENROUET",
   cp: 44530,
   telephone: "240876507",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BIMIER",
   finessju: 440047322,
   id: 440045201,
   commune: "DISTRE",
   cp: 49400,
   telephone: "241392054",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BIROT BIRON",
   finessju: 440047421,
   id: 440047439,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240023125",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BIZOT CHRISTIAN",
   finessju: 490009412,
   id: 490009420,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241922547",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BLANC",
   finessju: 490009446,
   id: 490009453,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241529064",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BLANC",
   finessju: 490009446,
   id: 490009487,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241526198",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BLOUIN-JEGO (SARL)",
   finessju: 490009495,
   id: 490009503,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241758084",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BOSCEENNE",
   finessju: 440024560,
   id: 440024750,
   commune: "BOUAYE",
   cp: 44830,
   telephone: "240326161",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BOULISSIERE-NOEL SARL",
   finessju: 490009545,
   id: 490009552,
   commune: "CHALONNES SUR LOIRE",
   cp: 49290,
   telephone: "241780121",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BOYER",
   finessju: 530007350,
   id: 530006410,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243051613",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BREMENT",
   finessju: 850008723,
   id: 850008756,
   commune: "VOUVANT",
   cp: 85120,
   telephone: "251008539",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BRETAGNE",
   finessju: 440042539,
   id: 440042588,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240787031",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BREVINOISE",
   finessju: 440028256,
   id: 440030351,
   commune: "PAIMBOEUF",
   cp: 44560,
   telephone: "240275161",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BREVINOISE",
   finessju: 440028256,
   id: 440035293,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "240644564",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BRISSON CHARLOTTE",
   finessju: 850023300,
   id: 850006792,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251687015",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE BURGAUD JEAN-LUC",
   finessju: 850023359,
   id: 850008012,
   commune: "LE PERRIER",
   cp: 85300,
   telephone: "251680907",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE CHALLANDAISE",
   finessju: 850019340,
   id: 850006974,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251930187",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE CHOLETAISE",
   finessju: 490015898,
   id: 490008984,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241621250",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE COTE DE LUMIERE",
   finessju: 850023276,
   id: 850008467,
   commune: "ST MICHEL EN L HERM",
   cp: 85580,
   telephone: "251271333",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE D'ORVAULT",
   finessju: 440029460,
   id: 440029486,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240630813",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DE L'ESTUAIRE",
   finessju: 440045235,
   id: 440045243,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "240889872",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DE L'ILE ET TAXI (SARL)",
   finessju: 850009861,
   id: 850009879,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251394080",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DE LA SEVRE",
   finessju: 850009887,
   id: 850008582,
   commune: "LA VERRIE",
   cp: 85130,
   telephone: "251654340",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DES PAYS DE LOIRE",
   finessju: 440040939,
   id: 440039139,
   commune: "NANTES",
   cp: 44300,
   telephone: "251890101",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DES PAYS DE LOIRE",
   finessju: 440040939,
   id: 440040988,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240259616",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DES PAYS DE LOIRE",
   finessju: 440040939,
   id: 440043297,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "240726099",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DES TROIS RIVIERES",
   finessju: 440039378,
   id: 440031250,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240796024",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DES TROIS RIVIERES",
   finessju: 440039378,
   id: 440047686,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240793113",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DOUILLARD",
   finessju: 440028454,
   id: 440024883,
   commune: "BOUSSAY",
   cp: 44190,
   telephone: "240068620",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DOUILLARD",
   finessju: 440028454,
   id: 440028462,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240039205",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DOUILLARD",
   finessju: 440028454,
   id: 440031334,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "240044860",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DOUILLARD",
   finessju: 440028454,
   id: 440043321,
   commune: "VALLET",
   cp: 44330,
   telephone: "240339212",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DOUILLARD",
   finessju: 440028454,
   id: 440043487,
   commune: "AIGREFEUILLE SUR MAINE",
   cp: 44140,
   telephone: "240066049",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DOUILLARD",
   finessju: 440028454,
   id: 440047272,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DU BECONNAIS",
   finessju: 490009263,
   id: 490009271,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241774173",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE DU PAYS DE POUZAUGES",
   finessju: 850018789,
   id: 850018805,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251649568",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ET TAXIS BLINEAU",
   finessju: 850019316,
   id: 850006727,
   commune: "BEAUVOIR SUR MER",
   cp: 85230,
   telephone: "251687279",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE FAIVRE",
   finessju: 850010166,
   id: 850006883,
   commune: "BOURNEZEAU",
   cp: 85480,
   telephone: "251407351",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GARNACHOISE",
   finessju: 850023391,
   id: 850007485,
   commune: "LA GARNACHE",
   cp: 85710,
   telephone: "251494016",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GODREAU-VILLAIN",
   finessju: 850020587,
   id: 850007071,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251469319",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GORRONNAISE MAINE",
   finessju: 530006501,
   id: 530006527,
   commune: "GORRON",
   cp: 53120,
   telephone: "243086182",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GORRONNAISE NORMANDIE",
   finessju: 530006501,
   id: 530007525,
   commune: "GORRON",
   cp: 53120,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GOULEAU",
   finessju: 440043339,
   id: 440043347,
   commune: "VALLET",
   cp: 44330,
   telephone: "240339133",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GRATON",
   finessju: 850008764,
   id: 850008772,
   commune: "CUGAND",
   cp: 85610,
   telephone: "251436969",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GRATON",
   finessju: 850008764,
   id: 850010927,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251064126",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GRATON DU NORD VENDEE",
   finessju: 850008764,
   id: 440049922,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240540653",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GREAUD GRANGER",
   finessju: 850024340,
   id: 850007725,
   commune: "BENET",
   cp: 85490,
   telephone: "251529878",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUEMENEENNE",
   finessju: 440026904,
   id: 440026953,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792059",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUERIN",
   finessju: 440041788,
   id: 440041838,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240974285",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUERIN GESLIN A MESANGER",
   finessju: 440041788,
   id: 440048494,
   commune: "MESANGER",
   cp: 44522,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUIGUENE GARCIA",
   finessju: 440037398,
   id: 440025393,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240282727",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUIHO",
   finessju: 440026987,
   id: 440027027,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240792004",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUILLOU AILLERIE",
   finessju: 440023562,
   id: 440041218,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240988040",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUILLOU AILLERIE",
   finessju: 440023562,
   id: 440048510,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUILLOU-AILLERIE",
   finessju: 440023562,
   id: 440023596,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240988040",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUILLOU-AILLERIE",
   finessju: 440023562,
   id: 440029015,
   commune: "MESANGER",
   cp: 44522,
   telephone: "240988040",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUILLOU-AILLERIE",
   finessju: 440023562,
   id: 440041168,
   commune: "ST MARS DU DESERT",
   cp: 44850,
   telephone: "240988040",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUINGUENE GARCIA",
   finessju: 440037398,
   id: 440025963,
   commune: "DERVAL",
   cp: 44590,
   telephone: "240077014",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE GUIVARCH",
   finessju: 850008780,
   id: 850008806,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251542312",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE HELIGON",
   finessju: 440037018,
   id: 440023927,
   commune: "AVESSAC",
   cp: 44460,
   telephone: "299910506",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE HEMERY (SARL)",
   finessju: 850019332,
   id: 850006966,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251680609",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE JACQUES CHASSELOUP",
   finessju: 490009651,
   id: 490009685,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "240835101",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE L'ALOUETTE",
   finessju: 850010737,
   id: 850007394,
   commune: "LES EPESSES",
   cp: 85590,
   telephone: "251612156",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE L'ALOUETTE",
   finessju: 850010737,
   id: 850007535,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251669754",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE L'ALOUETTE",
   finessju: 850010737,
   id: 850010877,
   commune: "VENDRENNES",
   cp: 85250,
   telephone: "251633648",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE LEGEENNE",
   finessju: 440027993,
   id: 440028025,
   commune: "LEGE",
   cp: 44650,
   telephone: "240049320",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE LEGEENNE",
   finessju: 440027993,
   id: 440043198,
   commune: "TOUVOIS",
   cp: 44650,
   telephone: "240316362",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE LIGERIENNE",
   finessju: 440049286,
   id: 440049294,
   commune: "VALLET",
   cp: 44330,
   telephone: "240339133",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE LOIRE ET SILLON",
   finessju: 440037208,
   id: 440037299,
   commune: "COUERON",
   cp: 44220,
   telephone: "240380812",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE LOIRE ET SILLON",
   finessju: 440037208,
   id: 440043461,
   commune: "VIGNEUX DE BRETAGNE",
   cp: 44360,
   telephone: "240571515",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE LORETAINE",
   finessju: 490017027,
   id: 490010121,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241774566",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE MARTIN",
   finessju: 440043438,
   id: 440043446,
   commune: "VIEILLEVIGNE",
   cp: 44116,
   telephone: "240265185",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE MAURICE BRISSAC SARL",
   finessju: 490010535,
   id: 490010543,
   commune: "BRISSAC LOIRE AUBANCE",
   cp: 49320,
   telephone: "241548232",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE MORINEAU",
   finessju: 850023367,
   id: 850008566,
   commune: "SOULLANS",
   cp: 85300,
   telephone: "251680861",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PACAUD ALLAIS",
   finessju: 440048486,
   id: 440033702,
   commune: "PREFAILLES",
   cp: 44770,
   telephone: "240216029",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PATRICK CLANIS",
   finessju: 850021106,
   id: 850007857,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251056262",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PATRICK JOUAN",
   finessju: 440045466,
   id: 440045474,
   commune: "ST NICOLAS DE REDON",
   cp: 44460,
   telephone: "299722300",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PATRON PASCAL",
   finessju: 440043008,
   id: 440043099,
   commune: "LE TEMPLE DE BRETAGNE",
   cp: 44360,
   telephone: "240570202",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PRECERON",
   finessju: 490013109,
   id: 490010352,
   commune: "LYS HAUT LAYON",
   cp: 49310,
   telephone: "241561055",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PRESQU'ILE SECOURS",
   finessju: 440027746,
   id: 440027324,
   commune: "HERBIGNAC",
   cp: 44410,
   telephone: "251769797",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE PROUANTAISE",
   finessju: 850019233,
   id: 850006800,
   commune: "LE BOUPERE",
   cp: 85510,
   telephone: "251914415",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE RENAULT DANIEL",
   finessju: 850011024,
   id: 850011032,
   commune: "LA CHAIZE LE VICOMTE",
   cp: 85310,
   telephone: "251314327",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE RENE RONDEAU",
   finessju: 850023292,
   id: 850006941,
   commune: "CHAILLE LES MARAIS",
   cp: 85450,
   telephone: "251567446",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE RETZ OCEAN",
   finessju: 440026342,
   id: 440026383,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "240397415",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ROBIN",
   finessju: 440024925,
   id: 440024941,
   commune: "BOUVRON",
   cp: 44130,
   telephone: "240563529",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ROUAUD",
   finessju: 440024313,
   id: 440024370,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240790355",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ROUAUD",
   finessju: 440024313,
   id: 440048528,
   commune: "LE GAVRE",
   cp: 44130,
   telephone: "240790355",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ROUILLER",
   finessju: 490010873,
   id: 490012408,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "241757333",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ROUILLER-FOUCHE",
   finessju: 490010873,
   id: 490010907,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241705954",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE ROUILLER-FOUCHE",
   finessju: 490010873,
   id: 490010915,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241701600",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE SARTHOISE- PHILVERT",
   finessju: 720020676,
   id: 720020684,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243954533",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE SERVICE URGENCE",
   finessju: 440047215,
   id: 440047223,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "240400004",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE SERVICE URGENCE",
   finessju: 440047215,
   id: 440048502,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE STE HERMINE 49 RTE NANTES",
   finessju: 850023748,
   id: 850008301,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251273118",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE STE HERMINE 101 RTE NANTES",
   finessju: 850023748,
   id: 850009903,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251278583",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE STE-THERESE",
   finessju: 440045300,
   id: 440029197,
   commune: "NANTES",
   cp: 44000,
   telephone: "240400040",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE SUD VENDEENNE GUYET BREMAND",
   finessju: 850023748,
   id: 850018201,
   commune: "LONGEVES",
   cp: 85200,
   telephone: "251693728",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE TAL YON",
   finessju: 850010083,
   id: 850010091,
   commune: "TALMONT ST HILAIRE",
   cp: 85440,
   telephone: "251052115",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE TAXI BARRE",
   finessju: 440052918,
   id: 440045326,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "240053903",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE TAXI HAUT ANJOU",
   finessju: 490010311,
   id: 490010337,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241954900",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE THOMAS",
   finessju: 850001231,
   id: 850021403,
   commune: "ST HILAIRE DES LOGES",
   cp: 85240,
   telephone: "251521388",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE TRELAZEENNE",
   finessju: 490010733,
   id: 490015088,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241690415",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE TURBET",
   finessju: 530005982,
   id: 530005990,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243049414",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCE VINCENTAISE",
   finessju: 440042828,
   id: 440042869,
   commune: "ST VINCENT DES LANDES",
   cp: 44590,
   telephone: "240553605",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES  DOITEAU",
   finessju: 530006535,
   id: 530006550,
   commune: "PRE EN PAIL ST SAMSON",
   cp: 53140,
   telephone: "243030522",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES A 85 ROUSSEAU",
   finessju: 850010828,
   id: 850008152,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251624231",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES A 85 ROUSSEAU SARL",
   finessju: 850010810,
   id: 850007444,
   commune: "LA FERRIERE",
   cp: 85280,
   telephone: "251406000",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ACTIVES",
   finessju: 440047389,
   id: 440047397,
   commune: "ST MARS DE COUTAIS",
   cp: 44680,
   telephone: "240735593",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES AGOSTINI JACKY",
   finessju: 720007475,
   id: 720007483,
   commune: "NEUFCHATEL EN SAOSNOIS",
   cp: 72600,
   telephone: "243979686",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ALIZES",
   finessju: 850010109,
   id: 850010406,
   commune: "GIVRAND",
   cp: 85800,
   telephone: "251554796",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ALIZES",
   finessju: 850010109,
   id: 850010414,
   commune: "ST HILAIRE DE RIEZ",
   cp: 85270,
   telephone: "251545655",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES AMAB",
   finessju: 490007671,
   id: 490008927,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241924126",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ANGEVINE",
   finessju: 490009065,
   id: 490012549,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241481088",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ANILLE BRAYE - BESSE/BRAYE",
   finessju: 720007343,
   id: 720008812,
   commune: "BESSE SUR BRAYE",
   cp: 72310,
   telephone: "243353111",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ANJOU TOURAINE",
   finessju: 490009693,
   id: 490010675,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241672111",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES APS",
   finessju: 850019191,
   id: 850008103,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251919276",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES APS",
   finessju: 850019191,
   id: 850010893,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251924101",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ARKRISS",
   finessju: 850021098,
   id: 850007980,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251211421",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ASSISTANCE",
   finessju: 440038099,
   id: 440028744,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240785373",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ASSISTANCE BREMAND",
   finessju: 850009911,
   id: 850009929,
   commune: "NALLIERS",
   cp: 85370,
   telephone: "251307575",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ASSOCIEES",
   finessju: 440047710,
   id: 440032878,
   commune: "REZE",
   cp: 44400,
   telephone: "240050538",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ASSOCIEES",
   finessju: 440047710,
   id: 440040368,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240380907",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ATLANTIC",
   finessju: 440029106,
   id: 440029114,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "240743338",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ATLANTIQUE URGENCES",
   finessju: 440047405,
   id: 440047413,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240156666",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ATLANTIQUE URGENCES",
   finessju: 440047405,
   id: 440052298,
   commune: "DONGES",
   cp: 44480,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES AZUR MARTIPONTAINES",
   finessju: 440052710,
   id: 440028926,
   commune: "PONT ST MARTIN",
   cp: 44860,
   telephone: "228009441",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES AZUR MARTIPONTAINES",
   finessju: 440052710,
   id: 440045383,
   commune: "ST LEGER LES VIGNES",
   cp: 44710,
   telephone: "228219707",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BARANGER CHRISTELLE",
   finessju: 490016672,
   id: 490009875,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241523032",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BARANGER CHRISTELLE",
   finessju: 490016672,
   id: 490009925,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241591741",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BARANGER UZUREAU",
   finessju: 490013109,
   id: 490010360,
   commune: "LA PLAINE",
   cp: 49360,
   telephone: "241559248",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BAUGEOISES",
   finessju: 490009164,
   id: 490009172,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891769",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BAUGEOISES",
   finessju: 490009164,
   id: 490009438,
   commune: "NOYANT VILLAGES",
   cp: 49490,
   telephone: "241895096",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BELLANGER",
   finessju: 530006683,
   id: 530006691,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660101",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BELLEVILLE - LE POIRE",
   finessju: 850010786,
   id: 850008046,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251318989",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BELLEVILLE - LE POIRE",
   finessju: 850010794,
   id: 850008533,
   commune: "BELLEVIGNY",
   cp: 85170,
   telephone: "251411684",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BELLOPRATAINE SARL",
   finessju: 490009297,
   id: 490009305,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241635664",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BEREAU DUCHAINE",
   finessju: 720013242,
   id: 720013341,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243293567",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BESNIER",
   finessju: 530006907,
   id: 530006915,
   commune: "ST BERTHEVIN",
   cp: 53940,
   telephone: "243681414",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIDET R. SARL-EURL",
   finessju: 490009313,
   id: 490009321,
   commune: "VIVY",
   cp: 49680,
   telephone: "241525151",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIDET R. SARL-EURL",
   finessju: 490009313,
   id: 490009347,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241527777",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIGNON",
   finessju: 720017136,
   id: 720016849,
   commune: "NOGENT LE BERNARD",
   cp: 72110,
   telephone: "243290011",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIMIER SARL",
   finessju: 490009396,
   id: 490009404,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241773263",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIZOT CHRISTIAN",
   finessju: 490009412,
   id: 490016029,
   commune: "LE LION D ANGERS",
   cp: 49220,
   telephone: "241951150",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIZOT CHRISTIAN",
   finessju: 490009412,
   id: 490016060,
   commune: "ERDRE EN ANJOU",
   cp: 49220,
   telephone: "241611890",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIZOT CHRISTIAN CANDE",
   finessju: 490009412,
   id: 490017621,
   commune: "CANDE",
   cp: 49440,
   telephone: "241929570",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BIZOT CHRISTIAN POUANCE",
   finessju: 490009412,
   id: 490017639,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241262068",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BLANC (SAS)",
   finessju: 490009446,
   id: 490009461,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241532996",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BOISGONTIER",
   finessju: 530006030,
   id: 530006055,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243049402",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BOUYER EURL",
   finessju: 490009560,
   id: 490009586,
   commune: "CHEMILLE EN ANJOU",
   cp: 49120,
   telephone: "241303133",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BRANEYRE",
   finessju: 530006246,
   id: 530006253,
   commune: "LE BOURGNEUF LA FORET",
   cp: 53410,
   telephone: "243377630",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES BROU",
   finessju: 720016658,
   id: 720016708,
   commune: "CERANS FOULLETOURTE",
   cp: 72330,
   telephone: "243878750",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CANTO",
   finessju: 530006436,
   id: 530006444,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243057878",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CANTO",
   finessju: 530006436,
   id: 530006584,
   commune: "JUVIGNE",
   cp: 53380,
   telephone: "243685098",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CASTROGONTERIENNES",
   finessju: 530006311,
   id: 530006337,
   commune: "AZE",
   cp: 53200,
   telephone: "243073569",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHANTONNAISIENNES",
   finessju: 850018839,
   id: 850007063,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943198",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHARPENTIER",
   finessju: 850023870,
   id: 850007667,
   commune: "LUCON",
   cp: 85400,
   telephone: "251560108",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHARPENTIER",
   finessju: 850023870,
   id: 850010901,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251305555",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHASSEVENT",
   finessju: 720018043,
   id: 720007533,
   commune: "THORIGNE SUR DUE",
   cp: 72160,
   telephone: "243890567",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHAUMOISES",
   finessju: 850001074,
   id: 850019282,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251212929",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHAUSSON",
   finessju: 720012772,
   id: 720012780,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243850992",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHESNEAU SERGE",
   finessju: 490009735,
   id: 490009743,
   commune: "ERDRE EN ANJOU",
   cp: 49220,
   telephone: "241614112",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHOLETAISES LE PUY ST BONNE",
   finessju: 490015898,
   id: 490016078,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241621250",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHOLETAISES SARL",
   finessju: 490015898,
   id: 490015948,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241621250",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CHRISTOPHE BARIL",
   finessju: 440038669,
   id: 440038719,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240785959",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES COLAISSEAU",
   finessju: 490012689,
   id: 490009776,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241581304",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES COLAISSEAU",
   finessju: 490012689,
   id: 490009784,
   commune: "LE MAY SUR EVRE",
   cp: 49122,
   telephone: "241631346",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES COLAISSEAU",
   finessju: 490012689,
   id: 490012739,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241641141",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES COLAISSEAU",
   finessju: 490012689,
   id: 490012788,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241630157",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES COTE DE LUMIERE",
   finessju: 850023276,
   id: 850010919,
   commune: "L AIGUILLON SUR MER",
   cp: 85460,
   telephone: "251271333",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CRESPINOISES",
   finessju: 490009792,
   id: 490009800,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241704470",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES CRONIER",
   finessju: 720007103,
   id: 720007202,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243244289",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DAVIAUD CELTON",
   finessju: 850020900,
   id: 850007030,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251930227",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DAVIAUD JOSEPH",
   finessju: 490009826,
   id: 490009834,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "241393299",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DAVIAUD JOSEPH",
   finessju: 490009826,
   id: 490009842,
   commune: "LA POSSONNIERE",
   cp: 49170,
   telephone: "241728101",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE BOUIN (SARL)",
   finessju: 850010174,
   id: 850010182,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251688121",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE CHATEAUNEUF SUR SARTHE",
   finessju: 490009180,
   id: 490013018,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "241211212",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'ATLANTIQUE",
   finessju: 850019167,
   id: 850007600,
   commune: "LUCON",
   cp: 85400,
   telephone: "251290505",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'ETOILE",
   finessju: 440024990,
   id: 440025005,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240688300",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'OCEAN JARDAISES",
   finessju: 850010505,
   id: 850007543,
   commune: "JARD SUR MER",
   cp: 85520,
   telephone: "251335729",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'OCEAN LONGEVILLAISES",
   finessju: 850010505,
   id: 850007592,
   commune: "LONGEVILLE SUR MER",
   cp: 85560,
   telephone: "251333076",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'OUEST",
   finessju: 440025575,
   id: 440025625,
   commune: "CONQUEREUIL",
   cp: 44290,
   telephone: "240510942",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'OUEST",
   finessju: 440025575,
   id: 440038628,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240516626",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE L'OUEST",
   finessju: 440025575,
   id: 440046191,
   commune: "BLAIN",
   cp: 44130,
   telephone: "240510942",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE LA MOINE ST GABORIEAU",
   finessju: 490010584,
   id: 490010592,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241647118",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE LA SEVRE",
   finessju: 850009887,
   id: 850007824,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251651273",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DE LA VALLEE",
   finessju: 490010550,
   id: 490011285,
   commune: "BEAUFORT EN ANJOU",
   cp: 49250,
   telephone: "241803094",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DELAHAYE",
   finessju: 530006840,
   id: 530006857,
   commune: "PORT BRILLET",
   cp: 53410,
   telephone: "243688217",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DELEE - STE JAMME/SARTHE",
   finessju: 720008341,
   id: 720008366,
   commune: "STE JAMME SUR SARTHE",
   cp: 72380,
   telephone: "243276718",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DELEE SAINT JAMME S/ SARTHE",
   finessju: 720008341,
   id: 720008374,
   commune: "SAVIGNE L EVEQUE",
   cp: 72460,
   telephone: "243275318",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DES MAUGES SARL",
   finessju: 490010485,
   id: 490010493,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241553556",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DES OLONNES",
   finessju: 850019290,
   id: 850008236,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251953252",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DOITEAU",
   finessju: 530006535,
   id: 530006543,
   commune: "JAVRON LES CHAPELLES",
   cp: 53250,
   telephone: "243034080",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DRONET HERVOUET",
   finessju: 850018755,
   id: 850008145,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251370436",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DU LOIR",
   finessju: 720007491,
   id: 720007517,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243459464",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DUCHAINE",
   finessju: 720013242,
   id: 720018936,
   commune: "LOMBRON",
   cp: 72450,
   telephone: "243767356",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES DURANDET",
   finessju: 850019035,
   id: 850008251,
   commune: "ST FULGENT",
   cp: 85250,
   telephone: "251426044",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES E ET P MORIN",
   finessju: 530006451,
   id: 530006477,
   commune: "EVRON",
   cp: 53600,
   telephone: "243013298",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ESNAULT FROGER",
   finessju: 720012467,
   id: 720012475,
   commune: "CONNERRE",
   cp: 72160,
   telephone: "243890107",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ESNAULT-FROGER",
   finessju: 720012467,
   id: 720007772,
   commune: "DOLLON",
   cp: 72390,
   telephone: "243713144",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES FLORENTAISES - COGNE",
   finessju: 490009990,
   id: 490010006,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241725193",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES FONTENAISIENNES",
   finessju: 850019050,
   id: 850007584,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251692216",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES FRESNOISES",
   finessju: 720007806,
   id: 720007814,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243346210",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GAIGNER",
   finessju: 530005289,
   id: 530005339,
   commune: "CRAON",
   cp: 53400,
   telephone: "243060771",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GAUTIER PORET",
   finessju: 720012517,
   id: 720012533,
   commune: "VIBRAYE",
   cp: 72320,
   telephone: "243936180",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GAUTIER-PORET MONTMIRAIL",
   finessju: 720012517,
   id: 720012525,
   commune: "MONTMIRAIL",
   cp: 72320,
   telephone: "243936519",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GENNES-LES ROSIERS PALLUET",
   finessju: 490010063,
   id: 490010071,
   commune: "GENNES VAL DE LOIRE",
   cp: 49350,
   telephone: "241380057",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GERARD LEVEQUE",
   finessju: 530006485,
   id: 530006493,
   commune: "FOUGEROLLES DU PLESSIS",
   cp: 53190,
   telephone: "243055791",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GORON CLAUDE",
   finessju: 720017888,
   id: 720007566,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243721838",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GOUGEON",
   finessju: 530006725,
   id: 530006733,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243009787",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUAIS",
   finessju: 530006295,
   id: 530006303,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53202,
   telephone: "243704343",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUERANGER-RIOUL CONLIE",
   finessju: 720007616,
   id: 720007624,
   commune: "CONLIE",
   cp: 72240,
   telephone: "243205328",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUERANGER-RIOUL SILLE",
   finessju: 720007616,
   id: 720012764,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243201185",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUILLOU AILLERIE",
   finessju: 440023562,
   id: 440028082,
   commune: "LIGNE",
   cp: 44850,
   telephone: "240770244",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUILLOU AILLERIE",
   finessju: 440023562,
   id: 440045003,
   commune: "LE CELLIER",
   cp: 44850,
   telephone: "240253333",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUILLOU-AILLERIE",
   finessju: 440023562,
   id: 440029353,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240295153",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUILLOU-AILLERIE",
   finessju: 440023562,
   id: 440035186,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "240978707",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUILMEAU",
   finessju: 850019399,
   id: 850006685,
   commune: "APREMONT",
   cp: 85220,
   telephone: "251557108",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUINGUENE",
   finessju: 440029411,
   id: 440029437,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240794712",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUION BARANGER",
   finessju: 490009354,
   id: 490009362,
   commune: "BEAULIEU SUR LAYON",
   cp: 49750,
   telephone: "241454088",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES GUION-BARANGER",
   finessju: 490009354,
   id: 490009370,
   commune: "BEAULIEU SUR LAYON",
   cp: 49750,
   telephone: "241783470",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES HERICOISES",
   finessju: 440044980,
   id: 440044998,
   commune: "HERIC",
   cp: 44810,
   telephone: "240579941",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES HERVE",
   finessju: 490010196,
   id: 490010204,
   commune: "TERRANJOU",
   cp: 49540,
   telephone: "241594338",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES HERVE",
   finessju: 490010196,
   id: 490010824,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241591531",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES HERVE",
   finessju: 490010196,
   id: 490010840,
   commune: "MONTREUIL BELLAY",
   cp: 49260,
   telephone: "241524323",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES HERVE",
   finessju: 490010196,
   id: 490012358,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241501846",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES INTER URGENCES",
   finessju: 850010760,
   id: 850010778,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251467778",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ISABELLE (SARL)",
   finessju: 720007822,
   id: 720007830,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243342535",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES JEAN RAYON",
   finessju: 530006154,
   id: 530006170,
   commune: "EVRON",
   cp: 53600,
   telephone: "243016832",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES JGSD",
   finessju: 490010733,
   id: 490010741,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241448000",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LAMBERT",
   finessju: 530006824,
   id: 530006832,
   commune: "OISSEAU",
   cp: 53300,
   telephone: "243002013",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LANDAISES",
   finessju: 850010729,
   id: 850007550,
   commune: "LES LANDES GENUSSON",
   cp: 85130,
   telephone: "251916375",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LANDIVYSIENNES",
   finessju: 530006592,
   id: 530006600,
   commune: "LANDIVY",
   cp: 53190,
   telephone: "243054028",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LASSEENNES",
   finessju: 530006626,
   id: 530006634,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243085373",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LE LUDE",
   finessju: 720012541,
   id: 720012566,
   commune: "LE LUDE",
   cp: 72800,
   telephone: "243946077",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LEROY",
   finessju: 530003029,
   id: 530003078,
   commune: "LASSAY LES CHATEAUX",
   cp: 53110,
   telephone: "243047177",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LES CHARMETTES",
   finessju: 850018896,
   id: 850008434,
   commune: "ESSARTS EN BOCAGE",
   cp: 85140,
   telephone: "251078686",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LOIRE ET MAUGES",
   finessju: 490010097,
   id: 490010022,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241785757",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LOIRE ET MAUGES",
   finessju: 490010097,
   id: 490010105,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241390574",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES LOROUSAINES OMNITRANSPORTS",
   finessju: 440037968,
   id: 440038008,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251719967",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MAINE GUILLOIS",
   finessju: 490009065,
   id: 490009073,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241253636",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MAINE GUILLOIS",
   finessju: 490010402,
   id: 490010410,
   commune: "MONTREUIL JUIGNE",
   cp: 49460,
   telephone: "241423152",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MAINE GUILLOIS",
   finessju: 490010402,
   id: 490010436,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241345136",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MAINE GUILLOIS",
   finessju: 490010402,
   id: 490010444,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241732929",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MARC LASSERRE",
   finessju: 490010295,
   id: 490013398,
   commune: "LA TESSOUALLE",
   cp: 49280,
   telephone: "241563788",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MARINE SARL",
   finessju: 850010141,
   id: 850010158,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390707",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MARTIN",
   finessju: 850001215,
   id: 850008111,
   commune: "ROCHESERVIERE",
   cp: 85620,
   telephone: "251949053",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MARTIN",
   finessju: 850001215,
   id: 850010703,
   commune: "LES BROUZILS",
   cp: 85260,
   telephone: "251423586",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MARTIN",
   finessju: 850001215,
   id: 850021338,
   commune: "L HERBERGEMENT",
   cp: 85260,
   telephone: "251424678",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MARTINET SARL",
   finessju: 490011210,
   id: 490011236,
   commune: "CHATEAUNEUF SUR SARTHE",
   cp: 49330,
   telephone: "241698907",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MAURICE THOUARCE SARL",
   finessju: 490010535,
   id: 490013448,
   commune: "BELLEVIGNE EN LAYON",
   cp: 49380,
   telephone: "241541446",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MAZEAISES",
   finessju: 490010550,
   id: 490010576,
   commune: "MAZE MILON",
   cp: 49630,
   telephone: "241802424",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MOISDONNAISES",
   finessju: 440038818,
   id: 440027787,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240072697",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MONTACUTAINES",
   finessju: 850018953,
   id: 850007766,
   commune: "MONTAIGU",
   cp: 85600,
   telephone: "251940174",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MONTOISES",
   finessju: 850019522,
   id: 850006693,
   commune: "LA BARRE DE MONTS",
   cp: 85550,
   telephone: "251685283",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MOTHAISES",
   finessju: 850013368,
   id: 850007832,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251947710",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MOUCHAMPAISES",
   finessju: 850018920,
   id: 850007865,
   commune: "MOUCHAMPS",
   cp: 85640,
   telephone: "251662592",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES MOUTIERROISES",
   finessju: 850010711,
   id: 850007915,
   commune: "MOUTIERS LES MAUXFAITS",
   cp: 85540,
   telephone: "251989898",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NAULLEAU",
   finessju: 850022674,
   id: 850006917,
   commune: "LA CAILLERE ST HILAIRE",
   cp: 85410,
   telephone: "251515171",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NAZAIRIENNES",
   finessju: 440041879,
   id: 440030344,
   commune: "MONTOIR DE BRETAGNE",
   cp: 44550,
   telephone: "240885656",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NAZAIRIENNES",
   finessju: 440041879,
   id: 440040699,
   commune: "ST JOACHIM",
   cp: 44720,
   telephone: "240885656",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NAZAIRIENNES",
   finessju: 440041879,
   id: 440043263,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "240459500",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NAZAIRIENNES",
   finessju: 440041879,
   id: 440047314,
   commune: "CORSEPT",
   cp: 44560,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NICOLAS",
   finessju: 440042729,
   id: 440042778,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "240806010",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NIESSERON",
   finessju: 720012970,
   id: 720012996,
   commune: "MANSIGNE",
   cp: 72510,
   telephone: "243461467",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NOBLET",
   finessju: 440028280,
   id: 440040269,
   commune: "DREFFEAC",
   cp: 44530,
   telephone: "240014974",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NOBLET",
   finessju: 440028280,
   id: 440048858,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NOBLET",
   finessju: 440028280,
   id: 440048874,
   commune: "ST GILDAS DES BOIS",
   cp: 44530,
   telephone: "240014974",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NORTAISES",
   finessju: 440047231,
   id: 440027597,
   commune: "HERIC",
   cp: 44810,
   telephone: "240576957",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NORTAISES",
   finessju: 440047231,
   id: 440029387,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240721414",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES NUIT ET JOUR",
   finessju: 720012616,
   id: 720012624,
   commune: "OISSEAU LE PETIT",
   cp: 72610,
   telephone: "243266050",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PACAUD ALLAIS",
   finessju: 440048486,
   id: 440048478,
   commune: "ST MICHEL CHEF CHEF",
   cp: 44730,
   telephone: "240279087",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PARCEENNES",
   finessju: 720017961,
   id: 720012590,
   commune: "PARCE SUR SARTHE",
   cp: 72300,
   telephone: "243953912",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PATRICK VETILLARD",
   finessju: 530006196,
   id: 530006204,
   commune: "BAIS",
   cp: 53160,
   telephone: "243370837",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PAULOUIN BRULON",
   finessju: 720007301,
   id: 720007327,
   commune: "BRULON",
   cp: 72350,
   telephone: "243956060",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PAULOUIN LOUE",
   finessju: 720007301,
   id: 720007335,
   commune: "LOUE",
   cp: 72540,
   telephone: "243882528",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PEGUET-BOUVET (SARL)",
   finessju: 490010683,
   id: 490010691,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241515063",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PEROISES (SARL)",
   finessju: 850010067,
   id: 850010075,
   commune: "LE CHAMP ST PERE",
   cp: 85540,
   telephone: "251409260",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PIERRE FORGET",
   finessju: 720008903,
   id: 720008937,
   commune: "LA CHARTRE SUR LE LOIR",
   cp: 72340,
   telephone: "243790884",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PRESQU'ILE SECOURS",
   finessju: 440027746,
   id: 440027753,
   commune: "LA CHAPELLE DES MARAIS",
   cp: 44410,
   telephone: "240532320",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES PROUANTAISES SARL BLANCHARD",
   finessju: 850010034,
   id: 850010042,
   commune: "ST PROUANT",
   cp: 85110,
   telephone: "251664284",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES RAINE",
   finessju: 490010774,
   id: 490010782,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241570224",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES RAINE",
   finessju: 490010774,
   id: 490010790,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241549824",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES RENOUX MARC",
   finessju: 850023334,
   id: 850007501,
   commune: "LA GUERINIERE",
   cp: 85680,
   telephone: "251397575",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ROUILLER",
   finessju: 490010873,
   id: 490010881,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241757333",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ROUILLER FOUCHE",
   finessju: 490010873,
   id: 490010725,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241725350",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SAINTE CHANTAL SARL",
   finessju: 490015989,
   id: 490010980,
   commune: "MONTREVAULT SUR EVRE",
   cp: 49110,
   telephone: "241567084",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440033553,
   id: 440026102,
   commune: "DONGES",
   cp: 44480,
   telephone: "240910707",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440033553,
   id: 440032902,
   commune: "TRIGNAC",
   cp: 44570,
   telephone: "240458292",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440033553,
   id: 440033660,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240700651",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440047736,
   id: 440034205,
   commune: "PRINQUIAU",
   cp: 44260,
   telephone: "240566666",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440033553,
   id: 440039469,
   commune: "PORNICHET",
   cp: 44380,
   telephone: "240616364",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440033553,
   id: 440042968,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "228016611",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SANSOUCY",
   finessju: 440033553,
   id: 440045417,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240431616",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SAUMUROISES SARL",
   finessju: 490011004,
   id: 490011012,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241382276",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SAUMUROISES SARL",
   finessju: 490011004,
   id: 490011020,
   commune: "VARENNES SUR LOIRE",
   cp: 49730,
   telephone: "241517878",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SAVARY VENEAU",
   finessju: 850010836,
   id: 850007873,
   commune: "MOUILLERON ST GERMAIN",
   cp: 85390,
   telephone: "251003488",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SAVARY VENEAU",
   finessju: 850010836,
   id: 850010943,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "228139793",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SECOURS COEUR DE RETZ",
   finessju: 440048825,
   id: 440042398,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240024025",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SEGREENNES",
   finessju: 490011046,
   id: 490011053,
   commune: "SEGRE EN ANJOU BLEU",
   cp: 49500,
   telephone: "241611277",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SEICHOISES",
   finessju: 490016821,
   id: 490009966,
   commune: "SEICHES SUR LE LOIR",
   cp: 49140,
   telephone: "241769855",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SNSM",
   finessju: 720007855,
   id: 720007434,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243334287",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SNSM",
   finessju: 720007855,
   id: 720007863,
   commune: "MAROLLES LES BRAULTS",
   cp: 72260,
   telephone: "243974680",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SNSM",
   finessju: 720007855,
   id: 720007871,
   commune: "BALLON ST MARS",
   cp: 72290,
   telephone: "243273018",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SNSM",
   finessju: 720007855,
   id: 720007897,
   commune: "BEAUMONT SUR SARTHE",
   cp: 72170,
   telephone: "243970194",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SNSM",
   finessju: 720007855,
   id: 720018894,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243974680",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SOS PAYS DE RETZ",
   finessju: 440038859,
   id: 440038909,
   commune: "LA MONTAGNE",
   cp: 44620,
   telephone: "240656027",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SOS PAYS DE RETZ",
   finessju: 440038859,
   id: 440045276,
   commune: "LE PELLERIN",
   cp: 44640,
   telephone: "240046519",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES ST DENIS D'ANJOU",
   finessju: 530006923,
   id: 530006931,
   commune: "ST DENIS D ANJOU",
   cp: 53290,
   telephone: "243705232",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SUD MAYENNE",
   finessju: 530005388,
   id: 530005438,
   commune: "CRAON",
   cp: 53400,
   telephone: "243062901",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SUD MAYENNE",
   finessju: 530005388,
   id: 530005479,
   commune: "COSSE LE VIVIEN",
   cp: 53230,
   telephone: "243062901",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SUD MAYENNE",
   finessju: 530005388,
   id: 530006881,
   commune: "ST AIGNAN SUR ROE",
   cp: 53390,
   telephone: "243066606",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SUD NANTES",
   finessju: 440043412,
   id: 440043420,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240030029",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES SUZERAINES",
   finessju: 720013044,
   id: 720013085,
   commune: "LA SUZE SUR SARTHE",
   cp: 72210,
   telephone: "243773041",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES TAXIS HERBRETAIS",
   finessju: 850018912,
   id: 850007527,
   commune: "LES HERBIERS CEDEX",
   cp: 85503,
   telephone: "251671153",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES TERRIEN DENIS",
   finessju: 720007921,
   id: 720007947,
   commune: "NOYEN SUR SARTHE",
   cp: 72430,
   telephone: "243957187",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES THIERRY BIGNON",
   finessju: 720017979,
   id: 720008051,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243712222",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES TINNIERE",
   finessju: 530006774,
   id: 530006782,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243986626",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES TRANSPORTS RAYON",
   finessju: 530006154,
   id: 530006162,
   commune: "BAIS",
   cp: 53160,
   telephone: "243379024",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES TURBET",
   finessju: 530005982,
   id: 530006006,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243045353",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES UNIES DE VIX",
   finessju: 850019076,
   id: 850008590,
   commune: "VIX",
   cp: 85770,
   telephone: "251006394",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES UNIES FONTENAY LE COMTE",
   finessju: 850019076,
   id: 850010752,
   commune: "FONTENAY LE COMTE CEDEX",
   cp: 85201,
   telephone: "251699696",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES UNIES LA CHATAIGNERAIE",
   finessju: 850019076,
   id: 850007121,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251696013",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES URGENCE ESTUAIRE",
   finessju: 440052447,
   id: 440052454,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES VAL DE LOIRE",
   finessju: 720012905,
   id: 720012921,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243440330",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES VAL DE VIE (SARL)",
   finessju: 850010695,
   id: 850007360,
   commune: "COMMEQUIERS",
   cp: 85220,
   telephone: "251548664",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES VARADAISES",
   finessju: 440043370,
   id: 440043388,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240983039",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES VENDEENNES",
   finessju: 850019274,
   id: 850008210,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251951953",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES VILLAINAISES",
   finessju: 530006956,
   id: 530006964,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243032292",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES VILLAINAISES",
   finessju: 530006956,
   id: 530006972,
   commune: "ST PIERRE DES NIDS",
   cp: 53370,
   telephone: "243036615",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AMBULANCES WAGNER",
   finessju: 530006790,
   id: 530006816,
   commune: "MONTSURS ST CENERE",
   cp: 53150,
   telephone: "243022283",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ANILLE BRAYE TRANSPORTS",
   finessju: 720007343,
   id: 720007350,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243350154",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ANJOU AMBULANCES",
   finessju: 490010857,
   id: 490012499,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241780000",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ANTARES AMBULANCES",
   finessju: 720017987,
   id: 720017995,
   commune: "LA BAZOGE",
   cp: 72650,
   telephone: "243240838",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "APFL LAPORTE",
   finessju: 850022609,
   id: 850007303,
   commune: "CHAVAGNES EN PAILLERS",
   cp: 85250,
   telephone: "251381794",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ASPJ AMBULANCES FEURPRIER",
   finessju: 530007012,
   id: 530007020,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243033165",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ASSISTANCE AMBULANCE",
   finessju: 440029262,
   id: 440029080,
   commune: "NANTES",
   cp: 44200,
   telephone: "240303300",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ASSISTANCE AMBULANCE",
   finessju: 440029262,
   id: 440039998,
   commune: "REZE",
   cp: 44400,
   telephone: "228000777",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ASSISTANCE AMBULANCE",
   finessju: 440029262,
   id: 440045250,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240121415",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ATL HERAULT SIRE",
   finessju: 850018987,
   id: 850008400,
   commune: "ST LAURENT SUR SEVRE",
   cp: 85290,
   telephone: "251678916",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AZUR AMBULANCES MONTFORT",
   finessju: 720007715,
   id: 720007723,
   commune: "MONTFORT LE GESNOIS",
   cp: 72450,
   telephone: "243767495",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AZUR AMBULANCES RTE DE COURCEMONT",
   finessju: 720007715,
   id: 720019504,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243892736",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "AZUR AMBULANCES TUFFE",
   finessju: 720007715,
   id: 720012954,
   commune: "TUFFE VAL DE LA CHERONNE",
   cp: 72160,
   telephone: "243934786",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "BDSM AMBULANCES",
   finessju: 850021841,
   id: 850007782,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "251942844",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "BOUGUENAIS AMBULANCE",
   finessju: 440024792,
   id: 440024800,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320132",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "BOULOIRE AMBULANCES",
   finessju: 720013515,
   id: 720013531,
   commune: "BOULOIRE",
   cp: 72440,
   telephone: "243354882",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "C2G AMBULANCES",
   finessju: 850019092,
   id: 850018672,
   commune: "ST PIERRE DU CHEMIN",
   cp: 85120,
   telephone: "251530571",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "C2G AMBULANCES",
   finessju: 850019092,
   id: 850018714,
   commune: "ST PIERRE DU CHEMIN",
   cp: 85120,
   telephone: "251517620",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CASTEL AMBULANCE DAVID",
   finessju: 440025120,
   id: 440025161,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240282222",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CASTEL AMBULANCE DAVID",
   finessju: 440025120,
   id: 440027654,
   commune: "ROUGE",
   cp: 44660,
   telephone: "240288075",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CASTEL AMBULANCE DAVID",
   finessju: 440025120,
   id: 440031193,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240072212",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CASTEL AMBULANCE DAVID",
   finessju: 440025120,
   id: 440040889,
   commune: "ST JULIEN DE VOUVANTES",
   cp: 44670,
   telephone: "240554422",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CASTEL AMBULANCE DAVID",
   finessju: 440025120,
   id: 440044956,
   commune: "DERVAL",
   cp: 44590,
   telephone: "240072770",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CASTEL AMBULANCE DAVID",
   finessju: 440025120,
   id: 440048536,
   commune: "HERIC",
   cp: 44810,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CENTRAL'AMBULANCES",
   finessju: 720012830,
   id: 720017052,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243887878",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CENTRAL'AMBULANCES LE MANS",
   finessju: 720012830,
   id: 720012855,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243771173",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CENTRALE D'AMBULANCES BEAUFILS",
   finessju: 530006071,
   id: 530006097,
   commune: "ANDOUILLE",
   cp: 53240,
   telephone: "243697207",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CENTRALE D'AMBULANCES BEAUFILS",
   finessju: 530006071,
   id: 530006105,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243029691",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CENTRE AMBULANCIER HAUT BOCAGE GEAY",
   finessju: 850019217,
   id: 850007451,
   commune: "SEVREMONT",
   cp: 85700,
   telephone: "251578083",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CENTRE AMBULANCIER HAUT BOCAGE GEAY",
   finessju: 850019209,
   id: 850007790,
   commune: "MONTOURNAIS",
   cp: 85700,
   telephone: "251579232",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "COEX AMBULANCE",
   finessju: 850023342,
   id: 850007352,
   commune: "COEX",
   cp: 85220,
   telephone: "251542424",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "COULAINES AMBULANCES",
   finessju: 720007574,
   id: 720007582,
   commune: "COULAINES",
   cp: 72190,
   telephone: "243811373",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CTRE AMBULANCIER ANJOU TOURAINE",
   finessju: 490009693,
   id: 490009701,
   commune: "BRAIN SUR ALLONNES",
   cp: 49650,
   telephone: "241520592",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CTRE AMBULANCIER ANJOU TOURAINE",
   finessju: 490009693,
   id: 490009727,
   commune: "VERNOIL LE FOURRIER",
   cp: 49390,
   telephone: "241515779",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "CTRE AMBULANCIER HAUT BOCAGE GEAY",
   finessju: 850019217,
   id: 850011040,
   commune: "POUZAUGES",
   cp: 85700,
   telephone: "251918552",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "ESNAUD AMBULANCE",
   finessju: 440037638,
   id: 440026896,
   commune: "GUEMENE PENFAO",
   cp: 44290,
   telephone: "240872139",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "EURL AMBULANCE GRASSET",
   finessju: 850019175,
   id: 850008491,
   commune: "MAILLEZAIS",
   cp: 85420,
   telephone: "251872939",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "EUROP'AMBULANCES",
   finessju: 720008127,
   id: 720008325,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243823333",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "EUROPE AMBULANCE",
   finessju: 440029213,
   id: 440029221,
   commune: "NANTES",
   cp: 44300,
   telephone: "240940550",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "EUROTRANS 72 AMBULANCES GUILLOT",
   finessju: 720017599,
   id: 490016375,
   commune: "DURTAL",
   cp: 49430,
   telephone: "241763195",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "FOUCAULT AMBULANCES MELINOISES",
   finessju: 530006741,
   id: 530006766,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243984548",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "JADE AMBULANCE SERVICE",
   finessju: 440039329,
   id: 440031177,
   commune: "LA PLAINE SUR MER",
   cp: 44770,
   telephone: "240215580",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "JADE AMBULANCE SERVICE",
   finessju: 440039329,
   id: 440042489,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240277339",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "JADE AMBULANCE SERVICE",
   finessju: 440039329,
   id: 440045284,
   commune: "LA BERNERIE EN RETZ",
   cp: 44760,
   telephone: "240646464",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "JADE AMBULANCE SERVICE PORNIC",
   finessju: 440039329,
   id: 440052389,
   commune: "PORNIC",
   cp: 44210,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "JADE AMBULANCE SERVICE STE PAZANNE",
   finessju: 440039329,
   id: 440048833,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "LA GAUBRETIERE AMBULANCE",
   finessju: 850021197,
   id: 850007493,
   commune: "LA GAUBRETIERE",
   cp: 85130,
   telephone: "251911372",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "LAMY AMBULANCES",
   finessju: 530006980,
   id: 530007004,
   commune: "STE SUZANNE ET CHAMMES",
   cp: 53270,
   telephone: "243014001",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "LAVAL AMBULANCES BELLANGER",
   finessju: 530006683,
   id: 530006717,
   commune: "NUILLE SUR VICOIN",
   cp: 53970,
   telephone: "243980481",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "LE MANS AMBULANCES",
   finessju: 720016948,
   id: 720018837,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243211974",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "LES AMBULANCES UNIES PISSOTTE",
   finessju: 850019076,
   id: 850008020,
   commune: "PISSOTTE",
   cp: 85200,
   telephone: "251698123",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "LOUPLANDE AMBULANCES SARL",
   finessju: 720017110,
   id: 720017128,
   commune: "LOUPLANDE",
   cp: 72210,
   telephone: "243885123",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "MADAME GRAFFIN MARTINE",
   finessju: 720013010,
   id: 720013036,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "243426282",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "MAREUIL AMBULANCE",
   finessju: 850018938,
   id: 850007717,
   commune: "MAREUIL SUR LAY DISSAIS",
   cp: 85320,
   telephone: "251973434",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "MAYET AMBULANCES",
   finessju: 720012723,
   id: 720012731,
   commune: "MAYET",
   cp: 72360,
   telephone: "243460619",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "MONSIEUR NIESSERON PIERRE",
   finessju: 720012970,
   id: 720013002,
   commune: "ST JEAN DE LA MOTTE",
   cp: 72510,
   telephone: "243454560",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "MUTUELLE PREVIADE RSS",
   finessju: 530007046,
   id: 530007053,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243532955",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "NANTES AMBULANCE",
   finessju: 440047355,
   id: 440029130,
   commune: "NANTES",
   cp: 44200,
   telephone: "240031833",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "NANTES URGENCES SANSOUCY",
   finessju: 440047363,
   id: 440047371,
   commune: "NANTES",
   cp: 44100,
   telephone: "-",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "PALLUAU AMBULANCES",
   finessju: 850008855,
   id: 850008863,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "251985174",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "PASTEL 85",
   finessju: 850023383,
   id: 850007311,
   commune: "CHAVAGNES LES REDOUX",
   cp: 85390,
   telephone: "251924101",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "PROUTZAKOFF AMBULANCE CONCELLOISE",
   finessju: 440040749,
   id: 440040798,
   commune: "ST JULIEN DE CONCELLES",
   cp: 44450,
   telephone: "240541977",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "REZE AMBULANCE - GUITTENY ET BERNARD",
   finessju: 440034353,
   id: 440035095,
   commune: "REZE",
   cp: 44400,
   telephone: "240842059",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SAINT JEAN AMBULANCE",
   finessju: 850019506,
   id: 850008376,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "251586650",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SARL AMBULANCES 72",
   finessju: 720017086,
   id: 720017094,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243230864",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SARL AMBULANCES ET TAXIS",
   finessju: 850010513,
   id: 850008384,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "251581351",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SARL AMBULANCES ET TAXIS NOTRE DAME",
   finessju: 850019530,
   id: 850007964,
   commune: "NOTRE DAME DE MONTS",
   cp: 85690,
   telephone: "251588555",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SARL AMBULANCES PARAMEDIC",
   finessju: 530003169,
   id: 530003219,
   commune: "FROMENTIERES",
   cp: 53200,
   telephone: "243071313",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SAS AMBULANCES FOURMI",
   finessju: 720014257,
   id: 720014265,
   commune: "LA CHAPELLE DU BOIS",
   cp: 72400,
   telephone: "243932210",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SCOP COULAINES AMBULANCES",
   finessju: 720007574,
   id: 720007590,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243826060",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SECOURS AMBULANCE",
   finessju: 440047199,
   id: 440047207,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240780379",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SOCIETE DES AMBULANCES DE CANDE",
   finessju: 490009610,
   id: 490009636,
   commune: "CANDE",
   cp: 49440,
   telephone: "241920118",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SOS AMBULANCES",
   finessju: 850020256,
   id: 850006891,
   commune: "BRETIGNOLLES SUR MER",
   cp: 85470,
   telephone: "251901950",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "TRANSPORTS DAVY",
   finessju: 490011194,
   id: 490011202,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241566229",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "URGENCE 44 AMBULANCE",
   finessju: 440052264,
   id: 440027621,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "251165555",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "URGENCE 44 AMBULANCE",
   finessju: 440052264,
   id: 440042299,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251160160",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "URGENCES NOBLET",
   finessju: 440049203,
   id: 440049187,
   commune: "DREFFEAC",
   cp: 44530,
   telephone: "240111888",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "USR AMBULANCES",
   finessju: 440047330,
   id: 440040178,
   commune: "ST ETIENNE DE MONTLUC",
   cp: 44360,
   telephone: "240859292",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "USR AMBULANCES",
   finessju: 440047330,
   id: 440047181,
   commune: "SAUTRON",
   cp: 44880,
   telephone: "240861854",
   categorie: "Service d'Ambulances"
 },
 {
   finess: "FINESS",
   name: "SERVICE REPAS A DOMICILE",
   finessju: 440005999,
   id: 440044238,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240825572",
   categorie: "Service de Repas a Domicile"
 },
 {
   finess: "FINESS",
   name: "AMAD DE ST JEAN DE MONTS",
   finessju: 850021692,
   id: 850021700,
   commune: "ST JEAN DE MONTS",
   cp: 85160,
   telephone: "251591696",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "APLS SSIADPA",
   finessju: 440031946,
   id: 440031961,
   commune: "PONTCHATEAU",
   cp: 44160,
   telephone: "240450779",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "HANDI SSIAD 85",
   finessju: 850011859,
   id: 850011891,
   commune: "DOMPIERRE SUR YON",
   cp: 85170,
   telephone: "961375723",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SADAPA",
   finessju: 440011773,
   id: 440032662,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240023011",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SADAPA LA ROCHE SUR YON",
   finessju: 850005075,
   id: 850012121,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251361229",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SCAD 3",
   finessju: 720008390,
   id: 720013218,
   commune: "LE MANS",
   cp: 72100,
   telephone: "272880173",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SIMAD DU BOCAGE MAYENNAIS",
   finessju: 530001015,
   id: 530032465,
   commune: "AMBRIERES LES VALLEES",
   cp: 53300,
   telephone: "243088352",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440018513,
   id: 440012540,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240282698",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440018562,
   id: 440017432,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "240320605",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440011757,
   id: 440025591,
   commune: "AIGREFEUILLE SUR MAINE",
   cp: 44140,
   telephone: "240038074",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440025658,
   id: 440025898,
   commune: "SION LES MINES",
   cp: 44590,
   telephone: "240289437",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440001543,
   id: 440027381,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240646426",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440041234,
   id: 440041242,
   commune: "MOISDON LA RIVIERE",
   cp: 44520,
   telephone: "240075991",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 440042075,
   id: 440042190,
   commune: "LIGNE",
   cp: 44850,
   telephone: "251122604",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 530031178,
   id: 530031590,
   commune: "LAVAL CEDEX",
   cp: 53013,
   telephone: "243494707",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 530031319,
   id: 530031616,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "243095565",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 720009687,
   id: 720008648,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "243607275",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 720001676,
   id: 720008739,
   commune: "FRESNAY SUR SARTHE",
   cp: 72130,
   telephone: "243332744",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD",
   finessju: 720008390,
   id: 720008747,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243450805",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ACCOMPAGNEMENT SOINS ET SANTE",
   finessju: 440005841,
   id: 440033496,
   commune: "NANTES",
   cp: 44300,
   telephone: "240504369",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ACHENEAU-GRAND LIEU",
   finessju: 440018620,
   id: 440031912,
   commune: "STE PAZANNE",
   cp: 44680,
   telephone: "240024785",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR DE CHAILLE LES MARAIS",
   finessju: 850012444,
   id: 850021023,
   commune: "CHAILLE LES MARAIS",
   cp: 85450,
   telephone: "251567174",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR DE L'HERMENAULT",
   finessju: 850012444,
   id: 850012154,
   commune: "L HERMENAULT",
   cp: 85570,
   telephone: "251877673",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR DE LA CHATAIGNERAIE",
   finessju: 850012444,
   id: 850021304,
   commune: "LA CHATAIGNERAIE",
   cp: 85120,
   telephone: "251526464",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR DE MORTAGNE SUR SEVRE",
   finessju: 850012444,
   id: 850014358,
   commune: "MORTAGNE SUR SEVRE",
   cp: 85290,
   telephone: "251652039",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR DE NOIRMOUTIER EN L'ILE",
   finessju: 850012444,
   id: 850021619,
   commune: "NOIRMOUTIER EN L ILE",
   cp: 85330,
   telephone: "251390788",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR DE STE HERMINE",
   finessju: 850012444,
   id: 850013004,
   commune: "STE HERMINE",
   cp: 85210,
   telephone: "251978108",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR LA MOTHE ACHARD",
   finessju: 850012444,
   id: 850021775,
   commune: "LES ACHARDS",
   cp: 85150,
   telephone: "251947919",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR MOUTIERS LES MAUXFAITS",
   finessju: 850012444,
   id: 850024118,
   commune: "MOUTIERS LES MAUXFAITS",
   cp: 85540,
   telephone: "251989957",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR RIVES DE LA BOULOGNE",
   finessju: 850012444,
   id: 850023441,
   commune: "MONTREVERD",
   cp: 85260,
   telephone: "251439120",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR RIVES DU LAY ET DE L'YON",
   finessju: 850012444,
   id: 850006362,
   commune: "RIVES DE L YON",
   cp: 85310,
   telephone: "251319984",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD AMARYLLIS ADMR PALLUAU",
   finessju: 850012444,
   id: 850021064,
   commune: "PALLUAU",
   cp: 85670,
   telephone: "251985763",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ASIDPA",
   finessju: 720001668,
   id: 720008630,
   commune: "MAMERS",
   cp: 72600,
   telephone: "243334937",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ASIDPA CONLIE",
   finessju: 720008390,
   id: 720008952,
   commune: "CONLIE",
   cp: 72240,
   telephone: "243205906",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ASIDPA DE SPAY",
   finessju: 720008390,
   id: 720008960,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243211436",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ASSADAPA",
   finessju: 440042927,
   id: 440025716,
   commune: "CLISSON",
   cp: 44190,
   telephone: "240543079",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ASSIEL",
   finessju: 440051381,
   id: 440027167,
   commune: "ANCENIS",
   cp: 44150,
   telephone: "240962788",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ASSMADONE",
   finessju: 530001007,
   id: 530032168,
   commune: "JAVRON LES CHAPELLES",
   cp: 53250,
   telephone: "243034963",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CANTON DE BEAUVOIR",
   finessju: 850000373,
   id: 850009267,
   commune: "BOUIN",
   cp: 85230,
   telephone: "251680404",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CANTON DE NORT SUR ERDRE",
   finessju: 440001949,
   id: 440001030,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "251120060",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CENTRE HOSPITALIER",
   finessju: 720000066,
   id: 720003466,
   commune: "MONTVAL SUR LOIR",
   cp: 72500,
   telephone: "243447700",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CH DOUE EN ANJOU",
   finessju: 490000403,
   id: 490541695,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241834610",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CH LVO",
   finessju: 850009010,
   id: 850009606,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251353205",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CH NORD MAYENNE",
   finessju: 530000074,
   id: 530003540,
   commune: "MAYENNE CEDEX",
   cp: 53103,
   telephone: "243087633",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CH POUANCE",
   finessju: 440000313,
   id: 490012192,
   commune: "OMBREE D ANJOU",
   cp: 49420,
   telephone: "241948771",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CHD LA ROCHE LUCON MONTAIGU",
   finessju: 850000019,
   id: 850018680,
   commune: "LUCON CEDEX",
   cp: 85407,
   telephone: "251569141",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD COSSE LE VIVIEN",
   finessju: 530000983,
   id: 530031988,
   commune: "COSSE LE VIVIEN",
   cp: 53230,
   telephone: "243989433",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD CTRE HOSPITALIER ST CALAIS",
   finessju: 720000140,
   id: 720016450,
   commune: "ST CALAIS",
   cp: 72120,
   telephone: "243636452",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD D'ORVAULT SAUTRON",
   finessju: 440018455,
   id: 440013381,
   commune: "ORVAULT CEDEX",
   cp: 44706,
   telephone: "240949321",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE FONTENAY LE COMTE",
   finessju: 850001173,
   id: 850021221,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251511401",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE L'ILE YEU",
   finessju: 850012444,
   id: 850018706,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "251583867",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE MAILLEZAIS",
   finessju: 850012444,
   id: 850012113,
   commune: "MAILLEZAIS",
   cp: 85420,
   telephone: "251872518",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE MESLAY-GREZ",
   finessju: 530008820,
   id: 530033521,
   commune: "MESLAY DU MAINE",
   cp: 53170,
   telephone: "243642900",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE REZE",
   finessju: 440018547,
   id: 440013241,
   commune: "REZE",
   cp: 44400,
   telephone: "251702908",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE ST GILLES CROIX DE VIE",
   finessju: 850020314,
   id: 850020322,
   commune: "ST GILLES CROIX DE VIE",
   cp: 85800,
   telephone: "251543333",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE ST HERBLAIN",
   finessju: 440018430,
   id: 440013399,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "228252783",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DE ST NAZAIRE",
   finessju: 440004471,
   id: 440013167,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "251100818",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DES 3 CHEMINS",
   finessju: 850011636,
   id: 850011644,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251672723",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DES CANTONS LEGE ST PHILBERT",
   finessju: 440000347,
   id: 440033843,
   commune: "CORCOUE SUR LOGNE",
   cp: 44650,
   telephone: "251116540",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DES SABLES D'OLONNE",
   finessju: 850020330,
   id: 850020348,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "251223302",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DU BOCAGE SABOLIEN",
   finessju: 720008390,
   id: 720011709,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "243952800",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD DU CENTRE MAUGES",
   finessju: 490019825,
   id: 490015583,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "241717685",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ENTRE LOIR ET MAYENNE",
   finessju: 750719239,
   id: 490540218,
   commune: "TIERCE",
   cp: 49125,
   telephone: "241421818",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ENTRE LOIRE ET COTEAUX",
   finessju: 490016789,
   id: 490016797,
   commune: "MONTILLIERS",
   cp: 49310,
   telephone: "241631757",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ERDRE ET SEVRE",
   finessju: 440018620,
   id: 440013233,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "251718833",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ESBV",
   finessju: 490015765,
   id: 490538865,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891138",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ESTUAIRE SUD LOIRE",
   finessju: 440018620,
   id: 440030450,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240277539",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD GEORGES COULON",
   finessju: 720012749,
   id: 720016567,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "243409213",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD HIC DE LA PRESQU'ILE",
   finessju: 440028538,
   id: 440040913,
   commune: "GUERANDE CEDEX",
   cp: 44354,
   telephone: "240626462",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD HOPITAL LOCAL",
   finessju: 530002591,
   id: 530003557,
   commune: "VILLAINES LA JUHEL",
   cp: 53700,
   telephone: "243087000",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD HOPITAL LOCAL",
   finessju: 530000058,
   id: 530031608,
   commune: "ERNEE",
   cp: 53500,
   telephone: "243083141",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD HOPITAL LOCAL BONNETABLE",
   finessju: 720002062,
   id: 720016492,
   commune: "BONNETABLE",
   cp: 72110,
   telephone: "243206004",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD HOPITAL LOCAL DE SILLE",
   finessju: 720007244,
   id: 720016807,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "243525327",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD HOPITAL LOCAL EVRON",
   finessju: 530000066,
   id: 530031970,
   commune: "EVRON",
   cp: 53600,
   telephone: "243666500",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD LA BLANCHINE",
   finessju: 490001468,
   id: 490542669,
   commune: "LA TESSOUALLE",
   cp: 49280,
   telephone: "241563297",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD LE BOCAGE",
   finessju: 490544236,
   id: 490544244,
   commune: "VAL D ERDRE AUXENCE",
   cp: 49370,
   telephone: "241774450",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD LOIRE ET MAUGES",
   finessju: 490004488,
   id: 490541075,
   commune: "MAUGES SUR LOIRE",
   cp: 49620,
   telephone: "241727645",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD MARILLET VOURAIE ADMR",
   finessju: 850012444,
   id: 850021809,
   commune: "LA CHAIZE LE VICOMTE",
   cp: 85310,
   telephone: "251058349",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD MAULEVRIER",
   finessju: 490000783,
   id: 490541687,
   commune: "MAULEVRIER",
   cp: 49360,
   telephone: "241550011",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD MUTUALITE ANJOU",
   finessju: 490535168,
   id: 490538618,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241838565",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD MUTUALITE ANJOU ANGERS",
   finessju: 490535168,
   id: 490532082,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241688365",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD NANTES SOINS A DOMICILE",
   finessju: 440004406,
   id: 440013142,
   commune: "NANTES CEDEX 1",
   cp: 44013,
   telephone: "240354343",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD NORD-OUEST SEGREEN",
   finessju: 490534997,
   id: 490532058,
   commune: "OMBREE D ANJOU",
   cp: 49520,
   telephone: "241616639",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD PH ADMR",
   finessju: 850012444,
   id: 850009796,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85001,
   telephone: "251441157",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD PORNIC COTE DE JADE",
   finessju: 440051415,
   id: 440030468,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240822114",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SANTE LOIRE VALLEE DE L'AUTHION",
   finessju: 490535218,
   id: 490537594,
   commune: "LONGUE JUMELLES",
   cp: 49160,
   telephone: "241521647",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SANTE SERVICES CHOLETAIS",
   finessju: 490012184,
   id: 490532041,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241292183",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SAVENAY",
   finessju: 440000859,
   id: 440042133,
   commune: "SAVENAY",
   cp: 44260,
   telephone: "240575050",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SEVRE ET LOIRE",
   finessju: 440054047,
   id: 440033504,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "251717572",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SILLON ET LOIRE",
   finessju: 440018620,
   id: 440017846,
   commune: "COUERON",
   cp: 44220,
   telephone: "240851297",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SOINS ET MAINTIEN A DOMICILE",
   finessju: 490536919,
   id: 490532074,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241580142",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD SOINS SANTE",
   finessju: 490535663,
   id: 490532108,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241720612",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD TERRES DE MONTAIGU",
   finessju: 850026527,
   id: 850025685,
   commune: "MONTAIGU CEDEX",
   cp: 85602,
   telephone: "251941940",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD VAL DE MOINE",
   finessju: 490543006,
   id: 490543014,
   commune: "SEVREMOINE",
   cp: 49450,
   telephone: "241646768",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD VIE A DOMICILE",
   finessju: 490536554,
   id: 490532165,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241666800",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD VILLE DU MANS",
   finessju: 720009729,
   id: 720008655,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243724210",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIAD VIVRE A DOMICILE",
   finessju: 440004497,
   id: 440013423,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240513091",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SSIDPAH LOIRE",
   finessju: 440005833,
   id: 440028918,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240258281",
   categorie: "Service de Soins Infirmiers A Domicile (S.S.I.A.D)"
 },
 {
   finess: "FINESS",
   name: "SERVICE DEDIE AUX MASP",
   finessju: 850021460,
   id: 850018052,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "251447898",
   categorie: "Service dedie mesures d'accompagnement social personnalise"
 },
 {
   finess: "FINESS",
   name: "SERVICE MASP HELIANTHE",
   finessju: 720019967,
   id: 720019983,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243771755",
   categorie: "Service dedie mesures d'accompagnement social personnalise"
 },
 {
   finess: "FINESS",
   name: "OVE-SESSAD JEAN DURET",
   finessju: 690793435,
   id: 440040707,
   commune: "NANTES",
   cp: 44100,
   telephone: "240710843",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "S3AS 72",
   finessju: 720008762,
   id: 720014653,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243240579",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAAAIS",
   finessju: 850012436,
   id: 850022153,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "249022771",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAAAIS-SAFEP MONTECLAIR",
   finessju: 490535168,
   id: 490542693,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241733818",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAAAS APAJH 44",
   finessju: 440018612,
   id: 440029726,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251801150",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAFEP SAAAIS LES HTS THEBAUDIERES",
   finessju: 440002467,
   id: 440043172,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240733507",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAS HANDICAPS RARES",
   finessju: 440018612,
   id: 440053866,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "272010051",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAS HANDICAPS RARES",
   finessju: 750719239,
   id: 490020278,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241564919",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAS HANDICAPS RARES",
   finessju: 750720245,
   id: 530008861,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243532211",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SAS HANDICAPS RARES",
   finessju: 720008762,
   id: 720020809,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243247508",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SERDAA",
   finessju: 750720245,
   id: 530033034,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243532211",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD",
   finessju: 850012436,
   id: 850017930,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251511441",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD",
   finessju: 850012436,
   id: 850017948,
   commune: "LUCON",
   cp: 85400,
   telephone: "228140478",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD  TRIMARAN L ESCALE",
   finessju: 720009562,
   id: 720006915,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243185030",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD 16-25 ANS",
   finessju: 850012436,
   id: 850027509,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "244402027",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ADAPEI 49",
   finessju: 490535192,
   id: 490016177,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241892049",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ADAPEI 49",
   finessju: 490535192,
   id: 490540374,
   commune: "SEGRE CEDEX",
   cp: 49503,
   telephone: "241923100",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ADAPEI 49",
   finessju: 490535192,
   id: 490542180,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241719222",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ALEXIS RICORDEAU",
   finessju: 440018661,
   id: 440041184,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240834562",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APF",
   finessju: 750719239,
   id: 440023752,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240904367",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APF",
   finessju: 750719239,
   id: 440032043,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240202151",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APF",
   finessju: 750719239,
   id: 440053320,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240202151",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APF",
   finessju: 750719239,
   id: 490540580,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241969127",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APIC'S",
   finessju: 530031434,
   id: 530008044,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914806",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APIC'S 44",
   finessju: 440018380,
   id: 440050391,
   commune: "NANTES",
   cp: 44000,
   telephone: "240122290",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APIC'S 49",
   finessju: 490535192,
   id: 490017753,
   commune: "ANGERS CEDEX 01",
   cp: 49018,
   telephone: "241886327",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD APICS",
   finessju: 720009562,
   id: 720019132,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243254145",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD AREAMS",
   finessju: 850020413,
   id: 440051563,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "240780330",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD AREAMS",
   finessju: 850020413,
   id: 850006495,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251478364",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD AREAMS",
   finessju: 850020413,
   id: 850009754,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "251929952",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD AREAMS",
   finessju: 850020413,
   id: 850017914,
   commune: "BOUFFERE",
   cp: 85600,
   telephone: "251941076",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD AREAMS",
   finessju: 850020413,
   id: 850018102,
   commune: "LES SABLES D OLONNE",
   cp: 85100,
   telephone: "251958676",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD BAGNEUX",
   finessju: 490535192,
   id: 490016185,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241501046",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD CLEMENCE ROYER",
   finessju: 440018612,
   id: 440026581,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240220419",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD CRF",
   finessju: 750721334,
   id: 490016805,
   commune: "DOUE EN ANJOU",
   cp: 49700,
   telephone: "241405908",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DATE",
   finessju: 850012436,
   id: 850026071,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "251511441",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DATE LES GONDOLIERS",
   finessju: 850012436,
   id: 850025933,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "244402027",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DE BRIANCON",
   finessju: 920718459,
   id: 490007374,
   commune: "LOIRE AUTHION",
   cp: 49250,
   telephone: "241661188",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DE L'ARCHE",
   finessju: 720000454,
   id: 720014430,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243517202",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DI TC  LES CHESNAIES",
   finessju: 490536828,
   id: 490007630,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241211480",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DI-TC L'ARBORETUM",
   finessju: 490535184,
   id: 490537370,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241438184",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DI-TFC APAJH 44",
   finessju: 440018612,
   id: 440013456,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251801150",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DI-TSA",
   finessju: 850012436,
   id: 850025750,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "249022237",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DJINH",
   finessju: 530000850,
   id: 530007590,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243499765",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DU CENRO",
   finessju: 440000156,
   id: 440047785,
   commune: "VERTOU",
   cp: 44120,
   telephone: "240030301",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD DU VAL DE LOIR",
   finessju: 720009562,
   id: 720006410,
   commune: "LA FLECHE",
   cp: 72200,
   telephone: "243481130",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD GALILEE",
   finessju: 690793435,
   id: 850020421,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "686566972",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD GEIST 0-7 ANS",
   finessju: 530000850,
   id: 530007582,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243560059",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD GEIST 6-16 ANS",
   finessju: 530000850,
   id: 530030154,
   commune: "LAVAL CEDEX 9",
   cp: 53031,
   telephone: "243498990",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD HENRI WALLON",
   finessju: 590799730,
   id: 850019811,
   commune: "BELLEVIGNY",
   cp: 85170,
   telephone: "251248900",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ITEP ARRIA",
   finessju: 440001485,
   id: 440042232,
   commune: "ORVAULT",
   cp: 44700,
   telephone: "228252965",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ITEP LES PIROGUES",
   finessju: 440042844,
   id: 850016700,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251241400",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD JEUNES ADULTES",
   finessju: 850020413,
   id: 850018300,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "228977904",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440024008,
   commune: "GUERANDE",
   cp: 44350,
   telephone: "240701505",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD JEUNESSE ET AVENIR",
   finessju: 440000966,
   id: 440050516,
   commune: "NOZAY",
   cp: 44170,
   telephone: "240701505",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'ENVOL",
   finessju: 720008762,
   id: 720020429,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243247508",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'ENVOL - ECOMMOY",
   finessju: 490020310,
   id: 720006329,
   commune: "ECOMMOY",
   cp: 72220,
   telephone: "243532211",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'ENVOL - LE LUART",
   finessju: 490020310,
   id: 720020833,
   commune: "LE LUART",
   cp: 72390,
   telephone: "252202072",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'ENVOL - MAMERS",
   finessju: 490020310,
   id: 720021039,
   commune: "MAMERS",
   cp: 72600,
   telephone: "252202072",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'ENVOL LE MANS",
   finessju: 490020310,
   id: 720020841,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243811443",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'ESTUAIRE",
   finessju: 440041101,
   id: 440046787,
   commune: "ST BREVIN LES PINS",
   cp: 44250,
   telephone: "251747200",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD L'OISEAU BLEU",
   finessju: 720008390,
   id: 720016864,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243503255",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA CHALOUERE",
   finessju: 490535184,
   id: 490017001,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241438184",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA CHAUSSEE",
   finessju: 490020310,
   id: 490543113,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241250318",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA COURTE ECHELLE",
   finessju: 720008796,
   id: 720008077,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243414112",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA FLEURIAYE",
   finessju: 440018661,
   id: 440052835,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "240472020",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA GUERINIERE",
   finessju: 850012436,
   id: 850018649,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "249022237",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA MAILLARDIERE",
   finessju: 530031434,
   id: 530003284,
   commune: "CHATEAU GONTIER CEDEX",
   cp: 53202,
   telephone: "243091250",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA MAISONNETTE",
   finessju: 850012436,
   id: 850018664,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "249022237",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA PASSERELLE",
   finessju: 490535184,
   id: 490017555,
   commune: "ANGERS CEDEX 02",
   cp: 49017,
   telephone: "241439476",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA PERDRIERE",
   finessju: 530000256,
   id: 530002708,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914730",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA POCTIERE",
   finessju: 850012436,
   id: 850024811,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "251498461",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA TREMBLAIE",
   finessju: 490536836,
   id: 490544251,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241650485",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA TURMELIERE",
   finessju: 490020310,
   id: 440049930,
   commune: "VALLET",
   cp: 44330,
   telephone: "272342123",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA TURMELIERE",
   finessju: 490020310,
   id: 490017464,
   commune: "BEAUPREAU EN MAUGES",
   cp: 49600,
   telephone: "244098011",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LA TURMELIERE",
   finessju: 490020310,
   id: 490018686,
   commune: "ST GEORGES SUR LOIRE",
   cp: 49170,
   telephone: "253571020",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LAVAL",
   finessju: 530031434,
   id: 530005925,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914806",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LE COLOMBIER",
   finessju: 490534849,
   id: 490016599,
   commune: "ST BARTHELEMY D ANJOU CEDE",
   cp: 49181,
   telephone: "241185000",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LE FIL BLEU",
   finessju: 440041101,
   id: 440053072,
   commune: "ST JEAN DE BOISEAU",
   cp: 44640,
   telephone: "251747200",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LE GRACALOU",
   finessju: 490536828,
   id: 490019254,
   commune: "BOUCHEMAINE",
   cp: 49080,
   telephone: "241489494",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LE PETIT POUCET",
   finessju: 850012436,
   id: 850018656,
   commune: "LES HERBIERS",
   cp: 85500,
   telephone: "249022237",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LE SILLON DE BRETAGNE",
   finessju: 750720831,
   id: 440047777,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240768356",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LEON DOUDARD",
   finessju: 530033000,
   id: 530003276,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "243044023",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES BARBUSSI�RES",
   finessju: 440018380,
   id: 440026557,
   commune: "ST HILAIRE DE CHALEONS",
   cp: 44680,
   telephone: "240317373",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES FRIMOUSSES",
   finessju: 850012436,
   id: 850018631,
   commune: "LA GUYONNIERE",
   cp: 85600,
   telephone: "249022237",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES OLIVIERS",
   finessju: 490536836,
   id: 490015377,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241741580",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES PITCHOUNS",
   finessju: 440018661,
   id: 440053130,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240007534",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES SABLES",
   finessju: 490016979,
   id: 490016458,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "241797940",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES TILLEULS",
   finessju: 440018661,
   id: 440053338,
   commune: "NORT SUR ERDRE",
   cp: 44390,
   telephone: "240093380",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LES VENTS D'OUEST",
   finessju: 490020310,
   id: 490016250,
   commune: "OREE D ANJOU",
   cp: 49270,
   telephone: "652111045",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD LUCIEN DESMONTS",
   finessju: 440018398,
   id: 440032290,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240701652",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD MARGUERITE YOURCENAR",
   finessju: 490535184,
   id: 490537297,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241663040",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD MARIE MOREAU",
   finessju: 440001352,
   id: 440046340,
   commune: "ST NAZAIRE CEDEX",
   cp: 44612,
   telephone: "240701821",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD MOTEUR APAJH44",
   finessju: 440018612,
   id: 440029593,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251801150",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD NORD ADAPEI 44",
   finessju: 440018380,
   id: 440026524,
   commune: "BLAIN",
   cp: 44130,
   telephone: "228050025",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD NORD LES PERRI�RES",
   finessju: 440018380,
   id: 440026532,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240281523",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD PAYS D'OZ",
   finessju: 720008804,
   id: 720015395,
   commune: "SOLESMES",
   cp: 72300,
   telephone: "243921224",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD POLE NANTAIS - ANTENNE NORD",
   finessju: 440018380,
   id: 440040434,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251843860",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD POLE NANTAIS - ANTENNE SUD",
   finessju: 440018380,
   id: 440040723,
   commune: "NANTES",
   cp: 44200,
   telephone: "967089309",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD SAPFI",
   finessju: 720008762,
   id: 720019512,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243247508",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD SAUMUROIS",
   finessju: 490536828,
   id: 490019247,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "241533737",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD ST PHILBERT",
   finessju: 690793435,
   id: 440054021,
   commune: "ST PHILBERT DE GRAND LIEU",
   cp: 44310,
   telephone: "251780330",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD TDI-TSA ARRIA",
   finessju: 440001485,
   id: 440046357,
   commune: "COUERON",
   cp: 44220,
   telephone: "249109380",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD TRAIT D'UNION",
   finessju: 720008762,
   id: 720015346,
   commune: "ALLONNES",
   cp: 72700,
   telephone: "243805193",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD TRES PRECOCE",
   finessju: 490535168,
   id: 490019817,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241738719",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD TSA AREAMS",
   finessju: 850020413,
   id: 850017922,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "787367151",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD TSL SIRIUS",
   finessju: 720008762,
   id: 720016898,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243247508",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSAD VALLEE DE L'ANJOU",
   finessju: 490535200,
   id: 490016243,
   commune: "VERNANTES",
   cp: 49390,
   telephone: "241839960",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSD 16-25 ANS",
   finessju: 750719239,
   id: 490019791,
   commune: "ST BARTHELEMY D ANJOU",
   cp: 49124,
   telephone: "241969127",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSD APF",
   finessju: 750719239,
   id: 490019809,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241564919",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSD APF",
   finessju: 750719239,
   id: 530032820,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243669872",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SESSD PLATEFORME RESSOURCES",
   finessju: 750719239,
   id: 440053288,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240202451",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSAD TOURNESOL",
   finessju: 720009562,
   id: 720016666,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243470580",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFIS",
   finessju: 850012436,
   id: 850024787,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251626264",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFIS APAJH",
   finessju: 720008762,
   id: 530032085,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243538669",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFIS LONGUEUR D'ONDES",
   finessju: 720008762,
   id: 720014661,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243240579",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFIS SAFEP LA PERSAGOTIERE",
   finessju: 440000073,
   id: 440043164,
   commune: "NANTES CEDEX 2",
   cp: 44262,
   telephone: "240756315",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFIS-SAFEP CHARLOTTE BLOUIN",
   finessju: 490535168,
   id: 490538493,
   commune: "ANGERS CEDEX 02",
   cp: 49105,
   telephone: "241368585",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFS NANTAIS APAJH 44",
   finessju: 440018612,
   id: 440030112,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "251801150",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSEFS NAZAIRIEN APAJH44",
   finessju: 440018612,
   id: 440053767,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240223868",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "SSESD",
   finessju: 850012436,
   id: 850024779,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251414636",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "UEM AUTISME",
   finessju: 530031434,
   id: 530008622,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243914806",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "UEM AUTISME",
   finessju: 850012436,
   id: 850026139,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "211102027",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "UEM CHAMP MANON",
   finessju: 720009562,
   id: 720020403,
   commune: "YVRE L EVEQUE",
   cp: 72530,
   telephone: "243185030",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "UEM ECOLE MONTESQUIEU",
   finessju: 490535184,
   id: 490019742,
   commune: "ANGERS",
   cp: 49000,
   telephone: "241663040",
   categorie: "Service d'education Speciale et de Soins a Domicile"
 },
 {
   finess: "FINESS",
   name: "AIDE A LA GESTION DU BUDGET FAMI",
   finessju: 720008853,
   id: 720021328,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243284475",
   categorie: "Service delegue aux prestations familiales"
 },
 {
   finess: "FINESS",
   name: "GESTION DU BUDGET FAMILIAL",
   finessju: 530000801,
   id: 530007608,
   commune: "LAVAL CEDEX",
   cp: 53010,
   telephone: "243495252",
   categorie: "Service delegue aux prestations familiales"
 },
 {
   finess: "FINESS",
   name: "SERVICE DELEGUES AUX PRESTATIONS FAMIL",
   finessju: 850020413,
   id: 850018037,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85004,
   telephone: "-",
   categorie: "Service delegue aux prestations familiales"
 },
 {
   finess: "FINESS",
   name: "SERVICE DELEGUES AUX PRESTATIONS FAMIL",
   finessju: 850021460,
   id: 850018094,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "-",
   categorie: "Service delegue aux prestations familiales"
 },
 {
   finess: "FINESS",
   name: "SERVICE DPF UDAF ANGERS",
   finessju: 490017878,
   id: 490017886,
   commune: "ANGERS CEDEX 01",
   cp: 49003,
   telephone: "241365100",
   categorie: "Service delegue aux prestations familiales"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'ENQUETES SOCIALES",
   finessju: 440007573,
   id: 440029627,
   commune: "NANTES",
   cp: 44200,
   telephone: "240471201",
   categorie: "Service d'Enquetes Sociales (S.E.S.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'ENQUETES SOCIALES",
   finessju: 720008853,
   id: 720014943,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243284475",
   categorie: "Service d'Enquetes Sociales (S.E.S.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE ENQUETES ET MEDIATION",
   finessju: 440011567,
   id: 440011575,
   commune: "NANTES",
   cp: 44000,
   telephone: "-",
   categorie: "Service d'Enquetes Sociales (S.E.S.)"
 },
 {
   finess: "FINESS",
   name: "ESPACE FAMILLE",
   finessju: 440018737,
   id: 440040921,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240191114",
   categorie: "Service d'Enquetes Sociales (S.E.S.)"
 },
 {
   finess: "FINESS",
   name: "SCE D'ENQUETES SOCIALES ASSPE",
   finessju: 440007300,
   id: 440029635,
   commune: "NANTES",
   cp: 44200,
   telephone: "251729300",
   categorie: "Service d'Enquetes Sociales (S.E.S.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE AIDE AUX TUTEURS FAMILIAUX",
   finessju: 720019926,
   id: 720019942,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243614747",
   categorie: "Service d'information et de soutien aux tuteurs familiaux"
 },
 {
   finess: "FINESS",
   name: "SERVICE D'AIDE AUX TUTEURS FAMILIAUX",
   finessju: 850021460,
   id: 850018060,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "-",
   categorie: "Service d'information et de soutien aux tuteurs familiaux"
 },
 {
   finess: "FINESS",
   name: "SCE INVESTIG. ORIENT. ED. ADSEA",
   finessju: 850020413,
   id: 850012196,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85005,
   telephone: "251445070",
   categorie: "Service Investigation Orientation Educative (S.I.O.E.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D INVESTIGATION EDUCATIVE",
   finessju: 490534849,
   id: 490020609,
   commune: "ANGERS",
   cp: 49100,
   telephone: "241330020",
   categorie: "Service Investigation Orientation Educative (S.I.O.E.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE D INVESTIGATIONS EDUCATIVES 72",
   finessju: 720008853,
   id: 720014935,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243284475",
   categorie: "Service Investigation Orientation Educative (S.I.O.E.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE INVESTIGATION EDUC APPUI PENAL",
   finessju: 720008853,
   id: 530029370,
   commune: "LAVAL",
   cp: 53000,
   telephone: "243660155",
   categorie: "Service Investigation Orientation Educative (S.I.O.E.)"
 },
 {
   finess: "FINESS",
   name: "SERVICE MANDATAIRE JUDICIAIRE",
   finessju: 850012436,
   id: 850017989,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85036,
   telephone: "251473879",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MANDATAIRE JUDICIAIRE",
   finessju: 850017997,
   id: 850018003,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "251462317",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MANDATAIRE JUDICIAIRE",
   finessju: 850020413,
   id: 850018029,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85004,
   telephone: "251448528",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MANDATAIRE JUDICIAIRE",
   finessju: 850021460,
   id: 850018045,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85017,
   telephone: "251447898",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MANDATAIRE PROTECTION JURIDIQU",
   finessju: 720019926,
   id: 720019934,
   commune: "LE MANS",
   cp: 72100,
   telephone: "243614747",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MJPM",
   finessju: 530006899,
   id: 530006949,
   commune: "LAVAL CEDEX 9",
   cp: 53063,
   telephone: "243491337",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MJPM",
   finessju: 530000801,
   id: 530029552,
   commune: "LAVAL CEDEX",
   cp: 53010,
   telephone: "243495252",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MJPM ATADEM ANGERS",
   finessju: 490017902,
   id: 490017910,
   commune: "LES PONTS DE CE",
   cp: 49130,
   telephone: "241322697",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MJPM CITE JUSTICE CITOYEN",
   finessju: 490017936,
   id: 490017944,
   commune: "ANGERS CEDEX 01",
   cp: 49010,
   telephone: "241809225",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MJPM HELIANTHE",
   finessju: 720019967,
   id: 720019975,
   commune: "LE MANS CEDEX 2",
   cp: 72058,
   telephone: "243771755",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SERVICE MJPM UDAF ANGERS",
   finessju: 490017878,
   id: 490017894,
   commune: "ANGERS CEDEX 01",
   cp: 49003,
   telephone: "241365100",
   categorie: "Service mandataire judiciaire a la protection des majeurs"
 },
 {
   finess: "FINESS",
   name: "SCE MEDICO-PSYCHO.REGION. CHU NANTES",
   finessju: 440000289,
   id: 440025880,
   commune: "NANTES",
   cp: 44300,
   telephone: "-",
   categorie: "Service Medico-Psychologique Regional (S.M.P.R.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD A2S",
   finessju: 490535218,
   id: 490541679,
   commune: "ANGERS CEDEX 01",
   cp: 49010,
   telephone: "230260058",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ACAFPA",
   finessju: 530000967,
   id: 530008929,
   commune: "LE BOURGNEUF LA FORET",
   cp: 53410,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ADAP SADAPA",
   finessju: 850026543,
   id: 850026923,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ADMR FONDATION GEORGES COULON",
   finessju: 720012749,
   id: 720020999,
   commune: "LE GRAND LUCE",
   cp: 72150,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ADMR MIEUX VIVRE A DOMICILE",
   finessju: 720002369,
   id: 720020981,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ADMR VENDEE",
   finessju: 850012444,
   id: 850026931,
   commune: "LA ROCHE SUR YON CEDEX",
   cp: 85001,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ALTER EGO",
   finessju: 720002369,
   id: 720020973,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243720500",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD AMAD LITTORAL TALMONT LES SABLE",
   finessju: 850020330,
   id: 850026915,
   commune: "OLONNE SUR MER",
   cp: 85340,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD ANGERS MAINE ET LOIRE",
   finessju: 490536554,
   id: 490020625,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD CHEZ VOUS AUTREMENT",
   finessju: 490536919,
   id: 490020666,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241580142",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD DU NORD SARTHE",
   finessju: 720007244,
   id: 720021005,
   commune: "SILLE LE GUILLAUME",
   cp: 72140,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD DU SAUMUROIS",
   finessju: 490535168,
   id: 490020658,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "230260057",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD PAYS DE CRAON",
   finessju: 530000660,
   id: 530008911,
   commune: "CRAON",
   cp: 53400,
   telephone: "243989433",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD SOINS SANTE ASSADOM",
   finessju: 490535663,
   id: 490020567,
   commune: "ANGERS",
   cp: 49100,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD SUD ESTUAIRE",
   finessju: 440018620,
   id: 440054328,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SPASAD SUD VENDEE",
   finessju: 850026899,
   id: 850026907,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "SSIAD ADMR PROJET DE VIE",
   finessju: 720002369,
   id: 720017250,
   commune: "ST SATURNIN",
   cp: 72650,
   telephone: "243720500",
   categorie: "Service Polyvalent Aide et Soins A Domicile (S.P.A.S.A.D.)"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440004638,
   commune: "MONNIERES",
   cp: 44690,
   telephone: "240264216",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440008423,
   commune: "ST PERE EN RETZ",
   cp: 44320,
   telephone: "240217071",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440009876,
   commune: "PLESSE",
   cp: 44630,
   telephone: "240796042",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019180,
   commune: "ABBARETZ",
   cp: 44170,
   telephone: "240870013",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019198,
   commune: "VAIR SUR LOIRE",
   cp: 44150,
   telephone: "240830070",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019206,
   commune: "ASSERAC",
   cp: 44410,
   telephone: "240017000",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019214,
   commune: "AVESSAC",
   cp: 44460,
   telephone: "240910087",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019222,
   commune: "DIVATTE SUR LOIRE",
   cp: 44450,
   telephone: "240540206",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019230,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240804007",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019248,
   commune: "LA BERNERIE EN RETZ",
   cp: 44760,
   telephone: "240827056",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019255,
   commune: "BESNE",
   cp: 44160,
   telephone: "240013013",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019271,
   commune: "LA BOISSIERE DU DORE",
   cp: 44430,
   telephone: "240339606",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019289,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970316",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019297,
   commune: "BRAINS",
   cp: 44830,
   telephone: "240655130",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019305,
   commune: "BOUEE",
   cp: 44260,
   telephone: "240723284",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019339,
   commune: "CASSON",
   cp: 44390,
   telephone: "240777508",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019347,
   commune: "LE CELLIER",
   cp: 44850,
   telephone: "240254018",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019370,
   commune: "LA CHAPELLE GLAIN",
   cp: 44670,
   telephone: "240815223",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019396,
   commune: "LA CHAPELLE LAUNAY",
   cp: 44260,
   telephone: "240723305",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019412,
   commune: "BOUVRON",
   cp: 44130,
   telephone: "240563218",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019420,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240986257",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019453,
   commune: "CHATEAU THEBAUD",
   cp: 44690,
   telephone: "240548318",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019461,
   commune: "CHAUVE",
   cp: 44320,
   telephone: "240275412",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019479,
   commune: "CHEIX EN RETZ",
   cp: 44640,
   telephone: "240543101",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019487,
   commune: "CHAUMES EN RETZ",
   cp: 44320,
   telephone: "240213022",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019495,
   commune: "LA CHEVALLERAIS",
   cp: 44810,
   telephone: "240791012",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019529,
   commune: "CONQUEREUIL",
   cp: 44290,
   telephone: "240792206",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019545,
   commune: "CORSEPT",
   cp: 44560,
   telephone: "240275196",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019560,
   commune: "COUFFE",
   cp: 44521,
   telephone: "240830005",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019578,
   commune: "CROSSAC",
   cp: 44160,
   telephone: "240010285",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019594,
   commune: "DREFFEAC",
   cp: 44530,
   telephone: "240669040",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019602,
   commune: "ERBRAY",
   cp: 44110,
   telephone: "240811116",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019610,
   commune: "FEGREAC",
   cp: 44460,
   telephone: "240912021",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019628,
   commune: "FERCE",
   cp: 44660,
   telephone: "240288757",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019636,
   commune: "VILLENEUVE EN RETZ",
   cp: 44580,
   telephone: "240214132",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019644,
   commune: "DISTRE",
   cp: 49400,
   telephone: "240414125",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019669,
   commune: "GENESTON",
   cp: 44140,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019685,
   commune: "GORGES",
   cp: 44190,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019693,
   commune: "GRAND AUVERNE",
   cp: 44520,
   telephone: "240816212",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019701,
   commune: "GRANDCHAMPS DES FONTAINES",
   cp: 44119,
   telephone: "240777326",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019719,
   commune: "LA GRIGONNAIS",
   cp: 44170,
   telephone: "240794661",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019735,
   commune: "GUENROUET",
   cp: 44530,
   telephone: "240876418",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019750,
   commune: "HERIC",
   cp: 44810,
   telephone: "240779913",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019768,
   commune: "ISSE",
   cp: 44520,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019776,
   commune: "JANS",
   cp: 44170,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019784,
   commune: "JOUE SUR ERDRE",
   cp: 44440,
   telephone: "240722502",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019800,
   commune: "LAVAU SUR LOIRE",
   cp: 44260,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019818,
   commune: "LUSANGER",
   cp: 44590,
   telephone: "240817308",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019859,
   commune: "MALVILLE",
   cp: 44260,
   telephone: "240723251",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019867,
   commune: "LA MARNE",
   cp: 44270,
   telephone: "240023069",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019875,
   commune: "MARSAC SUR DON",
   cp: 44170,
   telephone: "240875477",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019883,
   commune: "MASSERAC",
   cp: 44290,
   telephone: "240792418",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019891,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240770357",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019917,
   commune: "LA MEILLERAYE DE BRETAGNE",
   cp: 44520,
   telephone: "240816006",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019925,
   commune: "MESANGER",
   cp: 44522,
   telephone: "240830109",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019933,
   commune: "MESQUER",
   cp: 44420,
   telephone: "240619115",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019982,
   commune: "MONTRELAIS",
   cp: 44370,
   telephone: "240834588",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440019990,
   commune: "LES MOUTIERS EN RETZ",
   cp: 44760,
   telephone: "240827205",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020006,
   commune: "MOUZEIL",
   cp: 44850,
   telephone: "240972361",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020022,
   commune: "NOTRE DAME DES LANDES",
   cp: 44130,
   telephone: "240572214",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020030,
   commune: "NOYAL SUR BRUTZ",
   cp: 44110,
   telephone: "240811623",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020055,
   commune: "OUDON",
   cp: 44521,
   telephone: "240836017",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020063,
   commune: "LE PALLET",
   cp: 44330,
   telephone: "240264024",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020071,
   commune: "PANNECE",
   cp: 44440,
   telephone: "240972107",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020089,
   commune: "PAULX",
   cp: 44270,
   telephone: "240260218",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020097,
   commune: "PETIT AUVERNE",
   cp: 44670,
   telephone: "240815003",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020113,
   commune: "LE PIN",
   cp: 44540,
   telephone: "240970254",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020121,
   commune: "PIRIAC SUR MER",
   cp: 44420,
   telephone: "240235019",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020139,
   commune: "LA PLAINE SUR MER",
   cp: 44770,
   telephone: "240215014",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020147,
   commune: "LA PLANCHE",
   cp: 44140,
   telephone: "240319287",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020154,
   commune: "MACHECOUL ST MEME",
   cp: 44270,
   telephone: "240785125",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020162,
   commune: "PORT ST PERE",
   cp: 44710,
   telephone: "240365013",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020170,
   commune: "POUILLE LES COTEAUX",
   cp: 44522,
   telephone: "240831956",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020188,
   commune: "PREFAILLES",
   cp: 44770,
   telephone: "240216037",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020196,
   commune: "PRINQUIAU",
   cp: 44260,
   telephone: "240566290",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020204,
   commune: "QUILLY",
   cp: 44750,
   telephone: "240881422",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020212,
   commune: "LA REMAUDIERE",
   cp: 44430,
   telephone: "240782847",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020220,
   commune: "REMOUILLE",
   cp: 44140,
   telephone: "240262220",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020238,
   commune: "RIAILLE",
   cp: 44440,
   telephone: "240978025",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020246,
   commune: "LA ROCHE BLANCHE",
   cp: 44522,
   telephone: "240830112",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020261,
   commune: "ROUGE",
   cp: 44660,
   telephone: "240288520",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020279,
   commune: "LOIREAUXENCE",
   cp: 44370,
   telephone: "240834170",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020287,
   commune: "SAFFRE",
   cp: 44390,
   telephone: "240772489",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020303,
   commune: "ST AIGNAN GRANDLIEU",
   cp: 44860,
   telephone: "240268597",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020329,
   commune: "ST AUBIN DES CHATEAUX",
   cp: 44110,
   telephone: "240812713",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020352,
   commune: "ST FIACRE SUR MAINE",
   cp: 44690,
   telephone: "240548112",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020360,
   commune: "ST GEREON",
   cp: 44150,
   telephone: "240830245",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020378,
   commune: "VAIR SUR LOIRE",
   cp: 44150,
   telephone: "240830108",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020386,
   commune: "ST HILAIRE DE CHALEONS",
   cp: 44680,
   telephone: "240544212",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020394,
   commune: "ST HILAIRE DE CLISSON",
   cp: 44190,
   telephone: "240780456",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020402,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240970214",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020428,
   commune: "ST VIAUD",
   cp: 44320,
   telephone: "240275314",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020436,
   commune: "ST LEGER LES VIGNES",
   cp: 44710,
   telephone: "240315032",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020444,
   commune: "ST VINCENT DES LANDES",
   cp: 44590,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020469,
   commune: "ST LUMINE DE COUTAIS",
   cp: 44310,
   telephone: "240787157",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020501,
   commune: "ST MARS DE COUTAIS",
   cp: 44680,
   telephone: "240315053",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020519,
   commune: "ST MARS DU DESERT",
   cp: 44850,
   telephone: "240296094",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020543,
   commune: "SEVERAC",
   cp: 44530,
   telephone: "240887156",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020550,
   commune: "SION LES MINES",
   cp: 44590,
   telephone: "240289521",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020568,
   commune: "SOUDAN",
   cp: 44110,
   telephone: "240812216",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020576,
   commune: "SOULVACHE",
   cp: 44660,
   telephone: "240288052",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020592,
   commune: "TEILLE",
   cp: 44440,
   telephone: "240972315",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020600,
   commune: "LE TEMPLE DE BRETAGNE",
   cp: 44360,
   telephone: "240868962",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020626,
   commune: "LES TOUCHES",
   cp: 44390,
   telephone: "240722205",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020642,
   commune: "TRANS SUR ERDRE",
   cp: 44440,
   telephone: "240972253",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020659,
   commune: "TREFFIEUX",
   cp: 44170,
   telephone: "240794818",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020691,
   commune: "VAY",
   cp: 44170,
   telephone: "240794718",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020725,
   commune: "VIGNEUX DE BRETAGNE",
   cp: 44360,
   telephone: "240868218",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020733,
   commune: "VILLEPOT",
   cp: 44110,
   telephone: "240812620",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020741,
   commune: "ST MARS LA JAILLE",
   cp: 44540,
   telephone: "240927309",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020758,
   commune: "VUE",
   cp: 44640,
   telephone: "240213164",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020915,
   commune: "FROSSAY",
   cp: 44320,
   telephone: "240213214",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR",
   finessju: 440023620,
   id: 440020931,
   commune: "LA LIMOUZINIERE",
   cp: 44310,
   telephone: "240058282",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR DE DOULON",
   finessju: 440023620,
   id: 440021053,
   commune: "NANTES",
   cp: 44300,
   telephone: "240499503",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR DU RANZAI",
   finessju: 440023620,
   id: 440028959,
   commune: "NANTES",
   cp: 44000,
   telephone: "240930922",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR JONCOURS",
   finessju: 440023620,
   id: 440024222,
   commune: "NANTES",
   cp: 44000,
   telephone: "240463059",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR LA BARBERIE",
   finessju: 440019040,
   id: 440021004,
   commune: "NANTES",
   cp: 44300,
   telephone: "240761206",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR MANUFACTURE",
   finessju: 440023620,
   id: 440021061,
   commune: "NANTES",
   cp: 44000,
   telephone: "240742100",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR P.M.I.",
   finessju: 440023620,
   id: 440019354,
   commune: "LOUISFERT",
   cp: 44110,
   telephone: "-",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR TH.GERICAULT",
   finessju: 440023620,
   id: 440024297,
   commune: "NANTES",
   cp: 44000,
   telephone: "240430042",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE SECTEUR/STE-MARIE-S/M",
   finessju: 440023620,
   id: 440020493,
   commune: "PORNIC",
   cp: 44210,
   telephone: "240820601",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "CTRE MEDICO-SOC. LES CORDELIERS",
   finessju: 490535697,
   id: 490530854,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241623787",
   categorie: "Service Social Polyvalent de Secteur"
 },
 {
   finess: "FINESS",
   name: "COMITE PROB.ET ASSIST.LIBERES",
   finessju: 750810798,
   id: 440026250,
   commune: "NANTES",
   cp: 44000,
   telephone: "240202420",
   categorie: "Service Social Specialise ou Polyvalent de Categorie"
 },
 {
   finess: "FINESS",
   name: "COMITE PROB.ET ASSIST.LIBERES",
   finessju: 750810798,
   id: 440026268,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "240019920",
   categorie: "Service Social Specialise ou Polyvalent de Categorie"
 },
 {
   finess: "FINESS",
   name: "CRIFO",
   finessju: 440047876,
   id: 440047884,
   commune: "NANTES CEDEX 1",
   cp: 44015,
   telephone: "251727292",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "SERV TUTELLE PRESTATIONS",
   finessju: 720009794,
   id: 720004852,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243849760",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "SERV TUTELLE PRESTATIONS",
   finessju: 720009802,
   id: 720004860,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243849710",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "SERVICE TUTELLE PRESTATIONS",
   finessju: 720009729,
   id: 720004886,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243284709",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "SERVICE TUTELLE PRESTATIONS",
   finessju: 720008762,
   id: 720004894,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243247508",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "SERVICE TUTELLE PRESTATIONS",
   finessju: 720009562,
   id: 720004902,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243289869",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "UDAF 44",
   finessju: 440047835,
   id: 440047850,
   commune: "NANTES CEDEX 4",
   cp: 44105,
   telephone: "251803000",
   categorie: "Service Tutelle Prestation Sociale"
 },
 {
   finess: "FINESS",
   name: "ACTIVITES DE DIALYSE-MONTFORT",
   finessju: 440002590,
   id: 440005080,
   commune: "NANTES CEDEX 2",
   cp: 44202,
   telephone: "240328524",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "AUTODIALYSE ECHO SAISONNIER LA TRANCHE",
   finessju: 440002590,
   id: 850009762,
   commune: "LA TRANCHE SUR MER",
   cp: 85360,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "AUTODIALYSE ET DIALYSE MEDICALISEE",
   finessju: 440002590,
   id: 440027589,
   commune: "CHATEAUBRIANT",
   cp: 44110,
   telephone: "240076128",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE ECHO CHALLANS",
   finessju: 440002590,
   id: 850011271,
   commune: "CHALLANS",
   cp: 85300,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE LA FERTE BERNARD",
   finessju: 440002590,
   id: 720017755,
   commune: "LA FERTE BERNARD",
   cp: 72400,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-ANCENIS",
   finessju: 440002590,
   id: 440036218,
   commune: "ANCENIS CEDEX",
   cp: 44156,
   telephone: "240094415",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-BICHAT",
   finessju: 440002590,
   id: 720016690,
   commune: "LE MANS",
   cp: 72000,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-BOUGUENAIS",
   finessju: 440002590,
   id: 440036473,
   commune: "BOUGUENAIS",
   cp: 44340,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-CARQUEFOU",
   finessju: 440002590,
   id: 440042513,
   commune: "CARQUEFOU",
   cp: 44470,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-CH MAMERS",
   finessju: 440002590,
   id: 720017235,
   commune: "MAMERS",
   cp: 72600,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-FERRY LAVAL",
   finessju: 440002590,
   id: 530006188,
   commune: "LAVAL",
   cp: 53000,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-LESCURE",
   finessju: 440002590,
   id: 490008224,
   commune: "CHOLET",
   cp: 49300,
   telephone: "241584360",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE AUTODIALYSE-MANSION",
   finessju: 440002590,
   id: 490011350,
   commune: "ANGERS",
   cp: 49000,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE D'AUTODIALYSE ECHO SAUMUR",
   finessju: 440002590,
   id: 490016235,
   commune: "SAUMUR",
   cp: 49400,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE DIALYSE ECHO ILE D'YEU",
   finessju: 440002590,
   id: 850022047,
   commune: "L ILE D YEU",
   cp: 85350,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE DE DIALYSE FONTENAY LE COMTE",
   finessju: 440002590,
   id: 850023151,
   commune: "FONTENAY LE COMTE",
   cp: 85200,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "CENTRE DIALYSE ECHO SITE CH NORD MAYEN",
   finessju: 440002590,
   id: 530008788,
   commune: "MAYENNE",
   cp: 53100,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "DIAVERUM CHATEAU GONTIER",
   finessju: 490011384,
   id: 530008119,
   commune: "CHATEAU GONTIER",
   cp: 53200,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "STRUCTURE HEMODIALYSE-KEPLER",
   finessju: 440002590,
   id: 850009333,
   commune: "LA ROCHE SUR YON",
   cp: 85000,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "STRUCTURE HEMODIALYSE-LA BAULE",
   finessju: 440002590,
   id: 440039568,
   commune: "LA BAULE",
   cp: 44500,
   telephone: "240198391",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "UAD-UDM CAP SANTE ST NAZAIRE",
   finessju: 440002590,
   id: 440050441,
   commune: "ST NAZAIRE",
   cp: 44600,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "UNIT AUTODIALYSE-UDM SABLE SUR SARTHE",
   finessju: 440002590,
   id: 720016831,
   commune: "SABLE SUR SARTHE",
   cp: 72300,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "UNITE DE DIALYSE MEDICALISEE TRELAZE",
   finessju: 440002590,
   id: 490017613,
   commune: "TRELAZE",
   cp: 49800,
   telephone: "-",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "UNITES UDM ET UAD ECHO CH CHOLET",
   finessju: 440002590,
   id: 490020963,
   commune: "CHOLET",
   cp: 49300,
   telephone: "272004072",
   categorie: "Structure d'Alternative a la dialyse en centre"
 },
 {
   finess: "FINESS",
   name: "AIR SANTE",
   finessju: 490020328,
   id: 490020385,
   commune: "AVRILLE",
   cp: 49240,
   telephone: "241873353",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ALCURA",
   finessju: 360000889,
   id: 490020393,
   commune: "STE GEMMES SUR LOIRE",
   cp: 49130,
   telephone: "970810088",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ALCURA",
   finessju: 360000889,
   id: 720020940,
   commune: "LE MANS",
   cp: 72000,
   telephone: "970810088",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ALISEO",
   finessju: 440054112,
   id: 440054278,
   commune: "ORVAULT CEDEX",
   cp: 44702,
   telephone: "969397530",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ALISEO",
   finessju: 440054112,
   id: 490020401,
   commune: "BEAUCOUZE CEDEX",
   cp: 49071,
   telephone: "969397530",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ALISEO",
   finessju: 440054112,
   id: 720020965,
   commune: "LE MANS",
   cp: 72000,
   telephone: "969397530",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ALISEO",
   finessju: 440054112,
   id: 850026741,
   commune: "AUBIGNY LES CLOUZEAUX",
   cp: 85430,
   telephone: "969397530",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ATLAS MEDICAL",
   finessju: 490020575,
   id: 490020583,
   commune: "LONGUENEE EN ANJOU",
   cp: 49770,
   telephone: "241279808",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ATOUT MEDICAL",
   finessju: 350052080,
   id: 440054807,
   commune: "MALVILLE",
   cp: 44260,
   telephone: "240957102",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ATOUT MEDICAL",
   finessju: 350052080,
   id: 530008895,
   commune: "CHANGE",
   cp: 53810,
   telephone: "243599190",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "HANDI-PHARM ATLANTIQUE",
   finessju: 440054120,
   id: 440054286,
   commune: "HAUTE GOULAINE",
   cp: 44115,
   telephone: "251380000",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "HANDI-PHARM OCEAN",
   finessju: 850026667,
   id: 850026758,
   commune: "CHANTONNAY",
   cp: 85110,
   telephone: "251943182",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "HUMANAIR MEDICAL",
   finessju: 440054138,
   id: 440054294,
   commune: "COUERON",
   cp: 44220,
   telephone: "240053686",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ISIS MEDICAL",
   finessju: 440054146,
   id: 440054302,
   commune: "LE LOROUX BOTTEREAU",
   cp: 44430,
   telephone: "251794120",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "IXAIR MEDICAL",
   finessju: 500023197,
   id: 440055812,
   commune: "LE CELLIER",
   cp: 44850,
   telephone: "810003716",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LA GALERIE MEDICALE",
   finessju: 490009164,
   id: 490020419,
   commune: "BAUGE EN ANJOU",
   cp: 49150,
   telephone: "241891531",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LINDE HOMECARE FRANCE",
   finessju: 690039946,
   id: 720020908,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "243571780",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LINDE HOMECARE FRANCE THOUARE",
   finessju: 690039946,
   id: 440054211,
   commune: "THOUARE SUR LOIRE",
   cp: 44470,
   telephone: "251859090",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LVL MEDICAL",
   finessju: 440054153,
   id: 440054310,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "251891414",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LVL MEDICAL",
   finessju: 440054153,
   id: 490020427,
   commune: "DURTAL",
   cp: 49430,
   telephone: "241051673",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LVL MEDICAL",
   finessju: 440054153,
   id: 850026766,
   commune: "LE POIRE SUR VIE",
   cp: 85170,
   telephone: "251089970",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "M.A.D OUEST",
   finessju: 220023881,
   id: 850027020,
   commune: "AIZENAY",
   cp: 85190,
   telephone: "251311530",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ORKYN'",
   finessju: 940020787,
   id: 440054237,
   commune: "BASSE GOULAINE",
   cp: 44115,
   telephone: "240347728",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ORKYN'",
   finessju: 940020787,
   id: 720020916,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243391660",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "ORKYN' BEAUCOUZE",
   finessju: 940020787,
   id: 490020450,
   commune: "BEAUCOUZE",
   cp: 49070,
   telephone: "184900954",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "OXYPHARM",
   finessju: 760011429,
   id: 440054245,
   commune: "LES SORINIERES",
   cp: 44840,
   telephone: "228091270",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "PHARMAREVA",
   finessju: 850026675,
   id: 850026774,
   commune: "TREIZE SEPTIERS",
   cp: 85600,
   telephone: "251417171",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "S2A OXYGENE",
   finessju: 310027180,
   id: 490020377,
   commune: "ST LAMBERT LA POTHERIE",
   cp: 49070,
   telephone: "241236283",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "SARL VYANA MEDICAL",
   finessju: 560027435,
   id: 440054674,
   commune: "CHATEAU THEBAUD",
   cp: 44690,
   telephone: "228018170",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "SAS ELIVIE",
   finessju: 690039995,
   id: 440054385,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "763781366",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "SOS OXYGENE PAYS DE LA LOIRE",
   finessju: 440054161,
   id: 440054252,
   commune: "ST HERBLAIN",
   cp: 44800,
   telephone: "240595041",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "SOS OXYGENE PAYS DE LOIRE NORD",
   finessju: 720020858,
   id: 720020957,
   commune: "MULSANNE",
   cp: 72230,
   telephone: "243408515",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "SOS OXYGNE SUD LOIRE",
   finessju: 850026683,
   id: 850026733,
   commune: "LUCON",
   cp: 85400,
   telephone: "249071130",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "VITALAIRE",
   finessju: 750058414,
   id: 440054260,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "969369004",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "VITALAIRE",
   finessju: 750058414,
   id: 720020924,
   commune: "LE MANS",
   cp: 72000,
   telephone: "243789199",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "VIVISOL FRANCE SARL",
   finessju: 770021707,
   id: 440055861,
   commune: "TREILLIERES",
   cp: 44119,
   telephone: "240140309",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "VIVRADOM",
   finessju: 850026691,
   id: 850026782,
   commune: "MOUTIERS LES MAUXFAITS",
   cp: 85540,
   telephone: "251315757",
   categorie: "Structure Dispensatrice a domicile d'Oxyg�ne a usage medical"
 },
 {
   finess: "FINESS",
   name: "LINDE MEDICAL DOMICILE",
   finessju: 800017709,
   id: 720019603,
   commune: "LA CHAPELLE ST AUBIN",
   cp: 72650,
   telephone: "-",
   categorie: "Traitements Specialises a Domicile"
 },
 {
   finess: "FINESS",
   name: "LVL MEDICAL OUEST NANTES",
   finessju: 690004809,
   id: 440045649,
   commune: "NANTES CEDEX 3",
   cp: 44301,
   telephone: "-",
   categorie: "Traitements Specialises a Domicile"
 },
 {
   finess: "FINESS",
   name: "UEROS",
   finessju: 490535168,
   id: 440040400,
   commune: "ST SEBASTIEN SUR LOIRE",
   cp: 44230,
   telephone: "251790184",
   categorie: "Unites Evaluation Reentra�nement et d'Orient. Soc. et Pro."
 },
 {
   finess: "FINESS",
   name: "UEROS ARCEAU ANJOU",
   finessju: 490535168,
   id: 490008737,
   commune: "ANGERS CEDEX 02",
   cp: 49105,
   telephone: "241318602",
   categorie: "Unites Evaluation Reentra�nement et d'Orient. Soc. et Pro."
 },
 {
   finess: "FINESS",
   name: "SOS VILLAGE D'ENFANTS STE LUCE",
   finessju: 750801318,
   id: 440043784,
   commune: "STE LUCE SUR LOIRE",
   cp: 44980,
   telephone: "240188350",
   categorie: "Village d'Enfants"
 }
    ];
    return {users: {
      total: users.length,
      results: users
    }};
  }
}